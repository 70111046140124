import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import customFunctions from '../../../../helper/customFunctions';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../../components/Pagination';
import swal from 'sweetalert';

class LineItemsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineItems: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'DOCUMENT CREATED',
        field: 'document',
        width: 200,
      },
      {
        label: 'INVOICE NUMBER',
        field: 'invoiceno',
        width: 150,
      },
      {
        label: 'STATUS',
        field: 'status',
        width: 150,
      },
      {
        label: 'TYPE',
        field: 'type',
        width: 150,
      },
      {
        label: 'LINEITEM TITLE',
        field: 'lineitemtitle',
        width: 150,
      },
      {
        label: 'AMOUNT',
        field: 'amount',
        width: 150,
      },
      {
        label: 'VIEW INVOICE',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getLineItems();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        { startDate: props.startDate, endDate: props.endDate },
        () => {
          this.getLineItems();
        }
      );
    }
  }
  getLineItems = async (currentPage) => {
    let { pagination, startDate, endDate } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.getInvoice}?skip=${skip}&limit=${pagination.itemsPerPage}&start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          lineItems: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.props.callback();
  };

  downloadCSVForLineItems = async () => {
    const { startDate, endDate } = this.state;
    let endPoint = `${Api.lineItemDocumentCsvUrl}?skip=0&limit=10000&start_date=${startDate}&end_date=${endDate}`;

    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { lineItems, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    lineItems &&
      lineItems.length &&
      lineItems.map((lineitem, i) => {
        let createdDate =
          lineitem.issue_date &&
          customFunctions.getMDYDateTime(lineitem.issue_date);
        rows.push({
          index: (page - 1) * limit + (i + 1),
          document: createdDate || '-',
          invoiceno: lineitem.InvoiceCode || '-',
          status: lineitem.job_status || '-',
          type: lineitem.order_type || '-',
          lineitemtitle:
            lineitem && lineitem.items && lineitem.items.length > 0
              ? lineitem.items[0].item_name
              : '-',
          amount: lineitem.total || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/invoiceDetail/${lineitem.invoice_id}`,
                    {
                      orderDetails: lineitem,
                      type: 'lineItems',
                    }
                  );
                }}
              >
                <i
                  className="mdi mdi-eye"
                  id="viewJob"
                  title="View Line Item"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return lineItems;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light float-right"
              onClick={() => {
                this.downloadCSVForLineItems();
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getLineItems(pageNo);
          },
        }}
        tableClass="table-bordered"
        dropdownContainerClass="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="350px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getLineItems}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(LineItemsTables);

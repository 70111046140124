import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

class AddListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listItem: '',
      listItemErr: '',
      listItemId: '',
      ListTypeErr: '',
      ListData: [],
      ListTypeId: null,
      ListTypeName: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-listitem',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getListItem();
      customFunctions.setTitle('Edit List Item');
      this.setState({
        title: `Edit List Item`,
      });
    } else {
      customFunctions.setTitle('Add List Item');
      this.setState({
        title: `Add List Type`,
      });
    }
  };

  addListItem = async () => {
    const { listItem, ListTypeId, listItemId, status } = this.state;
    const postData = {
      list_item: listItem,
      type_id: ListTypeId && ListTypeId.toString(),
    };
    const endPoint = `${Api.AddListItemUrl}`;
    const callback = listItemId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (listItemId) {
      postData.id = parseInt(listItemId);
      postData.status = status;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (listItemId) {
        swal('List item updated successfully', '', 'success').then(() => {
          $this.props.history.push('/listitem', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('List item added successfully', '', 'success').then(() => {
          $this.props.history.push('/listitem');
        });
      }
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getListItem = async () => {
    const ListItemData = this.props.location.state.detail;
    this.setState({
      listItemId: ListItemData.ID,
      listItem: ListItemData.ListItem,
      ListTypeId: ListItemData.type_id,
      ListTypeName: { name: ListItemData.ListType },
      status: ListItemData.status,
    });
  };

  validateForm = (e) => {
    e.preventDefault();
    let ListTypeErr = '';
    let listItemErr = '';
    let isValid = true;
    const { ListTypeId, listItem, listItemId } = this.state;
    if (!listItemId) {
      if (String(ListTypeId).trim() === '') {
        ListTypeErr = 'List type is required';
        isValid = false;
      }
    }
    if (listItem.trim() === '') {
      listItemErr = 'List item is required';
      isValid = false;
    }
    this.setState({ listItemErr, ListTypeErr });
    if (isValid) {
      this.addListItem();
    }
  };

  render() {
    const {
      title,
      listItem,
      listItemErr,
      ListTypeErr,
      listItemId,
      ListTypeName,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-1 col-form-label"
                        >
                          List Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState({
                                ListTypeId: e ? e.ID : null,
                                ListTypeName: e ? { name: e.ListType } : '',
                              });
                            }}
                            isSearch={true}
                            disabled={listItemId ? true : false}
                            value={ListTypeName}
                            isClearable={ListTypeName}
                            apiname="listtype"
                            endpoint={Api.AddListTypeUrl + `?search=`}
                            selectPlaceholder="Select list type"
                          />
                          <span id="err">{ListTypeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-1 col-form-label"
                        >
                          List Item<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            value={listItem}
                            placeholder="listitem"
                            onChange={(e) => {
                              const re = /^[A-Za-z\s]+$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              )
                                this.setState({ listItem: e.target.value });
                            }}
                          />
                          <span id="err">{listItemErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-2" />
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/listitem', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddListItem);

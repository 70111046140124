import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import {
  SetCustomer,
  SetFromDate,
  SetMonth,
  SetMonthYear,
  SetOrderType,
  SetPaymentType,
  SetTechnician,
  SetToDate,
  SetVendor,
  SetWarrantyCode,
  SetWorkComplete,
  SetYear,
  SetSearchType,
  SetFilterType,
} from '../../../action/user.action';
import Loader from '../../../components/Loader';
import Table from '../../../components/Table/simpletable.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import Filter from '../Workorder/AddFilter';
import HelmetComponent from '../Title/index';

const columns = [
  {
    label: 'Sr. No.',
    id: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Vendor ID',
    id: 'vendorid',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Recall',
    id: 'recall',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Dispatch #',
    id: 'workorder',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'Job Title',
    id: 'jobtitle',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'H/O Name',
    id: 'ownerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Invoice',
    id: 'invoice',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date Invoice Paid',
    id: 'invoiceDate',
    sort: 'disabled',
    width: 180,
  },
  // {
  //   label: '1st Appt Date',
  //   id: 'FirstApptDate',
  //   sort: 'disabled',
  //   width: 120,
  // },
  {
    label: 'Job Materials List',
    id: 'materialList',
    sort: 'disabled',
    width: 150,
  },
  // {
  //   label: 'Date Recieved',
  //   id: 'dateRecieved',
  //   sort: 'disabled',
  //   width: 120,
  // },
  // {
  //   label: 'Date Completed',
  //   id: 'completeDate',
  //   sort: 'disabled',
  //   width: 140,
  // },
  // {
  //   label: 'Dispatch Date',
  //   id: 'workOrderDate',
  //   sort: 'disabled',
  //   width: 120,
  // },
  // {
  //   label: 'Invoice Number',
  //   id: 'invoiceid',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'What Was Done',
  //   id: 'whatwasdone',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Labor to Date',
  //   id: 'labortodate',
  //   sort: 'disabled',
  //   width: 120,
  // },
  // {
  //   label: 'Labor Total',
  //   id: 'labortotal',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Part Cost',
  //   id: 'partcost',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Autho Amt',
  //   id: 'authoAmount',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Autho #',
  //   id: 'authoNumber',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Est AVG COST PER CLAIM',
  //   id: 'estAvgCost',
  //   sort: 'disabled',
  //   width: 200,
  // },
  // {
  //   label: 'True AVG COST PER CLAIM',
  //   id: 'trueAvgCost',
  //   sort: 'disabled',
  //   width: 205,
  // },
  {
    label: 'Action',
    id: 'actions',
    sort: 'disabled',
    width: 80,
  },
];

class PendingInstallation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      orders: [],
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      orderId: null,
      showDelete: false,
      id: null,
      type: '',
      viewType: false,
      viewForm: false,
      showFilter: false,
      technician: '',
      warantyCode: '',
      roleId: null,
      login_id: null,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'ahsworkorders-accessahsworkorders'
    );
    if (this.props.searchType !== 'pendingInstallation') {
      await this.onCancelBtn('pendingInstallation');
      await SetSearchType('pendingInstallation');
    }
    var component =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromComponent;

    if (
      (!isAllowed && this.state.roleId === 4) ||
      (this.state.roleId === 4 && component !== 'profile')
    ) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('Pending Installation');
    const id = await this.props.match.params.customerId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          login_id: userdata.login_id,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        () => {
          this.getWorkOrder();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, login_id: userdata.login_id },
        () => {
          this.getWorkOrder();
        }
      );
    }

    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('orderDetails')) {
      this.setState({ viewType: true });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type
    ) {
      this.setState({ type: this.props.location.state.type });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      this.setState({
        orderDetails: await this.props.location.state.orderDetails,
      });
    }
    await this.setState({ id });
  };

  downloadCSV = async () => {
    const { roleId, login_id, keyword } = this.state;

    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;
    let endPoint = `${Api.downloadcsvAhsUrl}?search=${keyword}&isAuthRequired=0&pausedreason=3`;

    endPoint +=
      (roleId == 5 || roleId == 6 || roleId == 3) && login_id
        ? `&technician=${login_id}`
        : '';
    endPoint += roleId == 4 && login_id ? `&customer=${login_id}` : '';

    endPoint +=
      technician && technician.id ? `&technician=${technician.id}` : '';
    endPoint += customer && customer.id ? `&customer=${customer.id}` : '';
    endPoint +=
      orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    endPoint +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    endPoint +=
      WorkCompleteValue && WorkCompleteValue.id
        ? `&work_complete=${WorkCompleteValue.id}`
        : '';
    endPoint += vendorid && vendorid.id ? `&vendorid=${vendorid.id}` : '';

    endPoint += !month && !year && fromDate ? `&from_date=${fromDate}` : '';
    endPoint += !month && !year && toDate ? `&to_date=${toDate}` : '';
    endPoint += month && monthyear ? `&month=${monthyear}-${month}` : '';
    endPoint += year ? `&year=${year}` : '';

    // const { data } = await AuthApi.getDataFromServer(endPoint);
    // if (data) {
    //   // this.setState({
    //   //   c: data.data.data,
    //   // });

    // }
    window.open(endPoint, '_blank');
  };

  getWorkOrder = async (currentPage) => {
    const {
      roleId,
      login_id,
      limit,
      keyword,
      navSkip,
      navCurrentPage,
      pagination,
    } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip
      ? navSkip
      : page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&search=${keyword}&isAuthRequired=0&pausedreason=3`;
    // let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${limit}&search=${keyword}&approval=pending`;
    if ((roleId === 5 || roleId == 6 || roleId == 3) && login_id) {
      endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&search=${keyword}&technician=${login_id}&isAuthRequired=0&pausedreason=3`;
      // endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${limit}&technician=${login_id}&approval=pending`;
    }
    if (roleId === 4 && login_id) {
      endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&search=${keyword}&customer=${login_id}&isAuthRequired=0&pausedreason=3`;
      // endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${limit}&customer=${login_id}&approval=pending`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };

      this.setState(
        {
          isShowLoader: false,
          orders: data.data.data,
          currentPage: page,
          pagination: newPagination,
          // totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onFilterSearch = async (fPage) => {
    const { roleId, login_id, pagination } = this.state;
    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;

    if (fPage == 'clear') {
      this.setState({ currentPage: 1 });
      var currentPage = 1;
    } else {
      this.setState({ currentPage: fPage });
    }

    const page = fPage === 'clear' ? currentPage || 1 : fPage;
    // const skip = page * limit - limit;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    var endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&isAuthRequired=0&pausedreason=3&work_status=Paused`;
    var str = '';
    str +=
      (roleId == 5 || roleId == 6 || roleId == 3) && login_id
        ? `&technician=${login_id}`
        : '';
    str += roleId == 4 && login_id ? `&customer=${login_id}` : '';

    str += technician && technician.id ? `&technician=${technician.id}` : '';
    str += customer && customer.id ? `&customer=${customer.id}` : '';
    str += orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    str +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    str +=
      WorkCompleteValue && WorkCompleteValue.id
        ? `&work_complete=${WorkCompleteValue.id}`
        : '';
    str += vendorid && vendorid.id ? `&vendorid=${vendorid.id}` : '';

    str += !month && !year && fromDate ? `&from_date=${fromDate}` : '';
    str += !month && !year && toDate ? `&to_date=${toDate}` : '';
    str += month && monthyear ? `&month=${monthyear}-${month}` : '';
    str += year ? `&year=${year}` : '';

    const { data } = await AuthApi.getDataFromServer(endPoint + str);
    if (data && data.data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };

      this.setState(
        {
          orders: data.data.data,
          totalCount: data.data.total_count,
          pagination: newPagination,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { orders, limit, roleId } = this.state;
    const page = npage;
    const rows = [];
    orders &&
      orders.length &&
      orders.map((orders, i) => {
        orders.Created =
          orders.Created &&
          customFunctions.changeDateFormatMomentMDY(orders.Created);
        orders.OrderCompleteDate =
          orders.OrderCompleteDate &&
          customFunctions.changeDateFormatMomentMDY(orders.OrderCompleteDate);
        orders.labortodate =
          orders.labortodate &&
          customFunctions.changeDateFormatMomentMDY(orders.labortodate);
        orders.WorkOrderDate =
          orders.WorkOrderDate &&
          customFunctions.changeDateFormatMomentMDY(orders.WorkOrderDate);
        orders.FirstApptDate =
          orders.FirstApptDate &&
          customFunctions.changeDateFormatMomentMDY(orders.FirstApptDate);
        orders.invoiceDate =
          orders.invoiceDate &&
          customFunctions.changeDateFormatMomentMDY(orders.invoiceDate);
        let invoiceDate = '';
        let materialList = '';
        let invoice = '';
        orders.invdata &&
          orders.invdata.map((inv, i) => {
            invoiceDate = inv.invoice_updatedDate
              ? inv.invoice_updatedDate
              : '-';
            invoice = inv.InvoiceCode ? inv.InvoiceCode : '-';
            materialList = inv.material_list ? inv.material_list : '-';
            return inv;
          });
        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: (
            <React.Fragment>
              <a
                style={{ color: '#034fa1' }}
                href="/"
                title="view order"
                className=""
                disabled={orders.WorkOrder ? orders.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    path: '/pending-installation',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {orders.WorkOrder || '-'}
              </a>
            </React.Fragment>
          ),
          vendorid: orders.refid || '-',
          recall: orders.recall ? 'Yes' : 'No',
          ownerName: orders.CustomerName || '-',
          dateRecieved: orders.Created || '-',
          completeDate: orders.OrderCompleteDate || '-',
          workOrderDate: orders.WorkOrderDate || '-',
          invoiceid: orders.InvoiceCode || '-',
          whatwasdone: orders.parts || '-',
          labortodate: orders.labortodate || '-',
          labortotal: orders.labortotal || '-',
          partcost: orders.partcost || '-',
          authoAmount: orders.AuthoAmount || '-',
          jobtitle: orders.jobTitle || '-',
          authoNumber: orders.AuthoNumber || '-',
          estAvgCost: orders.estAvgCost || '-',
          trueAvgCost: orders.trueAvgCost || '-',
          invoice: invoice || '-',
          invoiceDate: invoiceDate || '-',
          FirstApptDate: orders.FirstApptDate || '-',
          materialList: materialList || '-',
          color: orders.color || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    path: '/pending-installation',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {roleId === 4 ? (
                  <i className="mdi mdi-eye" title="View Order"></i>
                ) : (
                  <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
                )}
              </a>
              {(roleId == 1 || roleId == 2) && (
                <a
                  href="/"
                  className="px-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, orderId: orders.ID });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Order"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return orders;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteOrder = async () => {
    const { orderId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        swal('Order deleted!', '', 'success').then(() => {
          this.getWorkOrder(currentPage);
        });
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrder();
  };

  clearSearch = () => {
    this.onCancelBtn();
    this.setState({ keyword: '', orders: [] }, () => {
      this.getWorkOrder();
    });
  };

  onAdvancedSearch = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  onCancelBtn = (type = '') => {
    this.setState({ showFilter: false });
    const {
      SetTechnician,
      SetWarrantyCode,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetFromDate,
      SetToDate,
      SetMonth,
      SetMonthYear,
      SetYear,
      SetFilterType,
      SetVendor,
    } = this.props;

    SetTechnician('');
    SetWarrantyCode('');
    SetCustomer('');
    SetOrderType('');
    SetPaymentType('');
    SetWorkComplete('');
    SetFromDate('');
    SetToDate('');
    SetMonth('');
    SetMonthYear('');
    SetYear('');
    SetFilterType('');
    SetVendor('');
    if (type === 'pendingInstallation') return;
    this.getWorkOrder();
  };

  // onSubmit = () => {
  //   const { showFilter } = this.state;
  //   this.setState({ showFilter: false });
  // };

  render() {
    const {
      rows,
      keyword,
      showDelete,
      showFilter,
      isShowLoader,
      roleId,
      navSkip,
      navCurrentPage,
      pagination,
    } = this.state;

    const { fromComponent, SetSearchType, searchType } = this.props;

    return (
      <AUX>
        <div
          className={
            fromComponent !== 'customerModule' ? 'page-content-wrapper' : ''
          }
        >
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <div
            className={
              fromComponent !== 'customerModule' ? 'container-fluid' : ''
            }
          >
            <HelmetComponent title="Pending Installation" />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the order ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteOrder();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, orderId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className={'row'}>
              <div className={'col-12'}>
                <div className="card m-b-20">
                  <div className="card-body">
                    {fromComponent !== 'customerModule' && (
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group row">
                            <div className="col-sm-8">
                              <form
                                onSubmit={this.onSearchSubmit}
                                autoComplete="off"
                              >
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      className="form-control"
                                      value={keyword}
                                      type="text"
                                      placeholder="Search by Customer, Technician, Home warranty code, Work complete, Work order type"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          keyword: e.target.value,
                                        });
                                        if (!e.target.value) this.clearSearch();
                                      }}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6">
                                    <button
                                      type="submit"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={() => {
                                        this.clearSearch();
                                      }}
                                      className="btn btn-secondary waves-effect m-l-10"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="reset"
                                onClick={async () => {
                                  this.onAdvancedSearch();
                                }}
                                className="btn btn-primary waves-effect waves-light m-l-10"
                              >
                                Advance Search
                              </button>
                            </div>
                          </div>
                        </div>
                        {(roleId == 1 || roleId == 2) && (
                          <div className="col-sm-4">
                            <div className="right-actions float-right">
                              {rows && rows.length > 0 && (
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() => {
                                    this.downloadCSV();
                                  }}
                                >
                                  <i className="mdi mdi-download"></i> CSV
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {showFilter && (
                      <Filter
                        // onSubmitButtonCallback={() => {
                        //   this.onSubmit();
                        // }}
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={() => this.onFilterSearch('clear')}
                        // showFilter={showFilter}
                        fromComponent="ahsWorkorder"
                      />
                    )}

                    <Table
                      columns={columns}
                      rows={rows}
                      // bulkActions={actions}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                  currentPage: pageNo,
                                },
                                () => this.onFilterSearch(pageNo)
                              )
                            : this.onFilterSearch(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  warantyCode: state.auth.warantyCode,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
  vendorid: state.auth.vendorid,
  searchType: state.auth.searchType,
});

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetWarrantyCode: (warantyCode) => dispatch(SetWarrantyCode(warantyCode)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  SetWorkComplete: (WorkCompleteValue) =>
    dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
  SetVendor: (vendorid) => dispatch(SetVendor(vendorid)),
  SetSearchType: (searchType) => dispatch(SetSearchType(searchType)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingInstallation)
);

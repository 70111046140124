import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CustomTable from '../../../components/Table/simpletable.component';
import swal from 'sweetalert';
import AddFile from './addFile.component';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';

class FileAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      rows: [],
      showDelete: false,
      fileId: null,
      roleID: null,
      WorkOrderId: null,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      column: [],
    };
  }

  actionColumnCallback = ({ id }) => {
    return (
      <React.Fragment>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ showDelete: true, fileId: id });
          }}
        >
          <i className="mdi mdi-delete text-danger" title="Delete File"></i>
        </a>
      </React.Fragment>
    );
  };

  componentDidMount = async () => {
    const columns = [
      {
        label: 'Sr.no',
        id: 'index',
      },
      {
        label: 'Attachment',
        id: 'url',
        render: ({ url }) => (
          <React.Fragment>
            <a href={url} target="blank">
              {' '}
              {url.split(Api.s3url)}{' '}
            </a>
          </React.Fragment>
        ),
      },
      {
        label: 'Status',
        id: 'is_approved',
        render: ({ is_approved, id }) => (
          <React.Fragment>
            <span>
              {is_approved === 1 ? (
                'Approved'
              ) : (
                <button
                  type="button"
                  onClick={() => this.changeStatus(id)}
                  className="btn btn-primary waves-effect m-l-10"
                >
                  Approve
                </button>
              )}
            </span>
          </React.Fragment>
        ),
      },
      {
        label: 'Action',
        id: 'id',
        render: this.actionColumnCallback,
      },
    ];

    this.setState({ column: columns });

    const roleId = JSON.parse(customFunctions.getLocalStorage('userdata'));
    this.setState({ roleID: roleId.role }, async () => {
      await this.getAttachment();
    });
  };

  getAttachment = async (currentPage) => {
    const { pagination, roleID } = this.state;
    const pageNo = currentPage || 1;
    const skip = pageNo * pagination.current_page - pagination.current_page;
    const techId =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.technicianDetails &&
      this.props.location.state.technicianDetails.id;

    const endPoint = `${Api.AddtechnicianUrl}/getAttachments`;
    let params = {
      tech_id: techId,
      skip: skip,
      limit: pagination.itemsPerPage,
    };
    if (roleID === 5) params.is_approved = 1;

    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.data) {
      const newPagination = {
        current_page: pageNo,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };

      this.setState(
        {
          files: data.data.data,
          pagination: newPagination,
        },
        () => {
          this.setTableData();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
      setTimeout(() => {
        this.props.history.push('/dashboard');
      }, 2000);
    }
  };

  changeStatus = async (fileId) => {
    const postData = {
      id: fileId,
      is_approved: 1,
    };
    const endPoint = `${Api.AddtechnicianUrl}/updateAttachment`;
    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      this.getAttachment();
      swal('Attachment Approved', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  deleteAttachment = async () => {
    const { fileId } = this.state;
    const endPoint = `${Api.AddtechnicianUrl}/deleteAttachment?attachment_id=${fileId}`;
    const { data, message } = await AuthApi.deleteDataFromServer(endPoint);
    if (data) {
      this.setState({ showDelete: false, fileId: null }, () => {
        this.getAttachment();
      });
      swal('Attachment deleted', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = () => {
    const { files, pagination } = this.state;
    const rows = [];
    files &&
      files.length &&
      files.map((file, i) => {
        rows.push({
          index:
            (pagination.current_page - 1) * pagination.itemsPerPage + (i + 1),
          url: file.url,
          id: file.id,
          is_approved: file.is_approved,
        });
        return file;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, pagination, showDelete, roleID } = this.state;

    return (
      <AUX>
        <Row>
          {roleID === 5 ? (
            <Col className="mt-3">
              <AddFile />
            </Col>
          ) : (
            ''
          )}
          <Col md={roleID === 5 ? 7 : 12} className="mt-0">
            <div className="card m-b-20">
              <div className="p-r-10 p-t-10">
                <div className="d-flex justify-content-end">
                  <div className="card-body" style={{ minWidth: '300px' }}>
                    {showDelete && (
                      <SweetAlert
                        title="Are you sure, do you want to delete the attachment ?"
                        error
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                          this.deleteAttachment();
                        }}
                        onCancel={() => {
                          this.setState({ showDelete: false, fileId: null });
                        }}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                    <CustomTable
                      columns={this.state.column}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.getAttachment(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </AUX>
    );
  }
}

export default withRouter(FileAttachment);

// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import { setHours, setMinutes } from 'date-fns';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import AddPaymentType from '../../../components/CustomerComplaint/AddPaymentType';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import AddTechnician from '../Workorder/AddTechnician';
import HelmetComponent from '../Title/index';
import AddCustomerPopup from './AddCustomerPopup';
import AddWarrantyCompany from './AddWarrantyCompany';
import CustomerMessagePopup from './CustomerMessagePopup';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';
import {
  SetMessageRecieverData,
  SetMessageBoxToggle,
  SetChatNotificationCount,
} from '../../../action/user.action';
import MessageBox from '../../../components/Layout/messageBox.component';

let timeRange = [
  { name: '30min', value: 30 },
  { name: '1hr', value: 60 },
  { name: '1hr 30min', value: 90 },
  { name: '2hr', value: 120 },
  { name: '2hr 30min', value: 150 },
  { name: '3hr', value: 180 },
  { name: '3hr 30min', value: 210 },
  { name: '4hr', value: 240 },
  { name: '4hr 30min', value: 270 },
  { name: '5hr', value: 300 },
  { name: '5hr 30min', value: 330 },
  { name: '6hr', value: 360 },
  { name: '6hr 30min', value: 390 },
  { name: '7hr', value: 420 },
  { name: '7hr 30min', value: 450 },
  { name: '8hr', value: 480 },
  { name: '8hr 30min', value: 510 },
  { name: '9hr', value: 540 },
  { name: '9hr 30min', value: 570 },
];
class WorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrderId: '',
      workOrderType: '',
      workOrderDate: new Date(),
      selectedCustomerName: '',
      selectedCustomerNameErr: '',
      wrntyCode: '',
      wrntyErr: '',
      cntctPrsn: '',
      tech: null,
      technicianErr: [],
      jobtitle: '',
      jobDesc: '',
      repairDesc: '',
      customerId: '',
      custmrAddress: '',
      custmrAddressErr: '',
      workCompleteValue: '',
      workcompleteValueErr: '',
      completionDate: '',
      completionDateErr: '',
      paymentAmount: '',
      paymentAmountErr: '',
      ApptDate: null,
      ApptDateErr: '',
      installerId: '',
      installerIdErr: '',
      paymentDate: null,
      workOrderTypeErr: '',
      workOrderDateErr: '',
      technicianId: null,
      techniciandata: [],
      wrntyCompany: '',
      wrntyCompanyName: '',
      wrntyCompErr: '',
      zipCode: '',
      jobTotal: '',
      jobTotalErr: '',
      paymentTotal: '',
      paymentTotalErr: '',
      techDeduction: '',
      createdDateTime: '',
      updatedTime: '',
      apptFromTimeErr: '',
      apptToTimeErr: '',
      updatedApptDate: '',
      editType: false,
      updatedCompletionDate: '',
      noteForReason: '',
      ToTime: null,
      FromTime: null,
      title: 'Add Work Order',
      skip: 0,
      limit: 1000,
      page: 1,
      startHours: '',
      startMinutes: '',
      selectedOption: [],
      techFunction: [],
      addToggle: false,
      selecteFunctiondOption: [],
      technicianFunctionErr: [],
      technicianObject: [
        {
          technician: '',
          technicianFunction: '',
          selectedToggle: true,
          isPrimary: 0,
        },
      ],
      disabledForCust: false,
      disabledAll: false,
      isShowLoader: false,
      WorkStatus: '',
      vendorid: null,
      vendorName: '',
      rangeMinutes: { name: '30min', value: 30 },
      authAmount: null,
      authNumber: null,
      scheduled: false,
      customerMsgBox: false,
      created: null,
      updated: null,
      ho_oops: '',
      authRequired: null,
      originalTechniciandata: [],
      isWorkOrderCompletedHistory: false,
      clearSelected: false,
      // ListData: [],
      // workorderdata: [],
      // homewrntycompanydata: [],
      // customerNameData: [],
      // paymentTypeList: [],
      // vendorsdata: [],
      customersDetailsData: {
        PhoneNumber1: null,
        PhoneNumber2: null,
        PhoneNumber3: null,
        PhoneNumber4: null,
      },
      custdata: {
        userid: null,
        roleid: null,
        image: '',
        name: '',
      },
      status: '',
      customerEmail: '',
      minimunDate: new Date(),
      apptDateToggle: false,
      messageBoxToggle: false,
    };
  }

  addTechnicianObject = (id) => {
    const {
      technicianObject,
      originalTechniciandata,
      techniciandata,
      technicianErr,
    } = this.state;
    technicianObject.push({
      technician: '',
      technicianFunction: '',
      selectedToggle: true,
      isPrimary: 0,
    });
    this.setState({ technicianObject }, () => {
      // let techarray = [];
      // originalTechniciandata &&
      //   originalTechniciandata.map((tech) => {
      //     const result = technicianObject.filter((techObject) => {
      //       if (techObject.selectedOption)
      //         return techObject.selectedOption.value === tech.value;
      //     });
      //     if (result && result.length > 0) {
      //       return false;
      //     } else {
      //       techarray.push(tech);
      //     }
      //   });
      let techarray = [...techniciandata];
      originalTechniciandata &&
        originalTechniciandata.map((tech) => {
          const result = technicianObject.filter((techObject) => {
            if (techObject.selectedOption)
              return techObject.selectedOption.value === tech.value;
          });
          if (result && result.length === 3) {
            techarray = techarray.filter((i) => i.value !== tech.value);
          }
        });
      this.setState({ techniciandata: techarray });
    });

    technicianObject &&
      technicianObject.map((tech, i) => {
        const result =
          technicianObject &&
          technicianObject.filter(
            (res, index) =>
              res.technician === tech.technician &&
              res.technicianFunction === tech.technicianFunction &&
              tech.technicianFunction !== ''
          );
        if (result.length > 1) {
          technicianErr[i] = 'Duplicate Entry found';
        }
      });
    this.setState({ technicianErr });
  };

  removeTechnicianObject = (id, obj) => {
    const {
      technicianObject,
      techFunction,
      techniciandata,
      originalTechniciandata,
    } = this.state;
    technicianObject.splice(id, 1);
    let techobj = technicianObject;
    this.setState({ technicianObject: [] }, () => {
      this.setState({ technicianObject: techobj }, () => {
        this.forceUpdate();
        this.getInstallerId();
      });
    });

    if (obj.technicianFunction === 'Installation') {
      let techfun = [...techFunction];
      techfun.push({ value: 'Installation', label: 'Installation' });
      this.setState({ techFunction: techfun });
    }

    let techarray = [...techniciandata];
    originalTechniciandata &&
      originalTechniciandata.map((tech) => {
        const result = technicianObject.filter((techObject) => {
          if (techObject.selectedOption)
            return techObject.selectedOption.value === tech.value;
        });
        if (
          result &&
          result.length < 3 &&
          techarray &&
          techarray.length &&
          techarray.findIndex((i) => i.value === tech.value) === -1
        ) {
          techarray.push({
            value: tech.value,
            label: obj.technician,
            name: obj.technician,
          });
        }
      });
    this.setState({ techniciandata: techarray, technicianErr: [] });
  };

  componentDidMount = async () => {
    // let isAllowed = await customFunctions.isAllowedRoute('submodule', 'addnewrecord-addworkorder');
    // if(!isAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    // let writeAllowed = await customFunctions.isAllowedRoute('submodule', 'addnewrecord-addworkorder', 'write');
    // if(writeAllowed){
    //   this.setState({disabledAll: false})
    // }else{
    //   this.setState({disabledAll: true})
    // }
    await this.getTechnician();

    const orderId = await this.props.match.params.customerId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id }, () => {
      if (userdata.role_id === 4) {
        this.setState({ disabledForCust: true });
      }
      if (userdata.role_id === 5 || userdata.role_id === 6) {
        this.setState({ technicianId: userdata.login_id });
      }
    });
    customFunctions.setLocalStorage('viewId', orderId);
    customFunctions.setLocalStorage('orderId', orderId);

    if (this.props) {
      if (
        (await this.props.location) &&
        (await this.props.location.state) &&
        (await this.props.location.state.authRequired)
      ) {
        await this.setState({
          authRequired: await this.props.location.state.authRequired,
        });
      }
    }
    if (this.props) {
      if (
        (await this.props.location) &&
        (await this.props.location.state) &&
        (await this.props.location.state.scheduleDetails) &&
        (await this.props.location.state.scheduleDetails.technicianId)
      ) {
        const myFutureTime = new Date(
          await this.props.location.state.scheduleDetails.apptDate
        );
        let res =
          myFutureTime &&
          myFutureTime.setMinutes(myFutureTime.getMinutes() + 240);
        let ToTime = new Date(res).toISOString();
        var selectedtech = {
          value: await this.props.location.state.scheduleDetails.technicianId,
          label: await this.props.location.state.scheduleDetails.technicianName,
        };
        let technicianObject = [
          {
            technician: await this.props.location.state.scheduleDetails
              .technicianName,
            technicianFunction: '',
            selectedToggle: false,
            isPrimary: 0,
            selectedOption: selectedtech,
          },
        ];
        await this.setState({
          technicianId: await this.props.location.state.scheduleDetails
            .technicianId,
          ApptDate: new Date(
            await this.props.location.state.scheduleDetails.apptDate
          ).toISOString(),
          FromTime: new Date(
            await this.props.location.state.scheduleDetails.apptDate
          ).toISOString(),
          ToTime,
          rangeMinutes: { name: '4hr', value: 240 },
          technicianObject,
        });
      }
    }

    const url_segment = this.props.match.url.split('/');

    this.setState({ orderId });
    if (url_segment && url_segment.includes('update-order')) {
      customFunctions.setTitle('Update Work Order');
      this.setState({
        editType: true,
      });
    } else {
      customFunctions.setTitle(this.state.title);
    }
    if (orderId) {
      await this.getOrderData(orderId);
    }
    this.setState({ addToggle: true });
    await this.getTechnicianFunction();
    // await this.getPresentMessageUsers();
    // await this.getListType();
    // await this.getWorkOrders();
    // await this.getCustomerLIst();
    // await this.getHomeWrntyCompany();
    // await this.getPaymentType();
    // await this.getVendors();
  };

  componentWillReceiveProps = async (props) => {
    const orderId = await this.props.match.params.customerId;
    if (props.type === 'schedule') {
      if (orderId) {
        await this.getOrderData(orderId);
        await this.setState({ scheduled: true });
      }
    }
    if (props.type === 'completejob') {
      this.setState({ disabledAll: true });
    }
    if (
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.type === 'orders-search' &&
      orderId !== props.history.location.state.orderDetails.ID
    ) {
      this.getOrderData(props.history.location.state.orderDetails.ID);
    }
  };

  getOrderData = async (orderId) => {
    const endPoint = await `${Api.orderById}?work_order_id=${orderId}&type=1`;
    let allTechObject = [];
    if (!this.state.scheduled) {
      this.setState({
        isShowLoader: true,
      });
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      if (data.data && data.data[0]) {
        if (
          data.data[0] &&
          data.data[0].multitech &&
          data.data[0].multitech.length
        ) {
          const selectedOption = [];
          const selecteFunctiondOption = [];
          const multiTechnician = data.data[0].multitech;

          if (multiTechnician && multiTechnician.length) {
            multiTechnician.map((technician) => {
              const result =
                this.state.techniciandata &&
                this.state.techniciandata.length &&
                this.state.techniciandata.filter(
                  (res, i) => res.value === technician.tech_id
                );

              if (result.length) {
                var selectedtech = {
                  value: technician.tech_id,
                  label: result[0].label,
                  name: result[0].label,
                };
              }

              var pretechdata = false;
              if (data.data[0].previoustechnicians) {
                const pretech = JSON.parse(data.data[0].previoustechnicians);
                if (pretech && pretech.length > 0) {
                  pretechdata = pretech.includes(technician.tech_id);
                }
              }
              allTechObject.push({
                isworked: pretechdata,
                technician: technician.tech_id,
                technicianFunction: technician.tech_function,
                selectedOption: selectedtech,
                isPrimary: technician.is_primary,
                selecteFunctiondOption: {
                  value: technician.tech_function,
                  label: technician.tech_function,
                  name: technician.tech_function,
                },
              });
              if (technician.tech_function === 'Installation') {
                this.setState({
                  techFunction: this.state.techFunction.filter(
                    (i) => i.value !== 'Installation'
                  ),
                });
              }
              return technician;
            });
            this.setState({
              techFunction: this.state.techFunction.filter(
                (i) => i.value !== 'Repair'
              ),
            });
          }
          this.setState({
            isShowLoader: false,
            technicianObject: allTechObject,
            selecteFunctiondOption: selecteFunctiondOption,
            selectedOption: selectedOption,
          });
        }
        const myFromTime = new Date(data.data[0].FromTime);
        const myToTime = new Date(data.data[0].ToTime);
        var rangeHour = customFunctions.diff_Minutes(myFromTime, myToTime);
        let customerAddress = `${data.data[0].AddressLine1_1} ${data.data[0].AddressLine2_1} ${data.data[0].City1} ${data.data[0].State1} ${data.data[0].Zip1} `;
        this.setState(
          {
            isShowLoader: false,
            workOrderId: data.data[0].WorkOrder,
            workOrderType: { name: data.data[0].WorkOrderType },
            WorkStatus: data.data[0].WorkStatus,
            workOrderDate:
              data.data[0].WorkOrderDate !== null
                ? new Date(data.data[0].WorkOrderDate)
                : '',
            selectedCustomerName: { name: data.data[0].CustomerName },
            customerId: data.data[0].CustomerID,
            // wrntyCode:{name:data.data[0].HomeWarrantyCode, value:data.data[0].HomeWarrantyCode},
            repairDesc: data.data[0].RepairDescription,
            custmrAddress: customerAddress,
            customerEmail: data.data[0].customeremail,
            equipInst: data.data[0].PiecesOfEquipmentInstall,
            workCompleteValue: {
              id: data.data[0].WorkComplete,
              name:
                data.data[0].WorkComplete && data.data[0].WorkComplete === 1
                  ? 'Yes'
                  : 'No',
            },
            paymentAmount: data.data[0].PaymentAmount,
            ApptDate:
              data.data[0].FirstApptDate !== null
                ? new Date(data.data[0].FirstApptDate)
                : '',
            installerId: data.data[0].InstallerID,
            zipCode: data.data[0].Zip1,
            jobTotal: data.data[0].JobTotal,
            jobDesc: data.data[0].JobDescription,
            installerCost: data.data[0].InstallerCost,
            paymentType: data.data[0].PaymentType,
            paymentTotal: data.data[0].Total,
            techDeduction: data.data[0].PayrollAmount,
            createdDateTime: data.data[0].Created
              ? new Date(data.data[0].Created)
              : '',
            created: data.data[0].Created,
            apptTime: data.data[0].ApptTime,
            updatedTime: data.data[0].Updated
              ? new Date(data.data[0].Updated)
              : '',
            updated: data.data[0].Updated,
            noteForReason: data.data[0].DeductionNote || '',
            FromTime: data.data[0].FromTime
              ? new Date(data.data[0].FromTime)
              : '',
            ToTime: data.data[0].ToTime ? new Date(data.data[0].ToTime) : '',
            wrntyCompany: data.data[0].sourceid,
            wrntyCompanyName: { name: data.data[0].source },
            vendorid: data.data[0].vendorid,
            vendorName: { name: data.data[0].vendorname },
            authNumber: data.data[0].AuthoNumber,
            authAmount: data.data[0].AuthoAmount,
            ho_oops: data.data[0].HoOOps,
            jobtitle: data.data[0].job_title,
            rangeMinutes: rangeHour
              ? timeRange.filter((range) => range.value === rangeHour)[0]
              : '',
            disabledAll:
              data.data[0].WorkComplete && data.data[0].WorkComplete === 1
                ? true
                : false,

            isWorkOrderCompletedHistory: data.data[0].isCompleted,
          },
          () => {
            this.setMinDate(data.data[0].WorkStatus);
          }
        );
        if (data.data[0].CustomerID) {
          this.updateData(data.data[0].CustomerID);
        }
      }
    }
    this.forceUpdate();
    this.setState({
      isShowLoader: false,
    });
  };

  // getListType = async () => {
  //   const endPoint = `${Api.AddListTypeUrl}`;
  //   this.setState({
  //     isShowLoader: false,
  //   });
  //   const { data } = await AuthApi.getDataFromServer(endPoint);
  //   if (data) {
  //     this.setState(
  //       {
  //         ListData: data.data.data,
  //       },
  //       () => {
  //         this.forceUpdate();
  //       }
  //     );
  //   }
  //   const { ListData } = this.state;
  //   let Technician = await ListData.find((o) => o.ListType === 'Technician');
  // };

  // getWorkOrders = async (searchKeyword) => {
  //   this.setState({ isShowLoader: true });
  //    const endPoint = searchKeyword
  //     ? `${Api.getOrderType}?type=1&search=${searchKeyword}`
  //     : `${Api.getOrderType}?type=1`;
  //   // const endPoint = `${Api.getOrderType}?type=1`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //     data.data.data.map((dt) => {
  //       dt.name = dt.order_type;
  //     });
  //     this.setState({
  //       isShowLoader: false,
  //       workorderdata: data.data.data,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: false });
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  // getHomeWrntyCompany = async (searchKeyword) => {
  //   const endPoint = searchKeyword
  //     ? `${Api.AddWarrantyCompany}?search=${searchKeyword}`
  //     : `${Api.AddWarrantyCompany}`;

  //   // const endPoint = `${Api.AddWarrantyCompany}?status=1`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //     data.data.data.map((dt) => {
  //       dt.name = dt.title;
  //     });
  //     this.setState({
  //       homewrntycompanydata: data.data.data,
  //     });
  //     return data.data.data;
  //   } else {
  //     swal(message ? message : "Something went wrong", '', 'error');
  //     return [];
  //   }
  // };

  // getCustomerLIst = async (searchKeyword) => {
  //     const endPoint = searchKeyword
  //     ? `${Api.customerActiveUrl}?type=1&search=${searchKeyword}`
  //     : `${Api.customerActiveUrl}?type=1`;

  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data) {
  //     data.data.data.map((dt) => {
  //       dt.name = dt.CustomerName;
  //     });
  //     this.setState({
  //       customerNameData: data.data.data,
  //     });
  //   } else {
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  // technicianObject = [{
  //   tech_id: 1,
  //   tech_function: 'test',
  // }];

  createOrder = async (e) => {
    const {
      orderId,
      workOrderType,
      workOrderDate,
      wrntyCode,
      repairDesc,
      workCompleteValue,
      completionDate,
      ApptDate,
      installerId,
      workOrderId,
      jobTotal,
      jobDesc,
      jobtitle,
      techDeduction,
      noteForReason,
      FromTime,
      ToTime,
      technicianObject,
      wrntyCompany,
      vendorid,
      authNumber,
      authAmount,
      ho_oops,
      scheduled,
      WorkStatus,
      isWorkOrderCompletedHistory,
      customerId,
    } = this.state;

    // const MultiTech = [{ tech_id: 1, tech_function: 'test' }];
    const MultiTech = [];
    technicianObject &&
      technicianObject.map((tech) => {
        if (tech.selectedOption && tech.selectedOption.value) {
          MultiTech.push({
            tech_id: tech.selectedOption.value,
            tech_function: tech.selecteFunctiondOption.value,
            name: tech.selecteFunctiondOption.value,
          });
        }
      });

    const apttest =
      ApptDate && ApptDate != null ? new Date(ApptDate).toString() : null;
    let frmtime =
      FromTime && FromTime != null
        ? new Date(FromTime).toTimeString().substr(0, 8)
        : null;
    frmtime =
      apttest && frmtime
        ? apttest.substring(0, 16) +
          frmtime +
          apttest.substring(24, apttest.length)
        : null;

    let totime =
      ToTime && ToTime != null
        ? new Date(ToTime).toTimeString().substr(0, 8)
        : null;
    totime =
      apttest && totime
        ? apttest.substring(0, 16) +
          totime +
          apttest.substring(24, apttest.length)
        : null;

    const postData = {
      order_code: workOrderId,
      customer_id: customerId,
      warranty_code: wrntyCode,
      order_date: new Date(workOrderDate).toISOString(),
      order_type: workOrderType && workOrderType.name,
      repair_desc: repairDesc,
      work_complete: workCompleteValue && workCompleteValue.id,
      first_appt_date: ApptDate ? new Date(ApptDate).toISOString() : null,
      installer_id: installerId,
      completion_date: completionDate || '',
      job_total: jobTotal,
      job_title: jobtitle,
      job_description: jobDesc,
      tech_deduction: techDeduction,
      from_time: frmtime ? new Date(frmtime).toISOString() : null,
      to_time: totime ? new Date(totime).toISOString() : null,
      deduction_note: noteForReason,
      multitech: MultiTech,
      sourceid: (wrntyCompany && parseInt(wrntyCompany)) || null,
      vendorid,
      AuthoNumber: authNumber,
      AuthoAmount: authAmount,
      HoOOps: ho_oops,
      work_status: WorkStatus,
    };
    // if order is completed in once the remove work_complete from payload
    if (isWorkOrderCompletedHistory) {
      delete postData.work_complete;
    }
    if (orderId) {
      postData.id = parseInt(orderId);
    }
    const endPoint = `${Api.workOrderUrl}`;
    const callback = orderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    this.setState({
      isShowLoader: true,
    });
    const { data } = await callback(endPoint, postData);

    if (data) {
      if (orderId) {
        if (scheduled) {
          if (MultiTech.length === 0) {
            this.setState({ isShowLoader: false });
            swal('Job unscheduled!', '', 'success');
            this.props.statusChangeCallback(orderId);
          } else {
            this.setState({ isShowLoader: false });
            await swal('Job scheduled!', '', 'success');
            await this.props.statusChangeCallback(orderId);
          }
          await this.setState({ scheduled: false }, async () => {
            await this.getOrderData(orderId);
          });
        } else {
          swal('Order Updated!', '', 'success');
        }
        // setTimeout(() => {
        //   // const previous_route =
        //   //   customFunctions.getLocalStorage('previous_route');
        //   // const current_route =
        //   //   customFunctions.getLocalStorage('current_route');
        //   // if (
        //   //   previous_route === '/dashboard' ||
        //   //   current_route === '/dashboard'
        //   // ) {
        //   //   this.setState({ isShowLoader: false });
        //   //   this.props.history.push('/dashboard');
        //   // } else {
        //   //   this.setState({ isShowLoader: false });
        //   //   // this.props.history.goBack();
        //   // }
        // }, 1000);
        this.setState({ isShowLoader: false });
        // setTimeout(() => {
        // this.props.history.goBack();
        if (this.props && this.props.previousPath) {
          this.props.history.push(this.props.previousPath);
        } else {
          this.props.history.push('/dashboard');
        }
        // }, 2000);
        return false;
      }
      swal(
        `Work Order company-name-WO-${data.data.LastInsertId} Created!`,
        '',
        'success'
      );
      this.setState({ isShowLoader: false });
      this.flushAllData();
      this.props.history.push('/dashboard');
      // this.props.history.push('/ahs-workorders');
    } else {
      this.setState({ isShowLoader: false });
      this.props.history.push('/dashboard');
    }

    this.setState({
      isShowLoader: false,
    });
  };

  updateData = async (id) => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getCustomerById}?customer_id=${id}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      let customerAddress = `${data.data.AddressLine1_1} ${data.data.AddressLine2_1} ${data.data.City1} ${data.data.State1} ${data.data.Zip1} `;
      let custNum = data.data.PhoneNumber1
        ? data.data.PhoneNumber1
        : data.data.PhoneNumber2
        ? data.data.PhoneNumber2
        : data.data.PhoneNumber3
        ? data.data.PhoneNumber3
        : data.data.PhoneNumber4
        ? data.data.PhoneNumber4
        : null;

      this.setState({
        cntctPrsn: data.data.ContactName,
        customerId: data.data.ID,
        custmrAddress: customerAddress,
        isShowLoader: false,
        customerEmail: data.data.email,
        customersDetailsData: {
          PhoneNumber1: data.data.PhoneNumber1,
          PhoneNumber2: data.data.PhoneNumber2,
          PhoneNumber3: data.data.PhoneNumber3,
          PhoneNumber4: data.data.PhoneNumber4,
        },
        custdata: {
          userid: data.data.userid,
          roleid: 4,
          image: '',
          name: data.data.CustomerName,
          phonenumber: custNum,
        },
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  updateCompletionDate = (workCompleteValue) => {
    if (workCompleteValue == 1) {
      var currentDate = new Date().toISOString();
      this.setState({
        completionDate: currentDate,
      });
    }
  };

  restrictCompletionDate = async (e) => {
    this.setState({
      updatedApptDate: e,
      ApptDate: '',
      completionDate: '',
    });
  };

  restrictApptDate = async (completionDate) => {
    this.setState({
      updatedCompletionDate: completionDate,
    });
  };

  validateForm = async (e) => {
    e.preventDefault();
    const {
      workOrderDate,
      selectedCustomerName,
      technicianObject,
      ApptDate,
      FromTime,
      ToTime,
      wrntyCompany,
      vendorid,
      jobtitle,
      workOrderType,
      scheduled,
    } = this.state;

    let workOrderTypeErr = '',
      wrntyErr = '',
      technicianErr = [],
      technicianFunctionErr = [],
      workOrderDateErr = '',
      custmrAddressErr = '',
      equipInstErr = '',
      workcompleteValueErr = '',
      ApptDateErr = '',
      installerIdErr = '',
      completionDateErr = '',
      paymentAmountErr = '',
      paymentTotalErr = '',
      jobTotalErr = '',
      jobDescErr = '',
      installerCostErr = '',
      selectedCustomerNameErr = '',
      paymentTypeErr = '',
      apptFromTimeErr = '',
      apptToTimeErr = '',
      isDuplicate = false,
      isValid = true,
      wrntyCompErr = '',
      vendorErr = '',
      jobtitleErr = '';

    const apttest =
      ApptDate && ApptDate != null ? new Date(ApptDate).toString() : null;
    let frmtime =
      FromTime && FromTime != null
        ? new Date(FromTime).toTimeString().substr(0, 8)
        : null;
    frmtime =
      apttest && frmtime
        ? apttest.substring(0, 16) +
          frmtime +
          apttest.substring(24, apttest.length)
        : null;

    let currentDate = Date.now();
    let fromDateTime = new Date(frmtime).getTime();
    if (
      this.props &&
      this.props.type === 'schedule' &&
      currentDate > fromDateTime
    ) {
      swal('Please update 1st appt date', '', 'error');
      this.setState({ scheduled: true });
      return;
    }

    if (ApptDate && FromTime === null) {
      apptFromTimeErr = 'Appt from time is required';
      isValid = false;
    }
    if (ApptDate && FromTime && ToTime === null) {
      apptToTimeErr = 'Please select range';
      isValid = false;
    }
    // if (completionDate === '' || !completionDate) {
    //   completionDateErr = 'Est. Completion Date is required';
    //   isValid = false;
    // }

    if (workOrderDate === '') {
      workOrderDateErr = 'Work Order date is required';
      isValid = false;
    }
    if (workOrderType === '') {
      workOrderTypeErr = 'Work Order type is required';
      isValid = false;
    }
    if (jobtitle === '') {
      jobtitleErr = 'Job title is required';
      isValid = false;
    }

    if (selectedCustomerName === '') {
      selectedCustomerNameErr = 'Customer Name is required';
      isValid = false;
    }

    if (!vendorid) {
      vendorErr = 'Vendor is required';
      isValid = false;
    }
    if (!wrntyCompany) {
      wrntyCompErr = 'Home Warranty Company is required';
      isValid = false;
    }

    technicianObject &&
      technicianObject.map((tech, i) => {
        const result =
          technicianObject &&
          technicianObject.filter(
            (res, index) =>
              res.technician === tech.technician &&
              res.technicianFunction === tech.technicianFunction
          );
        if (result.length > 1) {
          isDuplicate = true;
          technicianErr[i] = 'Duplicate Entry found';
          isValid = false;
          return false;
        }
        // if (!tech.technician) {
        //   technicianErr[i] = 'Technician is required';
        //   isValid = false;
        //   return;
        // }
        if (tech.technician && ApptDate === '') {
          ApptDateErr = 'Appt Date is required';
          isValid = false;
        }

        if (tech.technician && !tech.technicianFunction) {
          technicianFunctionErr[i] = 'Technician Function is required';
          isValid = false;
          return false;
        }
        if (isDuplicate) {
          return false;
        }
      });

    if (
      scheduled &&
      technicianObject &&
      technicianObject.length &&
      technicianObject[0].technician === ''
    ) {
      technicianErr[0] = 'Please select technician';
      isValid = false;
    }

    this.setState({
      workOrderTypeErr,
      workOrderDateErr,
      completionDateErr,
      workcompleteValueErr,
      selectedCustomerNameErr,
      installerIdErr,
      custmrAddressErr,
      technicianErr,
      paymentAmountErr,
      ApptDateErr,
      apptFromTimeErr,
      apptToTimeErr,
      equipInstErr,
      installerCostErr,
      jobTotalErr,
      jobDescErr,
      paymentTypeErr,
      paymentTotalErr,
      wrntyErr,
      technicianFunctionErr,
      wrntyCompErr,
      vendorErr,
      jobtitleErr,
    });

    if (isValid) {
      this.createOrder();
    }
  };

  flushAllData = () => {
    this.setState({
      workOrderType: '',
      workOrderDate: '',
      selectedCustomerName: '',
      wrntyCode: '',
      cntctPrsn: '',
      tech: '',
      jobtitle: '',
      jobDesc: '',
      repairDesc: '',
      custmrAddress: '',
      equipInst: '',
      workCompleteValue: '',
      completionDate: '',
      ApptDate: '',
      jobTotal: '',
      installerCost: '',
      zipCode: '',
      noteForReason: '',
      apptTimeTo: '',
      apptTimeFrom: '',
      wrntyCompErr: '',
      wrntyCompany: '',
      wrntyCompanyName: '',
      authNumber: '',
      authAmount: '',
      ho_oops: '',
      vendorid: null,
      vendorName: '',
      customerId: null,
    });
  };

  dateChanged = (d) => {
    this.setState({ workOrderDate: d });
  };

  setMinDate = (status) => {
    var date = new Date();
    if (status === 'Unscheduled') {
      this.setState({
        minimunDate: date,
      });
      this.forceUpdate();
      return;
    }
    if (this.state.ApptDate) {
      this.setState({
        minimunDate: date,
      });
      this.forceUpdate();
      // return new Date();
    } else {
      this.setState({
        minimunDate: this.state.workOrderDate,
      });
      this.forceUpdate();
      // return this.state.workOrderDate;
    }
  };

  setMaxDate = () => {
    let date = new Date(this.state.completionDate);
    return new Date(date.setDate(date.getDate() - 1));
  };

  getTechnician = async (dt = '') => {
    const { ApptDate, technicianObject } = this.state;
    this.setState({ isShowLoader: true });
    let endPoint = '';
    if (dt) {
      let filterDate = dt
        ? new Date(dt).toISOString()
        : ApptDate
        ? new Date(ApptDate).toISOString()
        : new Date().toISOString();
      endPoint = `${Api.technicianUrl}?from_date=${filterDate}`;
    } else {
      endPoint = `${Api.technicianUrl}?role=5,6`;
    }
    // const endPoint = `${Api.technicianUrl}?skip=0&limit=10000&onleave=approved&role=5`;
    // const endPoint = `${Api.technicianUrl}?skip=0&limit=10000&onleave=approved&role=5&status=1`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const techOptions = [];

      (await data.data.data) &&
        data.data.data.map((tech) => {
          techOptions.push({
            value: tech.id,
            label: `${tech.name}`,
            name: `${tech.name}`,
          });
        });

      if (dt) {
        let techObj = [];
        await data.data.data.map((i) => {
          technicianObject.filter((tech) => {
            if (tech.technician == i.id) techObj.push(tech);
          });
        });
        this.setState({
          technicianObject: techObj,
          // technicianObject: [
          //   {
          //     tech_id: 1,
          //     tech_function: 'test',
          //   },
          // ],
        });
      }

      this.setState({
        originalTechniciandata: techOptions,
        techniciandata: techOptions,
        isShowLoader: false,
        apptDateToggle: false,
      });
    } else {
      this.setState({ isShowLoader: false, apptDateToggle: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  handleTechnicianChange = (selectedOption, index) => {
    const {
      technicianObject,
      techFunction,
      techniciandata,
      originalTechniciandata,
    } = this.state;
    technicianObject[index].technician = selectedOption.name;
    technicianObject[index].selectedOption = selectedOption;
    technicianObject[index].selectedToggle = false;
    this.setState({ technicianObject, selectedOption, addToggle: true }, () => {
      this.getInstallerId();
    });
    if (index === 0) {
      this.handleTechFunctionChange(
        { value: 'Repair', label: 'Repair' },
        index
      );
      this.setState({
        techFunction: techFunction.filter((i) => i.value !== 'Repair'),
      });
    }

    let techarray = [...techniciandata];
    originalTechniciandata &&
      originalTechniciandata.map((tech) => {
        const result = technicianObject.filter((techObject) => {
          if (techObject.selectedOption)
            return techObject.selectedOption.value === tech.value;
        });
        if (result && result.length === 3) {
          techarray = techarray.filter((i) => i.value !== tech.value);
        }
        if (
          result &&
          result.length < 3 &&
          techarray &&
          techarray.length &&
          techarray.findIndex((i) => i.value === tech.value) === -1
        ) {
          techarray.push({
            value: tech.value,
            label: tech.label,
            name: tech.label,
          });
        }
      });
    this.setState({ techniciandata: techarray });
  };

  components = {
    DropdownIndicator: null,
  };

  getTechnicianFunction = async () => {
    const endPoint = ` ${Api.getTechFunction}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const functionData = [];

      data &&
        data.data.map((func) => {
          functionData.push({
            value: func,
            label: `${func}`,
          });
          return func;
        });

      this.setState({
        techFunction: functionData,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  // getVendors = async (searchKeyword) => {
  //   // this.setState({ isShowLoader: true });
  //   const endPoint = searchKeyword
  //     ? `${Api.vendorsUrl}?search=${searchKeyword}`
  //     : `${Api.vendorsUrl}`;
  //   // const endPoint = `${Api.vendorsUrl}?skip=0&limit=10000`;
  //   // const endPoint = `${Api.vendorsUrl}?skip=0&limit=10000&status=1`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //     this.setState({
  //       vendorsdata: data.data.data,
  //       isShowLoader: false,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: false });
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  handleTechFunctionChange = (selecteFunctiondOption, index) => {
    const { technicianObject, techFunction } = this.state;
    technicianObject[index].technicianFunction = selecteFunctiondOption.value;
    technicianObject[index].selecteFunctiondOption = selecteFunctiondOption;
    this.setState({ technicianObject, selecteFunctiondOption }, () => {
      this.getInstallerId();
    });
    if (selecteFunctiondOption.value === 'Installation') {
      this.setState({
        techFunction: techFunction.filter((i) => i.value !== 'Installation'),
      });
    }
    if (
      technicianObject &&
      technicianObject.length &&
      technicianObject.findIndex(
        (i) => i.technicianFunction && i.technicianFunction === 'Installation'
      ) === -1 &&
      techFunction.findIndex((i) => i.value === 'Installation') === -1
    ) {
      let techfun = [...techFunction];
      techfun.push({ value: 'Installation', label: 'Installation' });
      this.setState({ techFunction: techfun });
    }
  };

  getInstallerId = () => {
    const { technicianObject } = this.state;
    let newTechArr = [];
    if (technicianObject && technicianObject.length > 0) {
      technicianObject.map((dt, i) => {
        if (i === 0 && dt.technicianFunction === 'Installation') {
          return false;
        } else {
          if (dt.technicianFunction === 'Installation') {
            newTechArr.push(dt);
          }
        }
        return false;
      });
    }
    this.setState({
      installerId:
        newTechArr && newTechArr.length > 0 && newTechArr[0].selectedOption
          ? newTechArr[0].selectedOption.value
          : '',
    });
  };

  // getReadMessage = async (roleId, userId) => {
  //   let endPoint = `${Api.updateResentMessagesUrl}`;
  //   let postData = {
  //     is_read: 1,
  //     roleid: roleId,
  //     userid: userId,
  //   };

  //   const { data, message } = await AuthApi.putDataToServer(endPoint, postData);

  //   if (data && data.data && data.success) {
  //     this.getPresentMessageUsers();
  //   } else {
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  // getPresentMessageUsers = async () => {
  //   const { SetChatNotificationCount } = this.props;
  //   const endPoint = `${Api.getResentMessagesUrl}`;
  //   const { data } = await AuthApi.getDataFromServer(endPoint);
  //   if (!data) {
  //     return;
  //   }
  //   const allData = data.data;
  //   SetChatNotificationCount(allData.count);
  // };

  render() {
    const {
      orderId,
      techniciandata,
      // workorderdata,
      // homewrntycompanydata,
      workOrderId,
      workOrderType,
      workOrderDate,
      selectedCustomerName,
      selectedCustomerNameErr,
      cntctPrsn,
      technicianErr,
      jobtitle,
      jobDesc,
      repairDesc,
      customerId,
      custmrAddress,
      techDeduction,
      ApptDate,
      workCompleteValue,
      title,
      installerId,
      editType,
      workOrderDateErr,
      createdDateTime,
      updatedTime,
      jobTotalErr,
      // customerNameData,
      jobTotal,
      noteForReason,
      ToTime,
      FromTime,
      startHours,
      startMinutes,
      techFunction,
      technicianObject,
      technicianFunctionErr,
      addToggle,
      ApptDateErr,
      apptFromTimeErr,
      apptToTimeErr,
      disabledForCust,
      disabledAll,
      roleId,
      wrntyCompanyName,
      wrntyCompErr,
      isShowLoader,
      WorkStatus,
      // vendorsdata,
      vendorid,
      rangeMinutes,
      vendorErr,
      jobtitleErr,
      workOrderTypeErr,
      scheduled,
      customerMsgBox,
      technicianId,
      created,
      updated,
      authAmount,
      authNumber,
      ho_oops,
      authRequired,
      isWorkOrderCompletedHistory,
      vendorName,
      customersDetailsData,
      custdata,
      // selectedOption,
      status,
      customerEmail,
      minimunDate,
      messageBoxToggle,
    } = this.state;
    const { SetMessageBoxToggle, SetMessageRecieverData } = this.props;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {roleId && (
          <div className={editType ? '' : 'page-content-wrapper pt-0'}>
            <div
              className={editType ? '' : 'page-content-wrapper container-fluid'}
            >
              <HelmetComponent title={title} />
              <div className="row">
                <div className="col-12">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <form onSubmit={this.validateForm} autoComplete="off">
                        <div className="form-group row">
                          <label
                            htmlFor="workorder#"
                            className="col-sm-2 col-form-label"
                          >
                            Dispatch #
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              value={workOrderId}
                              placeholder="Dispatch ID"
                              disabled
                              type="text"
                              id="example-text-input"
                              onChange={(e) => {
                                this.setState({ workOrderId: e.target.value });
                              }}
                            />
                          </div>
                          <label
                            htmlFor="workorderdate"
                            className="col-sm-2 col-form-label"
                          >
                            Dispatch Date<span className="text-danger">*</span>{' '}
                          </label>
                          <div className="col-sm-4">
                            <DatePicker
                              className="form-control"
                              selected={workOrderDate}
                              disabled={orderId}
                              onChange={(date) => {
                                this.setState({ workOrderDate: date });
                                this.restrictCompletionDate(date);
                              }}
                              minDate={new Date()}
                              name="startDate"
                              dateFormat="MM/dd/yyyy"
                            />
                            <span id="err">{workOrderDateErr}</span>
                          </div>
                        </div>
                        <div
                          className="form-group row"
                          ref={this.props.scheduleRef}
                        >
                          <label
                            htmlFor="workordertype"
                            className="col-sm-2 col-form-label"
                          >
                            Work Order Type
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-4">
                            <SelectAsyncPaginate
                              onSelectChange={(e) => {
                                this.setState({
                                  workOrderType: e
                                    ? { name: e.order_type }
                                    : '',
                                });
                              }}
                              className="w-100"
                              isSearch={true}
                              disabled={
                                disabledForCust ||
                                roleId === 5 ||
                                roleId === 6 ||
                                (disabledAll && !scheduled && orderId)
                              }
                              value={workOrderType}
                              isClearable={workOrderType}
                              apiname="workordertype"
                              endpoint={Api.getOrderType + `?type=1&search=`}
                              selectPlaceholder="Work Order Type"
                            />

                            <span id="err">{workOrderTypeErr}</span>
                          </div>
                          <label
                            htmlFor="vendorid"
                            className="col-sm-2 col-form-label"
                          >
                            Vendor<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-4">
                            <SelectAsyncPaginate
                              onSelectChange={(e) => {
                                if (e) {
                                  this.setState({
                                    vendorid: e.id,
                                    vendorName: { name: e.name },
                                  });
                                }
                              }}
                              isClearable={vendorid}
                              className="w-100"
                              isSearch={true}
                              // disabled={disabledForCust || roleId === 5 || roleId === 6 || disabledAll || scheduled || orderId}
                              disabled={orderId}
                              value={vendorName}
                              apiname="vendor"
                              endpoint={Api.vendorsUrl + `?search=`}
                              selectPlaceholder="Vendor"
                            />
                            <span id="err">{vendorErr}</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="homewarntycode"
                            className="col-sm-2 col-form-label"
                          >
                            Home Warranty Company/Source
                            <span className="text-danger">*</span>{' '}
                          </label>
                          <div
                            className={`${
                              roleId === 4 ||
                              roleId === 5 ||
                              roleId === 6 ||
                              disabledAll ||
                              scheduled
                                ? 'col-sm-4'
                                : 'col-sm-4'
                            }`}
                          >
                            <div className="d-flex">
                              <SelectAsyncPaginate
                                onSelectChange={(e) => {
                                  this.setState({
                                    wrntyCompany: e ? e.id : null,
                                    wrntyCompanyName: e ? { name: e.name } : '',
                                  });
                                }}
                                isClearable={wrntyCompanyName}
                                className="w-100"
                                isSearch={true}
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  roleId === 6 ||
                                  disabledAll ||
                                  scheduled
                                }
                                value={wrntyCompanyName}
                                apiname="homewarranty"
                                endpoint={Api.AddWarrantyCompany + `?search=`}
                                selectPlaceholder="Home Warranty Company"
                              />
                              {roleId !== 4 &&
                                roleId !== 5 &&
                                roleId !== 6 &&
                                !disabledAll &&
                                !scheduled && (
                                  <div
                                    className="text-right ml-3"
                                    data-toggle="modal"
                                    data-target="#homewarrantycompany"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <button className="btn btn-light">
                                      <a href="/" className="">
                                        <Edit />
                                      </a>
                                    </button>
                                  </div>
                                )}
                            </div>
                            <span id="err">{wrntyCompErr}</span>
                          </div>
                        </div>
                        <div
                          className="card"
                          style={{
                            border: '1px solid #b9b6b6',
                            padding: '10px',
                            marginBottom: '20px',
                          }}
                        >
                          <div className="form-group row">
                            <label
                              htmlFor="customername"
                              className="col-sm-2 col-form-label"
                            >
                              Customer Name
                              <span className="text-danger">*</span>
                            </label>
                            <div
                              className={`${
                                roleId === 4 ||
                                roleId === 5 ||
                                roleId === 6 ||
                                disabledAll ||
                                (orderId && roleId === 1)
                                  ? 'col-sm-4'
                                  : 'col-sm-4'
                              }`}
                            >
                              <div className="d-flex">
                                <SelectAsyncPaginate
                                  onSelectChange={(e) => {
                                    this.setState(
                                      {
                                        customerId: e ? e.ID : null,
                                        selectedCustomerName: e
                                          ? { name: e.name }
                                          : '',
                                        cntctPrsn: '',
                                        custmrAddress: '',
                                        customerEmail: '',
                                        customersDetailsData: {},
                                      },
                                      () => {
                                        if (e) this.updateData(e.ID);
                                      }
                                    );
                                  }}
                                  isClearable={selectedCustomerName}
                                  className="w-100"
                                  isSearch={true}
                                  disabled={
                                    orderId ||
                                    disabledForCust ||
                                    disabledAll ||
                                    roleId === 5 ||
                                    roleId === 6
                                  }
                                  value={selectedCustomerName}
                                  apiname="customer"
                                  endpoint={
                                    Api.customerActiveUrl + `?type=1&search=`
                                  }
                                  selectPlaceholder="Customer Name"
                                />

                                {!orderId &&
                                  roleId !== 4 &&
                                  roleId !== 5 &&
                                  roleId !== 6 &&
                                  !disabledAll && (
                                    <div
                                      className=" ml-3 text-right"
                                      data-toggle="modal"
                                      data-target="#customer-popup"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <button className="btn btn-light">
                                        <a href="/" className="">
                                          <Edit />
                                        </a>
                                      </button>
                                    </div>
                                  )}
                              </div>
                              <span id="err">{selectedCustomerNameErr}</span>
                            </div>

                            <label
                              htmlFor="contactperson"
                              className="col-sm-2 col-form-label"
                            >
                              Contact Person
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                placeholder="Contact Person"
                                disabled
                                value={cntctPrsn}
                                type="text"
                                id="contactperson"
                                onChange={(e) => {
                                  this.setState({ cntctPrsn: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="customeraddress"
                              className="col-sm-2 col-form-label"
                            >
                              Customer Address
                            </label>
                            <div className="col-sm-4">
                              <textarea
                                className="form-control"
                                type="textarea"
                                id="customeraddress"
                                disabled
                                placeholder="Customer Address"
                                style={{ resize: 'none' }}
                                rows="2"
                                cols="80"
                                value={custmrAddress}
                                onChange={(e) => {
                                  this.setState({
                                    custmrAddress: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <label
                              htmlFor="customer#"
                              className="col-sm-2 col-form-label"
                            >
                              Customer#
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                placeholder="Customer#"
                                disabled
                                value={customerId}
                                type="text"
                                id="customer#"
                                onChange={(e) => {
                                  this.setState({ customerId: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          {roleId !== 4 && (
                            <div className="form-group row">
                              {(customersDetailsData.PhoneNumber1 ||
                                customersDetailsData.PhoneNumber2 ||
                                customersDetailsData.PhoneNumber3 ||
                                customersDetailsData.PhoneNumber4) && (
                                <>
                                  <label
                                    htmlFor="customer#"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Customer Phone Number
                                  </label>
                                  <div className="col-sm-4">
                                    <span className="mdi mdi-phone-classic"></span>
                                    <a
                                      className="m-l-10"
                                      href={`tel:${
                                        customersDetailsData.PhoneNumber1 ||
                                        customersDetailsData.PhoneNumber2 ||
                                        customersDetailsData.PhoneNumber3 ||
                                        customersDetailsData.PhoneNumber4
                                          ? customersDetailsData.PhoneNumber1 ||
                                            customersDetailsData.PhoneNumber2 ||
                                            customersDetailsData.PhoneNumber3 ||
                                            customersDetailsData.PhoneNumber4
                                          : ''
                                      }`}
                                    >
                                      {customersDetailsData.PhoneNumber1 ||
                                      customersDetailsData.PhoneNumber2 ||
                                      customersDetailsData.PhoneNumber3 ||
                                      customersDetailsData.PhoneNumber4
                                        ? customersDetailsData.PhoneNumber1 ||
                                          customersDetailsData.PhoneNumber2 ||
                                          customersDetailsData.PhoneNumber3 ||
                                          customersDetailsData.PhoneNumber4
                                        : ''}
                                    </a>
                                  </div>
                                </>
                              )}
                              {customerEmail && (
                                <>
                                  <label
                                    htmlFor="CustomerEmail"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Customer Email
                                  </label>
                                  <div className="col-sm-4">
                                    <a href={`mailto:${customerEmail}`}>
                                      {customerEmail}
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          <div className="form-group justify-content-between row d-flex">
                            {roleId !== 4 && customerId && (
                              <div className="col-sm-2">
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    SetMessageRecieverData(custdata);
                                    SetMessageBoxToggle(true);
                                    this.setState({
                                      messageBoxToggle: !messageBoxToggle,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-comment-multiple-outline m-r-10"></i>
                                  Message
                                </a>
                              </div>
                            )}
                            {orderId &&
                              !disabledForCust &&
                              // WorkStatus !== 'Completed' &&
                              // WorkStatus !== 'Paused' &&
                              WorkStatus !== 'Offered' && (
                                <>
                                  <div className="col-sm-4">
                                    <a
                                      href="/"
                                      type=""
                                      className="btn btn-primary"
                                      style={{ padding: '3px 10px' }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          customerMsgBox: true,
                                          status: 'Unable to Contact Customer',
                                        });
                                      }}
                                    >
                                      Left Customer a Message
                                    </a>
                                  </div>
                                </>
                              )}

                            <div className="col-sm-6">
                              {orderId && !disabledForCust && (
                                <>
                                  <a
                                    href="/"
                                    type=""
                                    className="btn btn-primary"
                                    style={{ padding: '3px 10px' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        customerMsgBox: true,
                                        status: 'Customer Missed Appointment',
                                      });
                                    }}
                                  >
                                    Customer Missed Appointment
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          id="technician2"
                          ref={this.props.scheduleRef}
                          tabIndex="-1"
                        >
                          {((orderId &&
                            ((technicianObject &&
                              technicianObject.length &&
                              technicianObject[0].technician !== '') ||
                              scheduled ||
                              (workCompleteValue && workCompleteValue.id))) ||
                            !orderId) && (
                            <div
                              className="card"
                              style={{
                                border: '1px solid #b9b6b6',
                                padding: '10px',
                                marginBottom: '20px',
                              }}
                            >
                              {/*{technicianObject && technicianObject.length > 0 && (*/}
                              <div className="form-group row">
                                <label
                                  htmlFor="apptdate"
                                  className="col-sm-2 col-form-label"
                                >
                                  1st Appt Date
                                </label>
                                <div
                                  className="col-sm-4"
                                  style={{ marginBottom: '10px' }}
                                >
                                  <DatePicker
                                    className="form-control"
                                    disabled={
                                      disabledForCust ||
                                      (disabledAll &&
                                        !isWorkOrderCompletedHistory) ||
                                      (!scheduled && orderId)
                                    }
                                    placeholderText="Select 1st Appt Date"
                                    selected={ApptDate}
                                    onChange={(e) => {
                                      swal(
                                        'Please wait, we are checking technician availability on selected date',
                                        '',
                                        'success',
                                        {
                                          closeOnClickOutside: false,
                                        }
                                      ).then(() => {});

                                      // if (!isWorkOrderCompletedHistory) {
                                      //   this.setState({
                                      //     technicianObject: [
                                      //       {
                                      //         technician: '',
                                      //         technicianFunction: '',
                                      //         selectedToggle: true,
                                      //         isPrimary: 0,
                                      //         selectedOption: [],
                                      //         selecteFunctiondOption: [],
                                      //       },
                                      //     ],
                                      //   });
                                      // }
                                      if (isWorkOrderCompletedHistory) {
                                        technicianObject &&
                                          technicianObject.length > 0 &&
                                          technicianObject.map(
                                            (tech, index) => {
                                              if (!tech.isworked) {
                                                technicianObject.splice(
                                                  index,
                                                  5
                                                );
                                              }
                                              this.setState({
                                                technicianObject,
                                              });
                                              return tech;
                                            }
                                          );
                                      }
                                      this.setState(
                                        {
                                          ApptDate:
                                            customFunctions.addTimeToDate(e),
                                        },
                                        () => {
                                          this.getTechnician(
                                            customFunctions.addTimeToDate(e)
                                          );
                                        }
                                      );
                                      this.restrictApptDate(e);
                                    }}
                                    name="startDate"
                                    dateFormat="MM/dd/yyyy"
                                    minDate={minimunDate}
                                    maxDate={this.setMaxDate()}
                                  />
                                  <span id="err">{ApptDateErr}</span>
                                </div>

                                <label
                                  htmlFor="appttime"
                                  className="col-sm-2 col-form-label"
                                >
                                  Appt Time
                                </label>
                                <div className="col-sm-4">
                                  <div className="row">
                                    <div className="row w-100 time-picker-input1">
                                      <div className="col-sm-4">
                                        <p>From Time</p>
                                      </div>
                                      <div className="col-sm-8">
                                        <DatePicker
                                          selected={FromTime}
                                          placeholderText="From Time"
                                          className="form-control mb-2"
                                          disabled={
                                            disabledForCust ||
                                            !ApptDate ||
                                            (disabledAll &&
                                              !isWorkOrderCompletedHistory) ||
                                            (!scheduled && orderId)
                                          }
                                          onChange={(date) => {
                                            const myFutureTime = new Date(date);
                                            if (
                                              rangeMinutes &&
                                              rangeMinutes.value
                                            ) {
                                              let res = myFutureTime.setMinutes(
                                                myFutureTime.getMinutes() +
                                                  rangeMinutes.value
                                              );
                                              this.setState({
                                                ToTime: new Date(res),
                                              });
                                            }
                                            this.setState(
                                              {
                                                FromTime: date,
                                                startMinutes: date.getMinutes(),
                                                startHours: date.getHours(),
                                              },
                                              () => {
                                                this.forceUpdate();
                                              }
                                            );
                                          }}
                                          showTimeSelect
                                          timeIntervals={1}
                                          showTimeSelectOnly
                                          minTime={
                                            ApptDate > new Date()
                                              ? undefined
                                              : setMinutes(
                                                  new Date(),
                                                  new Date().getMinutes()
                                                )
                                          }
                                          maxTime={
                                            ApptDate > new Date()
                                              ? undefined
                                              : setHours(
                                                  setMinutes(new Date(), 59),
                                                  23
                                                )
                                          }
                                          timeCaption="From"
                                          dateFormat="h:mm aa"
                                        />
                                      </div>
                                      <span id="err">{apptFromTimeErr}</span>
                                    </div>
                                    <div className="row w-100 time-picker-input2">
                                      <div className="col-sm-4">
                                        <p>Time Slot</p>
                                      </div>
                                      <div className="col-sm-8">
                                        <SelectStaticDropdown
                                          onSelectChange={(e) => {
                                            if (e) {
                                              const myFutureTime = new Date(
                                                FromTime
                                              );
                                              let res = myFutureTime.setMinutes(
                                                myFutureTime.getMinutes() +
                                                  Number(e.value)
                                              );
                                              this.setState(
                                                {
                                                  ToTime: new Date(res),
                                                  rangeMinutes: e
                                                    ? {
                                                        name: e.name,
                                                        value: Number(e.value),
                                                      }
                                                    : '',
                                                },
                                                () => {
                                                  this.forceUpdate();
                                                }
                                              );
                                            }
                                          }}
                                          disabled={
                                            disabledForCust ||
                                            !FromTime ||
                                            (disabledAll &&
                                              !isWorkOrderCompletedHistory) ||
                                            (!scheduled && orderId)
                                          }
                                          className="w-100 pb-2"
                                          isSearch={false}
                                          value={rangeMinutes}
                                          isClearable={rangeMinutes}
                                          dropdownType="timeRange"
                                          selectPlaceholder="Select Range"
                                        />
                                        <span id="err">{apptToTimeErr}</span>
                                      </div>
                                    </div>
                                    <div className="row w-100 time-picker-input1">
                                      <div className="col-sm-4">
                                        <p>To Time</p>
                                      </div>
                                      <div className="col-sm-8">
                                        <DatePicker
                                          selected={ToTime}
                                          placeholderText="To Time"
                                          className="form-control pr-0"
                                          disabled
                                          minDate={new Date(FromTime)}
                                          minTime={setHours(
                                            setMinutes(
                                              new Date(),
                                              startMinutes
                                            ),
                                            startHours
                                          )}
                                          maxTime={setHours(
                                            setMinutes(new Date(), 59),
                                            23
                                          )}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={1}
                                          timeCaption="To"
                                          dateFormat="h:mm aa"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*)}*/}
                              <div>
                                {technicianObject &&
                                  technicianObject.map((object, index) => (
                                    <div
                                      className="form-group row"
                                      key={`technician-${index}`}
                                    >
                                      <label
                                        htmlFor="technician"
                                        className="col-sm-2 col-form-label"
                                      >
                                        Technician
                                      </label>

                                      <div
                                        className={`${
                                          roleId === 4 ||
                                          roleId === 5 ||
                                          roleId === 6 ||
                                          disabledAll ||
                                          (!scheduled && orderId)
                                            ? 'col-sm-4'
                                            : 'col-sm-4'
                                        }`}
                                      >
                                        <div className="d-flex wo-technician-block">
                                          {/* <SelectAsyncPaginate
                                            onSelectChange={(e) => {
                                              if(e){
                                                this.handleTechnicianChange(
                                                  e,
                                                  index
                                                )
                                              }else{
                                                technicianObject[index].technician = '';
                                                technicianObject[index].selectedOption = '';
                                                technicianObject[index].selectedToggle = true;
                                                technicianObject[index].selecteFunctiondOption = '';
                                                technicianObject[index].technicianFunction = '';
                                                
                                                this.setState({technicianObject, clearSelected: true},()=>console.log(technicianObject));
                                              }
                                            }}
                                            clearSelected={clearSelected}
                                            className="w-100"
                                            isSearch={true}
                                            disabled={disabledForCust ||
                                              (roleId == 5 &&
                                                object.isPrimary === 1) ||
                                              object.isworked ||
                                              (!scheduled && orderId)}
                                            value={object.selectedOption || null}
                                            // changeData={(searchKeyword) =>
                                            //   this.getTechnician(searchKeyword)
                                            // }
                                            apiname='technician'
                                            endpoint={Api.technicianUrl+`?onleave=approved&role=5&search=`}
                                            selectPlaceholder="Technician"
                                          /> */}

                                          <ReactSelect
                                            components={this.components}
                                            value={object.selectedOption}
                                            isDisabled={
                                              disabledForCust ||
                                              ((roleId === 5 || roleId === 6) &&
                                                object.isPrimary === 1) ||
                                              object.isworked ||
                                              (!scheduled && orderId) ||
                                              (!orderId && !ApptDate)
                                            }
                                            placeholder="Select Technician"
                                            onChange={(values) => {
                                              this.handleTechnicianChange(
                                                values,
                                                index
                                              );
                                            }}
                                            options={techniciandata}
                                          />

                                          {roleId !== 4 &&
                                            roleId !== 5 &&
                                            roleId !== 6 &&
                                            !disabledAll &&
                                            ((scheduled && orderId) ||
                                              (!scheduled && !orderId)) && (
                                              <div
                                                className="ml-3 text-right"
                                                data-toggle="modal"
                                                data-target="#technician"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                <button className="btn btn-light">
                                                  <a href="/" className="">
                                                    <Edit />
                                                  </a>
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                        <span id="err">
                                          {technicianErr.length > 0 &&
                                            technicianErr[index]}
                                        </span>
                                      </div>

                                      <label
                                        htmlFor="technicianFunction"
                                        className="col-sm-2 col-form-label"
                                      >
                                        Technician Function
                                      </label>
                                      <div
                                        className={`${
                                          roleId === 4 ||
                                          disabledAll ||
                                          (!scheduled && orderId)
                                            ? 'col-sm-3'
                                            : 'col-sm-3'
                                        }`}
                                      >
                                        <ReactSelect
                                          components={this.components}
                                          placeholder="Technician Function"
                                          value={object.selecteFunctiondOption}
                                          onChange={(values) =>
                                            this.handleTechFunctionChange(
                                              values,
                                              index
                                            )
                                          }
                                          options={techFunction}
                                          isDisabled={
                                            object.selectedToggle ||
                                            ((roleId === 5 || roleId === 6) &&
                                              object.isPrimary === 1) ||
                                            disabledForCust ||
                                            object.isworked ||
                                            (disabledAll && object.isworked) ||
                                            (!scheduled && orderId) ||
                                            index === 0
                                          }
                                        />
                                        <span id="err">
                                          {technicianFunctionErr.length > 0 &&
                                            technicianFunctionErr[index]}
                                        </span>
                                      </div>
                                      {((roleId !== 4 &&
                                        roleId !== 5 &&
                                        !disabledAll &&
                                        !object.isworked &&
                                        index !== 0) ||
                                        (index === 0 &&
                                          technicianObject.length === 1) ||
                                        (roleId === 5 &&
                                          object.isPrimary !== 1)) &&
                                        ((scheduled && orderId) ||
                                          (!scheduled && !orderId)) && (
                                          <i
                                            className="icon-container mdi mdi-close"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.removeTechnicianObject(
                                                index,
                                                object
                                              );
                                            }}
                                          />
                                        )}
                                    </div>
                                  ))}
                              </div>

                              {roleId !== 4 &&
                                addToggle &&
                                ((scheduled && orderId) ||
                                  (!scheduled && !orderId)) && (
                                  <div className="form-group row">
                                    <Button
                                      variant="link"
                                      onClick={() => this.addTechnicianObject()}
                                    >
                                      <i
                                        style={{
                                          color: 'blue',
                                        }}
                                        className="mdi mdi-plus-circle"
                                        id="editDriver"
                                        title="View agent"
                                      >
                                        {' '}
                                        Add technician
                                      </i>
                                    </Button>
                                  </div>
                                )}
                              {scheduled &&
                                ((disabledAll && isWorkOrderCompletedHistory) ||
                                  !disabledAll) && (
                                  <div className="form-group row justify-content-center">
                                    <button
                                      className="btn btn-secondary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState(
                                          {
                                            WorkStatus: 'Scheduled',
                                            scheduled: false,
                                          },
                                          () => {
                                            this.validateForm(e);
                                          }
                                        );
                                      }}
                                    >
                                      Schedule
                                    </button>
                                    <button
                                      className="btn btn-secondary ml-2"
                                      onClick={(e) => {
                                        this.setState({ scheduled: false });
                                        this.getOrderData(orderId);
                                        this.flushAllData();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="jobtitle"
                            className="col-sm-2 col-form-label"
                          >
                            Job Title<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              placeholder="Job Title"
                              value={jobtitle}
                              disabled={
                                disabledForCust ||
                                roleId === 5 ||
                                roleId === 6 ||
                                disabledAll ||
                                scheduled
                              }
                              type="text"
                              id="jobtitle"
                              onChange={(e) => {
                                this.setState({ jobtitle: e.target.value });
                              }}
                            />
                            <span id="err">{jobtitleErr}</span>
                          </div>
                          <label
                            htmlFor="jobtotal"
                            className="col-sm-2 col-form-label"
                          >
                            Job Total
                          </label>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="jobTotal"
                                >
                                  $
                                </span>
                              </div>
                              <input
                                className="form-control"
                                value={jobTotal}
                                type="text"
                                disabled
                                // disabled={
                                //   disabledForCust ||
                                //   roleId === 5 ||
                                //   disabledAll ||
                                //   scheduled
                                // }
                                placeholder="$0.00"
                                id="jobtotal"
                                onChange={(e) => {
                                  const re = /^[0-9]*\.?[0-9]*$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({ jobTotal: e.target.value });
                                  }
                                }}
                              />
                            </div>
                            <span id="err">{jobTotalErr}</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-4">
                                <label htmlFor="jobdesc">Job Description</label>
                              </div>
                              <div className="col-8">
                                <textarea
                                  className="form-control"
                                  disabled={
                                    disabledForCust || disabledAll || scheduled
                                  }
                                  placeholder="some description goes here"
                                  type="textarea"
                                  id="jobdesc"
                                  style={{ resize: 'none', minHeight: '140px' }}
                                  cols="90"
                                  value={jobDesc}
                                  onChange={(e) => {
                                    this.setState({ jobDesc: e.target.value });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-4">
                                <label htmlFor="repairdesc">
                                  Repair Description
                                </label>
                              </div>
                              <div className="col-8">
                                <textarea
                                  className="form-control"
                                  disabled={
                                    disabledForCust || disabledAll || scheduled
                                  }
                                  placeholder="some description goes here"
                                  type="textarea"
                                  id="repairdesc"
                                  style={{ resize: 'none', minHeight: '140px' }}
                                  cols="90"
                                  value={repairDesc}
                                  onChange={(e) => {
                                    this.setState({
                                      repairDesc: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="techdeduction"
                            className="col-sm-2 col-form-label"
                          >
                            Tech Deduction
                          </label>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="jobTotal"
                                >
                                  $
                                </span>
                              </div>
                              <input
                                className="form-control"
                                value={techDeduction}
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  roleId === 6 ||
                                  disabledAll ||
                                  scheduled
                                }
                                type="text"
                                placeholder="Tech Deduction"
                                id="techDeduction"
                                onChange={(e) => {
                                  const re = /^[0-9]*\.?[0-9]*$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      techDeduction: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <label
                            htmlFor="techdeduction"
                            className="col-sm-2 col-form-label"
                          >
                            Note For Reason
                          </label>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <textarea
                                className="form-control"
                                placeholder="Note for reason"
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  roleId === 6 ||
                                  disabledAll ||
                                  scheduled
                                }
                                type="textarea"
                                id="repairdesc"
                                style={{ resize: 'none', minHeight: '140px' }}
                                cols="90"
                                value={noteForReason}
                                onChange={(e) => {
                                  this.setState({
                                    noteForReason: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="installerid"
                            className="col-sm-2 col-form-label"
                          >
                            Installer Id
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              value={installerId}
                              type="text"
                              disabled={
                                disabledForCust ||
                                roleId === 5 ||
                                roleId === 6 ||
                                disabledAll ||
                                scheduled
                              }
                              placeholder="Installer Id"
                              id="installerid"
                              onChange={(e) => {
                                this.setState({ installerId: e.target.value });
                              }}
                            />
                          </div>
                          {orderId && (
                            <div className="col-sm-6">
                              <div className="row">
                                <label
                                  htmlFor="workcomplete"
                                  className="col-sm-4 col-form-label"
                                >
                                  Work Complete
                                </label>
                                <div className="col-sm-8">
                                  <SelectStaticDropdown
                                    onSelectChange={(e) => {
                                      this.setState(
                                        {
                                          workCompleteValue: e
                                            ? { id: e.id, name: e.name }
                                            : '',
                                        },
                                        () => {
                                          if (e)
                                            this.updateCompletionDate(e.id);
                                          if (!e) this.updateCompletionDate('');
                                          this.forceUpdate();
                                        }
                                      );
                                    }}
                                    disabled={true}
                                    className="w-100"
                                    isSearch={false}
                                    value={workCompleteValue}
                                    isClearable={workCompleteValue}
                                    dropdownType="workComplete"
                                    selectPlaceholder="Work Complete"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {orderId && (
                          <div className="form-group row">
                            <label
                              htmlFor="createdatetime"
                              className="col-sm-2 col-form-label"
                            >
                              Created Date Time
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={
                                  createdDateTime &&
                                  customFunctions.getMDYDateTime(created)
                                }
                                type="text"
                                id="createdatetime"
                                onChange={(e) => {
                                  this.setState({
                                    createdDateTime: e.target.value,
                                  });
                                }}
                                disabled
                              />
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group row">
                                {updatedTime && (
                                  <>
                                    <label
                                      htmlFor="updated"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Updated Date Time
                                    </label>
                                    <div className="col-sm-8">
                                      <input
                                        className="form-control"
                                        value={
                                          updatedTime &&
                                          customFunctions.getMDYDateTime(
                                            updated
                                          )
                                        }
                                        type="text"
                                        id="updated"
                                        onChange={(e) => {
                                          this.setState({
                                            updatedTime: e.target.value,
                                          });
                                        }}
                                        disabled
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {(roleId === 1 || roleId === 2) && authRequired && (
                          <>
                            <div className="form-group row">
                              <label
                                htmlFor="authNumber"
                                className="col-sm-2 col-form-label"
                              >
                                Autho Number
                              </label>
                              <div className="col-sm-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={authNumber}
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.alphaNumericMasking(
                                        e.target.value
                                      );
                                    this.setState({
                                      authNumber: event.target.value,
                                    });
                                  }}
                                />
                              </div>

                              <label
                                htmlFor="authAmount"
                                className="col-sm-2 col-form-label"
                              >
                                Autho Amount
                              </label>
                              <div className="col-sm-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={authAmount}
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMaskingDecimal(
                                        e.target.value
                                      );
                                    this.setState({
                                      authAmount: event.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="ho_oops"
                                className="col-sm-2 col-form-label"
                              >
                                Home Owner OOPs
                              </label>
                              <div className="col-sm-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Home Owner OOPs"
                                  value={ho_oops}
                                  onChange={(e) => {
                                    this.setState({ ho_oops: e.target.value });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {roleId !== 4 && !disabledAll && !scheduled && (
                          <div className="form-group row d-flex justify-content-center">
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.flushAllData();
                                  this.props.history.goBack();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <AddTechnician
          getTechnicianCallback={() => {
            this.getTechnician();
          }}
        />
        <AddWarrantyCompany
        // getWarrantyCompanyCallback={() => {
        //   this.getHomeWrntyCompany();
        // }}
        />
        <AddCustomerPopup
        // getCustomerListCallback={() => {
        //   this.getCustomerLIst();
        // }}
        />
        <AddPaymentType
        // getPaymentTypeCallback={() => {
        //   this.getPaymentType();
        // }}
        />
        <CustomerMessagePopup
          customerMsgBox={customerMsgBox}
          callbackfun={() => {
            this.getOrderData(orderId);
            this.props.statusChangeCallback(orderId);
          }}
          popupClose={() => this.setState({ customerMsgBox: !customerMsgBox })}
          customerId={customerId}
          orderId={orderId}
          technicianId={technicianId}
          status={status}
          fromComponent="workOrder"
        />
        {messageBoxToggle && (
          <MessageBox
            onCallbackBox={() =>
              this.setState({ messageBoxToggle: !messageBoxToggle })
            }
          />
        )}
      </AUX>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetMessageBoxToggle: (data) => dispatch(SetMessageBoxToggle(data)),
  SetChatNotificationCount: (data) => dispatch(SetChatNotificationCount(data)),
  SetMessageRecieverData: (data) => dispatch(SetMessageRecieverData(data)),
});

const mapStateToProps = (state) => ({
  messageBoxToggle: state.auth.messageBoxToggle,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrder)
);

import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import Complaint from '../Complaints/index';
import FileAttachment from '../Workorder/FileAttachment.component';
import TechnicianWorkOrder from '../Workorder/orderDetails';
import ProfileLoss from '../Workorder/ProfitLoss';
import PurchaseView from '../Workorder/purchaseView';
import TechnicianReport from '../Reports/technicians';
// import TechPayroll from '../TechPayroll/TechPayrollListing';
import AddTechnician from './addTechnician';
import TechnicianContracts from './technicianContracts';
import TechnicianParts from './technicianParts';
import Loader from '../../../components/Loader';
import swal from 'sweetalert';
import DispatcherReport from '../Reports/dispatcherReport';
import TechPaySheet from '../Payroll/paysheet';

class ViewTechnician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: null,
      keyword: '',
      technicians: [],
      technicianDetails: {},
      fromComponent: '',
      userName: '',
      roleId: null,
      submodulesData: [],
      isShowLoader: false,
      paginationOptions: {},
      reportType: '',
    };
  }

  componentDidMount = async () => {
    // let isWorkorderAllowed = await customFunctions.isAllowedRoute('submodule', 'ahsworkorders-accessahsworkorders', 'write');
    // let isAllowed = await customFunctions.isAllowedRoute('submodule', 'technician-accesstechnician', 'write');
    // this.setState({isWorkorderAllowed});
    // if(!isAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
    await this.getPermissions(userdata.role_id);

    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    customFunctions.setTitle('View Technician');
    if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.customerId)
    ) {
      this.setState({ customerId: this.props.match.params.customerId });
    } else if (
      (await this.props) &&
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.technicianDetails)
    ) {
      this.setState({
        technicianDetails: await this.props.location.state.technicianDetails,
        reportType: await this.props.location.state.reportType,
      });
      if (this.props.location.state.fromComponent === 'profile') {
        customFunctions.setTitle('My Profile');
        this.setState({ fromComponent: 'profile' });
        await this.getProfileData(userdata.login_id);
      }
      if (this.props.location.state.fromComponent === 'technician') {
        this.setState({ fromComponent: 'technician' });
        const url_segment = this.props.match.url.split('/');
        if (url_segment && url_segment.includes('edit-technician')) {
          customFunctions.setTitle('Edit Technician');
        }
      }
    }
    // else {
    //   this.props.history.goBack();
    //   return;
    // }
  };

  getPermissions = async (role_id) => {
    const endPoint = `${Api.rolePermissionUrl}?role_id=${role_id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.success !== false) {
      let submodulesData = Object.keys(data.data.submodule);
      this.setState({ submodulesData });
      customFunctions.setStringifyDataToLocalStorage('permissions', data.data);
    }
  };

  getProfileData = async (userId) => {
    // this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getAdmin}?id=${userId}`
    );
    if (data && data.data) {
      this.setState({
        userName: data.data.first_name,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getTechnicians();
  };

  clearSearch = () => {
    this.setState({ keyword: '', technicians: [] });
  };

  getTechnicians = async () => {
    const { keyword } = this.state;
    this.setState({ isShowLoader: false });
    const endPoint = `${Api.technicianUrl}?search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      this.setState({
        technicians: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTechnicianName = (name) => {
    const { technicianDetails } = this.state;
    technicianDetails.name = name;
    this.setState({ technicianDetails });
  };

  render() {
    const {
      technicianDetails,
      isWorkorderAllowed,
      fromComponent,
      submodulesData,
      isShowLoader,
      paginationOptions,
      reportType,
      roleId,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card m-b-23">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 style={{ paddingLeft: '12px' }}>
                        {(technicianDetails && technicianDetails.name) || '-'}
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 text-right">
                          <form
                            onSubmit={this.onSearchSubmit}
                            autoComplete="off"
                          >
                            <div className="form-group row">
                              <div className="col-sm-5 col-md-5">
                                {/* <div
                                  id="myDropdown"
                                  className="dropdown-content"
                                >
                                  <input
                                    className="form-control"
                                    value={keyword}
                                    type="text"
                                    placeholder="Search here..."
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.setState({
                                        keyword: e.target.value,
                                      });
                                      if (!e.target.value) this.clearSearch();
                                    }}
                                    required={true}
                                    autoComplete="off"
                                  />
                                  {technicians && technicians.length > 0 && (
                                    <div>
                                      {technicians.map((item, index) => (
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              `/view-technician/${item.id}`,
                                              {
                                                technicianDetails: item,
                                                type: 'technician',
                                                detail: item,
                                              }
                                            );
                                            window.location.reload();
                                          }}
                                          key={`${index}-technician-list`}
                                        >
                                          {item.name}
                                        </a>
                                      ))}
                                    </div>
                                  )}
                                </div> */}
                              </div>
                              <div
                                className="col-sm-7 col-md-7"
                                style={{ paddingRight: '22px' }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light offset-md-2"
                                  onClick={() => {
                                    if (
                                      this.props &&
                                      this.props.location &&
                                      this.props.location.state &&
                                      this.props.location.state.path
                                    ) {
                                      this.props.history.push(
                                        `${this.props.location.state.path}`,
                                        {
                                          paginationObj: paginationOptions,
                                        }
                                      );
                                    } else {
                                      this.props.history.goBack();
                                    }
                                  }}
                                >
                                  Back
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-wrapper">
                    <div className="container-fluid">
                      <Tabs defaultActiveKey={'Technician Details'}>
                        <Tab
                          eventKey="Technician Details"
                          title="Profile Details"
                        >
                          <AddTechnician
                            fromComponent={'profile'}
                            setTechnicianName={(name) =>
                              this.setTechnicianName(name)
                            }
                          />
                        </Tab>
                        {isWorkorderAllowed &&
                          submodulesData.includes(
                            'techniciantabs-accessworkorders'
                          ) && (
                            <Tab eventKey="Work Orders" title="Work Orders">
                              <TechnicianWorkOrder />
                            </Tab>
                          )}
                        {submodulesData.includes(
                          'techniciantabs-accesspurchaseorders'
                        ) && (
                          <Tab
                            eventKey="Purchase Orders"
                            title="Purchase Orders"
                          >
                            <PurchaseView />
                          </Tab>
                        )}
                        {submodulesData.includes(
                          'techniciantabs-accesscustomercomplaints'
                        ) && (
                          <Tab
                            eventKey="Customer Complaints"
                            title="Customer Complaints"
                          >
                            <Complaint />
                          </Tab>
                        )}
                        {/* <TechPayroll /> */}
                        {submodulesData.includes(
                          'techniciantabs-accesspaysheet'
                        ) && (
                          <Tab eventKey={'Paysheet'} title="Paysheet">
                            {technicianDetails && technicianDetails.id && (
                              <TechPaySheet
                                fromComponent="viewTechnician"
                                technicianId={
                                  technicianDetails && technicianDetails.id
                                }
                              />
                            )}
                          </Tab>
                        )}
                        {submodulesData.includes(
                          'techniciantabs-accessprofitloss'
                        ) &&
                          reportType === 'technician' && (
                            <Tab eventKey="Profit/Loss" title="Profit/Loss">
                              {technicianDetails && technicianDetails.id && (
                                <ProfileLoss
                                  technicianId={
                                    technicianDetails && technicianDetails.id
                                  }
                                />
                              )}
                            </Tab>
                          )}
                        {submodulesData.includes(
                          'techniciantabs-accessparts'
                        ) && (
                          <Tab eventKey="Parts" title="Parts">
                            {technicianDetails && technicianDetails.id && (
                              <TechnicianParts
                                fromComponent="viewTechnician"
                                technicianId={
                                  technicianDetails && technicianDetails.id
                                }
                              />
                            )}
                          </Tab>
                        )}
                        {submodulesData.includes(
                          'techniciantabs-accessattachment'
                        ) && (
                          <Tab eventKey="Attachment" title="Attachment">
                            <FileAttachment />
                          </Tab>
                        )}
                        {technicianDetails &&
                          technicianDetails.id &&
                          fromComponent &&
                          submodulesData.includes(
                            'techniciantabs-accessreports'
                          ) && (
                            <Tab eventKey="Reports" title="Reports">
                              {[1, 5].includes(roleId) && (
                                <TechnicianReport
                                  fromComponent={fromComponent}
                                  technicianId={technicianDetails.id}
                                  technicianDetails={technicianDetails}
                                />
                              )}
                              {[1, 3].includes(roleId) && (
                                <DispatcherReport
                                  fromComponent={fromComponent}
                                  dispatcherId={technicianDetails.id}
                                  technicianDetails={technicianDetails}
                                />
                              )}
                            </Tab>
                          )}

                        {technicianDetails &&
                          technicianDetails.id &&
                          submodulesData.includes(
                            'techniciantabs-accesscontracts'
                          ) && (
                            <Tab eventKey="Contracts" title="Contracts">
                              <TechnicianContracts
                                fromComponent="technician"
                                technicianId={technicianDetails.id}
                              />
                            </Tab>
                          )}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(ViewTechnician);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import swal from 'sweetalert';
import { uploadFile } from 'react-s3';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class addFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      imageFile: '',
      imageErr: '',
      technicianId: null,
      uploadComplete: true,
    };
  }

  validateForm = (e) => {
    e.preventDefault();
    const { imageFile } = this.state;

    let isValid = true,
      imageErr = '';
    if (!imageFile) {
      imageErr = 'Image is required';
      isValid = false;
    }
    this.setState({ imageErr });

    if (isValid) {
      this.handleClick();
      this.uploadFileDocument();
    }
  };

  uploadFileDocument = async (props) => {
    const { uploadComplete, imageFile } = this.state;
    if (!uploadComplete) return;

    const userData = JSON.parse(localStorage.getItem('userdata'));
    const techId = userData.login_id;
    const payloadData = [];

    const postData = {
      tech_id: techId,
      type: 'pdf',
      is_approved: Number(0),
    };
    this.setState({
      isShowLoader: true,
    });
    if (imageFile) {
      const imgUrl = await this.uploadImage();
      if (imgUrl) postData.url = imgUrl;
    }
    payloadData.push(postData);
    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.AddtechnicianUrl}/addTechnicianAttachments`;
    const { data, message } = await callback(endPoint, payloadData);
    if (data && data.status !== 'Fail') {
      swal('Attachment added successfully', '', 'success');
      this.resetData();
      return;
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  OnImageChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    this.setState({
      imageFile: e.target.files[0],
      imageUrl: fileUrl,
    });
  };

  uploadImage = async () => {
    const { imageFile } = this.state;
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `attachments/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    this.setState({
      isShowLoader: false,
    });
    return imgData;
  };

  resetData = () => {
    document.getElementById('addFile').reset();
    this.setState({ imageFile: '' });
  };

  handleClick = () => {
    this.refs.btn.setAttribute('disabled', 'disabled');

    setTimeout(() => {
      this.refs.btn.removeAttribute('disabled');
    }, 2000);
  };

  render() {
    const { imageErr } = this.state;
    return (
      <div className="card m-b-20">
        <div className="p-r-10 p-t-10">
          <div className="d-flex justify-content-end">
            <div className="card-body">
              <form onSubmit={this.validateForm} id="addFile">
                <div className="form-group row">
                  <label
                    htmlFor="example-search-input"
                    className="col-sm-4 col-form-label"
                  >
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-5">
                    <div
                      className="input-group"
                      id="tagImageUpload"
                      style={{ display: 'block' }}
                    >
                      <input
                        id="imageUpload"
                        type="file"
                        className="tagImageUpload"
                        onChange={(e) => {
                          this.OnImageChange(e);
                        }}
                        accept=".pdf,image/jpeg,image/png,"
                      />
                    </div>
                    <span className="text-danger">{imageErr}</span>
                  </div>
                </div>

                <div className="centeralign">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    ref="btn"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    onClick={() => this.resetData()}
                    className="btn btn-secondary waves-effect m-l-10"
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(addFile);

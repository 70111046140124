import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

class WorkOrderParts extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      partsData: [],
      partId: '',
      page: 1,
      limit: 100,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isShowLoader: false,
      workorderid: null,
      disabledAll: false,
      roleId: null,
      sourceType: null,
      columns: [],
      showNote: false,
      note: '',
      noteAhsType: '',
      noteCustType: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });

    if (userdata.role_id === 4) {
      this.setState({ disabledAll: true });
    }
    if (this.props && this.props.workorderid) {
      this.setState(
        {
          workorderid: this.props.workorderid,
          sourceType:
            this.props.orderDetails && this.props.orderDetails.sourceid,
        },
        () => {
          this.getWorkOrderParts();
        }
      );
    }

    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Description',
        field: 'description',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Quantity',
        field: 'quantity',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Price',
        field: 'price',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Total',
        field: 'total',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Note',
        field: 'note',
        sort: 'disabled',
        width: 150,
      },
      // {
      //   label: 'Is Waiting',
      //   field: 'iswaiting',
      //   sort: 'disabled',
      //   width: 150,
      // },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 150,
      },
      // {
      //   label: 'Action',
      //   field: 'action',
      //   sort: 'disabled',
      // },
    ];
    if (userdata.role_id === 1 || userdata.role_id === 5) {
      let share = {
        label: 'Share',
        field: 'share',
        sort: 'disabled',
        width: 250,
      };
      columns.splice(7, 0, share);
    }
    this.setState({ columns });
  };

  changePartStatus = async (part, event) => {
    const { currentPage, workorderid } = this.state;
    // const payload = {
    //   id: part.id,
    //   partid: part.partid,
    //   quantity: part.quantity,
    //   note: part.note,
    //   iswaiting: 0,
    // };

    const payload = {
      id: part.id,
      work_order_id: workorderid,
      parts: [
        {
          partid: part.partid,
          quantity: part.quantity,
        },
      ],
    };

    const { data, message } = await AuthApi.postDataToServer(
      Api.addWaitingWorkOrderPartsUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('Part status changed!', '', 'success').then(() => {
        this.getWorkOrderParts(currentPage);
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = () => {
    const { partsData, currentPage, limit, disabledAll, roleId, workorderid } =
      this.state;
    const page = currentPage || 1;
    const rows = [];
    partsData &&
      partsData.map((part, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          name: part.name,
          description: part.description,
          note: part.note,
          quantity: part.quantity,
          total: part.total,
          price: part.price,
          iswaiting: part.iswaiting == 0 ? 'No' : 'Yes',
          status: (
            <React.Fragment>
              {part.iswaiting == 1 ? (
                roleId === 5 ? (
                  <input
                    type="button"
                    disabled={disabledAll}
                    id={`btn${part.id}`}
                    onClick={(event) => {
                      this.changePartStatus(part, event);
                    }}
                    value="Waiting"
                  />
                ) : (
                  <p style={{ width: '85px' }}>Waiting</p>
                )
              ) : (
                <p style={{ width: '85px' }}>Allocated</p>
              )}
            </React.Fragment>
          ),
          share: (
            <React.Fragment>
              <button
                className="btn btn-primary m-r-5"
                style={{ fontSize: '12px' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    showNote: true,
                    partId: part.id,
                    noteAhsType: part.sharetoahs,
                    noteCustType: part.sharetocust,
                  });
                }}
              >
                Add Note
              </button>
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: part.isreceived ? 'none' : '' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push('/workorder-part/' + part.id, {
                    workorderid: workorderid,
                    detail: part,
                    primaryTechnician: this.props.primaryTechnician,
                  });
                }}
              >
                {!disabledAll ? (
                  <i
                    className="mdi mdi-lead-pencil"
                    id="editPart"
                    title="Edit Part"
                  ></i>
                ) : (
                  'View'
                )}
              </a>
              {roleId === 1 && (
                <a
                  href="/"
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, partId: part.id });
                  }}
                >
                  <i className="mdi mdi-delete text-danger" title="Delete"></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return part;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getWorkOrderParts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, workorderid } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.workorderpartsUrl}/all?skip=${skip}&limit=${limit}&work_order_id=${workorderid}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          partsData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrderParts();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getWorkOrderParts();
    });
  };

  deletePart = async () => {
    const { partId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.workorderpartsUrl}?id=${partId}`
    );
    if (data) {
      this.setState({ showDelete: false, partId: null }, () => {
        this.getWorkOrderParts();
      });
      swal('Work Order Part deleted!', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  shareToCustomer = async (id, type) => {
    const { note } = this.state;
    const endPoint = `${Api.workorderPartsShareNotes}`;

    const callback = AuthApi.putDataToServer;
    const postData = {
      id,
      type,
      sharednote: note && note,
    };
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      if (type === 'sharetocust') {
        this.setState({
          isShowLoader: false,
          note: '',
          showNote: false,
        });
        swal('Note Shared to Customer', '', 'success');
      } else {
        this.setState({
          isShowLoader: false,
          note: '',
          showNote: false,
        });
        swal('Note Shared to AHS', '', 'success');
      }
      this.getWorkOrderParts();
    } else {
      this.setState({ isShowLoader: false, note: '', showNote: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      isShowLoader,
      workorderid,
      disabledAll,
      roleId,
      columns,
      showNote,
      partId,
      noteAhsType,
      noteCustType,
      note,
      sourceType,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div
          className="page-content-wrapper"
          style={{ paddingTop: '10px', margin: '0 -15px' }}
        >
          <div className="container-fluid">
            <HelmetComponent title="All Parts" />
            {showDelete && (
              <SweetAlert
                title="Are you sure, Do you want to delete this part ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deletePart();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, partId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {!disabledAll &&
                      this.props.primaryTechnician &&
                      [1, 5, 6].includes(roleId) && (
                        <div className="row" style={{ paddingBottom: '15px' }}>
                          <div className="col-sm-6"></div>
                          <div className="col-sm-6 text-right">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => {
                                this.props.history.push('/workorder-part/add', {
                                  workorderid: workorderid,
                                  primaryTechnician:
                                    this.props.primaryTechnician,
                                });
                              }}
                            >
                              Add Part
                            </button>
                          </div>
                        </div>
                      )}
                    <div ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getWorkOrderParts}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          size="md"
          className="rating-popup"
          show={showNote}
          onHide={() =>
            this.setState({ showNote: false, note: '', isShowLoader: false })
          }
        >
          <Modal.Header>
            <Modal.Title className="text-secondary">
              <h6>Share</h6>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() =>
                this.setState({
                  showNote: false,
                  note: '',
                  isShowLoader: false,
                })
              }
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="page-content-wrapper" style={{ paddingTop: '0px' }}>
              <div className="container-fluid">
                <div className="row">
                  {(!noteAhsType || !noteCustType) && (
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Note
                    </label>
                  )}
                  <div className="col-12">
                    <div className="form-group row">
                      <div className="col-sm-12">
                        {(!noteAhsType || !noteCustType) && (
                          <textarea
                            rows="3"
                            cols="80"
                            className="form-control"
                            value={note}
                            onChange={(e) => {
                              this.setState({ note: e.target.value });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row justify-content-center text-center">
                      <div className="col-sm-12">
                        {noteCustType ? (
                          <button
                            className="btn btn-secondary m-r-5"
                            disabled
                            style={{ fontSize: '12px' }}
                          >
                            Shared to Customer
                          </button>
                        ) : (
                          <button
                            className="btn btn-success m-r-5"
                            onClick={() => {
                              this.shareToCustomer(partId, 'sharetocust');
                            }}
                            style={{ fontSize: '12px' }}
                          >
                            Share To Customer
                          </button>
                        )}
                        {sourceType == 1 &&
                          (noteAhsType ? (
                            <button
                              className="btn btn-secondary"
                              disabled
                              style={{ fontSize: '12px' }}
                            >
                              Shared to Ahs
                            </button>
                          ) : (
                            <button
                              className="btn btn-success"
                              style={{ fontSize: '12px' }}
                              onClick={() => {
                                this.shareToCustomer(partId, 'sharetoahs');
                              }}
                            >
                              Share To Ahs
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default withRouter(WorkOrderParts);

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import Pagination from '../../../components/Pagination';
import swal from 'sweetalert';
import customFunctions from '../../../helper/customFunctions';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 50,
  },
  {
    label: 'List item',
    field: 'item',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'List Type',
    field: 'type',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    width: 150,
  },
];

export default class ListItem extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      ListItemData: [],
      listItem: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isWriteAllowed: false,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-listitem'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-listitem',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('All List Items');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => this.getListItem()
      );
    } else {
      this.getListItem();
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { ListItemData, limit, isWriteAllowed } = this.state;
    const rows = [];
    const page = npage;
    ListItemData &&
      ListItemData.map((item, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          type: item.ListType,
          item: item.ListItem,
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                id={`switch${item.ID}`}
                disabled={!isWriteAllowed}
                checked={Number(item.status) ? 'checked' : ''}
                switch="info"
                onChange={(event) => {
                  this.changeListItemStatus(item, event);
                }}
              />
              <label
                htmlFor={`switch${item.ID}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-list-item',
                    state: {
                      detail: item,
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="editlistitem"
                  title="Edit List Item"
                ></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, itemId: item.ID });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return item;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  changeListItemStatus = async (listItem, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      id: listItem.ID,
      list_item: listItem.ListItem,
      status: isChecked ? '1' : '0',
      type_id: listItem.type_id,
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.AddListItemUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('List item status changed', '', 'success');
      this.getListItem(currentPage);
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getListItem = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    const endPoint = `${Api.ListItemUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          ListItemData: data.data.data,
          totalCount: data.data.total_count,
          isShowLoader: false,
        },
        () => {
          this.setTableData(skip, page);
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getListItem();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getListItem();
    });
  };

  deleteListItem = async () => {
    const { itemId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.AddListItemUrl}?item_id=${itemId}`
    );
    if (data) {
      this.setState({ showDelete: false, itemId: null }, () => {
        swal('List item deleted!', '', 'success').then(() => {
          this.getListItem();
        });
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const {
      rows,
      totalCount,
      limit,
      currentPage,
      keyword,
      isWriteAllowed,
      showDelete,
      isShowLoader,
      navCurrentPage,
      navSkip,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All List Items" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the list item?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteListItem();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, itemId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by list item"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {isWriteAllowed && (
                        <div className="col-sm-6 text-right">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push('/add-list-item');
                            }}
                          >
                            Add List Item
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getListItem
                                )
                              : this.getListItem
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

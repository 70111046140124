// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import Loader from '../../../components/Loader';
import MapCircles from '../../../components/Map/mapCircles';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import AppointmentsTable from './ahsReportTables/appointmentsTable';
import AverageTable from './ahsReportTables/averageTable';
import DispatchesTable from './ahsReportTables/dispatchesTable';
import BarChart from './charts/BarChart';
import BarLineChart from './charts/BarLineChart';
import StackedBarChart from './charts/StackedBarChart';

class AHSReportCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'AHS Report Card',
      vendorList: ['test'],
      selectedStatuses: [],
      searchedStatus: [],
      keyword: '',
      dateRangeList: [
        'Current Week',
        'Last Week',
        'Current Month',
        'All Dates',
        '365 Days',
        '180 Days',
        '90 Days',
        '60 Days',
        '30 Days',
        '14 Days',
        '7 Days',
        '3 Days',
        '1 Day',
      ],
      dateRange: '30 Days',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      filterApplied: false,
      jobsCreatedBySource: [],
      filterCount: 1,
      initialHide: true,
      avgAccept: '',
      avgSchedule: '',
      avgComplete: '',
      jobsByPlace: [],
      avgTimeOnSite: '',
      firstTimeFixRate: '',
      timeAcceptToBreakDown: [],
      timeScheduleToBreakDown: [],
      timeOnsiteToBreakDown: [],
      timeCompleteToBreakDown: [],
      scheduleWithin24hrTime: [],
      isShowLoader: false,
    };
  }

  async componentDidMount() {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'reports-jobs'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle(this.state.title);
    await this.getJobCreatedSource();
    await this.getAvgSchedule();
    await this.getAvgComplete();
    await this.getAvgTimeOnSite();
    await this.getJobCounts();
    await this.getFirstTimeFixRate();
    await this.getHourlygraphrecords();
    await this.getscheduledwithin24hours();
  }

  components = {
    DropdownIndicator: null,
  };

  getJobCounts = async () => {
    var { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getJobCounts}?from_date=${startDate}&to_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ jobsByPlace: data.data });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getJobCreatedSource = async () => {
    var { startDate, endDate, dateRange } = this.state;
    this.setState({ isShowLoader: true });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getJobCreatedSource}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ jobsCreatedBySource: data.data });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getHourlygraphrecords = async () => {
    var { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getHourlygraphrecordsUrl}?from_date=${startDate}&to_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        timeAcceptToBreakDown: data.data.acceptGraph,
        timeScheduleToBreakDown: data.data.sheduledGraph,
        timeOnsiteToBreakDown: data.data.onSiteGraph,
        timeCompleteToBreakDown: data.data.completeGraph,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getscheduledwithin24hours = async () => {
    var { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getscheduledwithin24hoursUrl}?from_date=${startDate}&to_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ isShowLoader: false, scheduleWithin24hrTime: data.data });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getAvgSchedule = async () => {
    let endPoint = `${Api.getAvgTimeToAcceptScheduleUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        avgAccept: data.data.averagebreakdown,
        avgSchedule: data.data.sheduledBreakdown,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getAvgComplete = async () => {
    let endPoint = `${Api.getAvgTimeToCompleteUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        avgComplete: data.data.completedbreakdown,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getAvgTimeOnSite = async () => {
    let endPoint = `${Api.getAvgTimeOnSiteUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        avgTimeOnSite: data.data.completedbreakdown,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getFirstTimeFixRate = async () => {
    let endPoint = `${Api.getFirstTimeFixRateUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ firstTimeFixRate: data.data });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  filterCount = () => {
    const { selectedStatuses, dateRange } = this.state;
    let dateLen = dateRange !== '' ? 1 : 0;
    this.setState({
      filterCount: selectedStatuses && selectedStatuses.length + dateLen,
    });
  };

  changeDates = async () => {
    const { dateRange } = this.state;
    var date = new Date();
    let firstday;

    if (dateRange === 'Current Week') {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      // firstday = new Date(curr.setDate(first));
      firstday = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(first))
      );

      // date = new Date(curr.setDate(last));
      date = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(last))
      );
    }
    if (dateRange === 'Last Week') {
      let dates = customFunctions.getLastWeek(date);
      firstday = dates.firstDayOfLastWeek;
      date = dates.lastDayOfLastWeek;
    }
    if (dateRange === 'Current Month') {
      firstday = new Date(date.getFullYear(), date.getMonth(), 1);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (dateRange === '365 Days') {
      firstday = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '180 Days') {
      firstday = new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '90 Days') {
      firstday = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '60 Days') {
      firstday = new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '30 Days') {
      firstday = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '14 Days') {
      firstday = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '7 Days') {
      firstday = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '3 Days') {
      firstday = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '1 Day') {
      firstday = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    this.setState({ startDate: firstday, endDate: date, initialHide: false });

    if (dateRange === 'All Dates') {
      this.setState({ startDate: '', endDate: '' });
    }
    this.filterCount();
  };

  searchStatus = (search_term) => {
    const { vendorList } = this.state;
    this.setState({ keyword: search_term });
    var matched_status = [];
    search_term = search_term.toLowerCase();
    vendorList &&
      vendorList.forEach((item) => {
        if (item.toLowerCase().indexOf(search_term) !== -1) {
          matched_status.push(item);
        }
        this.setState({ searchedStatus: matched_status });
      });
  };

  callback = () => {
    this.setState({ filterApplied: false });
  };

  handleCreate = (inputValue) => {
    const { vendorList } = this.state;
    vendorList.push(inputValue);
    this.setState({ vendorList });
  };

  render() {
    const {
      title,
      // selectedStatuses,
      // searchedStatus,
      // vendorList,
      dateRange,
      dateRangeList,
      startDate,
      endDate,
      filterApplied,
      jobsCreatedBySource,
      filterCount,
      initialHide,
      avgAccept,
      avgSchedule,
      avgComplete,
      jobsByPlace,
      avgTimeOnSite,
      firstTimeFixRate,
      timeAcceptToBreakDown,
      timeScheduleToBreakDown,
      timeOnsiteToBreakDown,
      timeCompleteToBreakDown,
      scheduleWithin24hrTime,
      isShowLoader,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12 m-b-20">
                <div id="accordion">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                      border: '2px solid #CCC',
                      borderBottom: 'none',
                    }}
                  >
                    <div
                      style={{ fontSize: '16px', minHeight: '59px' }}
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <b>{`FILTERS(${filterCount})`}</b>{' '}
                      <span className="dropdown-toggle"></span>{' '}
                    </div>
                    {dateRange && (
                      <span className="selected-daterange">
                        <b>DateRange</b> {dateRange}
                        {initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                        {!initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse show`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                      </span>
                    )}
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card-body1">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="accordion" id="accordionExample">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Date Range
                                    </button>
                                  </div>

                                  <div
                                    id="collapseOne1"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body">
                                      <Radio
                                        checked={dateRange === 'Custom Range'}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dateRange: e.target.value,
                                            },
                                            () => this.filterCount()
                                          );
                                        }}
                                        id="customrange"
                                        value="Custom Range"
                                        color="default"
                                      />
                                      <label htmlFor="customrange">
                                        Custom Range
                                      </label>

                                      <br />
                                      {dateRange === 'Custom Range' && (
                                        <span>
                                          <DatePickerComponent
                                            selected={startDate}
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                              this.setState({
                                                startDate: date,
                                              });
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="form-control filter-date"
                                          />
                                          <br />
                                          <DatePickerComponent
                                            selected={endDate}
                                            placeholderText="End Date"
                                            onChange={(date) =>
                                              this.setState({ endDate: date })
                                            }
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            className="form-control filter-date"
                                          />
                                        </span>
                                      )}
                                      {dateRangeList &&
                                        dateRangeList.map((dates, index) => (
                                          <React.Fragment key={index}>
                                            <Radio
                                              checked={dateRange === dates}
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    dateRange: e.target.value,
                                                  },
                                                  () => this.changeDates()
                                                );
                                              }}
                                              id={`dates${index}`}
                                              value={dates}
                                              color="default"
                                            />
                                            <label htmlFor={`dates${index}`}>
                                              {dates}
                                            </label>
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            >
                              <div className="accordion" id="accordionExample1">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne2"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      VendorIDs
                                    </button>
                                  </div>
                                  <div
                                    id="collapseOne2"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample1"
                                  >
                                    <div className="card-body">
                                      <ReactCreatableSelect
                                            components={this.components}
                                            placeholder="Create Vendor"
                                            onCreateOption={this.handleCreate}
                                            options={vendorList}
                                          />
                                      {vendorList &&
                                          vendorList.map((status, index) => (
                                             <>
                                              <label htmlFor={`status${index}`}>
                                                {status}
                                              </label>
                                              <br />
                                            </>
                                          ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div
                              className="col-12"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4"></div>
                                  <div className="col-4">
                                    <span
                                      onClick={() =>
                                        this.setState(
                                          {
                                            dateRange: '30 Days',
                                            selectedStatuses: [],
                                            filterApplied: false,
                                            startDate: new Date(
                                              new Date().getTime() -
                                                30 * 24 * 60 * 60 * 1000
                                            ),
                                            endDate: new Date(),
                                          },
                                          () => this.filterCount()
                                        )
                                      }
                                      className="resetFilter f-btns"
                                    >
                                      RESET FILTERS
                                    </span>
                                    <button
                                      className="btn btn-secondary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="btn btn-primary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                      onClick={() => {
                                        this.setState(
                                          { filterApplied: true },
                                          async () => {
                                            await this.getJobCreatedSource();
                                            await this.getAvgSchedule();
                                            await this.getAvgComplete();
                                            await this.getAvgTimeOnSite();
                                            await this.getJobCounts();
                                            await this.getFirstTimeFixRate();
                                            await this.getHourlygraphrecords();
                                            await this.getscheduledwithin24hours();
                                          }
                                        );
                                      }}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="card col-6">
                        Job Volume
                        <div className="card-body">
                          <StackedBarChart
                            chartData={jobsCreatedBySource}
                            fromComponent="jobs-jobscreated"
                            width="45"
                            height="10"
                          />
                        </div>
                      </div>
                      <div className="card col-6">
                        <div className="card-body">
                          <MapCircles
                            jobsByPlace={jobsByPlace && jobsByPlace}
                            fromComponent="ahsReport"
                          />
                        </div>
                      </div>

                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time to Accept(Hours)
                          </p>
                          <h1>{avgAccept}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time to Accept(Hours)
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time to Schedule(Hours)
                          </p>
                          <h1>{avgSchedule}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time to Schedule(Hours)
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime3"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time to On Site(Days)
                          </p>
                          <h1>{avgTimeOnSite ? avgTimeOnSite : 0}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time to On Site(Days)
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime4"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time to Complete(Days)
                          </p>
                          <h1>{avgComplete ? avgComplete : 0}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time to Complete(Days)
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        Time to Accept Breakdown
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <BarChart
                            chartData={
                              timeAcceptToBreakDown && timeAcceptToBreakDown
                            }
                            fromComponent="ahsReport"
                          />
                        </div>
                      </div>
                      <div className="card col-3">
                        Time to Schedule Breakdown
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <BarChart
                            chartData={
                              timeScheduleToBreakDown && timeScheduleToBreakDown
                            }
                            fromComponent="ahsReport"
                          />
                        </div>
                      </div>
                      <div className="card col-3">
                        Time to On Site Breakdown
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <BarChart
                            chartData={
                              timeOnsiteToBreakDown && timeOnsiteToBreakDown
                            }
                            fromComponent="ahsReport"
                          />
                        </div>
                      </div>
                      <div className="card col-3">
                        Time to Complete Breakdown
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <BarChart
                            chartData={
                              timeCompleteToBreakDown && timeCompleteToBreakDown
                            }
                            fromComponent="ahsReport"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card m-b-20">
                <div className="card-body">
                  <div className="row">
                    <div className="card col-6">
                      Dispatches
                      <div className="card-body text-center py-3" id="dispatch">
                        <DispatchesTable
                          startDate={startDate}
                          endDate={endDate}
                          filterApplied={filterApplied}
                          // callback={this.callback}
                        />
                      </div>
                    </div>
                    <div className="card col-6">
                      Appointments
                      <div
                        className="card-body text-center py-3"
                        id="appointment"
                      >
                        <AppointmentsTable
                          startDate={startDate}
                          endDate={endDate}
                          filterApplied={filterApplied}
                          // callback={this.callback}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card col-6">
                      <p className="p-1">Schedule Within 24 Hours</p>
                      <BarLineChart
                        chartData={scheduleWithin24hrTime}
                        fromComponent="ahsReport"
                      />
                    </div>

                    <div className="card col-6">
                      <p className="p-1">
                        Average Complete Performance by Month (Days)
                      </p>
                      <div className="card-body text-center py-3" id="avgTime2">
                        <AverageTable
                          startDate={startDate}
                          endDate={endDate}
                          // filterApplied={filterApplied}
                          callback={this.callback}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card col-3">
                      <div className="card-body text-center py-3" id="avgTime3">
                        <p
                          className="avgClass"
                          style={{ fontSize: '16px', textAlign: 'left' }}
                        >
                          First Time Fix Rate
                        </p>
                        <h1>{firstTimeFixRate ? firstTimeFixRate : 0}</h1>
                        <p style={{ fontSize: '22px' }}>First Time Fix Rate</p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AHSReportCard);

// import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import customFunctions from '../../../helper/customFunctions';
import 'amazon-connect-streams';
import React, { useEffect } from 'react';

// const CCP_URL =
//   'https://test-connect-expand.my.connect.aws/ccp-v2/&output=embed';
// const REGION = 'us-east-1';

function AmazonConnect() {
  useEffect(() => {
    const container = document.getElementById('ccp');
    customFunctions.setTitle('CCP');
    // const configs = {
    //   ccpUrl: CCP_URL,
    //   loginPopup: true,
    //   loginPopupAutoClose: true,
    //   softphone: {
    //     allowFramedSoftphone: true,
    //   },
    //   region: REGION,
    // };
    // eslint-disable-next-line no-undef

    //Un comment the below line for notification connection once the amazon setup is complete
    // connect.core.initCCP(container, customFunctions.getInstanceDetails());
  }, []);
  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <HelmetComponent title="Amazon Connect" />
          <div id="ccp" style={{ width: '320px', height: '500px' }}></div>
        </div>
      </div>
    </AUX>
  );
}

export default AmazonConnect;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
class AddPaymentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: '',
      paymentTypeId: '',
      percentageAmount: '',
      percentageAmountErr: '',
      status: '',
      paymentTypeErr: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-paymenttype',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('Add Payment Type');
    this.setState({
      title: `Add Payment Type`,
    });

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getPaymentType();
      customFunctions.setTitle('Edit Payment Type');
      this.setState({
        title: `Edit Payment Type`,
      });
    }
  };

  getPaymentType = async () => {
    const paymentTypeData = this.props.location.state.detail;
    this.setState({
      paymentTypeId: paymentTypeData.id,
      paymentType: paymentTypeData.payment_type,
      status: paymentTypeData.status,
      percentageAmount: paymentTypeData.percentage
        ? paymentTypeData.percentage
        : 0,
    });
  };

  addPaymentType = async () => {
    const { paymentType, paymentTypeId, status, percentageAmount } = this.state;
    const postData = {
      payment_type: paymentType,
      percentage: percentageAmount,
    };
    if (paymentTypeId) {
      postData.payment_type_id = parseInt(paymentTypeId);
      postData.status = status;
      postData.percentage = percentageAmount;
    }
    const endPoint = `${Api.AddpaymentTypeUrl}`;
    const callback = paymentTypeId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (paymentTypeId) {
        swal('Payment type updated successfully', '', 'success').then(() => {
          $this.props.history.push('/payment-type', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Payment type added successfully', '', 'success').then(() => {
          $this.props.history.push('/payment-type');
        });
      }
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { paymentType, percentageAmount } = this.state;
    let paymentTypeErr = '',
      percentageAmountErr = '',
      isValid = true;
    if (paymentType.trim() === '') {
      paymentTypeErr = 'Payment Type is required';
      isValid = false;
    }

    if (percentageAmount === '') {
      percentageAmountErr = 'Percentage is required';
      isValid = false;
    }

    this.setState({ paymentTypeErr, percentageAmountErr });
    if (isValid) {
      this.addPaymentType();
    }
  };

  render() {
    const {
      title,
      paymentType,
      paymentTypeErr,
      percentageAmount,
      percentageAmountErr,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Payment Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="payment type"
                            value={paymentType}
                            onChange={(e) => {
                              this.setState({ paymentType: e.target.value });
                            }}
                          />
                          <span id="err">{paymentTypeErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="percentage-field"
                          className="col-sm-2 col-form-label"
                        >
                          Percentage Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                %
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={percentageAmount}
                              max="100"
                              min="0"
                              type="number"
                              placeholder="Percentage Amount"
                              id="percentage-field"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({
                                    percentageAmount: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                          <span id="err">{percentageAmountErr}</span>
                        </div>
                      </div>

                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/payment-type', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddPaymentType);

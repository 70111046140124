import React, { Component } from 'react';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import swal from 'sweetalert';

class AddComplaintStatus extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      statusId: '',
      statusErr: '',
      statusData: [],
    };
  }

  addStatus = async () => {
    const { status } = this.state;
    const { getComplaintsCallback } = this.props;
    const postData = {
      status: status,
    };
    const endPoint = `${Api.AddcomplaintStatusUrl}`;
    const { data, message } = await AuthApi.postDataToServer(
      endPoint,
      postData
    );
    if (data) {
      swal('Complaint status added successfully', '', 'success');
      if (getComplaintsCallback) getComplaintsCallback();
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal(message ? message : 'No data found', '', 'error');
      await this.flushAllData();
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { status } = this.state;
    let statusErr = '';
    let isValid = true;
    if (status.trim() === '') {
      statusErr = 'status is required';
      isValid = false;
    }
    this.setState({ statusErr });
    if (isValid) this.addStatus();
  };

  flushAllData = () => {
    this.setState({
      status: '',
      statusId: '',
      statusErr: '',
    });
  };

  render() {
    const { status, statusErr } = this.state;
    return (
      <div
        className="modal fade"
        id="complaintstatus"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Complaint Status
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validate} autoComplete="off">
                  <div className="form-group row justify-content-center">
                    <label
                      htmlFor="example-text-customer-charges"
                      className="col-sm-2 col-form-label"
                    >
                      Status
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        value={status}
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                        }}
                      />
                      <span id="err">{statusErr}</span>
                    </div>
                    <div className="col-md-2" />
                  </div>
                  <div className="form-group row justify-content-center">
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        onClick={() => {
                          this.flushAllData();
                        }}
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddComplaintStatus;

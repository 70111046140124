import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import { Row, Col, Button } from 'react-bootstrap';
import SENT from '../../../assets/images/sent.png';
import WON from '../../../assets/images/won.png';
import LOST from '../../../assets/images/lost.png';
import DRAFT from '../../../assets/images/draft.png';
import BALANCEDUE from '../../../assets/images/balanceDue.png';
import CANCEL from '../../../assets/images/cancel.png';
import PAID from '../../../assets/images/paid.png';
import CustomDropdown from '../../../components/Dropdown/dropdown.component';
import Loader from '../../../components/Loader';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';

const columns = [
  {
    label: 'Status',
    field: 'invoiceType',
    sort: 'disabled',
    width: 70,
  },
  // {
  //   label: 'Status',
  //   id: 'status',
  //   sort: 'disabled',
  // },
  {
    label: 'Invoice ID',
    field: 'invoiceNo',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Customer Name',
    field: 'customerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Job Details',
    field: 'jobDetails',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Cost($)',
    field: 'price',
    sort: 'disabled',
    width: 150,
  },
  // {
  //   label: 'Action',
  //   field: 'actions',
  //   sort: 'disabled',
  // },
];

class InvoiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      items: [],
      rows: [],
      invoiceId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      title: 'All Invoices',
      startDate: '',
      endDate: '',
      filterValue: '',
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      filterdata: [
        {
          name: 'All Invoices',
          value: '',
        },
        {
          name: 'Draft',
          value: 'Draft',
          imageUrl: DRAFT,
        },
        {
          name: 'Sent',
          value: 'Sent',
          imageUrl: SENT,
        },
        {
          name: 'Balance Due',
          value: 'BalanceDue',
          imageUrl: BALANCEDUE,
        },
        {
          name: 'Paid',
          value: 'Paid',
          imageUrl: PAID,
        },
      ],
      filterDateTypes: [
        {
          name: 'Issue Date',
          value: 'issue_date',
        },
        {
          name: 'Create Date',
          value: 'create_date',
        },
        {
          name: 'Work Start Date',
          value: 'work_start_date',
        },
      ],
      filterDateType: '',
      clearSelectedDropdown: false,
      roleId: null,
      fromComponent: '',
      isShowLoader: false,
      isWriteAllowed: false,
      filterType: '',
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-invoices'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-estimates',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    if (this.props.title) {
      customFunctions.setTitle(this.props.title);
    } else {
      customFunctions.setTitle(this.state.title);
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let fromComponent = this.props.fromComponent || '';

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          fromComponent,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getInvoices();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id, fromComponent },
        async () => {
          await this.getInvoices();
        }
      );
    }
  };

  getInvoices = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const {
      limit,
      keyword,
      filterValue,
      pagination,
      filterDateType,
      startDate,
      endDate,
      roleId,
      loginId,
      fromComponent,
      navSkip,
      navCurrentPage,
    } = this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${Api.getInvoice}?skip=${skip}&limit=${
      fromComponent == 'workorder' ? 1000 : pagination.itemsPerPage
    }`;
    endPoint += keyword ? '&search=' + keyword : '';
    if (filterValue) {
      endPoint += filterValue !== 'All' ? '&invoice_type=' + filterValue : '';
    }
    endPoint += filterDateType ? '&date_type=' + filterDateType : '';
    // endPoint += startDate
    //   ? '&start_date=' + customFunctions.getDate(startDate)
    //   : '';
    endPoint += startDate
      ? '&start_date=' + new Date(startDate).toISOString()
      : '';
    // endPoint += endDate ? '&end_date=' + customFunctions.getDate(endDate) : '';
    endPoint += endDate ? '&end_date=' + new Date(endDate).toISOString() : '';
    if (
      (roleId == 5 || roleId == 6) &&
      loginId &&
      fromComponent !== 'workorder'
    ) {
      endPoint += `&tech_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent !== 'workorder') {
      endPoint += `&customer_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent == 'workorder') {
      endPoint += `&customer_id=${loginId}&work_order_id=${this.props.workorderid}`;
    }
    if (roleId !== 4 && fromComponent == 'workorder') {
      endPoint += `&work_order_id=${this.props.workorderid}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.totalCount,
      };

      this.setState(
        {
          isShowLoader: false,
          items: data.data.data,
          totalCount: data.data.totalCount,
          pagination: paginationParams,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  downloadCSV = async () => {
    const {
      keyword,
      filterValue,
      filterDateType,
      startDate,
      endDate,
      roleId,
      loginId,
      fromComponent,
    } = this.state;

    let endPoint = `${Api.downloadInvoiceCsvUrl}?search=${keyword}`;

    if (filterValue) {
      endPoint += filterValue !== 'All' ? '&invoice_type=' + filterValue : '';
    }
    endPoint += filterDateType ? '&date_type=' + filterDateType : '';
    endPoint += startDate
      ? '&start_date=' + customFunctions.getDate(startDate)
      : '';
    endPoint += endDate ? '&end_date=' + customFunctions.getDate(endDate) : '';
    if (
      (roleId == 5 || roleId == 6) &&
      loginId &&
      fromComponent !== 'workorder'
    ) {
      endPoint += `&tech_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent !== 'workorder') {
      endPoint += `&customer_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent == 'workorder') {
      endPoint += `&customer_id=${loginId}&work_order_id=${this.props.workorderid}`;
    }
    if (roleId !== 4 && fromComponent == 'workorder') {
      endPoint += `&work_order_id=${this.props.workorderid}`;
    }
    window.open(endPoint, '_blank');
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { items, filterDateType, roleId, isWriteAllowed } = this.state;
    const rows = [];
    items &&
      items.length > 0 &&
      items.map((item, i) => {
        if (
          (roleId == 4 && item.invoice_type && item.invoice_type !== 'DRAFT') ||
          roleId !== 4
        ) {
          rows.push({
            invoiceType: (
              <React.Fragment>
                <img src={this.getImage(item.invoice_type)} alt="" />
              </React.Fragment>
            ),
            // status: item.invoice_type ? item.invoice_type : '-',
            invoiceNo: (
              <React.Fragment>
                <a
                  href="/"
                  style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.push(
                      `/invoiceDetail/${item.invoice_id}`,
                      {
                        path: '/invoices',
                        paginationObj: {
                          navSkip: skip,
                          navCurrentPage: npage,
                        },
                      }
                    );
                  }}
                >
                  #{item.invoice_id}
                </a>
              </React.Fragment>
            ),
            customerName: item.customer_name ? item.customer_name : '-',
            jobDetails: item.order_type ? item.order_type : '-',
            // date:
            //   filterDateType == 'create_date' || filterDateType == 'issue_date'
            //     ? customFunctions.getMDYDate(item.issue_date)
            //     : customFunctions.getMDYDate(item.work_start_date),
            date:
              filterDateType == 'issue_date' || item.issue_date
                ? customFunctions.getMDYDate(item.issue_date)
                : '-',
            price: item.total ? `$${item.total}` : '-',
            // actions: (
            //   <React.Fragment>
            //     <a
            //       href="/"
            //       className=""
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         this.props.history.push(`/invoiceDetail/${item.id}`);
            //       }}
            //     >
            //       <i className="mdi mdi-eye" title="Edit Line item"></i>
            //     </a>
            //     <a
            //       href="/"
            //       className=""
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         this.setState({ showDelete: true, lineItemId: item.id });
            //       }}
            //     >
            //       <i
            //         className="mdi mdi-delete text-danger"
            //         title="Delete Line item"
            //       ></i>
            //     </a>
            //   </React.Fragment>
            // ),
          });
        }
        return item;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteLineItem = async () => {
    const { lineItemId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getLineItem}?item_id=${lineItemId}`
    );
    if (data) {
      this.setState({ showDelete: false, lineItemId: null }, () => {
        this.getInvoices();
      });
      swal('Line item deleted', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getInvoices();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getInvoices();
    });
  };

  clearAllFilter = () => {
    this.setState(
      {
        startDate: '',
        endDate: '',
        filterValue: '',
        filterDateType: '',
        isFilterOn: false,
        clearSelectedDropdown: true,
      },
      () => {
        this.getInvoices();
      }
    );
  };

  getImage = (imgs) => {
    let img = imgs && imgs.toUpperCase();
    switch (img) {
      case 'DRAFT':
        return DRAFT;
        break;
      case 'WON':
        return WON;
        break;
      case 'LOST':
        return LOST;
        break;
      case 'SENT':
        return SENT;
        break;
      case 'PARTIAL':
        return BALANCEDUE;
        break;
      case 'PAID':
        return PAID;
        break;
    }
  };

  handleChangeInvoice = (item) => {
    this.setState(
      {
        filterValue: item.value,
        isFilterOn: true,
        clearSelectedDropdown: false,
      },
      () => {
        this.getInvoices();
      }
    );
  };
  handleChangeDate = (item) => {
    this.setState(
      {
        filterDateType: item.value,
        isFilterOn: true,
        clearSelectedDropdown: false,
      },
      () => {
        this.getInvoices();
      }
    );
  };

  getPaidInvoices = async () => {
    const { filterType } = this.state;
    let endPoint = `${Api.getInvoice}?skip=0&limit=1000&work_order_id=${this.props.workorderid}`;
    if (filterType == true) {
      endPoint += `&invoice_type=${'Paid'}`;
    }
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          isShowLoader: false,
          items: data.data.data,
          totalCount: data.data.totalCount,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
      this.setState({ isShowLoader: false });
    }
  };
  render() {
    const {
      keyword,
      rows,
      showDelete,
      filterdata,
      filterDateType,
      filterDateTypes,
      startDate,
      endDate,
      isFilterOn,
      clearSelectedDropdown,
      roleId,
      fromComponent,
      isShowLoader,
      isWriteAllowed,
      totalCount,
      currentPage,
      limit,
      filterType,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div
          className="page-content-wrapper"
          style={{ paddingTop: fromComponent == 'workorder' ? 0 : '80px' }}
        >
          <div className="container-fluid">
            {!fromComponent && <HelmetComponent title={this.state.title} />}
            {showDelete && (
              <SweetAlert
                title="Are you sure, do you want to delete the line item ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteLineItem();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, lineItemId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {fromComponent !== 'workorder' && (
                      <div className="table-header table-header-estimation">
                        <div className="left-section col-3 pl-0">
                          <form onSubmit={this.onSearchSubmit}>
                            <div className="form-group d-flex">
                              <div className="input-wrap">
                                <input
                                  className="form-control"
                                  value={keyword}
                                  type="text"
                                  placeholder="Search by customer"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({ keyword: e.target.value });
                                  }}
                                  required={true}
                                />
                                <div
                                  className="btn-close"
                                  onClick={() => {
                                    this.clearSearch();
                                  }}
                                >
                                  <img src={CANCEL} />
                                </div>
                              </div>

                              <div className="buttons-wrap">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="right-section col-9 pr-0">
                          <div className="right-actions col-12 select-box-estimation">
                            <Row>
                              <Col>
                                <div className="filterby">
                                  <CustomDropdown
                                    items={filterdata}
                                    placeholder="Filter by.."
                                    clearAllFilter={this.clearAllFilter}
                                    isFilterOn={isFilterOn}
                                    clearSelectedDropdown={
                                      clearSelectedDropdown
                                    }
                                    handleSelect={this.handleChangeInvoice}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="filterdate">
                                  <CustomDropdown
                                    items={filterDateTypes}
                                    placeholder="Select Date Type"
                                    clearSelectedDropdown={
                                      clearSelectedDropdown
                                    }
                                    handleSelect={this.handleChangeDate}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <DatePickerComponent
                                  selected={startDate}
                                  placeholderText="Start Date"
                                  disabled={!filterDateType}
                                  onChange={(date) => {
                                    this.setState(
                                      { startDate: date, isFilterOn: true },
                                      () => {
                                        if (endDate) {
                                          this.getInvoices();
                                        }
                                      }
                                    );
                                  }}
                                  maxDate={new Date()}
                                  startDate={startDate}
                                  endDate={endDate}
                                  className="form-control"
                                />
                              </Col>
                              <Col>
                                <DatePickerComponent
                                  selected={endDate}
                                  placeholderText="End Date"
                                  disabled={!filterDateType || !startDate}
                                  onChange={(date) =>
                                    this.setState(
                                      { endDate: date, isFilterOn: true },
                                      () => {
                                        this.getInvoices();
                                      }
                                    )
                                  }
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  className="form-control"
                                />
                              </Col>
                              {isWriteAllowed && [1, 2, 3].includes(roleId) && (
                                <Col>
                                  <Button
                                    style={{
                                      float: 'right',
                                      whiteSpace: 'nowrap',
                                      paddingLeft: '16px',
                                      paddingRight: '16px',
                                    }}
                                    onClick={() =>
                                      this.props.history.push(`/invoiceDetail`)
                                    }
                                  >
                                    Add Invoice
                                  </Button>
                                </Col>
                              )}
                              <Col style={{ paddingRight: 0 }}>
                                {fromComponent !== 'workorder' &&
                                  rows &&
                                  rows.length > 0 && (
                                    <div style={{ textAlign: 'right' }}>
                                      <button
                                        type="submit"
                                        style={{
                                          fontSize: '15px',
                                          padding: '6px 11px',
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={() => {
                                          this.downloadCSV();
                                        }}
                                      >
                                        <i className="mdi mdi-download"></i> CSV
                                      </button>
                                    </div>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    )}
                    {fromComponent === 'workorder' &&
                      [1, 2, 3].includes(roleId) && (
                        <>
                          <div style={{ textAlign: 'right' }}>
                            <span className="paid-switch">
                              <span className="pr-1">Paid</span>
                              <input
                                type="checkbox"
                                id="switch"
                                checked={Number(filterType) ? 'checked' : ''}
                                switch="info"
                                onChange={(event) => {
                                  this.setState(
                                    { filterType: event.target.checked },
                                    () => {
                                      this.getPaidInvoices();
                                    }
                                  );
                                }}
                              />
                              <label
                                htmlFor="switch"
                                data-on-label=""
                                data-off-label=""
                              ></label>
                            </span>
                            <Button
                              style={{
                                whiteSpace: 'nowrap',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                margin: '0 -4px 7px 20px',
                              }}
                              onClick={() =>
                                this.props.history.push(`/invoiceDetail`, {
                                  orderDetails: this.props.orderDetails,
                                  fromComponent: 'workorder',
                                })
                              }
                            >
                              Add Invoice
                            </Button>
                          </div>
                        </>
                      )}

                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getInvoices
                                )
                              : this.getInvoices
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(InvoiceComponent);

import React, { useState, useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import CANCEL from '../../assets/images/cancel.png';
import SEND from '../../assets/images/sendMsg.svg';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import customFunctions from '../../helper/customFunctions';
import { connect } from 'react-redux';
import {
  SetMessageBoxToggle
} from '../../action/user.action';

let interval = null;

const MessageBox = (props) => {
  const {
    messageRecieverData,
    roleId,
    SetMessageBoxToggle,
  } = props;
  const [messageText, setmessageText] = useState('');
  const [messages, setmessages] = useState([]);
  const [recieversData, setRecieverData] = useState(messageRecieverData);
  const [toggle, setToggle] = useState(false);
  const [user, setUser] = useState(null);

  const messagesEndRef = useRef(null);

  const scrollDown = () => {
    if (messagesEndRef && !messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollDown, [messages]);

  useEffect(() => {
    // setrecieverId(
    //   messageRecieverData && typeof messageRecieverData !== 'string'
    //     ? messageRecieverData.userid
    //     : null
    // );
    let user = JSON.parse(customFunctions.getDataFromLocalStorage('userdata'));
    setUser(user);
    setRecieverData(messageRecieverData);
    getMessages();
    interval = setInterval(() => {
      getMessages();
    }, 10000);

    return ()=>{
      clearInterval(interval);
    }
  }, []);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'chat-body',
      smooth: true,
    });
  };

  const getMessages = async () => {
    const {messageRecieverData} = props;
    const endPoint = `${Api.getSmsUrl}?sender_UID=${messageRecieverData.userid}&receiver_UID=${messageRecieverData.userid}&skip=0&limit=1000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (!data) {
      return;
    }
    if (data && data.data && data.data.data) {
      setmessages(data.data.data);
      scrollToBottom();
      if (!messageText) return;
      const dt = new Date();
      let newData = [...messages];
      newData.push({
        message: messageText,
        time_stamp: dt,
        img: recieversData && recieversData.image,
        name: user.username,
        status: 'sent',
      });
      setmessages([...newData]);
      setmessageText('');

      }
  };
  
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!messageText) return;
    const dt = new Date();
    let newData = [...messages];
    newData.push({
      message: messageText,
      time_stamp: dt,
      img: recieversData && recieversData.image,
      name: user.username,
      status: 'sent',
    });
    setmessages([...newData]);

    const postData = {
      receiverPhoneNumber: recieversData && recieversData.phonenumber,
      senderPhoneNumber: user.mobile,
      message: messageText
    }
    const endPoint = `${Api.sendSmsUrl}`;
    const callback = AuthApi.postDataToServer;

    await callback(endPoint, postData);
    getMessages();
    // setmessageText('');
    if (!messages.length) {
      scrollToBottom();
    }
  };

  const handleMsgSubmit = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
      setmessageText('');
      return false;
    }
  };

  const onChangeMsgHandle = (e) => {
    e.preventDefault();
    setmessageText(e.target.value);
  };


  return (
    <div
      className={`${
        (roleId === 1 || roleId === 4)
          ? 'user-admin-popup'
          : 'user-technician-popup'
      }  mid-container chat-popup`}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-right">
            <div className="card-header">
              <div className="d-flex">
                <h6
                  style={{ width: '95%' }}
                  onClick={() => {
                    if (toggle) {
                      setToggle(false);
                    } else {
                      setToggle(true);
                    }
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    company-name{' '}
                    {recieversData &&
                    recieversData.name
                      ? `- ${recieversData.name}`
                      : ''}{' '}
                  </span>
                </h6>
                <a
                  href="/"
                  className="chat-close-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    SetMessageBoxToggle(false);
                    props.onCallbackBox();
                  }}
                  style={{ width: '5%' }}
                >
                  <img
                    style={{ maxHeight: '10px', marginLeft: '10px' }}
                    src={CANCEL}
                    alt=""
                    className="svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

   
        <div style={{ display: toggle ? 'none' : 'block' }}>
          <div id="chat-body" className="chat-body">
            {messages &&
              messages.length > 0 &&
              messages.map((dt, id) => (
                <div className="chat-section" key={id}>
                  {(dt.receiver_userid === 0) ? (
                    <div
                      className={
                        (dt.receiver_userid === 0)
                        ? 'd-flex justify-content-start'
                          : ''
                      }
                    >
                      <div className="mt-3 d-flex justify-content-end">
                        <div
                          className="mt-2 card first-send"
                          style={{
                            wordBreak: 'break-word',
                            backgroundColor: '#e5f7cf',
                            width:
                            (dt.receiver_userid === 0) ? '255px' : '',
                          }}
                        >
                          {dt.sender_name ? (
                            <div className="card-header message-header-left">
                            {dt.sender_name}
                          </div>
                          ): null}
                          
                          <div className="card-body p-1">
                            <span className="msg_time_send">{dt.message}</span>
                            <span className="chat-date">
                              {`${customFunctions.getMonthDayYear(
                                dt.time_stamp
                              )} ${customFunctions.changeTimeOnlyHMS(
                                dt.time_stamp
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        !(dt.receiver_userid === 0 )
                        ? 'd-flex justify-content-end'
                          : ''
                      }
                    >
                      <div
                        className="mt-3 d-flex justify-content-end float-right"
                        style={{ width: '70%' }}
                      >
                        <div
                          className="mt-2 card first-send"
                          style={{
                            wordBreak: 'break-word',
                            backgroundColor: '#e5f7cf',
                            width:
                            !(dt.receiver_userid === 0) ? 'fit-content' : '',
                          }}
                        >
                           {dt.sender_name ? (
                            <div className="card-header message-header-right">
                            {dt.sender_name}
                          </div>
                          ): null}
                          <div className="card-body p-1">
                            <span className="msg_time_send">{dt.message}</span>
                            <span className="chat-date">
                              {`${customFunctions.getMonthDayYear(
                                dt.time_stamp
                              )} ${customFunctions.changeTimeOnlyHMS(
                                dt.time_stamp
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div ref={messagesEndRef} />
          </div>

          <div
            className="card-footer fixed-footer"
            style={{ display: toggle ? 'none' : 'block' }}
          >
            <div className="">
              <div className="chat-box">
                <form action="" onSubmit={sendMessage}>
                  <div className="input-group send">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={`Message to ${recieversData.name}`}
                      aria-label={`Message to ${recieversData.name}`}
                      aria-describedby="basic-addon2"
                      cols="40"
                      rows="2"
                      value={messageText?messageText: ''}
                      onChange={(e) => onChangeMsgHandle(e)}
                      onKeyPress={handleMsgSubmit}
                    />
                    <button type="submit" className="btn" title="send">
                      <img src={SEND} alt="" className="svg" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
     
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  SetMessageBoxToggle: (data) => dispatch(SetMessageBoxToggle(data)),
});

const mapStateToProps = (state) => ({
  messageBoxToggle: state.auth.messageBoxToggle,
  messageRecieverData: state.auth.messageRecieverData,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);

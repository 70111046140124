import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetComponent = (props) => {
  const { title } = props;
  const [newtitle, setTitle] = useState('');

  useEffect(() => {
    setTitle(title);
  }, [title]);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{newtitle} | company-name</title>
          <meta name="description" content="" />
          <link rel="canonical" href="" />
        </Helmet>
      </HelmetProvider>
    </div>
  );
};

export default HelmetComponent;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';
import menu from '../../helper/menu';
import cookie from 'react-cookies';
import customFunctions from './../../helper/customFunctions';
import { UserRestrictionCondition } from '../UserRestrictions/userrestrictions.component';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import { connect } from 'react-redux';
import { SetToggle } from '../../action/user.action';

class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: 'index',
      SubTab: '',
      MoreTab: '',
      dashboard_menu: false,
      email_menu: false,
      ui_menu: false,
      form_menu: false,
      chart_menu: false,
      table_menu: false,
      icon_menu: false,
      map_menu: false,
      auth_menu: false,
      extra_menu: false,
      eco_menu: false,
      emt_menu: false,
      hasSub: false,
      currentPath: '',
      userRole: '',
      permissions: null,
      open: false,
      roleId: null,
      userId: null,
      // JobsCounts: {
      //   UnscheduledOrders: null,
      //   ScheduleOrders: null,
      //   WaitingonCustomerApproval: null,
      //   PendingParts: null,
      //   WorkPending: null,
      //   authorizedCount: null,
      //   approvedCount: null,
      //   pendingCount: null,
      //   closedCount: null,
      //   waitingForTechnicianApproval: null,
      //   approvedByTechnicianUnpaid: null,
      //   approvedByAdminPaid: null
      // }
    };
  }

  setActiveTab = (tab, subtab, hasSub, toggleTab, e) => {
    const { Tab } = this.state;
    this.setState({ Tab: tab, SubTab: subtab, hasSub });
    customFunctions.setLocalStorage('activeTab', 'WO Details');
    if (tab === 'Quit Application') {
      customFunctions.clearLocalStorage()();
      cookie.remove('token');
      this.props.history.push('/login');
    }
    if (!subtab && Tab === tab) {
      this.setState({ open: !this.state.open });
    }
    if (!subtab && Tab !== tab) {
      this.setState({ open: true });
    }
    // if (subtab && subtab === 'Schedule') {
    //   const { SetToggle } = this.props;
    //   SetToggle(true);
    // }
  };

  componentDidMount() {
    let location = window.location.pathname.split('/');
    // var now_route = '';
    const userData =
      customFunctions.getStringifyDataFromLocalStorage('userdata');
    if (
      location[1] !== 'login' &&
      location[1] !== 'job-survey' &&
      location[1] !== 'live-location'
    ) {
      this.getPermissions(userData.role_id);
    }
    this.setState(
      { roleId: userData.role_id, userId: userData.login_id },
      async () => {
        if (
          location[1] !== 'login' &&
          location[1] !== 'job-survey' &&
          location[1] !== 'live-location'
        ) {
          await this.getCount();
        }
        await this.forceUpdate();
      }
    );

    const uRole =
      userData && Object.keys(userData).length > 0 ? userData.role_id : '';
    this.setState({ userRole: uRole });
    const currentPath = window.location.pathname;
    this.setState({ currentPath });
    // var pageUrl = window.location.pathname.split(/[?#]/)[0];
    // now_route = pageUrl.substr(1).replace(/_/g, ' ');
    for (let i = 0; i < menu.length; i++) {
      let menuTitle = '';
      let subTitle = '';
      if (menu[i].path === currentPath) {
        menuTitle = menu[i].title;
      }
      const submenu = menu[i].submenu;
      for (let j = 0; j < submenu.length; j++) {
        if (submenu[j].path === currentPath) {
          subTitle = submenu[j].title;
        }
      }
      if (menuTitle !== '' || subTitle !== '') {
        this.setState({ Tab: menu[i].title, SubTab: subTitle });
        // now_route =
        //   subTitle !== ''
        //     ? subTitle
        //     : menu[i].submenu.length === 0
        //       ? menu[i].title
        //       : '';
        break;
      }
    }

    $('.toggle-search').on('click', function () {
      var targetId = $(this).data('target');
      var $searchBar;
      if (targetId) {
        $searchBar = $(targetId);
        $searchBar.toggleClass('open');
      }
    });

    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
      $('body').toggleClass('enlarged');
    });

    $('li.no_sub a').on('click', function (event) {
      $('body').toggleClass('enlarged');
    });

    $('li.has_sub li').on('click', function (event) {
      $('body').toggleClass('enlarged');
    });
  }

  componentDidUpdate(prevProp, prevState) {
    // var now_route = '';
    const currentPath = this.props.location.pathname;
    const userData =
      customFunctions.getStringifyDataFromLocalStorage('userdata');
    const uRole =
      userData && Object.keys(userData).length > 0 ? userData.role_id : '';
    if (prevState.currentPath.toString() !== currentPath.toString()) {
      let menuTitle = '';
      let subTitle = '';
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].path === currentPath) {
          menuTitle = menu[i].title;
        }
        const submenu = menu[i].submenu;
        for (let j = 0; j < submenu.length; j++) {
          if (submenu[j].path === currentPath) {
            subTitle = submenu[j].title;
          }
        }
        if (menuTitle !== '' || subTitle !== '') {
          menuTitle = menu[i].title;
          this.setState({ Tab: menuTitle, SubTab: subTitle });
          // now_route =
          //   subTitle !== ''
          //     ? subTitle
          //     : menu[i].submenu.length === 0
          //       ? menu[i].title
          //       : '';
          break;
        }
      }
      this.setState({
        Tab: menuTitle,
        SubTab: subTitle,
        userRole: uRole,
        currentPath,
      });
    }
  }

  getPermissions = async (role_id) => {
    try {
      const endPoint = `${Api.rolePermissionUrl}?role_id=${role_id}`;
      const { data } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.success !== false) {
        this.setState({ permissions: data.data });
        customFunctions.setStringifyDataToLocalStorage(
          'permissions',
          data.data
        );
      }
    } catch (err) {}
  };

  getCount = async () => {
    try {
      const { roleId, userId } = this.state;
      var endPoint = `${Api.jobsCountUrl}`;
      var str = '';
      if (Number(roleId) === 4) {
        str += `?customer_id=${Number(userId)}`;
      }

      if ([3, 5, 6].includes(roleId)) {
        str += `?technician_id=${Number(userId)}`;
      }
      const { data } = await AuthApi.getDataFromServer(endPoint + str);
      if (data && data.success !== false && data.data) {
        menu &&
          menu.length > 0 &&
          menu.map((m) => {
            Object.keys(data.data).length &&
              Object.entries(data.data).map(([datakey, dataValue]) => {
                if (m.countKey === datakey) {
                  m.count = dataValue;
                }
              });

            Object.keys(m.submenu).length &&
              Object.entries(m.submenu).map(([dt, dtValue]) => {
                Object.keys(data.data).length &&
                  Object.entries(data.data).map(([datakey, dataValue]) => {
                    if (dtValue.countKey === datakey) {
                      dtValue.count = dataValue;
                    }
                  });
              });
          });
        this.forceUpdate();
      }
    } catch (err) {}
  };

  render() {
    const { Tab, SubTab, permissions, open } = this.state;
    const { toggle } = this.props;
    return (
      <div
        className={`left side-menu ${toggle ? 'col-sm-2' : ''}`}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          display: toggle ? 'none' : 'block',
        }}
      >
        <div className="logo-wrapper">
          <Link to="/dashboard" className="logo">
            <img alt="Logo" src="/assets/images/logo2.png" />
          </Link>
        </div>

        <div className="sidebar-inner slimscrollleft">
          <PerfectScrollbar>
            <div id="sidebar-menu">
              <ul>
                {menu.map((menuItem, index) => {
                  console.log('menuItem: ', menuItem);
                  const hasSubMenu = menuItem.submenu.length > 0;
                  let parentLiCls = hasSubMenu ? 'has_sub' : 'no_sub';
                  parentLiCls = `${parentLiCls} ${
                    Tab === menuItem.title && open
                      ? 'active-menu nav-active'
                      : ''
                  }`;
                  const parentLinkCls =
                    Tab === menuItem.title
                      ? 'waves-effect active-menu'
                      : 'waves-effect';

                  console.log('~permi', permissions, menuItem);
                  return (
                    <UserRestrictionCondition
                      permittedUsers={(permissions && permissions.module) || []}
                      roleId={menuItem.roleaccess}
                    >
                      <li className={parentLiCls} key={`${index}--sidebar`}>
                        <Link
                          to={menuItem.path}
                          className={parentLinkCls}
                          onClick={this.setActiveTab.bind(
                            this,
                            menuItem.title,
                            '',
                            hasSubMenu
                          )}
                        >
                          {' '}
                          {/* {menuItem.count > 0 && ( */}
                          <span className="badge badge-danger nav-link-badge">
                            {menuItem.count}
                          </span>
                          {/* )} */}
                          <i className={`mdi ${menuItem.icon}`}></i>
                          <span>
                            {menuItem.title}
                            {hasSubMenu && (
                              <span className="pull-right">
                                <i className="mdi mdi-chevron-right"></i>
                              </span>
                            )}
                          </span>
                        </Link>
                        {hasSubMenu && (
                          <ul
                            className="list-unstyled"
                            style={{
                              display: `(${
                                Tab === menuItem.title
                              }) ? 'block' : 'none'`,
                            }}
                          >
                            {menuItem.submenu.map((submenu, idx) => {
                              const submenuCls =
                                SubTab === submenu.title ? 'active-menu' : '';
                              return (
                                <UserRestrictionCondition
                                  key={idx}
                                  permittedUsers={
                                    (permissions &&
                                      permissions.submodule &&
                                      Object.keys(permissions.submodule)) ||
                                    []
                                  }
                                  roleId={submenu.roleaccess}
                                >
                                  <li key={`${idx}--list-unstyled`}>
                                    <Link
                                      className={submenuCls}
                                      onClick={this.setActiveTab.bind(
                                        this,
                                        menuItem.title,
                                        submenu.title,
                                        false
                                      )}
                                      to={submenu.path}
                                    >
                                      {/* {
                                        submenu.count > 0 && ( */}
                                      <span className="badge badge-danger nav-link-badge">
                                        {submenu.count}
                                      </span>
                                      {/* )} */}
                                      {submenu.title}
                                    </Link>
                                  </li>
                                </UserRestrictionCondition>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    </UserRestrictionCondition>
                  );
                })}
              </ul>
            </div>

            <div className="clearfix"></div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetToggle: (data) => dispatch(SetToggle(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(sidebar));

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';
import AddNote from '../Notes/addEdit';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      rows: [],
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      skip: 0,
      showDelete: false,
      workOrderId: null,
      notes: [],
      roleId: null,
      isShowLoader: false,
      note: '',
      loginId: null,
      createdById: null,
      columns: [],
      type: '',
      sourceType: null,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      {
        roleId: userdata.role_id,
        loginId: userdata.login_id,
        createdById: userdata.id,
        sourceType: this.props.orderDetails.sourceid,
      },
      async () => {
        await this.getNotes();
      }
    );

    const columns = [
      {
        label: 'ID',
        field: 'id',
        sort: 'disabled',
        width: 50,
      },
      {
        label: 'Notes',
        field: 'notes',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Created By',
        field: 'createdby',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Created At',
        field: 'date',
        sort: 'disabled',
        width: 170,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 150,
      },
    ];

    if (userdata.role_id === 1) {
      let share = {
        label: 'Share',
        field: 'share',
        sort: 'disabled',
        width: 250,
      };
      columns.splice(4, 0, share);
    }
    this.setState({ columns });
  };

  shareToCustomer = async (id, type) => {
    const endPoint = `${Api.shareNotes}`;

    const callback = AuthApi.putDataToServer;
    const postData = {
      id,
      type,
    };
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
      if (type === 'sharetocust') {
        swal('Note Shared to Customer', '', 'success');
      } else {
        swal('Note Shared to AHS', '', 'success');
      }
      this.getNotes();
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = () => {
    const { notes, roleId, createdById, sourceType } = this.state;
    const rows = [];
    notes &&
      notes.length &&
      notes.map((note, i) => {
        note.date = customFunctions.changeDateFormatMDY(note.date);
        note.notes =
          (note.notes && customFunctions.getSubString(note.notes)) || '-';
        if (
          (note.sharetocust === 1 && roleId === 4) ||
          roleId === 5 ||
          roleId === 6
        ) {
          rows.push({
            id: note.id || '-',
            notes: note.notes,
            date: note.date || '-',
            createdby:
              note.created_first_name + ' ' + note.created_last_name || '-',
            action: (
              <React.Fragment>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showNotes: true, note, type: 'edit' });
                  }}
                >
                  {(note.sharetocust === 1 && roleId === 4) ||
                  ((roleId == 5 || roleId == 6) &&
                    createdById !== note.createdby) ? (
                    'View'
                  ) : (
                    <i className="mdi mdi-lead-pencil" title="Edit Note"></i>
                  )}
                </a>
                {(roleId === 5 || roleId == 6) &&
                  createdById == note.createdby && (
                    <a
                      href="/"
                      style={{ paddingLeft: '10px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ showDelete: true, noteId: note.id });
                      }}
                    >
                      <i
                        className="mdi mdi-delete text-danger"
                        title="Delete Note"
                      ></i>
                    </a>
                  )}
              </React.Fragment>
            ),
          });
        }
        if (roleId === 1) {
          rows.push({
            id: note.id || '-',
            notes: note.notes || '-',
            date: note.date || '-',
            createdby:
              note.created_first_name + ' ' + note.created_last_name || '-',
            share: (
              <React.Fragment>
                {note.sharetocust ? (
                  <button
                    className="btn btn-secondary m-r-5"
                    disabled
                    style={{ fontSize: '12px' }}
                  >
                    Shared to Customer
                  </button>
                ) : (
                  <button
                    className="btn btn-success m-r-5"
                    onClick={() => this.shareToCustomer(note.id, 'sharetocust')}
                    style={{ fontSize: '12px' }}
                  >
                    Share To Customer
                  </button>
                )}
                {sourceType == 1 &&
                  (note.sharetoahs ? (
                    <button
                      className="btn btn-secondary"
                      disabled
                      style={{ fontSize: '12px' }}
                    >
                      Shared to Ahs
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      style={{ fontSize: '12px' }}
                      onClick={() =>
                        this.shareToCustomer(note.id, 'sharetoahs')
                      }
                    >
                      Share To Ahs
                    </button>
                  ))}
              </React.Fragment>
            ),
            action: (
              <React.Fragment>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showNotes: true, note, type: 'edit' });
                  }}
                >
                  {createdById !== note.createdby ? (
                    'View'
                  ) : (
                    <i className="mdi mdi-lead-pencil" title="Edit Note"></i>
                  )}
                </a>
                {createdById == note.createdby && (
                  <a
                    href="/"
                    className=""
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ showDelete: true, noteId: note.id });
                    }}
                  >
                    <i
                      className="mdi mdi-delete text-danger"
                      title="Delete Note"
                    ></i>
                  </a>
                )}
              </React.Fragment>
            ),
          });
        }
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getNotes = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.getNotes}?work_order_id=${this.props.workorderid}&skip=${skip}&limit=${limit}`;

    // if (roleId === 4) {
    //   endPoint += `&customer_id=${loginId}`;
    // }
    // if (roleId === 5) {
    //   endPoint += `&tech_id=${loginId}`;
    // }

    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState(
        {
          isShowLoader: false,
          notes: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.setState({
      isShowLoader: false,
    });
  };

  deleteNote = async () => {
    const { noteId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.deleteNotes}?note_id=${noteId}`
    );
    if (data) {
      this.setState({ showDelete: false, noteId: null }, () => {
        this.getNotes();
      });
      swal('Note deleted', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      isShowLoader,
      showNotes,
      note,
      roleId,
      columns,
      type,
    } = this.state;
    const { workorderid } = this.props;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="card m-b-20">
          {showDelete && (
            <div className="sweetalert">
              <SweetAlert
                title="Are you sure, Do you want to delete the note ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteNote();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, noteId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            </div>
          )}

          {(roleId === 1 || roleId === 5 || roleId === 6) && (
            <div style={{ paddingRight: '20px', paddingTop: '20px' }}>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showNotes: true, note: '', type: 'add' });
                  }}
                >
                  Add Note
                </button>
              </div>
            </div>
          )}
          <div className="card-body mdbtable">
            <MDBDataTable
              noBottomColumns={true}
              paging={false}
              bordered
              hover
              data={{ columns, rows }}
              searching={false}
              noRecordsFoundLabel="No records found"
              scrollX
              maxHeight="350px"
            />
            {totalCount > 10 && (
              <div className="pagination">
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  limit={limit}
                  getData={this.getNotes}
                />
              </div>
            )}
          </div>
        </div>
        {showNotes && (
          <AddNote
            showNotes={showNotes}
            technicians={this.props.technicians}
            workorderid={workorderid}
            noteId={note.id}
            note={note}
            type={type}
            handleClose={() => this.setState({ showNotes: false, type: '' })}
            handleCallback={() => this.getNotes()}
          />
        )}
      </AUX>
    );
  }
}

export default withRouter(Notes);

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import HelmetComponent from '../Title/index';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import LineChart from './charts/LineChart';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Customers',
      customerCount: '',
      dateRangeList: [
        'Current Week',
        'Last Week',
        'Current Month',
        'All Dates',
        '365 Days',
        '180 Days',
        '90 Days',
        '60 Days',
        '30 Days',
        '14 Days',
        '7 Days',
        '3 Days',
        '1 Day',
      ],
      dateRange: '30 Days',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      lineChartData: {},
      filterCount: 1,
      initialHide: true,
      isShowLoader: false,
    };
  }

  async componentDidMount() {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'reports-customers'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle(this.state.title);
    await this.getCustomers();
    await this.getCustomerPerWeekGraph();

    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'CUSTOMER ID',
        field: 'customerid',
        width: 150,
      },
      {
        label: 'EXTERNAL ID',
        field: 'externalid',
        width: 150,
      },
      {
        label: 'NAME',
        field: 'name',
        width: 150,
      },
      {
        label: 'PHONE NUMBER',
        field: 'phone',
        width: 150,
      },
      {
        label: 'EMAIL',
        field: 'email',
        width: 150,
      },
      // {
      //   label: 'COMPANY',
      //   field: 'company',
      //   width: 150,
      // },
      {
        label: 'HOME STREET',
        field: 'homestreet',
        width: 150,
      },
      {
        label: 'HOME CITY',
        field: 'homecity',
        width: 150,
      },
      {
        label: 'HOME STATE',
        field: 'homestate',
        width: 150,
      },
      {
        label: 'HOME POSTAL',
        field: 'homepostal',
        width: 150,
      },
      {
        label: 'BILLING STREET',
        field: 'billingstreet',
        width: 150,
      },
      {
        label: 'BILLING CITY',
        field: 'billingcity',
        width: 150,
      },
      {
        label: 'BILLING STATE',
        field: 'billingstate',
        width: 150,
      },
      {
        label: 'BILLING POSTAL',
        field: 'billingpostal',
        width: 150,
      },
      {
        label: 'CUSTOMER ADDED',
        field: 'customerCreated',
        width: 180,
      },
      {
        label: 'NOTES',
        field: 'notes',
        width: 150,
      },
      {
        label: 'VIEW CUSTOMER',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState({ columns });
  }

  getCustomers = async (currentPage) => {
    let { pagination, startDate, endDate, dateRange } = this.state;

    this.setState({ currentPage, isShowLoader: true });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.customerUrl}?skip=${skip}&limit=${pagination.itemsPerPage}&from_date=${startDate}&to_date=${endDate}`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          customers: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    await this.getCustomerPerWeekGraph();
  };

  downloadCSVForCustomers = async () => {
    let { startDate, endDate } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.downloadCustomerCsvUrl}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;
    window.open(endPoint, '_blank');
  };

  getCustomerPerWeekGraph = async () => {
    let { startDate, dateRange } = this.state;
    let dt = new Date(startDate);
    let year = startDate ? dt.getFullYear() : '';
    if (dateRange == '') {
      year = '';
    }
    let endPoint = `${Api.getCustomerWeekGraph}?year=${year}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success) {
      const lineChartData = data && data.data;
      this.setState({ isShowLoader: false, lineChartData });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = () => {
    const { customers, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    customers &&
      customers.length &&
      customers.map((customer, i) => {
        customer.Created = customFunctions.changeDateFormatMomentMDY(
          customer.Created
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          customerid: customer.ID || '-',
          externalid: customer.externalid || '-',
          name: customer.CustomerName || '-',
          phone: customer.PhoneNumber1 || '-',
          email: (
            <React.Fragment>
              <p style={{ wordBreak: 'break-word' }}>{customer.email || '-'}</p>
            </React.Fragment>
          ),
          company: customer.company || '-',
          homestreet: customer.AddressLine1_1 || '-',
          homecity: customer.City1 || '-',
          homestate: customer.State1 || '-',
          homepostal: customer.Zip1 || '-',
          billingstreet: customer.AddressLine2_1 || '-',
          billingcity: customer.City2 || '-',
          billingstate: customer.State2 || '-',
          billingpostal: customer.Zip2 || '-',
          customerCreated: customer.Created || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-customer/${customer.ID}`, {
                    customerDetails: customer,
                    type: 'customer',
                  });
                }}
              >
                <i className="mdi mdi-eye" id="viewJob" title="View Job"></i>
              </a>
            </React.Fragment>
          ),
          notes: customer.Notes || '-',
        });
        return customers;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  changeDates = async () => {
    const { dateRange } = this.state;
    var date = new Date();
    let firstday;

    if (dateRange === 'Current Week') {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      // firstday = new Date(curr.setDate(first));
      firstday = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(first))
      );

      // date = new Date(curr.setDate(last));
      date = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(last))
      );
    }
    if (dateRange === 'Last Week') {
      let dates = customFunctions.getLastWeek(date);
      firstday = dates.firstDayOfLastWeek;
      date = dates.lastDayOfLastWeek;
    }
    if (dateRange === 'Current Month') {
      firstday = new Date(date.getFullYear(), date.getMonth(), 1);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (dateRange === '365 Days') {
      firstday = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '180 Days') {
      firstday = new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '90 Days') {
      firstday = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '60 Days') {
      firstday = new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '30 Days') {
      firstday = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '14 Days') {
      firstday = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '7 Days') {
      firstday = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '3 Days') {
      firstday = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '1 Day') {
      firstday = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    this.setState({ startDate: firstday, endDate: date, initialHide: false });

    if (dateRange === 'All Dates') {
      this.setState({ startDate: '', endDate: '' });
    }
    this.filterCount();
  };

  filterCount = () => {
    const { dateRange } = this.state;
    let dateLen = dateRange !== '' ? 1 : 0;
    this.setState({ filterCount: dateLen });
  };
  render() {
    const {
      title,
      totalCount,
      dateRange,
      dateRangeList,
      startDate,
      endDate,
      rows,
      columns,
      lineChartData,
      filterCount,
      initialHide,
      limit,
      currentPage,
      isShowLoader,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12 m-b-20">
                <div id="accordion">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                      border: '2px solid #CCC',
                      borderBottom: 'none',
                    }}
                  >
                    <div
                      style={{ fontSize: '16px' }}
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <b>{`FILTERS(${filterCount})`}</b>{' '}
                      <span className="dropdown-toggle"></span>{' '}
                    </div>
                    {dateRange && (
                      <span className="selected-daterange">
                        <b>DateRange</b> {dateRange}
                        {initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                        {!initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse show`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                      </span>
                    )}
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card-body1">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="accordion" id="accordionExample">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Date Range
                                    </button>
                                  </div>

                                  <div
                                    id="collapseOne1"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body">
                                      <Radio
                                        checked={dateRange === 'Custom Range'}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dateRange: e.target.value,
                                            },
                                            () => this.filterCount()
                                          );
                                        }}
                                        id="customrange"
                                        value="Custom Range"
                                        color="default"
                                      />
                                      <label htmlFor="customrange">
                                        Custom Range
                                      </label>

                                      <br />
                                      {dateRange === 'Custom Range' && (
                                        <span>
                                          <DatePickerComponent
                                            selected={startDate}
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                              this.setState({
                                                startDate: date,
                                              });
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="form-control filter-date"
                                          />
                                          <br />
                                          <DatePickerComponent
                                            selected={endDate}
                                            placeholderText="End Date"
                                            onChange={(date) =>
                                              this.setState({ endDate: date })
                                            }
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            className="form-control filter-date"
                                          />
                                        </span>
                                      )}
                                      {dateRangeList &&
                                        dateRangeList.map((dates, index) => (
                                          <React.Fragment key={index}>
                                            <Radio
                                              checked={dateRange === dates}
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    dateRange: e.target.value,
                                                  },
                                                  () => this.changeDates()
                                                );
                                              }}
                                              id={`dates${index}`}
                                              value={dates}
                                              color="default"
                                            />
                                            <label htmlFor={`dates${index}`}>
                                              {dates}
                                            </label>
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            ></div>
                            <div
                              className="col-12"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4"></div>
                                  <div className="col-4">
                                    <span
                                      onClick={() =>
                                        this.setState(
                                          {
                                            dateRange: '30 Days',
                                            startDate: new Date(
                                              new Date().getTime() -
                                                30 * 24 * 60 * 60 * 1000
                                            ),
                                            endDate: new Date(),
                                          },
                                          () => this.filterCount()
                                        )
                                      }
                                      className="resetFilter f-btns"
                                    >
                                      RESET FILTERS
                                    </span>
                                    <button
                                      className="btn btn-secondary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="btn btn-primary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                      onClick={() => {
                                        this.getCustomers();
                                        this.getCustomerPerWeekGraph();
                                      }}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="card col-4">
                        <div
                          className="card-body text-center py-5"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Total Count
                          </p>
                          <h1>{totalCount}</h1>
                          <p style={{ fontSize: '22px' }}>Total Count</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {totalCount}
                          </p>
                        </div>
                      </div>
                      <div className="card col-8">
                        Customers Per Week
                        <div
                          className="card-body text-center py-5"
                          id="avgTime2"
                        >
                          <LineChart
                            lineChartData={lineChartData}
                            height={80}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="new-header-style">
                      <h6>Customers + Notes</h6>
                      {rows && rows.length ? (
                        <button
                          type="submit"
                          style={{ marginBottom: '5px' }}
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            this.downloadCSVForCustomers();
                          }}
                        >
                          <i className="mdi mdi-download"></i> CSV
                        </button>
                      ) : (
                        ''
                      )}
                    </div>

                    {/* <Table
                      columns={columns}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.getCustomers(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    /> */}
                    <div ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="400px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getCustomers}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(Customer);

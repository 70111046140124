import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import customFunctions from '../../helper/customFunctions';
import Loader from '../Loader';
import CANCEL from '../../assets/images/cancel.png';

const UniversSearchComponent = (props) => {
  const [disableButton, setDisableButton] = useState(false);
  const [universData, setUniversData] = useState([]);
  const [recentJobRows, setRecentJobRows] = useState([]);
  const [customerRows, setCustomerRows] = useState([]);
  const [technicianRows, setTechnicianRows] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [isShowLoader, setShowLoader] = useState(false);
  const [universDataEmpty, setUniversDataEmpty] = useState('');

  const customerColumn = [
    {
      label: 'Name',
      field: 'customerName',
      sort: 'disabled',
      // width: 150
    },
    {
      label: 'Home Address',
      field: 'homeAddress',
      sort: 'disabled',
      // width: 200
    },

    {
      label: 'Billing Address',
      field: 'billingAddress',
      sort: 'disabled',
      // width: 200
    },
    {
      label: 'Phone Number',
      field: 'phone',
      sort: 'disabled',
      // width: 200
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'disabled',
    },
    {
      label: 'Notes',
      field: 'notes',
      sort: 'disabled',
    },
    // {
    //   label: 'Labels',
    //   field: 'labels',
    //   sort: 'disabled',
    // },
  ];

  const recentJobColumn = [
    {
      label: 'Dispatch#',
      field: 'dispatchId',
      sort: 'disabled',
      // width: 150
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'disabled',
      // width: 150
    },
    {
      label: 'Source',
      field: 'source',
      sort: 'disabled',
      // width: 270
    },
    {
      label: 'Title',
      field: 'title',
      sort: 'disabled',
      // width: 200
    },

    // {
    //   label: 'Labels',
    //   field: 'labels',
    //   sort: 'disabled',
    //   // width: 200
    // },
    {
      label: 'Address',
      field: 'address',
      sort: 'disabled',
      // width: 200
    },
    {
      label: 'Customer',
      field: 'customer',
      sort: 'disabled',
    },
    {
      label: 'Appointment',
      field: 'appt',
      sort: 'disabled',
    },
  ];

  const technicianColumn = [
    {
      label: 'Technician Name',
      field: 'techName',
      sort: 'disabled',
      // width: 150
    },
    {
      label: 'Email Address',
      field: 'email',
      sort: 'disabled',
      // width: 200
    },
    {
      label: 'Address',
      field: 'address',
      sort: 'disabled',
      // width: 200
    },
    {
      label: 'Phone',
      field: 'phone',
      sort: 'disabled',
    },
  ];

  useEffect(() => {
    setUniversData(props.universData);
    setUniversDataEmpty(props.universDataEmpty);
  }, [props.universDataEmpty, props.universData]);

  useEffect(() => {
    setCustomerTableData();
    async function getRoleData() {
      var userdata = await customFunctions.getDataFromLocalStorage(
        'userdata',
        true
      );
      setRoleId(userdata.role_id);
    }
    getRoleData();
  }, [universData]);

  const handleClick = async (e, type) => {
    const url_segment = props.location.pathname.split('/');
    if (type === 'work') {
      setShowLoader(true);
      const { data, message } = await AuthApi.getDataFromServer(
        `${Api.orderById}?work_order_id=${e}`
      );
      if (data && data.data) {
        setShowLoader(false);
        props.handleModalCLose(false);
        props.history.push(`/update-order/${e}`, {
          orderDetails: data && data.data && data.data[0],
          type: 'orders-search',
        });
        // if (url_segment && url_segment.includes('update-order')) {
        //   window.location.reload();
        // }
      } else {
        setShowLoader(false);
        swal(message ? message : 'No data found', '', 'error');
      }
    } else if (type === 'customer') {
      setShowLoader(true);
      const { data, message } = await AuthApi.getDataFromServer(
        `${Api.getCustomerById}?customer_id=${e}`
      );
      if (data && data.data) {
        setShowLoader(false);
        props.handleModalCLose(false);
        props.history.push(`/update-customer/${e}`, {
          customerDetails: data && data.data,
          type: 'customer',
        });
        if (url_segment && url_segment.includes('update-customer')) {
          window.location.reload();
        }
      } else {
        setShowLoader(false);
        swal(message ? message : 'No data found', '', 'error');
      }
    } else if (type === 'technician') {
      setShowLoader(true);
      const { data, message } = await AuthApi.getDataFromServer(
        `${Api.technicianById}?tech_id=${e}`
      );
      if (data && data.data) {
        setShowLoader(false);
        props.handleModalCLose(false);
        props.history.push(`/edit-technician/${e}`, {
          technicianDetails:
            data && data.data && data.data.data && data.data.data[0],
          type: 'technician',
          detail: data && data.data && data.data.data && data.data.data[0],
        });
        if (url_segment && url_segment.includes('edit-technician')) {
          window.location.reload();
        }
      } else {
        setShowLoader(false);
        // swal(message ? message : 'No data found', '', 'error');
      }
    }
  };

  const setCustomerTableData = async () => {
    const recentRows = [];
    const customerRows = [];
    const technicianRows = [];

    (await universData) &&
      universData.Recent_Jobs &&
      universData.Recent_Jobs.map((uniData, i) => {
        recentRows.push({
          dispatchId: `company-name-WO-${uniData && uniData.ID}`,
          status: uniData && uniData.WorkStatus,
          source: uniData && uniData.Source,
          title: uniData && uniData.Title,
          labels: uniData && uniData.Labels,
          address: uniData && uniData.Address,
          customer:
            uniData && uniData.Customer && uniData.Customer.CustomerName,
          notes: uniData && uniData.Notes,
          appt: uniData && uniData.Appointment,
          clickEvent: () => handleClick(uniData.ID, 'work'),
        });
        return uniData;
      });
    setRecentJobRows(recentRows);

    (await universData) &&
      universData.Customers &&
      universData.Customers.map((uniData, i) => {
        customerRows.push({
          customerName: uniData && uniData.CustomerName,
          company: uniData && uniData.Company,
          homeAddress: uniData && uniData.HomeAddress,
          billingAddress: uniData && uniData.BillingAddress,
          phone: uniData && uniData.PhoneNumber,
          email: uniData && uniData.EmailAddress,
          notes: uniData && uniData.Notes,
          labels: uniData && uniData.Labels,
          clickEvent: () => handleClick(uniData.ID, 'customer'),
        });
        return uniData;
      });
    setCustomerRows(customerRows);

    (await universData) &&
      universData.Technicians &&
      universData.Technicians.map((uniData, i) => {
        technicianRows.push({
          techName: uniData && uniData.TechnicianName,
          address: uniData && uniData.Address,
          email: uniData && uniData.Email,
          phone: uniData && uniData.Phone,
          clickEvent: () => handleClick(uniData.ID, 'technician'),
        });
        return uniData;
      });
    setTechnicianRows(technicianRows);
  };

  const onTaskCancel = () => {
    props.handleModalCLose(false);
    if (disableButton) return;
    setDisableButton(true);
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div
        className="card"
        style={{
          margin: '-22px',
          height: '600px',
          overflowX: 'scroll',
          marginLeft: '0px',
          marginRight: '0px',
        }}
      >
        <div className="card-body" style={{ height: '600px' }}>
          <div
            className="btn-close"
            style={{ top: '19px' }}
            onClick={() => {
              onTaskCancel();
            }}
          >
            <img style={{ maxHeight: '10px' }} src={CANCEL} alt="cancel" />
          </div>
          {props.universDataEmpty !== 'true' ? (
            <div className="search-table">
              {[1, 4, 5].includes(roleId) &&
                recentJobRows &&
                recentJobRows.length > 0 && (
                  <>
                    <div>
                      <h4>
                        <i
                          className="mdi mdi-account-multiple"
                          title="Orders"
                        ></i>
                        &nbsp; Recent Jobs
                      </h4>
                    </div>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{
                        columns:
                          roleId === 4
                            ? recentJobColumn.filter((item, i) => i !== 4)
                            : recentJobColumn,
                        rows: recentJobRows,
                      }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                  </>
                )}
              {roleId === 1 && customerRows && customerRows.length > 0 && (
                <>
                  <div>
                    <h4>
                      <i className="mdi mdi-briefcase" title="Orders"></i>&nbsp;
                      Customers
                    </h4>
                  </div>
                  <MDBDataTable
                    // striped
                    noBottomColumns={true}
                    paging={false}
                    bordered
                    hover
                    data={{ columns: customerColumn, rows: customerRows }}
                    searching={false}
                    noRecordsFoundLabel="No records found"
                  />
                </>
              )}
              {roleId === 1 && technicianRows && technicianRows.length > 0 && (
                <>
                  <div>
                    <h4>
                      <i className="mdi mdi-briefcase" title="Orders"></i>&nbsp;
                      Technician
                    </h4>
                  </div>
                  <MDBDataTable
                    // striped
                    noBottomColumns={true}
                    paging={false}
                    bordered
                    hover
                    data={{ columns: technicianColumn, rows: technicianRows }}
                    searching={false}
                    noRecordsFoundLabel="No records found"
                  />
                </>
              )}
            </div>
          ) : (
            <span className="d-flex justify-content-center">
              No record found
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(UniversSearchComponent);

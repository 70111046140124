import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import swal from 'sweetalert';
import { SetUserData } from '../../../action/user.action';
import ATTACHMENT from '../../../assets/images/attachment.png';
import ImagePreview from '../../../components/ImagePreview';
import LocationMap from '../../../components/LocationMap/index';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import Geocode from 'react-geocode';
import ColorList from '../../../components/ColorPicker/colorList';
import TechnicianColorCode from './colorCodePicker';
import Loader from '../../../components/Loader';
// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class AddTechnician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianName: '',
      percentage: '',
      bio: '',
      dailyRate: '',
      signature: '',
      technicianNameErr: '',
      percentageErr: '',
      dailyRateErr: '',
      signatureErr: '',
      listItemErr: '',
      ListData: [],
      isSubmitted: false,
      technicianId: null,
      imageFile: null,
      imageUrl: '',
      viewType: false,
      tech_id: null,
      imgBucket: [],
      title: '',
      email: '',
      emailErr: '',
      password: '',
      passwordErr: '',
      selectedLocation: '',
      latitude: '',
      longitude: '',
      showPass: false,
      imagePreviewShow: false,
      showDelete: false,
      qualification: '',
      roleId: null,
      dob: '',
      address: '',
      phonenumber: '',
      ssn: '',
      cbramount: '',
      cbrpercentage: '',
      urlParam: '',
      modalShow: false,
      technicianColor: '#67a8e4',
      isShowLoader: false,
      technicianOption: [
        {
          value: '5',
          label: 'Technician',
        },
        {
          value: '3',
          label: 'Dispatcher',
        },
        {
          value: '6',
          label: 'Both',
        },
      ],
      roleValue: '',
      rolesErr: '',
      profile_pic: this.props.userdata.profile_pic,
      profileImageFile: null,
      profileImageUrl: '',
    };
  }

  componentDidMount = async () => {
    const { technicianOption } = this.state;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      login_id: userdata.login_id,
      userId: userdata.id,
      username: `${userdata.first_name}${userdata.last_name}`,
    });

    if (
      Number(userdata.role_id) === 5 ||
      Number(userdata.role_id) === 3 ||
      Number(userdata.role_id) === 6
    ) {
      let newTechnicianOption = [];
      if (userdata.role_id === 5) {
        newTechnicianOption = technicianOption.filter((t) => {
          return Number(t.value) !== 3;
        });
      }
      if (userdata.role_id === 3) {
        newTechnicianOption = technicianOption.filter((t) => {
          return Number(t.value) !== 5;
        });
      }
      if (userdata.role_id === 6) {
        newTechnicianOption = technicianOption.filter((t) => {
          return Number(t.value);
        });
      }
      this.setState({ technicianOption: newTechnicianOption }, () => {
        this.forceUpdate();
      });
    } else {
      this.setState({ technicianOption: technicianOption }, () => {
        this.forceUpdate();
      });
    }
    const url_segment = this.props.match.url.split('/');
    if (
      url_segment &&
      (url_segment.includes('edit-technician') ||
        url_segment.includes('edit-dispatcher'))
    ) {
      this.setState({ viewType: true });
    }
    let userType =
      url_segment.includes('edit-dispatcher') ||
      url_segment.includes('add-dispatcher')
        ? 'Dispatcher'
        : 'Technician';
    this.setState({
      urlParam: userType === 'Dispatcher' ? 'Dispatcher' : 'Technician',
    });

    const technicianId = this.props.match.params.technicianId;
    this.setState({ technicianId });
    if (technicianId) {
      await this.getTechnicianDetails();
      customFunctions.setTitle(`Edit ${userType}`);
      this.setState({ title: `Edit ${userType}` });
    } else {
      customFunctions.setTitle(`Add Team Member`);
      this.setState({ title: `Add Team Member` });
    }
  };

  getTechnicianDetails = async () => {
    const { technicianId, technicianOption, profileImageFile } = this.state;
    if (!profileImageFile) this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.technicianUrl}?tech_id=${technicianId}`
    );
    if (data && data.data && data.success) {
      let technicianData = data.data.data[0];
      const selectedLocation = {
        lat:
          technicianData && technicianData.latitude
            ? Number(technicianData.latitude)
            : null,
        lng:
          technicianData && technicianData.longitude
            ? Number(technicianData.longitude)
            : null,
      };

      if (technicianData) {
        let buck = [];
        technicianData.certficates &&
          technicianData.certficates.map((i) => {
            buck.push({ url: i.certficate });
            return i;
          });

        if (technicianData.role) {
          let result = [];
          result = technicianOption.filter(
            (tech) => technicianData.role == tech.value
          );
          this.setState({
            roleValue: result && result.length > 0 && result[0],
          });
        }
        this.setState({
          tech_id: technicianData.id,
          technicianName: technicianData.name,
          percentage: technicianData.percentage,
          dailyRate: technicianData.daily_rate,
          imageUrl: technicianData.signature,
          technician_id: technicianData.id,
          email: technicianData.email,
          bio: technicianData.bio,
          qualification: technicianData.qualification,
          password: technicianData.password,
          latitude: technicianData.latitude && Number(technicianData.latitude),
          longitude:
            technicianData.longitude && Number(technicianData.longitude),
          selectedLocation,
          imgBucket: buck,
          dob: technicianData.dob && new Date(technicianData.dob),
          ssn: technicianData.ssn,
          address: technicianData.address,
          phonenumber: technicianData.phonenumber,
          // cbramount: technicianData.cbramount,
          cbramount: technicianData.total_cbr,
          cbrpercentage: technicianData.cbrpercentage,
          technicianColor: technicianData.techcolor,
        });
      }
      this.setState({ isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  handelImageChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      imageFile: e.target.files[0],
      imageUrl: fileUrl,
    });
  };

  uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `technician/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  handelProfileImageChange = async (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    // const filename = await this.uploadProfile(e.target.files[0]);
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    this.setState({
      profileImageFile: e.target.files[0],
      profileImageUrl: fileUrl,
      profile_pic: fileUrl,
    });
  };

  uploadProfile = async (profileImageFile) => {
    if (!profileImageFile) return null;
    const { username } = this.state;
    const newImage = profileImageFile;
    const file = new File(
      [newImage],
      `${username}_${new Date().toISOString()}_img.fileName`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  onDeleteHandler = () => {
    const { imgBucket, index } = this.state;
    const buck = [];
    imgBucket.splice(index, 1);
    imgBucket &&
      imgBucket.map((bucket) => {
        //eslint-disable-line
        buck.push(bucket);
        return bucket;
      });
    this.setState({
      imgBucket: buck,
      showDelete: false,
      index: null,
      uploadComplete: true,
    });
  };

  validateForm = async (e) => {
    if (e) e.preventDefault();
    const { technicianName, email, password, latitude, longitude, roleValue } =
      this.state;
    let technicianNameErr = '',
      percentageErr = '',
      dailyRateErr = '',
      emailErr = '',
      passwordErr = '',
      locationErr = '',
      rolesErr = '',
      isValid = true;

    // if (
    //   latitude === '' ||
    //   longitude === '' ||
    //   latitude === null ||
    //   longitude == null
    // ) {
    //   locationErr = 'Location is required';
    //   isValid = false;
    // }
    if (technicianName === '') {
      technicianNameErr = 'Technician name is required';
      isValid = false;
    }
    if (
      email === '' ||
      (email !== '' && !customFunctions.validateEmail(email))
    ) {
      emailErr = 'Email is not valid';
      isValid = false;
    }
    if (password === '') {
      passwordErr = 'Password is required';
      isValid = false;
    }
    if (!roleValue) {
      rolesErr = 'role is required';
      isValid = false;
    }

    this.setState({
      technicianNameErr,
      percentageErr,
      dailyRateErr,
      emailErr,
      passwordErr,
      locationErr,
      rolesErr,
    });
    if (isValid) {
      this.addTechnician();
    }
  };

  addTechnician = async (e) => {
    this.setState({ isShowLoader: true });
    const {
      technicianName,
      percentage,
      bio,
      dailyRate,
      technicianId,
      imageFile,
      imgBucket,
      email,
      password,
      latitude,
      longitude,
      qualification,
      dob,
      address,
      phonenumber,
      ssn,
      cbrpercentage,
      roleId,
      technicianColor,
      roleValue,
      profile_pic,
      profileImageFile,
      profileImageUrl,
    } = this.state;

    const postData = {
      role: roleValue && Number(roleValue.value),
      technician_name: technicianName,
      percentage: percentage,
      daily_rate: dailyRate,
      latitude,
      longitude,
      bio,
      qualification,
      dob,
      address,
      phonenumber,
      cbrpercentage,
      ssn,
      techcolor: technicianColor,
      email,
      new_password: '',
      old_password: '',
      // profile_pic: profile_pic == '' ? '' : profile_pic
    };
    if (imageFile) {
      const imgUrl = await this.uploadImage(imageFile);
      if (imgUrl) postData.signature = imgUrl;
    }

    const callback = technicianId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.AddtechnicianUrl;
    if (!technicianId) {
      // postData.email = email;
      postData.password = password;
    }
    let cert = [];
    imgBucket &&
      imgBucket.map((i) => {
        cert.push({ certficate: i.url, cerficateid: '' });
        return i;
      });
    if (cert && cert.length > 0) {
      postData.certficates = cert;
    }
    if (technicianId) {
      postData.technician_id = parseInt(technicianId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      if (technicianId) {
        if (!profileImageFile)
          swal(
            roleId == 5
              ? 'Technician profile updated successfully'
              : roleId == 3
              ? 'Dispatcher profile updated successfully'
              : 'Team member updated successfully',
            '',
            'success'
          );

        // below code for header profile name using redux
        if ([5, 3, 6].includes(roleId)) {
          const { userdata } = this.props;
          userdata.first_name = technicianName.split(' ')[0];
          userdata.last_name = technicianName.split(' ')[1];
          if (profileImageFile) {
            let imgurl = await this.uploadProfile(profileImageFile);
            if (imgurl) {
              userdata.profile_pic = imgurl;
              await this.props.SetUserData(userdata);
              await this.userUpdateAPI(imgurl);
            }
          }
        }
        await this.getTechnicianDetails();

        if (roleId === 1) {
          this.props.setTechnicianName(technicianName);
          this.props.history.push('/technicians');
        }
      } else {
        swal('Team member added successfully', '', 'success').then(() => {
          if (roleId === 1) this.props.history.push('/technicians');
        });
      }
      this.setState({ isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      if (message === 'Updated') {
        swal('Team member updated successfully', '', 'success').then(() => {
          if (roleId === 1) this.props.history.push('/technicians');
        });
      } else {
        swal(message ? message : 'No data found', '', 'error');
      }
    }
  };

  userUpdateAPI = async (imgurl) => {
    const {
      // profile_pic,
      // profileImageFile,
      userId,
      roleId,
    } = this.state;
    const postData = {
      login_id: userId,
      profile_pic: imgurl == '' ? '' : imgurl,
    };

    if (roleId === 5) postData.role = 5;
    if (roleId === 3) postData.role = 3;

    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.getAdmin;
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, payload);

    if (data && data.success) {
      if (imgurl) window.location.reload();
      this.setState({ isShowLoader: false });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  handleImageFileUpload = async (e) => {
    const { imgBucket } = this.state;
    let addedFiles = e.target.files;
    this.setState({ addedFiles });

    let uploadedImages = imgBucket || [];
    let keys = Object.keys(addedFiles);
    if (!keys.length) return;
    this.setState({ uploadComplete: false });
    for (let i = 0; i < keys.length; i++) {
      const fileRes = await this.uploadImage(addedFiles[keys[i]]);
      if (!fileRes) {
        continue;
      }
      uploadedImages.push({ url: fileRes });
    }
    this.setState({ imgBucket: uploadedImages, uploadComplete: true });
    this.fileInput.value = '';
  };

  onMapClick = (e, marker, event) => {
    const selectedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    this.setState(
      {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
        selectedLocation,
      },
      () => {
        this.getAddressFromLatLog(selectedLocation);
      }
    );
  };

  getAddressFromLatLog = async (location) => {
    if (!location) return '';
    var $this = this;
    Geocode.fromLatLng(location.lat, location.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        $this.setState({
          address,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  colorChange = (e) => {
    e.preventDefault();
    const { technicianColor } = this.state;
    this.setState({
      technicianColor: technicianColor ? technicianColor : '#67a8e4',
      modalShow: true,
    });
  };

  render() {
    const {
      title,
      technicianName,
      percentage,
      dailyRate,
      bio,
      technicianNameErr,
      imageUrl,
      viewType,
      imgBucket,
      email,
      password,
      emailErr,
      passwordErr,
      selectedLocation,
      locationErr,
      technicianId,
      showPass,
      imagePreviewShow,
      imagePreview,
      showDelete,
      qualification,
      dob,
      phonenumber,
      ssn,
      cbramount,
      cbrpercentage,
      roleId,
      modalShow,
      technicianColor,
      isShowLoader,
      technicianOption,
      roleValue,
      rolesErr,
      profile_pic,
    } = this.state;

    return (
      <AUX>
        <div className={viewType ? '' : 'page-content-wrapper'}>
          <div className={viewType ? '' : 'container-fluid'}>
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, you want to delete technician?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.onDeleteHandler();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false });
                  }}
                >
                  You will not be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {roleId !== 1 && (
                      <div className="text-center m-b-10">
                        <div className="position-relative">
                          <input
                            onChange={(e) => {
                              this.handelProfileImageChange(e);
                            }}
                            title={`Edit Profile Picture`}
                            className="profile-file"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg,"
                          />
                          <img
                            className="profilepic"
                            src={
                              profile_pic
                                ? profile_pic
                                : '/assets/images/users/user.png'
                            }
                            alt=""
                            width={100}
                            height={100}
                          />
                          <span className="profile-pen" style={{ left: '54%' }}>
                            <i
                              className="mdi mdi-lead-pencil"
                              title={`Edit Profile Picture`}
                            ></i>
                          </span>
                        </div>
                      </div>
                    )}
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row ">
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Roles<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <ReactSelect
                            value={roleValue}
                            placeholder="Select"
                            isDisabled={roleId !== 1}
                            onChange={(e) => {
                              if (e) {
                                let type = {};
                                type.label = e.label;
                                type.value = e.value;
                                this.setState({ roleValue: type });
                              }
                            }}
                            options={technicianOption}
                          />
                          <span id="err">{rolesErr}</span>
                        </div>

                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={technicianName}
                            type="text"
                            placeholder="Enter name"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ technicianName: e.target.value });
                            }}
                            disabled={roleId !== 1}
                          />
                          <span id="err">{technicianNameErr}</span>
                        </div>
                      </div>

                      <div className="row form-group">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Bio
                        </label>

                        <div className="col-sm-4">
                          <textarea
                            className="form-control"
                            type="textarea"
                            id="bio"
                            placeholder="Bio"
                            style={{ resize: 'none' }}
                            rows="3"
                            cols="80"
                            value={bio}
                            onChange={(e) => {
                              this.setState({
                                bio: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Qualification
                        </label>

                        <div className="col-sm-4">
                          <textarea
                            className="form-control"
                            type="textarea"
                            id="qualification"
                            placeholder="Qualification"
                            style={{ resize: 'none' }}
                            rows="3"
                            cols="80"
                            value={qualification}
                            onChange={(e) => {
                              this.setState({
                                qualification: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="example-search-input1"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <input
                              type="email"
                              disabled={technicianId}
                              className="form-control"
                              autoComplete="new-email"
                              value={email}
                              placeholder="Enter email"
                              id="example-text-input1"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>
                          <span id="err">{emailErr}</span>
                        </div>
                      </div>
                      {!technicianId && (
                        <div className="row form-group">
                          <label
                            htmlFor="example-search-input2"
                            className="col-sm-2 col-form-label"
                          >
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-4">
                            <input
                              type={`${showPass ? 'text' : 'password'}`}
                              className="form-control"
                              value={password}
                              autoComplete="new-password"
                              placeholder="Enter password"
                              id="example-text-input"
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                            />
                            <div
                              className="password__toggle"
                              onClick={() =>
                                this.setState({ showPass: !showPass })
                              }
                            >
                              <i
                                className={`${
                                  showPass ? 'active' : 'inactive'
                                } icon icon--md icon--eye`}
                              ></i>
                              <i
                                className={`${
                                  !showPass ? 'active' : 'inactive'
                                } icon icon--md icon--eye-line-through`}
                              ></i>
                            </div>
                            <span id="err">{passwordErr}</span>
                          </div>
                        </div>
                      )}

                      <div className="form-group row ">
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={phonenumber !== 'null' ? phonenumber : ''}
                            type="text"
                            maxLength="13"
                            placeholder="822-456-102-1"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({
                                phonenumber: customFunctions.getMobile(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Date of Birth
                        </label>
                        <div className="col-md-4">
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            className="form-control"
                            placeholderText="Date of Birth"
                            selected={dob}
                            onChange={(date) => {
                              this.setState({ dob: date });
                            }}
                            maxDate={new Date()}
                            name="dob"
                            dateFormat="MM/dd/yyyy"
                            disabled={roleId !== 1}
                          />
                        </div>
                      </div>
                      <div className="form-group row ">
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          CBR Percentage
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                %
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={cbrpercentage}
                              // placeholder="%"
                              id="example-text-input"
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({
                                    cbrpercentage: e.target.value,
                                  });
                                }
                              }}
                              disabled={roleId !== 1}
                            />
                          </div>
                        </div>
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label pb-3"
                        >
                          CBR YTD Balance
                        </label>
                        <div className="col-md-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={cbramount}
                              placeholder="CBR YTD Balance"
                              id="example-text-input"
                              // onChange={(e) => {
                              //   const re = /^[0-9.,\b]+$/;
                              //   if (
                              //     e.target.value === '' ||
                              //     re.test(String(e.target.value))
                              //   ) {
                              //     this.setState({ cbramount: e.target.value });
                              //   }
                              // }}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row form-group">
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Percentage
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                %
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={percentage}
                              // placeholder="%"
                              id="example-text-input"
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({ percentage: e.target.value });
                                }
                              }}
                              disabled={roleId !== 1}
                            />
                          </div>
                        </div>
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Daily Rate
                        </label>
                        <div className="col-md-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={dailyRate}
                              // placeholder="$"
                              id="example-text-input"
                              onChange={(e) => {
                                const re = /^[0-9.,\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(String(e.target.value))
                                ) {
                                  this.setState({ dailyRate: e.target.value });
                                }
                              }}
                              disabled={roleId !== 1}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Address
                        </label>
                        <div className="col-sm-4">
                          <LocationMap
                            location={selectedLocation}
                            onMapClick={this.onMapClick}
                            callback={(loc) => {
                              let selectedLocation = {
                                lat: loc.lat,
                                lng: loc.lng,
                              };
                              this.setState(
                                {
                                  latitude: loc.lat,
                                  longitude: loc.lng,
                                  selectedLocation,
                                },
                                () => {
                                  this.getAddressFromLatLog(loc);
                                }
                              );
                            }}
                          />
                          <br />
                          <span id="err">{locationErr}</span>
                        </div>
                        <div className="col-sm-6">
                          <div className="row">
                            <label
                              htmlFor="example-search-input"
                              className="col-sm-4 col-form-label pb-3"
                            >
                              SSN
                            </label>
                            <div className="col-sm-8  pb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={ssn}
                                placeholder="SSN"
                                id="example-text-input"
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState({ ssn: event.target.value });
                                }}
                                disabled={roleId !== 1}
                              />
                            </div>

                            <label
                              htmlFor="example-search-input"
                              className="col-sm-4 col-form-label"
                            >
                              Choose color
                            </label>
                            <div className="col-sm-8 row pb-2">
                              <div className="input-group">
                                <div className="col-sm-3">
                                  <ColorList
                                    color_code={
                                      technicianColor
                                        ? technicianColor
                                        : '#67a8e4'
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <button
                                    className="btn btn-light"
                                    onClick={(e) => this.colorChange(e)}
                                  >
                                    <a href="/" className="">
                                      <Edit />
                                    </a>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <label
                              htmlFor="example-search-input"
                              className="col-sm-4 col-form-label"
                            >
                              Photo ID
                            </label>
                            <div className="col-sm-8 row pb-2">
                              {!imageUrl && roleId !== 1 && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <label
                                      htmlFor="imageUpload"
                                      className="photo-upload"
                                    >
                                      <input
                                        id="imageUpload"
                                        type="file"
                                        className=""
                                        onChange={(e) => {
                                          this.handelImageChange(e);
                                        }}
                                        accept="image/*"
                                        crossOrigin="anonymous"
                                      />
                                    </label>
                                  </div>
                                </div>
                              )}
                              {imageUrl && (
                                <div className="col-md-8">
                                  <img
                                    id="profileImage"
                                    src={imageUrl}
                                    alt=""
                                    width="60"
                                    height="60"
                                  />
                                </div>
                              )}
                            </div>
                            <label
                              htmlFor="example-search-input"
                              className="col-sm-4 col-form-label"
                            >
                              Certificates
                            </label>
                            <div className="col-sm-8">
                              <div className="input-group">
                                <label
                                  htmlFor="imageUpload"
                                  className="photo-upload"
                                >
                                  <input
                                    id="imageUpload"
                                    ref={(ref) => (this.fileInput = ref)}
                                    type="file"
                                    multiple
                                    className=""
                                    accept=".pdf,.doc,image/jpeg,image/png,"
                                    onChange={(e) => {
                                      this.handleImageFileUpload(e);
                                    }}
                                  />
                                </label>
                              </div>
                              <div>
                                {imgBucket &&
                                  imgBucket.map((props, index) => {
                                    let ext = props.url.substr(
                                      -3,
                                      props.url.lastIndexOf('.')
                                    );
                                    let image = null;
                                    image =
                                      ext === 'pdf' || ext === 'doc'
                                        ? ATTACHMENT
                                        : null;

                                    return (
                                      <span
                                        className="span-container"
                                        key={index}
                                        style={{ marginRight: '12px' }}
                                      >
                                        <img
                                          className="img-container my-10"
                                          src={image ? image : props.url}
                                          alt=""
                                          style={{
                                            height: '110px',
                                            width: '95px',
                                            marginBottom: '12px',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              imagePreviewShow: true,
                                              imagePreview: props.url,
                                            })
                                          }
                                          title={props.url}
                                        />
                                        {!this.state.editType &&
                                          (roleId == 1 || roleId == 2) && (
                                            <i
                                              className="icon-container mdi mdi-close"
                                              style={{
                                                position: 'absolute',
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({
                                                  index: index,
                                                  showDelete: true,
                                                });
                                              }}
                                            />
                                          )}
                                      </span>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-7 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              if (
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.path
                              ) {
                                this.props.history.push(
                                  `${this.props.location.state.path}`
                                );
                              } else {
                                this.props.history.goBack();
                              }
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-sm-5 text-right">
                          {[5, 3, 6].includes(roleId) && (
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/profile', {
                                  fromcomponent: 'technician',
                                });
                              }}
                            >
                              Change Password?
                            </a>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {imagePreviewShow && imagePreview && (
          <ImagePreview
            imagePreviewShow={imagePreviewShow}
            imagePreview={imagePreview}
            handleClose={() => this.setState({ imagePreviewShow: false })}
          />
        )}
        {modalShow && (
          <TechnicianColorCode
            show={modalShow}
            color={technicianColor}
            handleModalCLose={(e) => this.setState({ modalShow: e })}
            callback={(color) => {
              this.setState({ technicianColor: color });
            }}
          />
        )}
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => ({
  userdata: state.auth.userdata,
});

const mapDispatchtoProps = (dispatch) => ({
  SetUserData: (data) => dispatch(SetUserData(data)),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(AddTechnician)
);

import React, { Component } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import CardPayment from '../CustomerBilling/invoicePayment';

class PaymentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chequeAmount: null,
      chequeNumber: null,
      chequePaymentDate: '',
      chequeAmountErr: '',
      chequeNumberErr: '',
      chequePaymentDateErr: '',

      cashAmount: null,
      cashPaymentDate: '',
      cashAmountErr: '',
      cashPaymentDateErr: '',

      invoiceId: null,
      totalAmount: null,
      due_amount: null,
      touched: {},
      isValidateCash: false,
      isValidateCheque: false,
      isShowLoader: false,
      paymentTypeList: [],
      activeTab: '',
    };
  }
  async componentDidMount() {
    this.setState({
      invoiceId: await this.props.invoiceId,
      totalAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      chequeAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      cashAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      due_amount: this.props.due_amount,
    });
    this.getPaymentType();
  }

  getPaymentType = async () => {
    const endPoint = `${Api.paymentTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      let response = data.data.data;
      let active =
        response &&
        response.length &&
        response.filter(
          (i) => i.status === '1' && i.payment_type.toLowerCase() !== 'finance'
        );
      this.setState({
        paymentTypeList: data.data.data,
        activeTab:
          active && active.length && active[0].payment_type.toLowerCase(),
      });
    }
  };

  async componentWillReceiveProps(props) {
    this.setState({
      due_amount: props.due_amount,
      totalAmount: props.due_amount ? props.due_amount : props.totalAmount,
      chequeAmount: props.due_amount ? props.due_amount : props.totalAmount,
      cashAmount: props.due_amount ? props.due_amount : props.totalAmount,
    });
  }

  validateCash = () => {
    const { cashAmount, cashPaymentDate, totalAmount, due_amount } = this.state;

    let isValid = true,
      cashAmountErr,
      cashPaymentDateErr;

    if (
      !cashAmount ||
      (cashAmount && (cashAmount.trim() === '' || cashAmount <= 0))
    ) {
      isValid = false;
      cashAmountErr = 'Please enter amount';
    }
    if (
      cashAmount &&
      cashAmount.trim() !== '' &&
      parseFloat(cashAmount) > totalAmount
    ) {
      isValid = false;
      cashAmountErr = `Entered amount should not be greater than ${
        due_amount ? 'due' : 'total'
      } amount`;
    }
    if (!cashPaymentDate) {
      isValid = false;
      cashPaymentDateErr = 'Please enter a payment date';
    }

    this.setState({
      cashAmountErr,
      cashPaymentDateErr,
    });
    return isValid;
  };

  validateCheque = () => {
    const {
      chequeAmount,
      chequeNumber,
      chequePaymentDate,
      totalAmount,
      due_amount,
    } = this.state;

    let isValid = true,
      chequeAmountErr,
      chequeNumberErr,
      chequePaymentDateErr;

    if (!chequeAmount || chequeAmount <= 0) {
      isValid = false;
      chequeAmountErr = 'Please enter amount';
    }

    if (chequeAmount && parseFloat(chequeAmount) > totalAmount) {
      isValid = false;
      chequeAmountErr = `Entered amount should not be greater than ${
        due_amount ? 'due' : 'total'
      } amount`;
    }
    if (!chequeNumber || (chequeNumber && chequeNumber.trim() === '')) {
      isValid = false;
      chequeNumberErr = 'Please enter check number';
    }

    if (!chequePaymentDate) {
      isValid = false;
      chequePaymentDateErr = 'Please enter a payment date';
    }

    this.setState({
      chequeAmountErr,
      chequeNumberErr,
      chequePaymentDateErr,
    });
    return isValid;
  };

  handleTouch(field_name) {
    let { touched } = this.state;
    if (field_name && touched[field_name] !== true) {
      touched[field_name] = true;
      this.setState(
        {
          touched,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }
  startPayment = async (e, type) => {
    e.preventDefault();
    this.setState({
      isValidateCash: type === 'cash' ? true : false,
      isValidateCheque: type === 'cheque' ? true : false,
    });
    let isValid = type === 'cash' ? this.validateCash() : this.validateCheque();
    if (isValid) {
      const {
        invoiceId,
        totalAmount,
        cashPaymentDate,
        cashAmount,
        chequeAmount,
        chequeNumber,
        chequePaymentDate,
      } = this.state;
      const payload = {
        invoice_id: invoiceId,
        amount: totalAmount,
        payment_type: type,
        cheque_no: '',
        status: 'success',
        payment_response: {},
      };

      if (type === 'cheque') {
        payload.payment_date = customFunctions.getDate(chequePaymentDate);
        payload.received_amt = chequeAmount && chequeAmount.trim();
        payload.cheque_no = chequeNumber && chequeNumber.trim();
        payload.response_code = '1';
        payload.transaction_id = '0';
      }
      if (type === 'cash') {
        payload.payment_date = customFunctions.getDate(cashPaymentDate);
        payload.received_amt = cashAmount && cashAmount.trim();
        payload.response_code = '1';
        payload.transaction_id = '0';
      }
      const endPoint = `${Api.addPayment}`;

      const callback = AuthApi.postDataToServer;
      this.setState({
        isShowLoader: true,
      });
      const { data, message } = await callback(endPoint, payload);
      if (data && data.success) {
        swal('Payment successfull', '', 'success');
        this.setState(
          {
            isShowLoader: false,
            chequeAmount: null,
            chequeNumber: null,
            chequePaymentDate: '',
            cashAmount: null,
            cashPaymentDate: '',
            cardAmount: null,
            cardName: '',
            cardNumber: null,
            cardExpMonth: '',
            cardExpYear: '',
            cardCvv: '',
            cardPaymentDate: '',
          },
          () => {
            this.forceUpdate();
            this.props.callback();
          }
        );
      } else {
        this.setState({
          isShowLoader: false,
        });
        swal(message ? message : 'No data found', '', 'error');
      }
    }
  };

  // handleSelect = (key) => {
  //   const { invoiceId, totalAmount, due_amount } = this.state;
  //   if (key == 'card') {
  //     this.props.history.push('/invoice-customer-pay', {
  //       invoiceId,
  //       totalAmount,
  //       due_amount,
  //     });
  //   }
  // };

  render() {
    const {
      chequeAmount,
      chequePaymentDate,
      chequeNumber,
      chequeAmountErr,
      chequePaymentDateErr,
      chequeNumberErr,
      cashAmount,
      cashPaymentDate,
      cashAmountErr,
      cashPaymentDateErr,
      touched,
      isValidateCash,
      isValidateCheque,
      due_amount,
      isShowLoader,
      totalAmount,
      invoiceId,
      paymentTypeList,
      activeTab,
    } = this.state;

    return (
      <>
        <Modal
          centered
          size="lg"
          show={this.props.paymentPopup}
          onHide={this.props.handleClose}
        >
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <Modal.Header>
            <Modal.Title className="text-secondary">
              <h5>Payment</h5>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.handleClose()}
            ></i>
          </Modal.Header>
          <Modal.Body>
            {activeTab === '' && (
              <h5 className="text-center">Payment options not available</h5>
            )}
            <Tabs
              defaultActiveKey={activeTab}
              // onSelect={(e) => this.handleSelect(e)}
            >
              {paymentTypeList &&
                paymentTypeList.map((i) => {
                  if (
                    i.payment_type.toLowerCase() === 'cash' &&
                    i.status === '1' &&
                    this.props.roleId !== 4
                  ) {
                    return (
                      <Tab eventKey="cash" title="CASH">
                        <form onSubmit={(e) => this.startPayment(e, 'cash')}>
                          <div className="card">
                            <div className="card-body m-t-10">
                              {due_amount && (
                                <div className="row">
                                  <div className="col-sm-4" />
                                  <p className="col-sm-4 my-0">
                                    Balance due amount: ${due_amount}
                                  </p>
                                </div>
                              )}
                              <div className="form-group row">
                                <div className="col-sm-1" />
                                <label
                                  htmlFor=""
                                  className="col-sm-3 text-secondary"
                                >
                                  AMOUNT<span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="$0"
                                    value={cashAmount}
                                    className="form-control"
                                    onChange={async (e) => {
                                      let event = e;
                                      event.target.value =
                                        customFunctions.numberMaskingDecimal(
                                          e.target.value
                                        );
                                    }}
                                    onBlur={() => {
                                      this.validateCash();
                                      this.handleTouch('cashAmount');
                                    }}
                                  />
                                  <span id="err">
                                    {((touched &&
                                      touched.cashAmount &&
                                      cashAmountErr) ||
                                      (isValidateCash && cashAmountErr)) &&
                                      cashAmountErr}
                                  </span>
                                </div>
                                <div className="col-sm-1" />
                              </div>
                              <div className="form-group row">
                                <div className="col-sm-1" />
                                <label
                                  htmlFor=""
                                  className="col-sm-3 text-secondary"
                                >
                                  PAYMENT DATE
                                  <span className="text-danger">*</span>
                                </label>

                                <div className="col-sm-7">
                                  <DatePicker
                                    className="form-control"
                                    placeholderText="01/01/2021"
                                    selected={cashPaymentDate}
                                    onChange={(date) => {
                                      this.setState({ cashPaymentDate: date });
                                    }}
                                    // minDate={new Date()}
                                    name="cashPaymentDate"
                                    dateFormat="MM/dd/yyyy"
                                  />
                                  <span id="err">
                                    {isValidateCash &&
                                      cashPaymentDateErr &&
                                      cashPaymentDateErr}
                                  </span>
                                </div>
                                <div className="col-sm-1" />
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{ paddingBottom: '28px' }}
                            >
                              <div className="col-sm-7" />
                              <Button
                                variant="primary"
                                onClick={() => {}}
                                type="submit"
                              >
                                Submit Payment
                              </Button>
                              <Button
                                variant="secondary"
                                className="l-m-10 saveBtns"
                                type="reset"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </Tab>
                    );
                  }
                  if (
                    i.payment_type.toLowerCase() === 'cheque' &&
                    i.status === '1'
                  ) {
                    return (
                      <Tab eventKey="cheque" title="CHEQUE">
                        <form onSubmit={(e) => this.startPayment(e, 'cheque')}>
                          <div className="card">
                            <div className="card-body m-t-10">
                              {due_amount && (
                                <div className="row">
                                  <div className="col-sm-4" />
                                  <p className="col-sm-4 my-0">
                                    Balance due amount: ${due_amount}
                                  </p>
                                </div>
                              )}
                              <div className="form-group row">
                                <div className="col-sm-1" />
                                <label
                                  htmlFor=""
                                  className="col-sm-3 text-secondary"
                                >
                                  AMOUNT<span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="$0"
                                    value={chequeAmount}
                                    className="form-control"
                                    onChange={async (e) => {
                                      let event = e;
                                      event.target.value =
                                        customFunctions.numberMaskingDecimal(
                                          e.target.value
                                        );
                                      this.setState(
                                        {
                                          chequeAmount: event.target.value,
                                        },
                                        () => {
                                          this.validateCheque();
                                          this.handleTouch('chequeAmount');
                                        }
                                      );
                                    }}
                                    onBlur={() => {
                                      this.validateCheque();
                                      this.handleTouch('chequeAmount');
                                    }}
                                  />
                                  <span id="err">
                                    {((touched &&
                                      touched.chequeAmount &&
                                      chequeAmountErr) ||
                                      (isValidateCheque && chequeAmountErr)) &&
                                      chequeAmountErr}
                                  </span>
                                </div>
                                <div className="col-sm-1" />
                              </div>
                              <div className="form-group row">
                                <div className="col-sm-1" />
                                <label
                                  htmlFor=""
                                  className="col-sm-3 text-secondary"
                                >
                                  CHEQUE NUMBER
                                  <span className="text-danger">*</span>
                                </label>

                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="Enter #"
                                    value={chequeNumber}
                                    className="form-control"
                                    onChange={async (e) => {
                                      let event = e;
                                      this.setState(
                                        {
                                          chequeNumber: event.target.value,
                                        },
                                        () => {
                                          this.validateCheque();
                                          this.handleTouch('chequeNumber');
                                        }
                                      );
                                    }}
                                    onBlur={() => {
                                      this.validateCheque();
                                      this.handleTouch('chequeNumber');
                                    }}
                                  />
                                  <span id="err">
                                    {((touched &&
                                      touched.chequeNumber &&
                                      chequeNumberErr) ||
                                      (isValidateCheque && chequeNumberErr)) &&
                                      chequeNumberErr}
                                  </span>
                                </div>
                                <div className="col-sm-1" />
                              </div>
                              <div className="form-group row">
                                <div className="col-sm-1" />
                                <label
                                  htmlFor=""
                                  className="col-sm-3 text-secondary"
                                >
                                  PAYMENT DATE
                                  <span className="text-danger">*</span>
                                </label>

                                <div className="col-sm-7">
                                  <DatePicker
                                    className="form-control"
                                    placeholderText="01/01/2021"
                                    selected={chequePaymentDate}
                                    onChange={(date) => {
                                      this.setState({
                                        chequePaymentDate: date,
                                      });
                                    }}
                                    // minDate={new Date()}
                                    name="chequePaymentDate"
                                    dateFormat="MM/dd/yyyy"
                                  />
                                  <span id="err">
                                    {isValidateCheque &&
                                      chequePaymentDateErr &&
                                      chequePaymentDateErr}
                                  </span>
                                </div>
                                <div className="col-sm-1" />
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{ paddingBottom: '28px' }}
                            >
                              <div className="col-sm-7" />
                              <Button
                                variant="primary"
                                onClick={() => {}}
                                type="submit"
                              >
                                Submit Payment
                              </Button>
                              <Button
                                variant="secondary"
                                className="l-m-10 saveBtns"
                                type="reset"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </Tab>
                    );
                  }
                  if (
                    i.payment_type.toLowerCase() === 'credit' &&
                    i.status === '1'
                  ) {
                    return (
                      <Tab eventKey="credit" title="CARD">
                        <CardPayment
                          invoiceId={invoiceId}
                          totalAmount={totalAmount}
                          due_amount={due_amount}
                          fromComponent="payment"
                        />
                      </Tab>
                    );
                  }
                })}
            </Tabs>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(PaymentPopup);

import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import ModalInput from '../../../components/Modal/modal.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';

class TermsPopUp extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      isChecked: false,
      selectedId: null,
      taskModalShow: false,
    };
  }

  componentDidMount = (props) => {
    this.getContracts();
    this.setState({ taskModalShow: this.props.show });
  };

  getContracts = async (props) => {
    let endPoint = `${Api.getContractItem}?type=Invoice Contract`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ contracts: data.data.data });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  selectHandler = (e, i) => {
    if (e.target.checked) {
      this.setState({ isChecked: true, selectedId: i });
    } else {
      this.setState({ isChecked: false, selectedId: null });
    }
  };

  submitHandler = () => {
    this.props.handleModalCLose(false);
    this.props.onChanges(this.state.selectedId);
    // this.props.handleModalCLose(false);

    // const { getPopupCallBack, backId } = this.props;
    // if (getPopupCallBack) {
    //   getPopupCallBack(this.state.selectedId);
    //   // backId = this.state.selectedId;
    // }
    // if (this.modalRef) window.$(this.modalRef).modal('hide');
  };

  onTaskCancel = () => {
    this.setState({ taskModalShow: false });
    this.props.handleModalCLose(false);
  };

  render() {
    const { contracts, taskModalShow, isChecked } = this.state;
    return (
      <>
        <ModalInput
          size="lg"
          centered
          onHide={this.onTaskCancel}
          show={taskModalShow}
          label="Add Terms"
          id="contained-modal-title-vcenter"
          modalBody={
            <Form
              onSubmit={(e) => {
                this.submitHandler(e);
              }}
            >
              <Row>
                <Col>
                  <>
                    <ul>
                      <div>
                        {contracts &&
                          contracts.map((contract, i) => (
                            <div className="mt5">
                              <input
                                type="radio"
                                id={`termRadio${contract.id}`}
                                name="term"
                                value={isChecked}
                                onChange={(e) =>
                                  this.selectHandler(e, contract.id)
                                }
                              />
                              <label
                                htmlFor={`termRadio${contract.id}`}
                                style={{ marginLeft: '3%' }}
                              >
                                {contract.contract_name}
                              </label>
                              <hr />
                            </div>
                          ))}
                      </div>
                    </ul>
                  </>
                </Col>
              </Row>
              <div className="float-end">
                <Button variant="primary" type="submit" className="saveBtns">
                  Save
                </Button>
                <Button
                  variant="secondary"
                  className="l-m-10 saveBtns"
                  onClick={() => this.onTaskCancel()}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          }
        />
      </>
    );
  }
}

export default TermsPopUp;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
class AddSubModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submodule_name: '',
      submoduleErr: '',
      submoduleId: '',
      module_name: '',
      moduleErr: '',
      moduleData: [],
      moduleId: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
      return;
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getSubModule();
      customFunctions.setTitle('Edit Submodule');
      this.setState({
        title: 'Edit Submodule',
      });
    } else {
      customFunctions.setTitle('Add Submodule');
      this.setState({
        title: 'Add Submodule',
      });
    }
    this.getModule();
  };

  addSubModule = async () => {
    const { submodule_name, moduleId, submoduleId } = this.state;
    const postData = {
      submodule_name,
      module_id: moduleId,
    };
    const endPoint = `${Api.subModuleUrl}`;
    const callback = submoduleId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (submoduleId) {
      postData.submodule_id = parseInt(submoduleId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;

      if (submoduleId) {
        swal('Submodule updated successfully', '', 'success').then(() => {
          $this.props.history.push('/submodules', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Submodule added successfully', '', 'success').then(() => {
          $this.props.history.push('/submodules');
        });
      }
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getSubModule = async () => {
    const submoduleData = this.props.location.state.detail;
    this.setState({
      submoduleId: submoduleData.submodule_id,
      module_name: submoduleData.module_name,
      submodule_name: submoduleData.submodule_name,
      moduleId: submoduleData.module_id,
    });
  };

  getModule = async () => {
    const endPoint = `${Api.moduleUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        moduleData: data.data.data,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    let moduleErr = '';
    let submoduleErr = '';
    let isValid = true;
    const { moduleId, submodule_name } = this.state;

    if (!moduleId) {
      moduleErr = 'Module is required';
      isValid = false;
    }
    if (submodule_name.trim() === '') {
      submoduleErr = 'Submodule is required';
      isValid = false;
    }
    this.setState({ moduleErr, submoduleErr });
    if (isValid) {
      this.addSubModule();
    }
  };

  render() {
    const {
      title,
      moduleData,
      submodule_name,
      moduleId,
      submoduleErr,
      moduleErr,
      submoduleId,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      {/* <div className="col-sm-12">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div> */}
                    </div>
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Module<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <select
                            className="form-control"
                            value={moduleId}
                            onChange={(e) => {
                              this.setState({ moduleId: e.target.value });
                            }}
                            disabled={submoduleId ? true : false}
                          >
                            <option value="">Select Module</option>
                            {moduleData.map((md) => (
                              <option value={md.module_id}>
                                {md.module_name}
                              </option>
                            ))}
                          </select>
                          <span id="err">{moduleErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Submodule<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            value={submodule_name}
                            placeholder="submodule"
                            onChange={(e) => {
                              const re = /^[A-Za-z\s]+$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              )
                                this.setState({
                                  submodule_name: e.target.value,
                                });
                            }}
                          />
                          <span id="err">{submoduleErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/submodules', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddSubModule);

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import customFunctions from '../../../helper/customFunctions';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 50,
  },
  {
    label: 'Messages',
    field: 'messages',
    sort: 'disabled',
    width: 180,
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    width: 80,
  },
];

export default class ListItem extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      qualityTemplateMsgData: [],
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Good Better Best Template Msg');
    this.getQualityTemplateMsg();
  };

  setTableData = () => {
    const { qualityTemplateMsgData } = this.state;
    const rows = [];
    qualityTemplateMsgData &&
      qualityTemplateMsgData.map((estimate) => {
        rows.push({
          index: 1,
          messages: estimate.message,
          action: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-quality-template-msg',
                    state: {
                      detail: estimate,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="goodbetterbesttemplatemsg"
                  title="Edit Good Better Best Template Msg"
                ></i>
              </a>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, estimateId: estimate.id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return estimate;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getQualityTemplateMsg = async () => {
    this.setState({ isShowLoader: true });

    const endPoint = `${Api.qualityTemplateMsgUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          qualityTemplateMsgData: data.data ? data.data : [],
          isShowLoader: false,
        },
        () => {
          this.setTableData();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  deleteQualityTemplateMsg = async () => {
    const { estimateId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.qualityTemplateMsgUrl}?message_id=${estimateId}`
    );
    if (data) {
      this.setState({ showDelete: false, estimateId: null }, () => {
        swal('Good Better Best template msg deleted!', '', 'success').then(
          () => {
            this.getQualityTemplateMsg();
          }
        );
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const { rows, showDelete, isShowLoader, qualityTemplateMsgData } =
      this.state;
    return (
      <>
        <AUX>
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <HelmetComponent title="Good Better Best Template Msg" />
              <Loader showLoader={isShowLoader} needFullPage={false} />
              {showDelete && (
                <div className="sweetalert">
                  <SweetAlert
                    title="Are you sure, do you want to delete the Good Better Best Template Msg?"
                    error
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      this.deleteQualityTemplateMsg();
                    }}
                    onCancel={() => {
                      this.setState({ showDelete: false, estimateId: null });
                    }}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6" />
                        {qualityTemplateMsgData &&
                          qualityTemplateMsgData.length === 0 && (
                            <div className="col-sm-6 text-right mb-3">
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => {
                                  this.props.history.push(
                                    '/add-quality-template-msg'
                                  );
                                }}
                              >
                                Add Good Better Best Template Msg
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="mdbtable" ref={this.anyRef}>
                        <MDBDataTable
                          // striped
                          noBottomColumns={true}
                          paging={false}
                          bordered
                          hover
                          data={{ columns, rows }}
                          searching={false}
                          noRecordsFoundLabel="No records found"
                          scrollX
                          maxHeight="350px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AUX>
      </>
    );
  }
}

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 80,
  },
  {
    label: 'Item Name',
    field: 'itemName',
    sort: 'disabled',
    width: 250,
  },
  {
    label: 'Good Price($)',
    field: 'goodPrice',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Better Price($)',
    field: 'betterPrice',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Best Price($)',
    field: 'bestPrice',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 150,
  },
];

class ItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      items: [],
      rows: [],
      lineItemId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      title: 'All Line Items',
      isWriteAllowed: false,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-items'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-items',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle(this.state.title);
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getItems();
        }
      );
    } else {
      await this.getItems();
    }
  };

  getItems = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${Api.getLineItem}?skip=${skip}&limit=${limit}&search=${keyword}&`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          items: data.data.data,
          totalCount: data.data.total_count,
          isShowLoader: false,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { items, limit, isWriteAllowed } = this.state;
    const page = npage;
    const rows = [];
    items &&
      items.length > 0 &&
      items.map((item, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
        });
        item &&
          item.items &&
          item.items.map((lineItem) => {
            rows[i].itemName =
              lineItem && lineItem.item_name ? lineItem.item_name : '-';
            rows[i][
              lineItem.quality === 'Good'
                ? 'goodPrice'
                : lineItem.quality === 'Better'
                ? 'betterPrice'
                : 'bestPrice'
            ] = lineItem.item_price;
            // rows[i][lineItem.quality === 'Good' ? 'goodPrice' : lineItem.quality === 'Better' ? 'betterPrice' : lineItem.quality === 'Best' ? 'bestPrice' : ''] = lineItem.item_price;
            rows[i].actions = // eslint-disable-line
              (
                <React.Fragment>
                  <a
                    href="/"
                    style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                    className=""
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.history.push(
                        `/update-line-item/${lineItem.item_id}`,
                        {
                          lineItemDetails: item,
                          type: 'item',
                          path: '/items',
                          paginationObj: {
                            navSkip: skip,
                            navCurrentPage: npage,
                          },
                        }
                      );
                    }}
                  >
                    <i className="mdi mdi-lead-pencil" title="Edit item"></i>
                  </a>
                  <a
                    href="/"
                    style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                    className=""
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({
                        showDelete: true,
                        lineItemId: lineItem.item_id,
                      });
                    }}
                  >
                    <i
                      className="mdi mdi-delete text-danger"
                      title="Delete item"
                    ></i>
                  </a>
                </React.Fragment>
              );
            // rows.push({
            //   index: (page - 1) * limit + (i + 1),
            //   itemName: lineItem && lineItem.item_name ? lineItem.item_name : '-',
            //   goodPrice: lineItem && lineItem.quality === 'Good' ? lineItem.item_price : '-',
            //   betterPrice: lineItem && lineItem.quality === 'Better' ? lineItem.item_price : '-',
            //   bestPrice: lineItem && lineItem.quality === 'Best' ? lineItem.item_price : '-',
            //   actions: (
            //     <React.Fragment>
            //       <a
            //         href="/"
            //         style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
            //         className=""
            //         onClick={(e) => {
            //           e.preventDefault();
            //           e.stopPropagation();
            //           this.props.history.push(`/update-line-item/${item.id}`, {
            //             lineItemDetails: item,
            //             type: 'item',
            //           });
            //         }}
            //       >
            //         <i className="mdi mdi-lead-pencil" title="Edit item"></i>
            //       </a>
            //       <a
            //         href="/"
            //         style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
            //         className=""
            //         onClick={(e) => {
            //           e.preventDefault();
            //           e.stopPropagation();
            //           this.setState({ showDelete: true, lineItemId: item.id });
            //         }}
            //       >
            //         <i
            //           className="mdi mdi-delete text-danger"
            //           title="Delete item"
            //         ></i>
            //       </a>
            //     </React.Fragment>
            //   ),
            // });
            return lineItem;
          });
        return item;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteLineItem = async () => {
    const { lineItemId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getLineItem}?item_id=${lineItemId}`
    );
    if (data) {
      this.setState({ showDelete: false, lineItemId: null }, () => {
        this.getItems();
      });
      swal('Billing item deleted successfully', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getItems();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getItems();
    });
  };

  downloadCSV = async () => {
    const { keyword } = this.state;

    var endPoint = `${Api.downloadItemsCsvUrl}?search=${keyword}`;

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      isWriteAllowed,
      isShowLoader,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={this.state.title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the billing item ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteLineItem();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, lineItemId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here by name"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="right-section">
                        {rows && rows.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                        {isWriteAllowed && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={() => {
                              this.props.history.push('/add-line-item');
                            }}
                          >
                            Add Billing Item
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getItems
                                )
                              : this.getItems
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(ItemComponent);

import React, { Component } from 'react';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { SetUserData } from '../../../action/user.action';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title';

class addOfficeAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      newPassword: '',
      fnameErr: '',
      lnameErr: '',
      emailErr: '',
      passErr: '',
      roleId: null,
      login_id: null,
      backupPassword: '',
      showNewPass: false,
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.adminId
    ) {
      await this.setState({ userId: this.props.match.params.adminId });
    }
    this.setState(
      {
        roleId: userdata.role_id,
        login_id: userdata.login_id,
      },
      async () => {
        if (this.state.userId) {
          this.getAdminData();
        }
      }
    );
    customFunctions.setTitle(
      this.state.userId
        ? 'Edit Office Admin Assistant'
        : 'Add Office Admin Assistant'
    );
  };

  getAdminData = async () => {
    const { userId } = this.state;
    let endPoint = `${Api.getAdmin}?id=${userId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data && data.data.data.length > 0) {
      this.setState({
        first_name: data.data.data[0].first_name,
        last_name: data.data.data[0].last_name,
        email: data.data.data[0].email,
        mobile: data.data.data[0].mobile,
      });
    } else {
      var $this = this;
      swal(message ? message : 'No data found', '', 'error');
      setTimeout(() => {
        $this.props.history.push('/dashboard');
      }, 1000);
    }
  };

  validateForm = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, newPassword, userId } = this.state;
    let fnameErr = '',
      lnameErr = '',
      emailErr = '',
      passErr = '',
      isValid = true;

    if (!first_name || (first_name && first_name.trim() === '')) {
      fnameErr = 'First name is required';
      isValid = false;
    }
    if (!last_name || (last_name && last_name.trim() === '')) {
      lnameErr = 'Last name is required';
      isValid = false;
    }
    if (!email || (email && email.trim() === '')) {
      emailErr = 'Email is required';
      isValid = false;
    } else if (!customFunctions.validateEmail(email)) {
      emailErr = 'Invalid email';
    }

    if (!userId && (newPassword.trim() === '' || !newPassword)) {
      passErr = 'Password is required';
      isValid = false;
    }

    await this.setState({
      fnameErr,
      lnameErr,
      emailErr,
      passErr,
    });
    if (isValid) {
      this.userUpdate();
    }
  };

  userUpdate = async () => {
    const {
      first_name,
      last_name,
      email,
      newPassword,
      userId,
      mobile,
      paginationOptions,
    } = this.state;
    const postData = {
      first_name,
      last_name,
      email,
      mobile,
      username: first_name + ' ' + last_name,
      role: 2,
      password: newPassword ? newPassword : '',
    };
    if (userId) {
      postData.login_id = userId;
    }
    const callback = userId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.getAdmin;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== 'Fail') {
      var $this = this;
      if (userId) {
        swal('Office Admin Assistant updated successfully', '', 'success').then(
          () => {
            if (
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.path
            ) {
              this.props.history.push(`${this.props.location.state.path}`, {
                paginationObj: paginationOptions,
              });
            }
          }
        );
      } else {
        swal('Office Admin Assistant added successfully', '', 'success').then(
          () => {
            $this.props.history.push(`/office-admin-assistants`);
          }
        );
      }
    } else {
      swal(
        (data && data.message) || message || 'Please try again later',
        '',
        'error'
      );
    }
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      newPassword,
      fnameErr,
      lnameErr,
      emailErr,
      passErr,
      mobile,
      roleId,
      showNewPass,
      userId,
      paginationOptions,
    } = this.state;
    return (
      <AUX>
        <div className={[1, 5].includes(roleId) ? 'page-content-wrapper' : ''}>
          <HelmetComponent title="My Profile" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body m-t-30">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          First Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={first_name}
                            type="text"
                            placeholder="John"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ first_name: e.target.value });
                            }}
                          />
                          <span id="err">{fnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={last_name}
                            type="text"
                            placeholder="Doe"
                            id="example-search-input"
                            onChange={(e) => {
                              this.setState({ last_name: e.target.value });
                            }}
                          />
                          <span id="err">{lnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label
                          htmlFor="example-email-input"
                          className="col-sm-2 col-form-label"
                        >
                          Email<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={email}
                            type="text"
                            autoComplete="new-email"
                            placeholder="user@example.com"
                            id="example-email-input"
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                          />
                          <span id="err">{emailErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label className="col-sm-2 col-form-label">
                          Mobile
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            value={mobile}
                            placeholder="9999999999"
                            maxLength="13"
                            onChange={(e) => {
                              this.setState({
                                mobile: customFunctions.getMobile(
                                  e.target.value
                                ),
                              });
                            }}
                          />
                        </div>
                      </div>
                      {!userId && (
                        <div className="form-group row">
                          <div className="col-sm-2" />
                          <label
                            htmlFor="new-pass"
                            className="col-sm-2 col-form-label"
                          >
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-5">
                            <input
                              className="form-control"
                              value={newPassword}
                              autoComplete="new-password"
                              type={`${showNewPass ? 'text' : 'password'}`}
                              placeholder="*****"
                              id="new-pass"
                              onChange={(e) => {
                                this.setState({ newPassword: e.target.value });
                              }}
                            />
                            <div
                              className="password__toggle"
                              onClick={() =>
                                this.setState({ showNewPass: !showNewPass })
                              }
                            >
                              <i
                                className={`${
                                  showNewPass ? 'active' : 'inactive'
                                } icon icon--md icon--eye`}
                              ></i>
                              <i
                                className={`${
                                  !showNewPass ? 'active' : 'inactive'
                                } icon icon--md icon--eye-line-through`}
                              ></i>
                            </div>
                            <span id="err">{passErr}</span>
                          </div>
                        </div>
                      )}
                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-4">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.path
                              ) {
                                this.props.history.push(
                                  `${this.props.location.state.path}`,
                                  {
                                    paginationObj: paginationOptions,
                                  }
                                );
                              } else {
                                this.props.history.goBack();
                              }
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => ({
  userdata: state.auth.userdata,
});

const mapDispatchtoProps = (dispatch) => ({
  SetUserData: (data) => dispatch(SetUserData(data)),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(addOfficeAdmin)
);

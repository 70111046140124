import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import Pagination from '../../../components/Pagination';
import swal from 'sweetalert';
import customFunctions from '../../../helper/customFunctions';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Module',
    field: 'module_name',
    sort: 'disabled',
  },
  {
    label: 'Submodule',
    field: 'submodule_name',
    sort: 'disabled',
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
  },
];

export default class SubModule extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      moduleData: [],
      subModuleData: [],
      moduleName: '',
      moduleId: '',
      subModuleName: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      showDelete: false,
      submoduleId: null,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('All SubModule');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => {
          this.getSubModule();
          this.getModule();
        }
      );
    } else {
      this.getSubModule();
      this.getModule();
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { subModuleData, limit } = this.state;
    const rows = [];
    const page = npage;
    subModuleData &&
      subModuleData.map((sbmodule, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          module_name: sbmodule.module_name,
          submodule_name: sbmodule.submodule_name,
          action: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-submodule',
                    state: {
                      detail: sbmodule,
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="editsubmodule"
                  title="Edit Submodule"
                ></i>
              </a>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, submoduleId: sbmodule.id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return sbmodule;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getModule = async (currentPage = 0) => {
    this.setState({ currentPage });
    const endPoint = `${Api.moduleUrl}?skip=0&limit=10`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          moduleData: data.data.data,
          moduleId: data.data.module_id,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getSubModule = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;
    const endPoint = `${Api.subModuleUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          isShowLoader: false,
          subModuleData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getSubModule();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getSubModule();
    });
  };

  deleteSubModule = async () => {
    const { submoduleId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.subModuleUrl}?submodule_id=${submoduleId}`
    );
    if (data) {
      this.setState({ showDelete: false, submoduleId: null }, () => {
        swal('SubModule deleted!', '', 'success').then(() => {
          this.getSubModule(currentPage);
        });
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const {
      rows,
      totalCount,
      limit,
      currentPage,
      keyword,
      showDelete,
      isShowLoader,
      navCurrentPage,
      navSkip,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <HelmetComponent title="All Submodules" />
        {showDelete && (
          <div className="sweetalert">
            <SweetAlert
              title="Are you sure, Do you want to delete this sub module ?"
              error
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.deleteSubModule();
              }}
              onCancel={() => {
                this.setState({ showDelete: false, submoduleId: null });
              }}
            >
              You won't be able to revert this!
            </SweetAlert>
          </div>
        )}
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by submodule name"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6 text-right">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            this.props.history.push('/add-submodule');
                          }}
                        >
                          Add Submodule
                        </button>
                      </div>
                    </div>
                    <div ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                      />
                    </div>
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                },
                                () => this.getSubModule
                              )
                            : this.getSubModule
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import AddComplaintCategory from '../../../components/CustomerComplaint/AddComplaintCategory';
import AddComplaintStatus from '../../../components/CustomerComplaint/AddComplaintStatus';
import AddCustomerPopup from '../Workorder/AddCustomerPopup';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import swal from 'sweetalert';
import 'react-datepicker/dist/react-datepicker.css';
import HelmetComponent from '../Title/index';
import addDays from 'date-fns/addDays';
import Loader from '../../../components/Loader';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

export default class Complaint extends Component {
  constructor(props) {
    console.log('~propsInConst~', props);

    super(props);
    this.state = {
      complaintId: '',
      customerNameData: [],
      techniciandata: [],
      complaintStatusData: [],
      homeWrntyCode: '',
      complaintDetails: '',
      complaintCategory: '',
      technician: '',
      openDate: new Date(),
      closeDate: addDays(new Date(), 1),
      daysOpen: '',
      notes: '',
      cntctPerson: '',
      email: '',
      custmrAddress: '',
      reportingPerson: '',
      complaintStatus: '',
      customerId: null,
      complaintCategoryData: [],
      homeWrntyCodeErr: '',
      complaintDetailsErr: '',
      complaintCategoryErr: '',
      customerIdErr: '',
      technicianErr: '',
      openDateErr: '',
      closeDateErr: '',
      daysOpenErr: '',
      notesErr: '',
      reportingPersonErr: '',
      complaintStatusErr: '',
      receivedBy: '',
      createdDate: '',
      updatedDate: '',
      viewType: false,
      ComplaintCode: '',
      title: '',
      complaintData: null,
      roleId: null,
      disableAll: false,
      isShowLoader: false,
      customerResolution: '',
      actionTaken: '',
      technicianName: '',
      workorderName: '',
      customerName: '',
      complaintCategoryName: '',
      complaintStatusName: '',
      clearJobByCust: false,
      clearTechByJobID: false,
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    // let isAllowed = await customFunctions.isAllowedRoute(
    //   'submodule',
    //   'addnewrecord-addnewcomplaint'
    // );
    // if (!isAllowed) {
    //   this.props.history.push('/dashboard');
    //   return;
    // }

    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addnewcomplaint',
      'write'
    );
    if (writeAllowed) {
      this.setState({ disableAll: false });
    } else {
      this.setState({ disableAll: true });
    }
    // this.getTechnician();
    await this.getComplaintCategory();
    await this.getComplaintStatus();
    await this.getCustomerLIst();
    console.log('~props~', this.props);
    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      if (this.props.location.state.orderDetails.CustomerID) {
        this.setCustomerData(
          this.props.location.state.orderDetails.CustomerID,
          ''
        );
      }
      this.setState(
        {
          disabledFromWorkorder: true,
          workorderId: this.props.location.state.orderDetails.ID,
          customerId: this.props.location.state.orderDetails.CustomerID,
          workorderName: {
            name: this.props.location.state.orderDetails.WorkOrder,
          },
          customerName: {
            name: this.props.location.state.orderDetails.CustomerName,
          },
        },
        async () => {
          this.forceUpdate();
          await this.getWorkorders(
            this.props.location.state.orderDetails.CustomerID
          );
          await this.setTechnician(this.props.location.state.orderDetails.ID);
        }
      );
    }

    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id }, () => {
      if (this.state.roleId == 4 && userdata.login_id) {
        this.setCustomerData(userdata.login_id, '');
        this.getWorkorders(userdata.login_id);
        this.setState({
          customerId: userdata.login_id,
          customerName: { name: userdata.username },
        });
      }
      if (this.state.roleId == 5 || this.state.roleId === 6) {
        this.setState({ disableAll: true });
      }
    });

    if (this.props.match.params.complaintId) {
      var complaintId = this.props.match.params.complaintId;
      this.setState({ complaintId });
      const url_segment = this.props.match.url.split('/');
      if (url_segment && url_segment.includes('view-complaintDetails')) {
        this.setState({ viewType: true, title: `View Complaint` });
        customFunctions.setTitle('View Complaint');
      } else {
        customFunctions.setTitle(
          this.state.roleId == 5 || this.state.roleId == 6
            ? `View Complaint`
            : `Edit Complaint`
        );
        this.setState({
          title:
            this.state.roleId == 5 || this.state.roleId == 6
              ? `View Complaint`
              : `Edit Complaint`,
        });
      }
      await this.getComplaintDetails();
    } else {
      customFunctions.setTitle('Add Complaint');
      this.setState({ title: `Add Complaint` });
    }
  };

  getComplaintDetails = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getComplaintDetailsById}?complaint_id=${this.props.match.params.complaintId}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data[0]) {
      this.setState({ isShowLoader: false });
      var complaintData = data.data[0];
      if (complaintData.ComplaintOpenDate) {
        if (complaintData.ComplaintOpenDate.indexOf('T') > -1) {
          const date = complaintData.ComplaintOpenDate.split('T')[0];
          const openDate = new Date(complaintData.ComplaintOpenDate);
          complaintData.ComplaintOpenDate = openDate;
          this.setState({ openDate });
        }
      }
      if (complaintData.ComplaintCloseDate) {
        if (complaintData.ComplaintCloseDate.indexOf('T') > -1) {
          const date = complaintData.ComplaintCloseDate.split('T')[0];
          const closeDate = new Date(complaintData.ComplaintCloseDate);
          complaintData.ComplaintCloseDate = closeDate;
          this.setState({ closeDate });
        }
      }
      this.setState(
        {
          customerId: complaintData.Customer,
          customerName: { name: complaintData.CustomerName },
          workorderId: complaintData.WorkOrderId,
          ComplaintCode: complaintData.ComplaintCode,
          homeWrntyCode: complaintData.HomeWarrantyCode,
          technician: complaintData.technician_id,
          technicianName: { name: complaintData.Technician },
          complaintDetails: complaintData.ComplaintDetails,
          complaintCategory: complaintData.Category,
          complaintCategoryName: { name: complaintData.Category },
          receivedBy: complaintData.ComplaintReceivedBy,
          customerResolution: complaintData.customer_resolution,
          actionTaken: complaintData.action,
          reportingPerson: complaintData.ReportingPersonName,
          complaintStatus: complaintData.ComplaintStatus,
          complaintStatusName: { name: complaintData.ComplaintStatus },
          notes: complaintData.Notes,
          daysOpen: complaintData.of_days,
          complaintId: complaintData.ID,
          openDate:
            complaintData.ComplaintOpenDate !== null
              ? new Date(complaintData.ComplaintOpenDate)
              : '',
          closeDate:
            complaintData.ComplaintCloseDate !== null
              ? new Date(complaintData.ComplaintCloseDate)
              : '',
          complaintData,
          workorderName: { name: complaintData.WorkOrder },
        },
        async () => {
          await this.getWorkorders(complaintData.Customer);
          await this.setTechnician(this.state.workorderId);
        }
      );
    }

    this.setState({ isShowLoader: false });
    if (complaintData.Created) {
      var createddate = new Date(complaintData.Created);
      this.setState({
        createdDate: customFunctions.getMDYDateTime(createddate),
      });
    }

    if (complaintData.Updated) {
      var updateddate = new Date(complaintData.Updated);
      this.setState({
        updatedDate: customFunctions.getMDYDateTime(updateddate),
      });
    }

    this.setCustomerData(complaintData.Customer, '');
  };

  getWorkorders = async (custID) => {
    this.setState({ isShowLoader: true });
    if (custID) {
      const endPoint = `${Api.workOrderUrl}/by_customer?customer_id=${custID}&skip=0&limit=1000`;
      const { data, message } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.data && data.data.data) {
        const currentDate = Date.now();
        const result = data.data.data.filter((item) => {
          return currentDate > new Date(item.ToTime).getTime();
        });
        if (result.length > 0) {
          result.map((r) => {
            r.name = r.WorkOrder;
          });
          this.setState({
            workordersdata: result,
            isShowLoader: false,
          });
        }
      } else {
        this.setState({ isShowLoader: false });
        swal(message ? message : 'No data found', '', 'error');
      }
    }
  };

  getComplaintCategory = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.AddcomplaintCategoryUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        complaintCategoryData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getCustomerLIst = async (searchKeyword) => {
    this.setState({ isShowLoader: true });
    const endPoint = searchKeyword
      ? `${Api.customerUrl}?search=${searchKeyword}`
      : `${Api.customerUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      data.data.data.map((dt) => {
        dt.name = dt.CustomerName;
      });
      this.setState({
        customerNameData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTechnician = async (workorderid) => {
    const { workordersdata } = this.state;
    let data =
      workordersdata && workordersdata.filter((i) => i.ID == workorderid);
    let techdata = data && data.length > 0 ? data[0].multitech : [];
    techdata &&
      techdata.length > 0 &&
      techdata.map((td) => {
        td.name = td.techname;
      });
    this.setState({ techniciandata: techdata });
  };

  // getTechnician = async () => {
  //   const endPoint = `${Api.AddtechnicianUrl}?skip=0&limit=100000`;
  //   const { data } = await AuthApi.getDataFromServer(endPoint);
  //   if (data) {
  //     this.setState({
  //       techniciandata: data.data.data,
  //     });
  //   }
  // };

  getComplaintStatus = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.AddcomplaintStatusUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        complaintStatusData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setCustomerData = async (custId, searchKeyword) => {
    this.setState({ isShowLoader: true });
    // const custValue = customerId.value ? customerId.value : customerId;
    const endPoint = searchKeyword
      ? `${Api.getCustomerById}?customer_id=${custId}&search=${searchKeyword}`
      : `${Api.getCustomerById}?customer_id=${custId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        cntctPerson: data.data.ContactName,
        email: data.data.EmailAddress,
        custmrAddress: data.data.AddressLine1_1,
        customerName: { name: data.data.CustomerName },
        customerId: data.data.ID,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const { workorderId, technician, complaintDetails, customerId } =
      this.state;
    let homeWrntyCodeErr = '',
      complaintDetailsErr = '',
      customerIdErr = '',
      technicianErr = '',
      workorderErr = '';
    if (complaintDetails === '') {
      complaintDetailsErr = 'Complaint details is required';
      isValid = false;
    }
    if (customerId === '') {
      customerIdErr = 'Customer name is required';
      isValid = false;
    }
    if (technician === '') {
      technicianErr = 'Technician  is required';
      isValid = false;
    }
    if (workorderId === '' || !workorderId) {
      workorderErr = 'Workorder  is required';
      isValid = false;
    }
    this.setState({
      homeWrntyCodeErr,
      complaintDetailsErr,
      customerIdErr,
      technicianErr,
      workorderErr,
    });
    if (isValid) {
      this.addComplaint();
    }
  };

  addComplaint = async (e) => {
    const {
      complaintId,
      complaintCategory,
      homeWrntyCode,
      technician,
      complaintDetails,
      openDate,
      closeDate,
      notes,
      reportingPerson,
      complaintStatus,
      customerId,
      receivedBy,
      complaintData,
      workorderId,
      customerResolution,
      actionTaken,
      paginationOptions,
    } = this.state;

    let dt = closeDate;
    if (
      complaintId &&
      complaintData &&
      complaintData.ComplaintCloseDate &&
      new Date(complaintData.ComplaintCloseDate) < openDate
    ) {
      dt = addDays(openDate, 1);
    }
    const postData = {
      customer_id: customerId && Number(customerId),
      warranty_code: homeWrntyCode,
      technician: technician && Number(technician),
      work_order_id: workorderId,
      complaint_details: complaintDetails,
      category: complaintCategory,
      open_date: openDate ? new Date(openDate).toISOString() : null,
      received_by: receivedBy,
      close_date: dt ? new Date(dt).toISOString() : null,
      reporting_person: reportingPerson,
      status: complaintStatus,
      notes: notes,
      customer_resolution: customerResolution,
      action: actionTaken,
    };
    const callback = complaintId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.complaintUrl;
    if (complaintId) {
      postData.id = parseInt(complaintId);
    }
    // const payload = customFunctions.clean(postData);
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== 'Fail') {
      var $this = this;
      if (complaintId) {
        swal('Customer complaint updated successfully', '', 'success').then(
          () => {
            if (
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.path
            ) {
              this.props.history.push(`${this.props.location.state.path}`, {
                paginationObj: paginationOptions,
              });
            } else {
              this.props.history.goBack();
            }
          }
        );
      } else {
        swal('Customer complaint added successfully', '', 'success').then(
          () => {
            $this.props.history.goBack();
          }
        );
      }
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  updateOpenDate = () => {
    const { openDate, closeDate, complaintData } = this.state;
    if (closeDate <= openDate) {
      this.setState(
        {
          closeDate: '',
          complaintData,
        },
        () => this.forceUpdate()
      );
    }
  };

  render() {
    const {
      title,
      complaintId,
      complaintCategoryName,
      technician,
      technicianErr,
      complaintDetails,
      daysOpen,
      cntctPerson,
      email,
      custmrAddress,
      reportingPerson,
      complaintStatusName,
      customerId,
      receivedBy,
      customerIdErr,
      complaintDetailsErr,
      createdDate,
      updatedDate,
      viewType,
      ComplaintCode,
      roleId,
      customerName,
      disableAll,
      disabledFromWorkorder,
      workorderId,
      workorderErr,
      isShowLoader,
      customerResolution,
      actionTaken,
      workorderName,
      technicianName,
      clearJobByCust,
      clearTechByJobID,
      paginationOptions,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {roleId && (
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <HelmetComponent title={title} />
              <div className="row">
                <div className="col-12">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <fieldset disabled={viewType ? true : false}>
                        <form
                          onSubmit={this.validateForm}
                          autoComplete="off"
                          id="add_complaint"
                        >
                          <div className="form-group row">
                            {complaintId && (
                              <>
                                <label
                                  htmlFor="example-text-input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Complaint#
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    value={ComplaintCode}
                                    type="text"
                                    placeholder="John"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.setState({
                                        complaintId: e.target.value,
                                      });
                                    }}
                                    disabled
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          {roleId !== 4 && (
                            <span>
                              <div className="form-group row">
                                <label
                                  htmlFor="example-search-input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Customer Name
                                  <span className="text-danger">*</span>
                                </label>
                                <div
                                  className={`${
                                    roleId === 4 ||
                                    roleId === 5 ||
                                    roleId === 6 ||
                                    complaintId ||
                                    disabledFromWorkorder
                                      ? 'col-sm-4'
                                      : 'col-sm-3'
                                  }`}
                                >
                                  <SelectAsyncPaginate
                                    onSelectChange={(e) => {
                                      this.setState(
                                        {
                                          customerId: e ? e.ID : '',
                                          clearJobByCust: true,
                                          clearTechByJobID: true,
                                          customerName: e
                                            ? { name: e.CustomerName }
                                            : '',
                                          workorderId: null,
                                          technician: null,
                                        },
                                        () => {
                                          this.forceUpdate();
                                          if (e) {
                                            this.getWorkorders(e.ID);
                                            this.setCustomerData(e.ID);
                                          }
                                        }
                                      );
                                    }}
                                    className="w-100"
                                    isSearch={true}
                                    isClearable={customerName}
                                    value={customerName}
                                    apiname="customer"
                                    endpoint={
                                      Api.customerActiveUrl + `?type=1&search=`
                                    }
                                    disabled={
                                      complaintId !== '' ||
                                      roleId === 4 ||
                                      disableAll ||
                                      disabledFromWorkorder
                                        ? true
                                        : false
                                    }
                                    selectPlaceholder="Select Customer"
                                  />
                                  {/* <select
                                    className="form-control"
                                    value={customerId}
                                    onChange={(e) => {
                                      this.setState(
                                        { customerId: e.target.value },
                                        () => {
                                          this.setCustomerData(
                                            this.state.customerId
                                          );
                                          this.getWorkorders();
                                        }
                                      );
                                    }}
                                    disabled={
                                      complaintId !== '' ||
                                      roleId === 4 ||
                                      disableAll ||
                                      disabledFromWorkorder
                                        ? true
                                        : ''
                                    }
                                  >
                                    {roleId == 4 && (
                                      <option
                                        selected="selected"
                                        value="customerId"
                                      >
                                        {customerName}
                                      </option>
                                    )}
                                    <option value="">Select</option>
                                    {customerNameData.map((data) => (
                                      <option
                                        value={data.ID}
                                        key={`${data.ID}`}
                                      >{`${data.CustomerName}`}</option>
                                    ))}
                                  </select>*/}
                                  <span id="err">{customerIdErr}</span>
                                </div>
                                {!complaintId &&
                                  roleId !== 4 &&
                                  roleId !== 6 &&
                                  roleId !== 5 &&
                                  !disabledFromWorkorder && (
                                    <div
                                      className="col-sm-1 text-right"
                                      data-toggle="modal"
                                      data-target="#customer-popup"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <button className="btn btn-light">
                                        <a href="/">
                                          <Edit />
                                        </a>
                                      </button>
                                    </div>
                                  )}
                                <label
                                  htmlFor="example-text-input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Email
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    value={email}
                                    type="text"
                                    placeholder="john@gmail.com"
                                    id="example-text-input"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-2">
                                  <label htmlFor="example-text-input">
                                    Customer Address
                                  </label>
                                </div>
                                <div className="col-4">
                                  <textarea
                                    className="form-control"
                                    type="textarea"
                                    id="example-text-input"
                                    rows="2"
                                    cols="90"
                                    value={custmrAddress}
                                    disabled
                                  />
                                </div>

                                <label
                                  htmlFor="example-text-input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Contact Person
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    value={cntctPerson}
                                    type="text"
                                    placeholder="Contact Person"
                                    id="example-text-input"
                                    disabled
                                  />
                                </div>
                              </div>
                            </span>
                          )}

                          <div className="form-group row">
                            <div className="col-sm-6">
                              <div className="form-group row">
                                <label
                                  htmlFor="example-search-input"
                                  className="col-sm-4 col-form-label"
                                >
                                  Workorder
                                  <span className="text-danger">*</span>
                                </label>
                                {console.log('~customerId~', customerId)}
                                <div className="col-sm-8">
                                  <SelectAsyncPaginate
                                    onSelectChange={(e) => {
                                      this.setState(
                                        {
                                          workorderId: e ? e.ID : null,
                                          clearJobByCust: false,
                                          clearTechByJobID: true,
                                          workorderName: e
                                            ? { name: e.WorkOrder }
                                            : '',
                                          technician: null,
                                        },
                                        () => {
                                          if (e) this.setTechnician(e.ID);
                                        }
                                      );
                                    }}
                                    className="w-100"
                                    isSearch={true}
                                    isClearable={
                                      workorderName && !clearJobByCust
                                    }
                                    value={workorderName}
                                    clearCacheOnID={customerId}
                                    clearSelectedByParent={clearJobByCust}
                                    apiname="workorder"
                                    endpoint={
                                      Api.workOrderUrl +
                                      `/by_customer?customer_id=${customerId}&search=`
                                    }
                                    disabled={
                                      complaintId ||
                                      (this.props.location.state &&
                                        this.props.location.state
                                          .orderDetails &&
                                        this.props.location.state.orderDetails
                                          .ID)
                                        ? true
                                        : false
                                    }
                                    selectPlaceholder="Select Workorder"
                                  />
                                  {/* <select
                                    className="form-control"
                                    disabled={
                                      complaintId ||
                                      (this.props.location.state &&
                                        this.props.location.state
                                          .orderDetails &&
                                        this.props.location.state.orderDetails
                                          .ID)
                                        ? true
                                        : false
                                    }
                                    value={workorder}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          workorder: e.target.value,
                                        },
                                        () => {
                                          this.setTechnician(
                                            this.state.workorder
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    <option value="">Select Workorder</option>
                                    {workordersdata &&
                                      workordersdata.map((data) => (
                                        <option
                                          value={data.ID}
                                          key={`workorder${data.ID}`}
                                        >{`${data.WorkOrder}`}</option>
                                      ))}
                                  </select> */}
                                  <span id="err">{workorderErr}</span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="example-search-input"
                                  className="col-sm-4 col-form-label"
                                >
                                  Technician
                                  <span className="text-danger">*</span>
                                </label>
                                <div
                                  // className={`${
                                  //   roleId === 4 || roleId === 5
                                  //     ? 'col-sm-8'
                                  //     : 'col-sm-6'
                                  // }`}
                                  className="col-sm-8"
                                >
                                  <SelectAsyncPaginate
                                    onSelectChange={(e) => {
                                      this.setState({
                                        technician: e ? e.tech_id : null,
                                        technicianName: { name: e.name },
                                        clearTechByJobID: false,
                                      });
                                    }}
                                    className="w-100"
                                    isSearch={true}
                                    isClearable={
                                      technician &&
                                      !clearTechByJobID &&
                                      !clearJobByCust
                                    }
                                    value={technicianName}
                                    clearCacheOnID={workorderId}
                                    clearSelectedByParent={clearTechByJobID}
                                    apiname="job-technician"
                                    endpoint={
                                      Api.orderById +
                                      `?work_order_id=${workorderId}`
                                    }
                                    selectPlaceholder="Select Technician"
                                    disabled={disableAll || complaintId}
                                  />
                                  {/* <select
                                    className="form-control"
                                    disabled={disableAll || complaintId}
                                    value={technician}
                                    onChange={(e) => {
                                      this.setState({
                                        technician: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select Technician</option>
                                    {techniciandata &&
                                      techniciandata.map((data) => (
                                        <option
                                          value={data.tech_id}
                                          key={`technician${data.tech_id}`}
                                        >{`${data.techname}`}</option>
                                      ))}
                                  </select> */}
                                  <span id="err">{technicianErr}</span>
                                </div>
                                {/* {roleId !== 4 && roleId !== 5 && (
                                <div
                                  className="col-sm-2 text-right"
                                  data-toggle="modal"
                                  data-target="#technician"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <button className="btn btn-light">
                                    <a href="/">
                                      <Edit />
                                    </a>
                                  </button>
                                </div>
                              )} */}
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="example-search-input"
                                  className="col-sm-4 col-form-label"
                                >
                                  Complaint Category
                                </label>
                                <div
                                  className={`${
                                    roleId === 4 || roleId === 6 || roleId === 5
                                      ? 'col-sm-8'
                                      : 'col-sm-8'
                                  }`}
                                >
                                  <div className="d-flex">
                                    <SelectAsyncPaginate
                                      onSelectChange={(e) => {
                                        if (e) {
                                          this.setState({
                                            complaintCategory: e.category,
                                            complaintCategoryName: {
                                              name: e.category,
                                            },
                                          });
                                        } else {
                                          this.setState({
                                            complaintCategory: '',
                                            complaintCategoryName: '',
                                          });
                                        }
                                      }}
                                      className="w-100"
                                      isSearch={true}
                                      isClearable={complaintCategoryName}
                                      value={complaintCategoryName}
                                      apiname="complaint-category"
                                      endpoint={
                                        Api.AddcomplaintCategoryUrl + `?search=`
                                      }
                                      selectPlaceholder="Select Category"
                                      disabled={disableAll || complaintId}
                                    />
                                    {/* <select
                                        className="form-control"
                                        value={complaintCategory}
                                        disabled={disableAll || complaintId}
                                        onChange={(e) => {
                                          this.setState({
                                            complaintCategory: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value=""> Select Category</option>
                                        {complaintCategoryData.map((category) => (
                                          <option
                                            value={category.category}
                                            key={`${category.category}`}
                                          >{`${category.category}`}</option>
                                        ))}
                                      </select> */}
                                    {roleId !== 4 &&
                                      roleId !== 6 &&
                                      roleId !== 5 &&
                                      !complaintId && (
                                        <div
                                          className="ml-3 text-right"
                                          data-toggle="modal"
                                          data-target="#complaint-category"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <button className="btn btn-light">
                                            <a href="/">
                                              <Edit />
                                            </a>
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group1 row">
                                <div className="col-4">
                                  <label htmlFor="example-text-input">
                                    Complaint Details
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="col-8">
                                  <textarea
                                    className="form-control"
                                    type="textarea"
                                    disabled={disableAll || complaintId}
                                    placeholder="complaint details goes here"
                                    style={{ resize: 'none' }}
                                    id="example-text-input"
                                    rows="6"
                                    cols="90"
                                    onChange={(e) => {
                                      this.setState({
                                        complaintDetails: e.target.value,
                                      });
                                    }}
                                    value={complaintDetails}
                                  />
                                  <span id="err">{complaintDetailsErr}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span>
                            <div className="form-group row">
                              <div className="col-sm-6">
                                {roleId !== 4 && (
                                  <>
                                    <div className="form-group row">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        # Days Open
                                      </label>
                                      <div className="col-sm-8">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="days open"
                                          id="example-text-input"
                                          value={daysOpen}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        Reporting Person
                                      </label>
                                      <div className="col-sm-8">
                                        <input
                                          className="form-control"
                                          value={reportingPerson}
                                          type="text"
                                          disabled={disableAll}
                                          placeholder="Reporting person"
                                          id="example-text-input"
                                          onChange={(e) => {
                                            const re = /^[a-zA-Z ]*$/;
                                            if (
                                              e.target.value === '' ||
                                              re.test(e.target.value)
                                            ) {
                                              this.setState({
                                                reportingPerson: e.target.value,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                {(roleId == 4 && complaintId) ||
                                roleId !== 4 ? (
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Complaint Status
                                    </label>
                                    <div
                                      className={`${
                                        roleId === 1 ||
                                        roleId === 4 ||
                                        roleId === 6 ||
                                        roleId === 5
                                          ? 'col-sm-8'
                                          : 'col-sm-8'
                                      }`}
                                    >
                                      <div className="d-flex">
                                        <SelectAsyncPaginate
                                          onSelectChange={(e) => {
                                            this.setState({
                                              complaintStatus: e
                                                ? e.status
                                                : '',
                                              complaintStatusName: e
                                                ? { name: e.status }
                                                : '',
                                            });
                                          }}
                                          className="w-100"
                                          isSearch={true}
                                          isClearable={complaintStatusName}
                                          value={
                                            complaintStatusName &&
                                            complaintStatusName
                                          }
                                          apiname="complaint-status"
                                          endpoint={
                                            Api.AddcomplaintStatusUrl +
                                            `?search=`
                                          }
                                          selectPlaceholder="Select Status"
                                          disabled={disableAll || roleId == 4}
                                        />
                                        {/* <select
                                          className="form-control"
                                          value={complaintStatus}
                                          disabled={disableAll || roleId == 4}
                                          onChange={(e) => {
                                            this.setState({
                                              complaintStatus: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value="">
                                            Select Status
                                          </option>
                                          {complaintStatusData.map((status) => (
                                            <option
                                              value={status.status}
                                              key={`Status${status.status}`}
                                            >{`${status.status}`}</option>
                                          ))}
                                        </select> */}
                                        {roleId !== 4 &&
                                          roleId !== 5 &&
                                          !complaintId && (
                                            <div
                                              className="ml-3 text-right"
                                              data-toggle="modal"
                                              data-target="#complaintstatus"
                                              onClick={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <button className="btn btn-light">
                                                <a href="/">
                                                  <Edit />
                                                </a>
                                              </button>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {roleId !== 4 && (
                                <div className="col-sm-6">
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Complaint Received
                                    </label>
                                    <div className="col-sm-8">
                                      <div
                                        style={{
                                          display: 'inline-block',
                                          width: '135px',
                                        }}
                                      >
                                        <Radio
                                          checked={receivedBy === 'phone'}
                                          disabled={disableAll || complaintId}
                                          onChange={(e) => {
                                            this.setState({
                                              receivedBy: e.target.value,
                                            });
                                          }}
                                          value="phone"
                                          color="default"
                                          name="radio-button-demo"
                                        />
                                        Phone
                                      </div>
                                      <div
                                        style={{
                                          display: 'inline-block',
                                          width: '135px',
                                        }}
                                      >
                                        <Radio
                                          checked={receivedBy === 'email'}
                                          disabled={disableAll || complaintId}
                                          onChange={(e) => {
                                            this.setState({
                                              receivedBy: e.target.value,
                                            });
                                          }}
                                          value="email"
                                          color="default"
                                          name="radio-button-demo"
                                        />
                                        Email
                                      </div>
                                      <div
                                        style={{
                                          display: 'inline-block',
                                          width: '135px',
                                        }}
                                      >
                                        <Radio
                                          checked={receivedBy === 'website'}
                                          disabled={disableAll || complaintId}
                                          onChange={(e) => {
                                            this.setState({
                                              receivedBy: e.target.value,
                                            });
                                          }}
                                          value="website"
                                          color="default"
                                          name="radio-button-demo"
                                        />
                                        Website
                                      </div>
                                      <div
                                        style={{
                                          display: 'inline-block',
                                          width: '135px',
                                        }}
                                      >
                                        <Radio
                                          checked={receivedBy === 'other'}
                                          disabled={disableAll || complaintId}
                                          onChange={(e) => {
                                            this.setState({
                                              receivedBy: e.target.value,
                                            });
                                          }}
                                          value="other"
                                          color="default"
                                          name="radio-button-demo"
                                        />
                                        Other
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </span>
                          <div className="form-group row">
                            {((roleId == 4 && complaintId) || roleId == 1) && (
                              <>
                                <div className="col-2">
                                  <label htmlFor="example-text-input">
                                    Customer Resolution
                                  </label>
                                </div>
                                <div className="col-4">
                                  <textarea
                                    className="form-control"
                                    type="textarea"
                                    disabled={roleId == 4 ? true : false}
                                    placeholder="Customer resolution here"
                                    style={{ resize: 'none' }}
                                    id="example-text-input"
                                    rows="6"
                                    cols="90"
                                    onChange={(e) => {
                                      this.setState({
                                        customerResolution: e.target.value,
                                      });
                                    }}
                                    value={customerResolution}
                                  />
                                </div>
                              </>
                            )}
                            {(roleId == 5 || roleId == 6 || roleId == 1) && (
                              <>
                                <div className="col-2">
                                  <label htmlFor="example-text-input">
                                    Action Taken
                                  </label>
                                </div>
                                <div className="col-4">
                                  <textarea
                                    className="form-control"
                                    type="textarea"
                                    disabled={
                                      roleId == 5 || roleId == 6 ? true : false
                                    }
                                    placeholder="Action taken here"
                                    style={{ resize: 'none' }}
                                    id="example-text-input"
                                    rows="6"
                                    cols="90"
                                    onChange={(e) => {
                                      this.setState({
                                        actionTaken: e.target.value,
                                      });
                                    }}
                                    value={actionTaken}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          {complaintId && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-search-input"
                                className="col-sm-2 col-form-label"
                              >
                                Created Date Time
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  value={createdDate}
                                  type="text"
                                  placeholder="-"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({
                                      createdDate: e.target.value,
                                    });
                                  }}
                                  disabled
                                />
                              </div>
                              {updatedDate && (
                                <>
                                  <label
                                    htmlFor="example-search-input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Updated Date Time
                                  </label>
                                  <div className="col-sm-4">
                                    <input
                                      className="form-control"
                                      value={updatedDate}
                                      type="text"
                                      placeholder="-"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          updatedDate: e.target.value,
                                        });
                                      }}
                                      disabled
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          {!viewType && !disableAll && (
                            <div className="form-group row justify-content-center">
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  onClick={() => {
                                    document
                                      .getElementById('add_complaint')
                                      .reset();
                                    if (
                                      this.props &&
                                      this.props.location &&
                                      this.props.location.state &&
                                      this.props.location.state.path
                                    ) {
                                      this.props.history.push(
                                        `${this.props.location.state.path}`,
                                        {
                                          paginationObj: paginationOptions,
                                        }
                                      );
                                    } else {
                                      this.props.history.goBack();
                                    }
                                  }}
                                  className="btn btn-secondary waves-effect m-l-10"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <AddComplaintCategory
          getComplaintCategoryCallback={() => {
            this.getComplaintCategory();
          }}
        />
        {/* <AddTechnician
          getTechnicianCallback={() => {
            this.getTechnician();
          }}
        /> */}
        {/* <AddWarrantyCode
          getWarrantyCodeCallback={() => {
            this.getHomeWrntyCode();
          }}
        /> */}
        <AddComplaintStatus
          getComplaintsCallback={() => {
            this.getComplaintStatus();
          }}
        />
        <AddCustomerPopup
          getCustomerListCallback={() => {
            this.getCustomerLIst();
          }}
        />
      </AUX>
    );
  }
}

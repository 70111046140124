import queryString from 'query-string';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import CalendarTimeLine from '../../../components/FullCalender/timeline';
import Loader from '../../../components/Loader';
import LocationRGMap from '../../../components/Map/reactGMap';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import CompleteJobPopup from '../Workorder/completeJobPopup';
import HelmetComponent from '../Title/index';
import WorkOrderDetails from './WorkOrderDetails';
import {
  SetChatBoxToggle,
  // SetChatTechDetails,
  SetChatRecieverData,
  SetChatNotificationCount,
  SetCalenderDate,
} from '../../../action/user.action';

const userProfilePath = '/assets/images/users/user.png';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Schedule Dispatch',
      selectedStatus: ['all'],
      jobs: [],
      source: [],
      technicians: [],
      serviceTypes: [],
      selectedSource: [],
      selectedTechnicians: [],
      selectedServiceTypes: [],
      selectedJob: '',
      showFilterPopup: false,
      filterTempSelction: {
        selectedSource: [],
        selectedTechnicians: [],
        selectedServiceTypes: [],
      },
      workstatus: {
        all: 0,
        offered: 0,
        unscheduled: 0,
        scheduled: 0,
        paused: 0,
        inprogress: 0,
        completed: 0,
      },
      appliedFilter: false,
      limit: 5,
      totalCount: 0,
      currentPage: 1,
      places: [],
      scheduleWorkOrder: [],
      directions: [],
      routeMarker: [],
      placeMarker: [],
      techniciansColor: {},
      mapstartdate: new Date(),
      mapenddate: new Date(),
      mapWorkOrder: [],
      mapTechnicianResource: [],
      mapSelectedWorkOrder: null,
      mapSelectedTechnician: '',
      defaultCenter: {
        lat: 40.689167,
        lng: -111.993889,
      },
      isWriteAllowed: false,
      togglebtn: false,
      isShowLoader: false,
      workOrderToggle: false,
      workOrderId: null,
      roleId: null,
      jobStatus: '',
      workCompleteJob: false,
      customerId: null,
      loginId: null,
      jobPlaceMarker: [],
      allJobs: [],
    };
    this.mapRef = React.createRef();
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id, loginId: userdata.login_id });

    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'work-schedule'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'work-schedule',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle(this.state.title);
    const qs = queryString.parse(this.props.history.location.search);
    if (!qs.job_type)
      return this.props.history.push('/schedule?job_type=service');

    this.setState({ selectedJob: qs.job_type.toLocaleLowerCase() });
    // this.getAllJobs();
    this.getJobs();
    this.getTechnicians();
    this.getSource();
    this.getServiceType();
    // this.getScheduledWorkOrder();
    // this.getCalenderWorkOrder();
  };
  // getColorCode = async (data = []) => {
  //   let code = customFunctions.getMyColor();
  //   if (data.includes(code)) {
  //     code = await this.getColorCode(data);
  //   }
  //   return code;
  // };

  getScheduledWorkOrder = async (stDate = '', edDate = '') => {
    let scheduleWorkOrder = [];
    let techniciansRoute = {};
    let routeMarkerData = [];
    let placeMarkerData = [];
    this.setState({ isShowLoader: true });

    const {
      mapstartdate,
      mapenddate,
      selectedTechnicians,
      techniciansColor,
      roleId,
      loginId,
      selectedStatus,
    } = this.state;

    let sdate = mapstartdate ? new Date(mapstartdate) : new Date();
    sdate = customFunctions.formatDate(sdate, '-', 'ymd');

    let edate = mapenddate ? new Date(mapenddate) : new Date();
    edate = customFunctions.formatDate(edate, '-', 'ymd');

    let endPoint = `${Api.getScheduleWorkOrder}?from_date=${
      stDate ? new Date(stDate).toISOString() : new Date(sdate).toISOString()
    }&to_date=${
      edDate ? new Date(edDate).toISOString() : new Date(edate).toISOString()
    }`;
    if ([1, 6].includes(roleId)) {
      if (selectedTechnicians.length)
        endPoint += `&Technician=${selectedTechnicians.join(',')}`;
    }

    if ([5, 3].includes(roleId)) {
      endPoint += `&Technician=${loginId}`;
    }
    this.setState({
      scheduleWorkOrder: [],
      directions: [],
      routeMarker: [],
      placeMarker: [],
    });

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.success !== false) {
      let filteredWorkorders =
        (await data.data) && (await data.data.length)
          ? await data.data.filter((i) =>
              selectedStatus.includes(i.WorkStatus.toLocaleLowerCase())
            )
          : [];
      if (filteredWorkorders.length) {
        for (let item of filteredWorkorders) {
          // if (!techniciansColor[item.technicainid]) {
          //   techniciansColor[item.technicainid] = await this.getColorCode(
          //     Object.values(techniciansColor)
          //   );
          // }

          item.shorttechname = await customFunctions.getShortName(
            item.technicianName
          );
          item.color = item.techcolor ? item.techcolor : '#67a8e4';
          if (!techniciansRoute[item.technicainid]) {
            techniciansRoute[item.technicainid] = [];
            if (item.tech_latitude && item.tech_longitude) {
              techniciansRoute[item.technicainid].push({
                latitude: item.tech_latitude,
                longitude: item.tech_longitude,
                color: item.techcolor ? item.techcolor : '#67a8e4',
              });
              placeMarkerData.push({
                ...item,
                name: item.technicianName,
                latitude: item.tech_latitude,
                longitude: item.tech_longitude,
                image: item.tech_profile_pic,
                option: {
                  label: {
                    color: item.techcolor ? item.techcolor : '#67a8e4',
                    text: item.shorttechname,
                  },
                  icon: {
                    path: 'circle',
                    strokeColor: item.techcolor ? item.techcolor : '#67a8e4',
                  },
                },
              });
            }
            let markLength =
              techniciansRoute[item.technicainid].length > 1
                ? techniciansRoute[item.technicainid].length - 1
                : 1;
            techniciansRoute[item.technicainid].push({
              latitude: item.latitude,
              longitude: item.longitude,
              color: item.techcolor ? item.techcolor : '#67a8e4',
            });
            routeMarkerData.push({
              ...item,
              name: item.WorkOrder,
              option: {
                label: { color: 'white', text: markLength.toString() },
                icon: { fillColor: item.techcolor ? item.techcolor : 'green' },
              },
            });
          } else {
            techniciansRoute[item.technicainid].push({
              latitude: item.latitude,
              longitude: item.longitude,
              color: item.techcolor ? item.techcolor : '#67a8e4',
            });
            let markLength =
              techniciansRoute[item.technicainid].length > 1
                ? techniciansRoute[item.technicainid].length - 1
                : 1;
            routeMarkerData.push({
              ...item,
              name: item.WorkOrder,
              option: {
                label: { color: 'white', text: markLength.toString() },
                icon: { fillColor: item.techcolor ? item.techcolor : 'green' },
              },
            });
          }
          scheduleWorkOrder.push(item);
          item = null;
          this.forceUpdate();
        }
      }
    }

    this.setState(
      {
        scheduleWorkOrder,
        directions: [...Object.values(techniciansRoute)],
        routeMarker: routeMarkerData,
        placeMarker: placeMarkerData,
        techniciansColor,
        isShowLoader: false,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  getCalenderWorkOrder = async (sd, ed) => {
    const { roleId, loginId, selectedStatus, selectedTechnicians } = this.state;
    var scheduleWorkOrder = [];
    var technicianResource = [];
    this.setState({ isShowLoader: true });
    let sdate = new Date(sd).toISOString();
    let edate = new Date(ed).toISOString();
    // let sdate = sd ? new Date(sd) : new Date();
    // sdate = customFunctions.formatDate(sdate, '-', 'ymd');
    // let edate = ed ? new Date(ed) : new Date();
    // edate = customFunctions.formatDate(edate, '-', 'ymd');
    await this.props.SetCalenderDate(sdate);
    if (
      selectedStatus &&
      selectedStatus.length &&
      (selectedStatus.includes('scheduled') ||
        selectedStatus.includes('inprogress'))
    ) {
      this.getAllJobs(sdate);
    }
    // this.setState(
    //   {
    //     mapWorkOrder: [],
    //     mapTechnicianResource: [],
    //     scheduleWorkOrder: [],
    //     directions: [],
    //     routeMarker: [],
    //     placeMarker: [],
    //   });
    // if(selectedStatus && (!selectedStatus.includes('scheduled') && !selectedStatus.includes('inprogress') && !selectedStatus.includes('all') )) {
    //   return;
    // }

    let endPoint = `${Api.getScheduleWorkOrder}?from_date=${sdate}&to_date=${edate}`;
    if (roleId === 1) {
      if (selectedTechnicians.length)
        endPoint += `&Technician=${selectedTechnicians.join(',')}`;
    }

    if ([5, 3].includes(roleId)) {
      endPoint += `&Technician=${loginId}`;
    }

    try {
      const { data } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.success !== false) {
        let filteredWorkorders = await data.data;
        // let filteredWorkorders = await data.data && await data.data.length ? await data.data.filter(i =>selectedStatus.includes(i.WorkStatus.toLocaleLowerCase())): [];

        if (filteredWorkorders.length) {
          for (let item of filteredWorkorders) {
            // if (!techniciansColor[item.technicainid]) {
            //   techniciansColor[item.technicainid] = await this.getColorCode(
            //     Object.values(techniciansColor)
            //   );
            // }
            let tech = {
              id: item.technicainid,
              title: customFunctions.capitalizeFirstLetter(item.technicianName),
              eventColor: item.techcolor ? item.techcolor : '#67a8e4',
              color: item.techcolor ? item.techcolor : '#67a8e4',
              tech_function: item.tech_function,
              vendorid: item.vendorid,
            };
            if (
              technicianResource.filter((i) => i.id == item.technicainid)
                .length === 0
            )
              technicianResource.push(tech);

            let slot = {
              ...item,
              start: new Date(item.FromTime),
              end: new Date(item.ToTime),
              resourceId: item.technicainid,
              title: item.WorkOrder,
            };
            scheduleWorkOrder.push(slot);
          }
        }
      }
    } catch (err) {}

    technicianResource.sort((res1, res2) => {
      return this.compareObjects(res1, res2, 'title');
    });
    this.setState(
      {
        mapWorkOrder: scheduleWorkOrder,
        mapTechnicianResource: technicianResource,
        mapstartdate: sdate,
        mapenddate: edate,
        isShowLoader: false,
      },
      () => {
        this.getScheduledWorkOrder(sdate, edate);
        this.forceUpdate();
      }
    );
  };

  compareObjects = (object1, object2, key) => {
    const obj1 = object1[key].toUpperCase();
    const obj2 = object2[key].toUpperCase();

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  };

  getTechnicians = async () => {
    let technicians = [];
    this.setState({ isShowLoader: true });
    let endPoint = `${Api.technicianUrl}?skip=0&limit=10000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      technicians = data.data ? data.data.data : [];
    }
    this.setState({ technicians, isShowLoader: false });
  };

  getSource = async () => {
    // endpoint changes for source type to vendors as per new req
    let source = [];
    this.setState({ isShowLoader: true });
    const { data } = await AuthApi.getDataFromServer(
      `${Api.vendorsUrl}?skip=0&limit=100`
    );
    if (data && data.success !== false) {
      source = data.data ? data.data.data : [];
    }
    this.setState({ source, isShowLoader: false });
  };

  getServiceType = async () => {
    // endpoint changes for service type to work order type as per new req
    const serviceTypes = [];
    this.setState({ isShowLoader: true });
    const { data } = await AuthApi.getDataFromServer(
      `${Api.workOrderTypeUrl}?skip=0&limit=100&type=2&status=1`
    );
    if (data && data.success !== false) {
      data.data &&
        data.data.data &&
        data.data.data.map((item) => {
          serviceTypes.push({ id: item.id, name: item.order_type });
        });
    }
    this.setState({ serviceTypes, isShowLoader: false });
  };

  getJobs = async (currentPage = 1) => {
    this.setState({ currentPage, isShowLoader: true });
    const {
      limit,
      selectedTechnicians,
      selectedSource,
      selectedServiceTypes,
      selectedStatus,
      workstatus,
      roleId,
      loginId,
      filterTempSelction,
    } = this.state;

    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 1);
    const page = currentPage || 1;
    const skip = page * limit - limit;
    // customFunctions.formatDate(date,'-','ymd')
    let endPoint = `${
      Api.getWorkOrder
    }?skip=${skip}&limit=${limit}&from_date=${new Date(
      date
    ).toISOString()}&to_date=${new Date(endDate).toISOString()}`;
    if ([5, 3].includes(roleId)) {
      endPoint += `&technician=${loginId}`;
    }

    // if (filterTempSelction.selectedTechnicians.length && selectedStatus.length && !selectedStatus.includes('offered'))
    //   endPoint += `&technician=${selectedTechnicians.join(',')}`;

    if (selectedTechnicians.length && !selectedStatus.includes('offered'))
      endPoint += `&technician=${selectedTechnicians.join(',')}`;

    if (selectedSource.length)
      endPoint += `&vendorid=${selectedSource.join(',')}`;

    if (selectedStatus.length > 0 && selectedStatus.includes('unscheduled')) {
      endPoint += `&withUnscheduled=true`;
    }

    if (selectedServiceTypes.length)
      endPoint += `&pausedStatus=${selectedServiceTypes
        .map((i) => i.toLocaleLowerCase())
        .join(',')}`;

    if (selectedStatus.length && !selectedStatus.includes('all'))
      endPoint += `&work_status=${selectedStatus
        .map((i) => i.toLocaleLowerCase())
        .join(',')}`;

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      workstatus.offered = 0;
      workstatus.scheduled = 0;
      workstatus.unscheduled = 0;
      workstatus.paused = 0;
      workstatus.completed = 0;
      workstatus.inprogress = 0;
      workstatus.all = 0;
      this.setState({ isShowLoader: false });
      data.data &&
        data.data.status_count &&
        data.data.status_count.map((item) => {
          workstatus.all += parseInt(item.totalCount);
          if (item.WorkStatus)
            workstatus[item.WorkStatus.toLowerCase()] = item.totalCount;
        });

      this.setState({
        jobs: data.data && data.data.data ? data.data.data : [],
        totalCount:
          data.data && data.data.total_count ? data.data.total_count : 0,
        workstatus,
      });
    }
    this.setState({ isShowLoader: false });
  };

  getAllJobs = async (sdate = '') => {
    this.setState({ isShowLoader: true });
    const {
      selectedTechnicians,
      selectedSource,
      selectedServiceTypes,
      selectedStatus,
      workstatus,
      roleId,
      loginId,
    } = this.state;
    // const date = new Date();
    // date.setFullYear(date.getFullYear() - 1);
    // const endDate = new Date();
    // endDate.setFullYear(endDate.getFullYear() + 1);

    let endPoint = `${Api.getWorkOrder}?skip=0&limit=1000`;
    var startDate = sdate ? sdate : this.props.calDate;
    if (
      selectedStatus &&
      selectedStatus.length &&
      (selectedStatus.includes('scheduled') ||
        selectedStatus.includes('inprogress'))
    ) {
      endPoint += `&from_date=${new Date(
        startDate
      ).toISOString()}&to_date=${new Date(startDate).toISOString()}`;
    }

    if ([5, 3].includes(roleId)) {
      endPoint += `&technician=${loginId}`;
    }

    if (selectedTechnicians.length && !selectedStatus.includes('offered'))
      endPoint += `&technician=${selectedTechnicians.join(',')}`;

    if (selectedSource.length)
      endPoint += `&vendorid=${selectedSource.join(',')}`;

    if (selectedServiceTypes.length)
      endPoint += `&pausedStatus=${selectedServiceTypes
        .map((i) => i.toLocaleLowerCase())
        .join(',')}`;

    if (selectedStatus.length && !selectedStatus.includes('all'))
      endPoint += `&work_status=${selectedStatus
        .map((i) => i.toLocaleLowerCase())
        .join(',')}`;

    try {
      const { data } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.success !== false) {
        this.setState(
          {
            isShowLoader: false,
            allJobs: data.data.data,
            totalCount: data.data.total_count,
            workstatus,
            jobPlaceMarker: [],
          },
          () => {
            let placeMarkerData = [];
            this.state.allJobs &&
              this.state.allJobs.length > 0 &&
              this.state.allJobs.map((item) => {
                placeMarkerData.push({
                  ...item,
                  name: item.technicianName,
                  popup_type: 'workorder',
                  latitude: item.latitude,
                  longitude: item.longitude,
                  option: {
                    label: { color: 'black', text: item.shorttechname },
                    icon: {
                      path: 'circle',
                      strokeColor: 'red',
                    },
                  },
                });
              });
            this.setState(
              {
                jobPlaceMarker: placeMarkerData,
              },
              () => {
                this.forceUpdate();
              }
            );
          }
        );
      } else {
        this.setState({ isShowLoader: false });
      }
    } catch (err) {}
  };

  changeStatus = (sts) => {
    const { selectedStatus, mapstartdate, mapenddate } = this.state;
    var data = [...selectedStatus];
    if (sts !== 'all' && !data.includes(sts)) {
      data = data.filter((item) => item !== 'all');
      data.push(sts);
    } else {
      data = data.filter((item) => item !== sts);
      data = data.length > 0 ? data : ['all'];
    }
    data = sts === 'all' ? [sts] : data;

    this.setState({ selectedStatus: [...data] }, async () => {
      await this.getJobs();
      await this.getAllJobs();
      if (sts === 'scheduled' || sts === 'inprogress') {
        await this.getScheduledWorkOrder(mapstartdate, mapenddate);
      }
    });
  };

  onFilterClose = () => {
    const {
      filterTempSelction,
      selectedTechnicians,
      selectedSource,
      selectedServiceTypes,
    } = this.state;
    let clear = false;
    filterTempSelction.selectedTechnicians = [];
    filterTempSelction.selectedSource = [];
    filterTempSelction.selectedServiceTypes = [];

    clear =
      selectedTechnicians.length > 0 ||
      selectedSource.length > 0 ||
      selectedServiceTypes.length > 0;

    this.setState(
      { showFilterPopup: false, appliedFilter: clear, filterTempSelction },
      () => {
        this.getJobs();
        this.getAllJobs();
      }
    );
  };

  onFilterOpen = (e) => {
    e.preventDefault();
    const {
      filterTempSelction,
      selectedSource,
      selectedTechnicians,
      selectedServiceTypes,
    } = this.state;
    filterTempSelction.selectedSource = [...selectedSource];
    filterTempSelction.selectedTechnicians = [...selectedTechnicians];
    filterTempSelction.selectedServiceTypes = [...selectedServiceTypes];
    this.setState({ showFilterPopup: true, filterTempSelction });
  };

  onFilterSubmit = (e) => {
    e.preventDefault();
    const { filterTempSelction, mapstartdate, mapenddate } = this.state;
    this.setState(
      {
        showFilterPopup: false,
        appliedFilter:
          filterTempSelction.selectedSource.length > 0 ||
          filterTempSelction.selectedTechnicians.length > 0 ||
          filterTempSelction.selectedServiceTypes.length > 0,
        selectedSource: [...filterTempSelction.selectedSource],
        selectedTechnicians: [...filterTempSelction.selectedTechnicians],
        selectedServiceTypes: [...filterTempSelction.selectedServiceTypes],
        selectedStatus: [],
      },
      () => {
        this.getJobs();
        this.getAllJobs();
        // this.getScheduledWorkOrder();
        this.getCalenderWorkOrder(mapstartdate, mapenddate);
      }
    );
  };

  clearFilter = (e) => {
    e.preventDefault();
    const { filterTempSelction, mapstartdate, mapenddate } = this.state;
    filterTempSelction.selectedTechnicians = [];
    filterTempSelction.selectedSource = [];
    filterTempSelction.selectedServiceTypes = [];
    this.setState(
      {
        appliedFilter: false,
        filterTempSelction,
        selectedTechnicians: [],
        selectedSource: [],
        selectedServiceTypes: [],
      },
      () => {
        this.getJobs();
        this.getAllJobs();
        // this.getScheduledWorkOrder();
        this.getCalenderWorkOrder(mapstartdate, mapenddate);
      }
    );
  };

  onSelectFilterData = (key, value) => {
    const { filterTempSelction } = this.state;
    if (filterTempSelction[key].includes(value)) {
      filterTempSelction[key] = filterTempSelction[key].filter(
        (i) => i !== value
      );
    } else {
      filterTempSelction[key].push(value);
    }
    this.setState({ filterTempSelction });
  };

  renderRouteWindow = (item) => {
    let startDate = item.FromTime
      ? customFunctions.formatDate(item.FromTime, ' ', 'Md')
      : '';
    let starttime = item.FromTime
      ? customFunctions.formatDate(item.FromTime, ' ', 'dt')
      : '';
    let endtime = item.ToTime
      ? customFunctions.formatDate(item.ToTime, ' ', 'dt')
      : '';
    let timezone = item.FromTime
      ? customFunctions.formatDate(item.FromTime, ' ', 'dz')
      : '';

    return (
      <ul className="pl-0">
        <li
          className={`mb-2`}
          style={{ borderLeft: `3px solid ${item.color}` }}
        >
          <div className="profile d-flex">
            <img src={userProfilePath} alt="profile2" />
            <span>&nbsp;&nbsp;{item.WorkOrder}</span>
          </div>
          <div className="action-bar d-flex">
            <div className="col-12 address">
              {item.CustomerName && (
                <div className="date">
                  {startDate} / {starttime} - {endtime} {timezone}
                </div>
              )}
              {item.CustomerName && (
                <div className="fullname">
                  {item.CustomerName.toUpperCase()}
                </div>
              )}
              <span>
                {item.AddressLine1_1 ? item.AddressLine1_1.trim() + ', ' : ''}
                {item.AddressLine2_1 ? item.AddressLine2_1.trim() + ' ' : ''}
                {item.State1 ? item.State1.trim() + ', ' : ''}
                {item.Zip1 ? item.Zip1.trim() : ''}
              </span>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  renderPlaceWindow = (item) => {
    let startDate = item.FromTime
      ? customFunctions.formatDate(item.FromTime, '/', 'mdy')
      : '';

    return (
      <>
        {item && item.popup_type === 'workorder' ? (
          <div
            className="pl-0"
            style={{ cursor: 'pointer' }}
            onClick={(e) => this.redirectWorkOrder(item.ID, item.WorkStatus)}
          >
            <h6 className="m-0">
              <b>{item.WorkStatus !== 'Offered' && item.WorkOrder}</b>
            </h6>
            {item.WorkStatus === 'Offered' ? (
              <b title={item.job_title}>{item.job_title}</b>
            ) : (
              <span>{item.job_title}</span>
            )}
            <hr />
            <span style={{ fontSize: '11px' }}>
              {item.WorkStatus === 'Paused' ||
              item.WorkStatus === 'Offered' ||
              !item.FromTime
                ? 'No Appointments'
                : this.getTimeZone(
                    item.FirstApptDate,
                    item.FromTime,
                    item.ToTime
                  )}
              <br />
              <span style={{ fontSize: '13px' }}>{item.CustomerName}</span>
              <br />
              {item.AddressLine1_1 + ', ' + item.City1 + ', ' + item.Country1}
            </span>
          </div>
        ) : (
          <div className="pl-0">
            <h6 className="m-0">
              <b>{item.name}</b>
            </h6>
            <hr />
            <p>No data since - {startDate}</p>
            {![5].includes(this.state.roleId) &&
              item.technicainid !== this.state.loginId && (
                <p>
                  <a
                    href="/"
                    onClick={(e) => {
                      let techdata = {
                        userid: item.technicianuserid,
                        roleid: 5 || 6,
                        image: '',
                        name: item.name,
                      };
                      e.preventDefault();
                      this.props.SetChatBoxToggle(true);
                      // this.props.SetChatTechDetails(techdata);
                      this.props.SetChatRecieverData(techdata);
                      this.getReadMessage(
                        this.state.roleId,
                        item.technicianuserid
                      );
                    }}
                  >
                    Message
                  </a>
                </p>
              )}
          </div>
        )}
      </>
    );
  };

  setTechnicianMap = (id) => {
    const { mapWorkOrder } = this.state;
    const filterWorkOrder = mapWorkOrder.filter(
      (item) => item.technicainid == id
    );
    let position = {
      lat: 0,
      lng: 0,
    };
    if (filterWorkOrder.length > 0) {
      position.lat = Number(filterWorkOrder[0].tech_latitude);
      position.lng = Number(filterWorkOrder[0].tech_longitude);
      setTimeout(() => {
        var ele = document.querySelector(
          `[title="${filterWorkOrder[0].technicianName}"]`
        );
        if (ele) ele.click();
      }, 200);
    }
    this.scrollTop();
    this.setState({
      defaultCenter: { ...position },
      mapSelectedTechnician: id,
    });
  };

  redirectWorkOrder = (id, status) => {
    this.setState({
      workOrderToggle: true,
      workOrderId: id,
      jobStatus: status,
    });
    // this.props.history.push('/update-order/' + id);
  };

  scrollTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  updateAppointment = async (params, techid, tech_function, vendorid) => {
    let postData = {
      ...params,
    };

    if (techid && tech_function) {
      postData.multitech = [];
      postData.multitech.push({
        tech_id: techid,
        tech_function: tech_function,
      });
    }
    if (vendorid) {
      postData.vendorid = vendorid;
    }
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.workOrderUrl}`;
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      swal('Appointment updated successfully', '', 'success');
      await this.getScheduledWorkOrder();
      this.getCalenderWorkOrder(params.from_time, params.to_time);
      this.forceUpdate();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  setWorkStatus = async (type) => {
    const { workOrderId } = this.state;
    this.setState({ type });
    let payload = {
      work_order_id: workOrderId,
    };
    let api = '';
    if (type === 'schedule') return;
    if (type === 'acknowledge') {
      api = Api.workOrderAcknowledgement;
      payload.status = 'Offered';
    }
    if (type === 'archivejob') {
      api = Api.workOrderAcknowledgement;
      payload.status = 'archive';
    }
    if (type === 'canceljob') {
      api = Api.workOrderCompleted;
      payload.status = 'Cancelled';
    }
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${api}`;

    const callback = AuthApi.putDataToServer;

    const { data } = await callback(endPoint, payload);
    if (data) {
      if (type === 'archivejob') {
        swal('Workorder Archived successfully', '', 'success');
        this.props.history.push('/ahs-workorders');
      }
    }
    this.setState({
      isShowLoader: false,
    });
  };

  getReadMessage = async (roleId, userId) => {
    let endPoint = `${Api.updateResentMessagesUrl}`;
    let postData = {
      is_read: 1,
      roleid: roleId,
      userid: userId,
    };

    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);

    if (data && data.data && data.success) {
      this.getPresentMessageUsers();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getPresentMessageUsers = async () => {
    const { SetChatNotificationCount } = this.props;
    const endPoint = `${Api.getResentMessagesUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (!data) {
      return;
    }
    const allData = data.data;
    SetChatNotificationCount(allData.count);
  };

  getTimeZone = (ApptDate, FromTime, ToTime) => {
    const dt = customFunctions.getLocalStorage('userTimeOffset');
    let date = customFunctions.convertISODatetoOffsetWiseDate({
      apptDate: ApptDate ? new Date(ApptDate).toISOString() : '',
      currentFromDate: FromTime ? new Date(FromTime).toISOString() : '',
      currentToDate: ToTime ? new Date(ToTime).toISOString() : '',
      offsetMins: dt,
    });
    return date;
  };

  render() {
    const {
      selectedStatus,
      title,
      jobs,
      limit,
      currentPage,
      showFilterPopup,
      technicians,
      source,
      serviceTypes,
      filterTempSelction,
      appliedFilter,
      workstatus,
      totalCount,
      directions,
      placeMarker,
      routeMarker,
      defaultCenter,
      mapTechnicianResource,
      mapWorkOrder,
      mapSelectedWorkOrder,
      togglebtn,
      isShowLoader,
      workOrderId,
      workOrderToggle,
      roleId,
      jobStatus,
      workCompleteJob,
      customerId,
      jobPlaceMarker,
    } = this.state;
    const { toggle } = this.props;
    return (
      <AUX>
        <div className="page-content-wrapper schedule">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {![5, 3].includes(roleId) && (
                    <div
                      className={`left-panel position-relative ${
                        !togglebtn ? 'col-md-4 d-block' : 'd-none'
                      }`}
                    >
                      <div className="row">
                        <ul className="d-flex filter-status">
                          <li
                            className={`border bg-all-b ${
                              selectedStatus.includes('all')
                                ? 'bg-all active'
                                : ''
                            }`}
                            title="All"
                            onClick={(e) => {
                              this.changeStatus('all');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.all}
                          </li>
                          <li
                            className={`border bg-offered-b ${
                              selectedStatus.includes('offered')
                                ? 'bg-offered active'
                                : ''
                            }`}
                            title="offered"
                            onClick={(e) => {
                              this.changeStatus('offered');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.offered}
                          </li>
                          <li
                            className={`border bg-unscheduled-b ${
                              selectedStatus.includes('unscheduled')
                                ? 'bg-unscheduled active'
                                : ''
                            }`}
                            title="unscheduled"
                            onClick={(e) => {
                              this.changeStatus('unscheduled');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.unscheduled}
                          </li>
                          <li
                            className={`border bg-scheduled-b ${
                              selectedStatus.includes('scheduled')
                                ? 'bg-scheduled active'
                                : ''
                            }`}
                            title="scheduled"
                            onClick={(e) => {
                              this.changeStatus('scheduled');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.scheduled}
                          </li>
                          <li
                            className={`border bg-paused-b ${
                              selectedStatus.includes(
                                'paused',
                                './assets/images/workstatus/iconPaused.svg'
                              )
                                ? 'bg-paused active'
                                : ''
                            }`}
                            title="paused"
                            onClick={(e) => {
                              this.changeStatus('paused');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.paused}
                          </li>
                          <li
                            className={`border bg-inprogress-b ${
                              selectedStatus.includes('inprogress')
                                ? 'bg-inprogress active'
                                : ''
                            }`}
                            title="inprogress"
                            onClick={(e) => {
                              this.changeStatus('inprogress');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.inprogress}
                          </li>
                          <li
                            className={`border bg-completed-b ${
                              selectedStatus.includes('completed')
                                ? 'bg-completed active'
                                : ''
                            }`}
                            title="completed"
                            onClick={(e) => {
                              this.changeStatus('completed');
                              // this.clearFilter(e);
                            }}
                          >
                            {workstatus.completed}
                          </li>
                        </ul>
                      </div>
                      <div className="row filter-section">
                        {!workOrderToggle && (
                          <div className="filter col-12 p-b-10">
                            <button
                              type="button"
                              className={`btn ${
                                appliedFilter ? 'btn-success' : 'btn-primary'
                              }`}
                              onClick={this.onFilterOpen}
                            >
                              {appliedFilter
                                ? 'Filtering is on..'
                                : 'Filter by..'}
                            </button>
                            {appliedFilter && (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.clearFilter}
                              >
                                X
                              </button>
                            )}
                          </div>
                        )}
                        {!workOrderToggle && (
                          <div className="list-item col-12 pl-0">
                            <ul className="filter-list">
                              {jobs &&
                                jobs.map((item, id) => (
                                  <li
                                    key={id}
                                    className={`bg-${
                                      item.WorkStatus &&
                                      item.WorkStatus.toLowerCase()
                                    }-l mb-2 cp`}
                                    onClick={() =>
                                      this.redirectWorkOrder(
                                        item.ID,
                                        item.WorkStatus
                                      )
                                    }
                                  >
                                    <div className="card">
                                      <div className="card-body p-1">
                                        <div className="profile d-flex">
                                          <img
                                            src={userProfilePath}
                                            alt="profile1"
                                          />
                                          <span>
                                            &nbsp;&nbsp;
                                            {item.WorkOrder && item.WorkOrder}
                                            <br />
                                            &nbsp;&nbsp;
                                            {item.job_title && item.job_title}
                                          </span>
                                        </div>
                                        {item.WorkStatus !== 'Unscheduled' &&
                                          item.WorkStatus !== 'Offered' && (
                                            <div>
                                              <span>
                                                &nbsp;&nbsp;
                                                {item.FirstApptDate &&
                                                item.FromTime &&
                                                item.ToTime
                                                  ? this.getTimeZone(
                                                      item.FirstApptDate,
                                                      item.FromTime,
                                                      item.ToTime
                                                    )
                                                  : 'No Appointment Date'}
                                              </span>
                                            </div>
                                          )}
                                        <div className="status">
                                          <span
                                            className={`${
                                              item.WorkStatus
                                                ? 'fg-' +
                                                  item.WorkStatus.toLowerCase()
                                                : ''
                                            }`}
                                          >
                                            {item.WorkStatus &&
                                              item.WorkStatus.toUpperCase()}
                                          </span>
                                        </div>
                                        <div className="action-bar d-flex">
                                          <div className="col-7 address">
                                            {item.CustomerName && (
                                              <div className="fullname">
                                                {item.CustomerName}
                                              </div>
                                            )}
                                            <span>
                                              {item.AddressLine1_1
                                                ? item.AddressLine1_1.trim() +
                                                  ', '
                                                : ''}
                                              {item.AddressLine2_1
                                                ? item.AddressLine2_1.trim() +
                                                  ', '
                                                : ''}
                                              {item.City1
                                                ? item.City1.trim() + ', '
                                                : ''}
                                              {item.State1
                                                ? item.State1.trim() + ', '
                                                : ''}
                                              {item.Zip1
                                                ? item.Zip1.trim()
                                                : ''}
                                            </span>
                                          </div>
                                          <div className="col-5 action">
                                            {item.WorkStatus &&
                                              (item.WorkStatus.toLowerCase() ===
                                                'scheduled' ||
                                                item.WorkStatus.toLowerCase() ===
                                                  'paused') && (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary text-wrap"
                                                  id="btn-action"
                                                  name="btn-action"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({
                                                      workCompleteJob: true,
                                                      workOrderId: item.ID,
                                                      jobStatus:
                                                        item.WorkStatus,
                                                      customerId:
                                                        item.CustomerID,
                                                    });
                                                  }}
                                                >
                                                  COMPLETE
                                                </button>
                                              )}
                                            {item.WorkStatus &&
                                              item.WorkStatus.toLowerCase() ===
                                                'offered' && (
                                                <button
                                                  type="button"
                                                  className="btn btn-success text-wrap"
                                                  id="btn-action"
                                                  name="btn-action"
                                                  onClick={(e) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  ACKNOWLEDGE
                                                </button>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              {!jobs.length && (
                                <li className="">
                                  <div className="card">
                                    <div className="card-body p-1">
                                      <div className="text-center">
                                        <span>no jobs found</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                        {workOrderId && workOrderToggle && (
                          <WorkOrderDetails
                            workOrderToggle={workOrderToggle}
                            jobStatus={jobStatus}
                            workOrderId={workOrderId}
                            callback={async () => {
                              this.setState({
                                workOrderToggle: !workOrderToggle,
                              });
                              await this.getScheduledWorkOrder();
                              await this.getJobs();
                            }}
                          />
                        )}
                      </div>
                      {!workOrderToggle && (
                        <div className="pagination">
                          <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            limit={limit}
                            getData={this.getJobs}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`right-panel ${
                      !togglebtn && ![5, 3].includes(roleId)
                        ? 'col-md-8'
                        : 'col-md-12'
                    }`}
                  >
                    <div className="col-12">
                      <div className="map-container">
                        {![5, 3].includes(roleId) && (
                          <div>
                            {togglebtn ? (
                              <button
                                style={{ left: 0 }}
                                onClick={() =>
                                  this.setState({ togglebtn: false })
                                }
                                className="btn btn-light position-absolute togglebtn"
                              >
                                <span className="mdi mdi-chevron-double-right"></span>
                              </button>
                            ) : (
                              <button
                                style={{ left: toggle ? '382px' : '333px' }}
                                onClick={() =>
                                  this.setState({ togglebtn: true })
                                }
                                className="btn btn-light position-absolute togglebtn"
                              >
                                <span className="mdi mdi-chevron-double-left"></span>
                              </button>
                            )}
                          </div>
                        )}
                        <LocationRGMap
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          places={directions}
                          isMarkerShown
                          placeMarker={placeMarker}
                          routeMarker={routeMarker}
                          // renderRouteWindow={this.renderRouteWindow}
                          renderPlaceWindow={this.renderPlaceWindow}
                          defaultCenter={defaultCenter}
                          center={defaultCenter}
                          defaultZoom={10}
                          jobPlaceMarker={jobPlaceMarker}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {roleId && (
                        <CalendarTimeLine
                          ref={this.mapRef}
                          firstDay={1}
                          // schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                          schedulerLicenseKey={
                            process.env.REACT_APP_FULLCALENDAR_KEY
                          }
                          headerToolbar={{
                            left: 'prev',
                            center: 'title',
                            right: 'next today',
                            // right: 'resourceTimelineDay,resourceTimelineWeek',
                          }}
                          resourceAreaHeaderContent="Technician"
                          initialView="resourceTimelineDay"
                          expandRows={true}
                          nowIndicator={true}
                          resources={mapTechnicianResource}
                          events={mapWorkOrder}
                          lazyFetching={true}
                          datesSet={async (ds) => {
                            var d = new Date(ds.end);
                            d.setDate(d.getDate() - 1);
                            this.getCalenderWorkOrder(ds.start, d);
                            // let sdate = customFunctions.formatDate(
                            //   ds.start,
                            //   '-',
                            //   'ymd'
                            // );
                            await this.props.SetCalenderDate(ds.start);
                            this.getAllJobs();
                          }}
                          resourceLabelContent={(e) => {
                            var techid = e.resource._resource.id;
                            return (
                              <a
                                href="/"
                                className="cp"
                                style={{ float: 'left' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setTechnicianMap(techid);
                                }}
                              >
                                <span
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    paddingRight: '10px',
                                    borderRadius: '50%',
                                    backgroundColor:
                                      e.resource.extendedProps.color,
                                    marginRight: '10px',
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                &nbsp;{e.fieldValue}
                              </a>
                            );
                          }}
                          select={(e) => {
                            this.props.history.push('/work-order', {
                              scheduleDetails: {
                                technicianName: e.resource._resource.title,
                                technicianId: e.resource._resource.id,
                                apptDate: e.start,
                              },
                            });
                          }}
                          selectable={roleId === 1}
                          editable={roleId === 1}
                          eventClick={(e) => {
                            let selectedObj = {
                              ...e.event._def.extendedProps,
                              id: e.event._def.publicId,
                            };
                            let position = {
                              lat: Number(selectedObj.latitude),
                              lng: Number(selectedObj.longitude),
                            };
                            this.scrollTop();
                            setTimeout(() => {
                              var ele = document.querySelector(
                                `[title="${e.event._def.title}"]`
                              );
                              if (ele) ele.click();
                            }, 200);

                            this.setState({
                              mapSelectedWorkOrder: selectedObj,
                              defaultCenter: { ...position },
                            });
                          }}
                          // slotLabelContent={(e) => {
                          //   return <button>abc</button>
                          // }}
                          // slotLaneContent={(e) => {
                          //   return <button>a</button>
                          // }}
                          slotDuration="00:30:00"
                          snapDuration="00:30:00"
                          eventContent={(arg) => {
                            var item = arg.event._def.extendedProps;
                            return (
                              <div
                                className={
                                  mapSelectedWorkOrder &&
                                  mapSelectedWorkOrder.id == item.id
                                    ? 'event-active'
                                    : ''
                                }
                                style={{
                                  borderLeft: `5px solid black`,
                                  padding: '1px',
                                }}
                              >
                                <span>
                                  <b>{item.CustomerName}</b>
                                </span>
                                <br />
                                <span>{item.WorkOrder}</span>
                                <br />
                                <span>{item.job_title}</span>
                                <p>
                                  {item.AddressLine1_1
                                    ? item.AddressLine1_1.trim() + ', '
                                    : ''}
                                  {item.AddressLine2_1
                                    ? item.AddressLine2_1.trim() + ' '
                                    : ''}
                                  {item.City1 ? item.City1.trim() + ', ' : ''}
                                  {item.State1 ? item.State1.trim() + ', ' : ''}
                                  {item.Zip1 ? item.Zip1.trim() : ''}
                                </p>
                                <span>{item.ServiceType}</span>
                                {item.WorkStatus !== 'Unscheduled' &&
                                  item.WorkStatus !== 'Offered' && (
                                    <span>
                                      {item.FirstApptDate &&
                                      item.FromTime &&
                                      item.ToTime
                                        ? this.getTimeZone(
                                            item.FirstApptDate,
                                            item.FromTime,
                                            item.ToTime
                                          )
                                        : 'No Appointment Date'}
                                    </span>
                                  )}
                              </div>
                            );
                          }}
                          eventDrop={(info) => {
                            let apptDetails = {
                              first_appt_date: info.event.start,
                              from_time: info.event.start,
                              to_time: info.event.end,
                              id: Number(info.event._def.publicId),
                            };
                            let technicianId = null;
                            let multitech = null;
                            let vendorid = null;
                            if (
                              info.newResource &&
                              info.newResource._resource
                            ) {
                              technicianId = info.newResource._resource.id;
                              multitech =
                                info.newResource._resource.extendedProps
                                  .tech_function;
                              vendorid =
                                info.newResource._resource.extendedProps
                                  .vendorid;
                            } else {
                              technicianId =
                                info.event._def.extendedProps.technicainid;
                              multitech =
                                info.event._def.extendedProps.tech_function;
                              vendorid = info.event._def.extendedProps.vendorid;
                            }
                            this.updateAppointment(
                              apptDetails,
                              technicianId,
                              multitech,
                              vendorid
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showFilterPopup && (
              <Modal
                className={'filter-popup'}
                size="lg"
                centered
                onHide={this.onFilterClose}
                show={showFilterPopup}
              >
                <div className="add-task-popup schedule-filter-popup">
                  <Modal.Header className="col-12">
                    <Modal.Title id="md-filder" className="w-100">
                      <div className="float-right">
                        <Button
                          variant="secondary"
                          className="saveBtns"
                          onClick={() => this.onFilterClose()}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="saveBtns l-m-10"
                          onClick={this.onFilterSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="table-responsive"
                      style={{
                        paddingLeft: '40px',
                        maxHeight: '450px',
                        overflow: 'auto',
                      }}
                    >
                      <table className="w-100">
                        <thead>
                          <tr className="">
                            <th>Technicians</th>
                            <th>Vendor ID</th>
                            <th>Work Order Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <ul className="pl-0">
                                {technicians.map((item, i) => (
                                  <li
                                    key={i}
                                    className="d-flex filter-items cp"
                                    onClick={() => {
                                      this.onSelectFilterData(
                                        'selectedTechnicians',
                                        item.id
                                      );
                                    }}
                                  >
                                    <span>
                                      <input
                                        type="checkbox"
                                        id={`technician${item.id}`}
                                        onChange={() => {}}
                                        checked={
                                          filterTempSelction.selectedTechnicians.includes(
                                            item.id
                                          )
                                            ? 'checked'
                                            : ''
                                        }
                                      />
                                    </span>
                                    <span>&nbsp;{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td>
                              <ul className="pl-0">
                                {source.map((item, i) => (
                                  <li
                                    key={i}
                                    className="d-flex filter-items cp"
                                    onClick={() => {
                                      this.onSelectFilterData(
                                        'selectedSource',
                                        item.id
                                      );
                                    }}
                                  >
                                    <span>
                                      <input
                                        type="checkbox"
                                        id={`technician${item.id}`}
                                        onChange={() => {}}
                                        checked={
                                          filterTempSelction.selectedSource.includes(
                                            item.id
                                          )
                                            ? 'checked'
                                            : ''
                                        }
                                      />
                                    </span>
                                    <span>&nbsp;{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td>
                              <ul className="pl-0">
                                {serviceTypes.map((item, i) => (
                                  <li
                                    key={i}
                                    className="d-flex filter-items cp"
                                    onClick={() => {
                                      this.onSelectFilterData(
                                        'selectedServiceTypes',
                                        item.name
                                      );
                                    }}
                                  >
                                    <span>
                                      <input
                                        type="checkbox"
                                        id={`technician${item.id}`}
                                        onChange={() => {}}
                                        checked={
                                          filterTempSelction.selectedServiceTypes.includes(
                                            item.name
                                          )
                                            ? 'checked'
                                            : ''
                                        }
                                      />
                                    </span>
                                    <span>&nbsp;{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            )}
          </div>
        </div>
        <CompleteJobPopup
          WorkStatus={jobStatus}
          workCompleteJob={workCompleteJob}
          callbackfun={async () => {
            await this.getJobs();
            await this.getAllJobs();
          }}
          popupClose={() =>
            this.setState({ workCompleteJob: !workCompleteJob })
          }
          workorderId={workOrderId}
          custId={customerId}
        />
      </AUX>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetChatBoxToggle: (data) => dispatch(SetChatBoxToggle(data)),
  SetChatNotificationCount: (data) => dispatch(SetChatNotificationCount(data)),
  SetCalenderDate: (data) => dispatch(SetCalenderDate(data)),
  SetChatRecieverData: (data) => dispatch(SetChatRecieverData(data)),
});

const mapStateToProps = (state) => ({
  toggle: state.auth.toggle,
  chatBoxToggle: state.auth.chatBoxToggle,
  calDate: state.auth.calDate,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Schedule)
);

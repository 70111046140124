import React, { Component } from 'react';
import swal from 'sweetalert';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import customFunctions from './../../helper/customFunctions';

let inputChangedPromise;

export default class ChatUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerData: [],
      technicianData: [],
      adminData: [],
      userId: [],
      keyword: '',
      roleId: null,
    };
  }

  async componentDidMount() {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    await this.setState({
      userId: userdata.id,
      roleId: userdata.role_id,
    });

    this.getTechnicians();
    this.getCustomers();
    this.getAdmin();
  }
  getTechnicians = async () => {
    const { userId, keyword } = this.state;
    let endPoint = `${
      Api.technicianUrl
    }?skip=${0}&limit=${100}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const allTechData = data.data.data.filter((e) => {
        return e.userid !== userId;
      });
      this.setState({
        technicianData: allTechData,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getCustomers = async () => {
    const { userId, keyword } = this.state;
    let endPoint = `${
      Api.customerUrl
    }?skip=${0}&limit=${100}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const allCustData = data.data.data.filter((e) => {
        return e.userid !== userId;
      });
      this.setState({
        customerData: allCustData,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getAdmin = async () => {
    const { userId, keyword } = this.state;
    let endPoint = `${Api.adminUrl}?skip=${0}&limit=${100}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const allAdminData = data.data.filter((e) => {
        return e.userid !== userId;
      });
      this.setState({
        adminData: allAdminData,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onsearchSubmit = async (search, type = '') => {
    const { keyword, roleId } = this.state;
    search = search && search.trim();
    if (search === keyword) {
      return;
    }
    if (inputChangedPromise) {
      clearTimeout(inputChangedPromise);
    }
    inputChangedPromise = setTimeout(
      type === 'technician' && roleId !== 4
        ? this.getTechnicians.bind(this)
        : this.getCustomers.bind(this),
      1000
    );
  };

  getShortName = (name) => {
    let newName = name
      ? name.charAt(0) +
        (name.split(' ').length >= 1
          ? name.split(' ')[1]
            ? name.split(' ')[1][0]
            : ''
          : '')
      : '';
    return newName;
  };

  render() {
    const { technicianData, customerData, adminData, keyword, roleId } =
      this.state;
    return (
      <div className="card chatt">
        {(this.props.onSelect === 'technician' ||
          (roleId === 4 && !this.props.onSelect)) && (
          <input
            type="text"
            onChange={(e) =>
              this.setState({ keyword: e.target.value }, () =>
                this.onsearchSubmit(keyword, 'technicians')
              )
            }
            className="form-control"
            placeholder="Search for technicians"
          />
        )}

        {roleId !== 4 &&
          (this.props.onSelect === 'customers' || !this.props.onSelect) && (
            <input
              type="text"
              onChange={(e) =>
                this.setState({ keyword: e.target.value }, () =>
                  this.onsearchSubmit(keyword, 'customers')
                )
              }
              className="form-control"
              placeholder="Search for customers"
            />
          )}
        <div
          className={this.props.onSelect ? 'card-body' : ''}
          style={{ maxHeight: '300px', overflowY: 'scroll', paddingTop: '0' }}
        >
          <div className="inbox-widget">
            {this.props.onSelect === 'customers' &&
            customerData &&
            customerData.length
              ? customerData.map((item, key) => {
                  item.shortName =
                    item.CustomerName && this.getShortName(item.CustomerName);
                  let custdata = {
                    id: item.ID,
                    roleid: 4,
                    image: '',
                    name: item.CustomerName,
                    userid: item.userid,
                  };
                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onCallbackBox(custdata);
                        this.props.onUserSelectCallback('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.CustomerName
                                ? `${item.CustomerName}`
                                : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}

            {this.props.onSelect === 'technician' &&
            technicianData &&
            technicianData.length
              ? technicianData.map((item, key) => {
                  item.shortName = item.name && this.getShortName(item.name);
                  let techdata = {
                    id: item.id,
                    roleid: 5,
                    image: '',
                    name: item.name,
                    userid: item.userid,
                  };
                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onCallbackBox(techdata);
                        this.props.onUserSelectCallback('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.name ? `${item.name}` : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}
            {this.props.onSelect === 'admin' && adminData && adminData.length
              ? adminData.map((item, key) => {
                  let fname = item.first_name
                    ? customFunctions.capitalizeFirstLetter(item.first_name)
                    : '';
                  let lname = item.last_name
                    ? customFunctions.capitalizeFirstLetter(item.last_name)
                    : '';
                  let fullname = fname + ' ' + lname;
                  let matches = fullname && fullname.match(/\b(\w)/g); // ['J','S','O','N']
                  const shortName = matches.join('');
                  let adminAllData = {
                    id: item.id,
                    roleid: 1,
                    image: '',
                    name: fullname,
                    userid: item.userid,
                  };
                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onCallbackBox(adminAllData);
                        this.props.onUserSelectCallback('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {shortName}
                              </span>
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {fullname ? fullname : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}
          </div>
        </div>
      </div>
    );
  }
}

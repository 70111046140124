import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

class TechnicianParts extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      columns: [],
      partsData: [],
      partId: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isShowLoader: false,
      roleId: null,
      technicianId: null,
      isWriteAllowed: false,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'allocatedparts-accessallocatedparts'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'allocatedparts-accessallocatedparts',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Description',
        field: 'description',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Quantity',
        field: 'quantity',
        sort: 'disabled',
        width: 150,
      },
      // {
      //   label: 'Action',
      //   field: 'action',
      //   sort: 'disabled',
      // },
    ];

    if (userdata.role_id === 1) {
      columns[4] = {
        label: 'Balance Stock',
        field: 'balancestock',
        sort: 'disabled',
        width: 150,
      };
      columns[5] = {
        label: 'Is Reorder',
        field: 'isreoder',
        sort: 'disabled',
        width: 150,
      };
    }

    if (
      this.props.fromComponent !== 'viewTechnician' &&
      (userdata.role_id !== 5 || userdata.role_id !== 6)
    ) {
      columns[6] = {
        label: 'Technician',
        field: 'technicianname',
        sort: 'disabled',
        width: 150,
      };
    }
    if (this.props.fromComponent !== 'viewTechnician') {
      customFunctions.setTitle('Allocated Parts');
    }

    this.setState(
      { roleId: userdata.role_id, technicianId: userdata.login_id, columns },
      () => {
        this.getTechnicianParts();
      }
    );
  };

  setTableData = () => {
    const { partsData, currentPage, limit } = this.state;
    const page = currentPage || 1;
    const rows = [];
    partsData &&
      partsData.map((part, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          name: part.name,
          description: part.description,
          quantity: part.quantity,
          balancestock: part.balancestock,
          technicianname: part.technicainname,
          isreoder: part.isreoder ? 'Yes' : 'No',
        });
        return part;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getTechnicianParts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword, technicianId, roleId } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    this.setState({
      isShowLoader: true,
    });
    let endPoint = `${Api.technicianPartsUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    if (
      technicianId &&
      (roleId === 5 || roleId === 6) &&
      this.props.fromComponent !== 'viewTechnician'
    ) {
      endPoint += `&technicianid=${technicianId}`;
    }
    if (
      this.props.technicianId &&
      this.props.fromComponent === 'viewTechnician'
    ) {
      endPoint += `&technicianid=${this.props.technicianId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          partsData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getTechnicianParts();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getTechnicianParts();
    });
  };

  downloadCSV = async () => {
    const { keyword } = this.state;
    const endPoint = `${Api.downloadgetTechnicianPartsCsvUrl}?search=${keyword}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      isShowLoader,
      columns,
    } = this.state;
    const { fromComponent } = this.props;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div
          className={
            fromComponent !== 'viewTechnician' ? 'page-content-wrapper' : ''
          }
        >
          <div className="">
            <HelmetComponent title="All Allocated Parts" />
            {showDelete && (
              <SweetAlert
                title="Are you sure, Do you want to delete this part ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deletePart();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, partId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {fromComponent !== 'viewTechnician' && (
                      <form onSubmit={this.onSearchSubmit}>
                        <div className="form-group row">
                          <div className="col-sm-3">
                            <input
                              className="form-control"
                              value={keyword}
                              type="text"
                              placeholder="Search by part name"
                              id="example-text-input"
                              onChange={(e) => {
                                this.setState({ keyword: e.target.value });
                              }}
                              required={true}
                            />
                          </div>
                          <div className="col-sm-5">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Search
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.clearSearch();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-sm-4">
                            {rows && rows.length > 0 && (
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light float-right"
                                onClick={() => {
                                  this.downloadCSV();
                                }}
                              >
                                <i className="mdi mdi-download"></i> CSV
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getTechnicianParts}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(TechnicianParts);

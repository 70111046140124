// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import EditMainAccount from '../Workorder/editMainAccount';
import EditSubAccountData from '../Workorder/editSubAccountData';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

class AddNewExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expense_type: 1,
      poId1: '',
      po: '',
      orderId: null,
      poDate: new Date(),
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      ExpenseDesc: '',
      startDate: '',
      mainAcc: [],
      subAccList: [],
      title: '',
      isShowLoader: false,
      clearSubByMain: false,
      mainAccountName: '',
      subAccountName: '',
      technician: '',
      dispatcher: '',
      toggleType: 'technician',
      role: 5,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addnewexpense'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addnewexpense',
      'write'
    );
    if (writeAllowed) {
      this.setState({ disabledAll: false });
    } else {
      this.setState({ disabledAll: true });
    }
    customFunctions.setTitle('Add New Expense');
    this.setState({ title: `Add New Expense` });
    // const orderId = this.props.match.params.id;
    await this.getMainAccountData();
    // await this.getSubAccountData();
    await this.getPoId();
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  validate = () => {
    const { poDate, mainAccount, subAccount, poAmount } = this.state;

    let poDateErr = '',
      mainAccountErr = '',
      subAccountErr = '',
      poAmountErr = '',
      ExpenseDescErr = '';

    let isValid = true;

    if (poDate === null) {
      poDateErr = 'Order Date is required';
      isValid = false;
    }
    if (mainAccount === '') {
      mainAccountErr = 'Main Account is required';
      isValid = false;
    }
    if (subAccount === '') {
      subAccountErr = 'Sub Account is required';
      isValid = false;
    }

    if (poAmount === '') {
      poAmountErr = 'PO Amount is required';
      isValid = false;
    }

    this.setState({
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
      ExpenseDescErr,
    });

    if (isValid) {
      this.createPurchaseOrder();
    }
  };

  createPurchaseOrder = async (e) => {
    const {
      poDate,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      po,
      expense_type,
      dispatcher,
      technician,
    } = this.state;

    const postData = {
      expense_code: po,
      po_date: new Date(poDate).toISOString(),
      main_account: mainAccount,
      sub_account: subAccount,
      po_amount: poAmount,
      description: ExpenseDesc,
      expense_type,
    };
    if (dispatcher) {
      postData.technician = dispatcher.id;
    }
    if (technician) {
      postData.technician = technician.id;
    }
    const endPoint = `${Api.createPurchaseOrder}`;

    const { data } = await AuthApi.postDataToServer(endPoint, postData);
    if (data) {
      swal('New expense added successfully', '', 'success');
      this.flushAllData();
      setTimeout(() => {
        var $this = this;
        $this.props.history.push(`/orderDetails`);
      }, 100);
      return;
    }
  };

  flushAllData = async () => {
    this.setState({
      poDate: '',
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      workOrder: '',
      cstomer: '',
      ExpenseDesc: '',
      poId2: '',
      code: '',
      pieceEquipInst: '',
      technician: '',
      installerId: '',
      supplyHouse: '',
      equipInvoice: '',
      miscInvoice: '',
      miscPoAmount: '',
      equipPo: '',
      equipPoAmount: '',
      miscPo: '',
      purchaseDesc: '',
    });
  };

  getPoId = async () => {
    const endPoint = `${Api.createPurchaseOrder}/po_id`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        po: data.data.ExpenseCode,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  getMainAccountData = async (searchKeyword) => {
    this.setState({ isShowLoader: true });
    const endPoint = searchKeyword
      ? `${Api.AddListTypeUrl}?search=${searchKeyword}`
      : `${Api.AddListTypeUrl}`;
    // const endPoint = `${Api.AddListTypeUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      data.data.data.map((dt) => {
        dt.name = dt.ListType;
      });
      this.setState({
        isShowLoader: false,
        mainAcc: data.data.data,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getSubAccountData = async (mainAccount, searchKeyword) => {
    // this.setState({ isShowLoader: true });
    const endPoint = searchKeyword
      ? `${Api.ListItemByType}?type_id=${mainAccount}&search=${searchKeyword}`
      : `${Api.ListItemByType}?type_id=${mainAccount}`;

    // const endPoint = `${Api.ListItemByType}?type_id=${mainAccount}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      data.data.data.map((dt) => {
        dt.name = dt.ListItem;
      });
      this.setState(
        {
          subAccList: data.data.data,
        },
        () => this.forceUpdate()
      );
    }
  };

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  onRegularExpense = () => {
    this.setState({
      expense_type: '1',
    });
  };

  onPOExpense = () => {
    this.setState(
      {
        expense_type: '0',
      },
      () => {}
    );
    var $this = this;
    $this.props.history.push('/purchase');
  };

  render() {
    const {
      poAmount,
      mainAccount,
      subAccount,
      poDate,
      ExpenseDesc,
      po,
      title,
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
      mainAcc,
      subAccList,
      isShowLoader,
      clearSubByMain,
      mainAccountName,
      subAccountName,
      technician,
      dispatcher,
      toggleType,
      role,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button
                            onClick={this.onRegularExpense}
                            style={{
                              backgroundColor:
                                this.state.expense_type === 1 ? 'black' : '',
                            }}
                            className="btn btn-outline-secondary"
                          >
                            Regular Expenses
                          </button>
                        </div>
                        <div className="col-sm-3">
                          <button
                            onClick={this.onPOExpense}
                            style={{
                              backgroundColor:
                                this.state.expense_type === 0 ? 'black' : '',
                            }}
                            className="btn btn-outline-secondary"
                          >
                            PO Expenses
                          </button>
                        </div>
                      </div>
                      <br></br>

                      <div className="form-group row">
                        <label
                          htmlFor="expense#"
                          className="col-sm-2 col-form-label"
                        >
                          Expense#
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={po}
                            placeholder="(New)"
                            type="text"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ po: e.target.value });
                            }}
                          />
                        </div>
                        <label
                          htmlFor="technician"
                          className="col-sm-2 col-form-label"
                        >
                          Select Type
                        </label>
                        <div className="col-sm-4">
                          <Radio
                            checked={toggleType == 'technician'}
                            onChange={(e) => {
                              this.setState({
                                toggleType: e.target.value,
                                role: 5,
                              });
                            }}
                            value="technician"
                            color="default"
                            name="radio-button-demo"
                          />
                          Technician
                          <Radio
                            checked={toggleType == 'dispatcher'}
                            onChange={(e) => {
                              this.setState({
                                toggleType: e.target.value,
                                role: 3,
                              });
                            }}
                            value="dispatcher"
                            color="default"
                            name="radio-button-demo"
                          />
                          Dispatcher
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="expensedate"
                          className="col-sm-2 col-form-label"
                        >
                          Expense Date
                        </label>
                        <div className="col-sm-4">
                          <DatePicker
                            className="form-control"
                            selected={poDate}
                            onChange={(e) => {
                              this.setState({ poDate: e });
                            }}
                            name="startDate"
                            dateFormat="MM/dd/yyyy"
                          />

                          <span id="err">{poDateErr}</span>
                        </div>
                        {toggleType == 'technician' ? (
                          <>
                            <label
                              htmlFor="technician"
                              className="col-sm-2 col-form-label"
                            >
                              Technician
                            </label>
                            <div className="col-sm-4">
                              {toggleType == 'technician' && (
                                <SelectAsyncPaginate
                                  onSelectChange={(e) => {
                                    this.setState(
                                      {
                                        technician: e
                                          ? { id: e.id, name: e.name }
                                          : '',
                                      },
                                      () => {
                                        this.forceUpdate();
                                      }
                                    );
                                  }}
                                  className="w-100"
                                  isSearch={true}
                                  clearCacheOnID={role}
                                  isClearable={technician}
                                  value={technician}
                                  clearSelectedByParent={dispatcher}
                                  apiname="technician"
                                  endpoint={
                                    Api.technicianUrl + `?role=${role}&search=`
                                  }
                                  selectPlaceholder="Select Technician"
                                />
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <label
                              htmlFor="dispatcher"
                              className="col-sm-2 col-form-label"
                            >
                              Dispatcher
                            </label>
                            <div className="col-sm-4">
                              {toggleType == 'dispatcher' && role == 3 && (
                                <SelectAsyncPaginate
                                  onSelectChange={(e) => {
                                    this.setState(
                                      {
                                        dispatcher: e
                                          ? { id: e.id, name: e.name }
                                          : '',
                                      },
                                      () => {
                                        this.forceUpdate();
                                      }
                                    );
                                  }}
                                  className="w-100"
                                  isSearch={true}
                                  clearCacheOnID={role}
                                  isClearable={dispatcher}
                                  value={dispatcher}
                                  clearSelectedByParent={technician}
                                  apiname="dispatcher"
                                  endpoint={
                                    toggleType == 'dispatcher' &&
                                    Api.technicianUrl + `?role=${role}&search=`
                                  }
                                  selectPlaceholder="Select Dispatcher"
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Main Account<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-3">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState(
                                {
                                  mainAccount: e ? e.ID : null,
                                  mainAccountName: e ? { name: e.name } : '',
                                  clearSubByMain: true,
                                },
                                () => {
                                  if (e) this.getSubAccountData(e.ID, '');
                                }
                              );
                            }}
                            className="w-100"
                            isSearch={true}
                            isClearable={mainAccount}
                            value={mainAccountName}
                            apiname="listtype"
                            endpoint={Api.AddListTypeUrl + `?search=`}
                            selectPlaceholder="Select Main Account"
                          />

                          {/* <select
                            className="form-control"
                            value={mainAccount}
                            onChange={(e) => {
                              this.setState({ mainAccount: e.target.value });
                              this.getSubAccountData(e.target.value);
                            }}
                          >
                            <option value="">Select Main account </option>
                            {mainAcc.map((name) => (
                              <option
                                value={name.ID}
                              >{`${name.ListType}`}</option>
                            ))}
                          </select> */}
                          <span id="err">{mainAccountErr}</span>
                        </div>
                        <div className="col-sm-1">
                          <button
                            className="btn btn-light"
                            data-toggle="modal"
                            data-target="#mainaccount"
                          >
                            <a
                              href="/"
                              className=""
                              data-toggle="modal"
                              data-target="#mainaccount"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Edit />
                            </a>
                          </button>
                        </div>
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Sub Account<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-3">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState({
                                subAccount: e ? e.ID : null,
                                clearSubByMain: false,
                                subAccountName: e ? { name: e.name } : '',
                              });
                            }}
                            className="w-100"
                            isSearch={true}
                            isClearable={subAccount && !clearSubByMain}
                            value={subAccountName}
                            clearCacheOnID={mainAccount}
                            clearSelectedByParent={clearSubByMain}
                            apiname="listitem"
                            endpoint={
                              Api.ListItemByType +
                              `?type_id=${mainAccount}&search=`
                            }
                            selectPlaceholder="Select subaccount"
                          />

                          {/* <select
                            className="form-control"
                            value={subAccount}
                            onChange={(e) => {
                              this.setState({ subAccount: e.target.value });
                            }}
                          >
                            <option value="">Select Sub Account</option>
                            {subAccList &&
                              subAccList.map((name) => (
                                <option
                                  value={name.ID}
                                >{`${name.ListItem}`}</option>
                              ))}
                          </select> */}
                          <span id="err">{subAccountErr}</span>
                        </div>
                        <div className="col-sm-1">
                          <button
                            className="btn btn-light"
                            data-toggle="modal"
                            data-target="#subaccount"
                          >
                            <a
                              href="/"
                              className=""
                              data-toggle="modal"
                              data-target="#subaccount"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Edit />
                            </a>
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Expense Amount<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={poAmount}
                              placeholder="$0.00"
                              type="text"
                              id="example-text-input"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({ poAmount: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <span id="err">{poAmountErr}</span>
                        </div>
                        {/* <div className="col-sm-1">
                          <button className="btn btn-light">
                            <Edit />
                          </button>
                        </div> */}
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Expense Description{' '}
                        </label>
                        <div className="col-10">
                          <textarea
                            className="form-control"
                            type="textarea"
                            placeholder="Expense description goes here"
                            id="example-text-input"
                            style={{ resize: 'none' }}
                            value={ExpenseDesc}
                            rows="4"
                            cols="90"
                            onChange={(e) => {
                              this.setState({ ExpenseDesc: e.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-3 ">
                          <button
                            type="submit"
                            onClick={this.validate}
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.flushAllData();
                              this.props.history.push('/orderDetails');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditMainAccount
          getMainAccountDataCallback={() => {
            this.getMainAccountData();
          }}
          getSubAccountDataCallback={(id) => {
            if (id) this.getSubAccountData(id, '');
          }}
        />
        <EditSubAccountData />
      </AUX>
    );
  }
}

export default withRouter(AddNewExpense);

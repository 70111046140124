import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Name',
    field: 'technician_name',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Roles',
    field: 'roles',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Percentage(%)',
    field: 'Percentage',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'CBR Amount($)',
    field: 'CbrAmount',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'CBR Percentage(%)',
    field: 'CbrPercentage',
    sort: 'disabled',
    width: 180,
  },
  {
    label: 'Daily Rate($)',
    field: 'DailyRate',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 150,
  },
];

class Technicians extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      technicians: [],
      rows: [],
      technicianId: null,
      showDelete: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      isWriteAllowed: false,
      addNewAllowed: false,
      isShowLoader: false,
      urlParam: 'technicians',
      navSkip: null,
      navCurrentPage: null,
      roleId: null,
      userRole: null,
      filterValue: '',
      filterdata: [
        {
          value: 5,
          label: 'Technician',
        },
        {
          value: 3,
          label: 'Dispatcher',
        },
        {
          value: 6,
          label: 'Both',
        },
      ],
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
    });
    let url = window.location.href;
    let writeAllowed = false;
    customFunctions.setTitle('Team Members');
    if (url.split('/')[3] === 'dispatchers') {
      this.setState({ urlParam: 'dispatchers' });
      let isAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'dispatchers-accessdispatchers'
      );
      if (!isAllowed) {
        this.props.history.push('/dashboard');
        return;
      }
      let addNewAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'addnewrecord-adddispatcher',
        'write'
      );
      if (addNewAllowed) this.setState({ addNewAllowed: true });
      writeAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'dispatchers-accessdispatchers',
        'write'
      );
    } else {
      let isAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'technician-accesstechnician'
      );
      if (!isAllowed) {
        this.props.history.push('/dashboard');
        return;
      }
      let addNewAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'addnewrecord-addtechnician',
        'write'
      );
      if (addNewAllowed) this.setState({ addNewAllowed: true });
      writeAllowed = await customFunctions.isAllowedRoute(
        'submodule',
        'technician-accesstechnician',
        'write'
      );
    }
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getTechnicians();
        }
      );
    } else {
      await this.getTechnicians();
    }
  };

  getTechnicians = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword, urlParam, navSkip, navCurrentPage, filterValue } =
      this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    const endPoint = `${
      Api.technicianUrl
    }?skip=${skip}&limit=${limit}&search=${keyword}&role=${
      filterValue ? filterValue.value : '5,6,3'
    }`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      this.setState(
        {
          technicians: data.data.data,
          totalCount: data.data.total_count,
          isShowLoader: false,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  changeTechnicianStatus = async (technician, event) => {
    var isChecked = event.target.checked;
    const { currentPage, urlParam } = this.state;
    const payload = {
      role: urlParam === 'technicians' ? '5,6' : 3,
      technician_id: technician.id,
      percentage: technician.percentage,
      daily_rate: technician.daily_rate,
      technician_name: technician.name,
      status: isChecked ? '1' : '0',
      signature: technician.signature,
    };

    const { data, message } = await AuthApi.putDataToServer(
      Api.AddtechnicianUrl,
      payload
    );
    if (data && data.success !== false) {
      swal(`Team member status changed successfully`, '', 'success');
      this.getTechnicians(currentPage);
    } else {
      alert(message);
    }
  };

  downloadCSV = async () => {
    const { keyword, filterValue, urlParam } = this.state;
    let endPoint = `${Api.downloadTechnicianCsvUrl}?search=${keyword}&role=${
      filterValue ? filterValue.value : '5,6,3'
    }`;

    window.open(endPoint, '_blank');
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });

    const {
      technicians,
      limit,
      currentPage,
      isWriteAllowed,
      urlParam,
      roleId,
    } = this.state;
    const page = npage;
    const rows = [];
    technicians &&
      technicians.length > 0 &&
      technicians.map((technician, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          technician_name: technician.name,
          Percentage: technician.percentage,
          CbrAmount: technician.total_cbr,
          CbrPercentage: technician.cbrpercentage,
          DailyRate: technician.daily_rate,
          roles:
            technician.role == 5
              ? 'Technician'
              : technician.role == 3
              ? 'Dispatcher'
              : 'Both',
          userRole: technician.role,
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                disabled={!isWriteAllowed}
                id={`switch${technician.id}`}
                checked={Number(technician.status) ? 'checked' : ''}
                switch="info"
                onChange={(event) => {
                  this.changeTechnicianStatus(technician, event);
                }}
              />
              <label
                htmlFor={`switch${technician.id}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          actions: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className="pl-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/edit-${
                      [5, 6].includes(technician.role)
                        ? 'technician'
                        : 'dispatcher'
                    }/${technician.id}`,
                    {
                      technicianDetails: technician,
                      type: 'technician',
                      fromComponent: 'technician',
                      detail: technician,
                      path: '/technicians',
                      paginationObj: {
                        navSkip: skip,
                        navCurrentPage: npage,
                      },
                      reportType: [5, 6].includes(technician.role)
                        ? 'technician'
                        : 'dispatcher',
                    }
                  );
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  title={`Edit ${
                    [5, 6].includes(technician.role)
                      ? 'Technician'
                      : 'Dispatcher'
                  }`}
                ></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    showDelete: true,
                    technicianId: technician.id,
                  });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title={`Delete ${
                    [5, 6].includes(technician.role)
                      ? 'Technician'
                      : 'Dispatcher'
                  }`}
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return technician;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deteleTechnician = async () => {
    const { technicianId, urlParam } = this.state;
    const { data } = await AuthApi.deleteDataFromServer(
      `${Api.AddtechnicianUrl}?technician_id=${technicianId}`
    );
    if (data) {
      this.setState({ showDelete: false, technicianId: null }, () => {
        swal(`Team member deleted successfully`, '', 'success').then(() => {
          this.getTechnicians(1);
        });
      });
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getTechnicians();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getTechnicians();
    });
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      addNewAllowed,
      isShowLoader,
      urlParam,
      navSkip,
      navCurrentPage,
      roleId,
      filterdata,
      filterValue,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent
              title={`${
                urlParam === 'dispatchers'
                  ? 'All Dispatchers'
                  : 'All Technicians'
              }`}
            />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title={`Are you sure, do you want to delete ?`}
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deteleTechnician();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, technicianId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            value={keyword}
                            type="text"
                            placeholder="Search by name"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ keyword: e.target.value });
                            }}
                            required={true}
                          />
                        </div>
                        <div className="col-sm-8 col-md-5">
                          <div className="row">
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-sm-6">
                              <ReactSelect
                                value={filterValue}
                                placeholder="Select"
                                onChange={(e) => {
                                  if (e) {
                                    let type = {};
                                    type.label = e.label;
                                    type.value = e.value;
                                    this.setState({ filterValue: type }, () => {
                                      this.getTechnicians();
                                      this.forceUpdate();
                                    });
                                  } else {
                                    this.setState({ filterValue: '' }, () => {
                                      this.getTechnicians();
                                    });
                                  }
                                }}
                                isClearable={true}
                                options={filterdata}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 text-right">
                          {rows && rows.length > 0 && (
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => {
                                this.downloadCSV();
                              }}
                            >
                              <i className="mdi mdi-download"></i> CSV
                            </button>
                          )}
                          {addNewAllowed && (
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light m-l-10"
                              onClick={() => {
                                this.props.history.push('/add-technician');
                              }}
                            >
                              Add Team Member
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getTechnicians
                                )
                              : this.getTechnicians
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(Technicians);

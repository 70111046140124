import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import './style.css';
import PlaceSearch from '../PlaceSearch/index';
import Geocode from 'react-geocode';
import { setKey } from 'react-geocode';
class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedAddress: '',
      location: this.props.location || '',
    };
  }

  componentDidMount() {
    // Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    setKey(process.env.REACT_APP_MAP_KEY);
    this.getAddressFromLatLog(this.props.location);
  }

  componentWillReceiveProps(props) {
    if (props.location) {
      this.getAddressFromLatLog(props.location);
    } else {
      this.setState({ formattedAddress: '' });
    }
  }

  setAddress = async (place) => {
    var selectedPlace = place;
    this.setState(
      {
        formattedAddress: place.formattedAddress,
        geoLocation: place.geoLocation,
      },
      () => {
        if (this.mapRef) {
          this.mapRef.map.setZoom(19);
          this.mapRef.map.panTo(selectedPlace.geoLocation);
        }
      }
    );
    let $this = this;
    if (this.props.callback) {
      Geocode.fromAddress(place.formattedAddress).then(
        (response) => {
          $this.props.callback(response.results[0].geometry.location);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  getAddressFromLatLog = async (location) => {
    if (!location) return '';
    var $this = this;
    Geocode.fromLatLng(location.lat, location.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        $this.setState({ formattedAddress: address, location });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  getLatLogFromAddress = async (location) => {
    if (!location) return '';
    var $this = this;
    Geocode.fromAddress(location).then(
      (response) => {
        $this.setState({ location: response.results[0].geometry.location });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    let { location, google, onMapClick } = this.props;
    const { formattedAddress } = this.state;
    const restMapProps = {};
    if (location && location.lat && location.lng) {
      restMapProps.center = location;
    }
    // location = this.state.location ?  this.state.location : location;
    return (
      <div className="">
        <div className="">
          <div className="card-body1">
            <div className="map-wrapper">
              <PlaceSearch
                classes="form-control"
                placeValue={formattedAddress}
                onPlaceSelect={(place) => {
                  this.setAddress(place);
                  this.getLatLogFromAddress(place);
                }}
                google={google}
                onValueChange={(val, loc = {}) => {
                  this.setState(
                    { formattedAddress: val, geoLocation: loc },
                    () => {
                      // this.setAddress(val);
                      this.getLatLogFromAddress(val);
                    }
                  );
                }}
              />
              <Map
                google={google}
                ref={(r) => (this.mapRef = r)}
                onClick={onMapClick}
                zoom={19}
                {...restMapProps}
                style={{ height: '225px', width: '100%' }}
              >
                {location && location.lat && location.lng && (
                  <Marker position={location} />
                )}
              </Map>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
})(LocationMap);

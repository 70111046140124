import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import NotePopup from './notePopup';

class RecordingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      recordData: [],
      rows: [],
      columns: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      recordingId: null,
      id: null,
      type: null,
      isWriteAllowed: false,
      isShowLoader: false,
      isPlaying: false,
      isCollection: false,
      recordNote: '',
      noteToggle: false,
      recordingDetails: '',
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'recording-accessrecording'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'recording-accessrecording',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    if (this.props.fromComponent !== 'customer') {
      customFunctions.setTitle('All Recordings');
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      { roleId: userdata.role_id, loginId: userdata.login_id },
      async () => {
        await this.getRecording();
      }
    );
    let columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Agent Name',
        field: 'agentName',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Agent Number',
        field: 'agentNumber',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Call Type',
        field: 'callType',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Customer Name',
        field: 'custName',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Customer Number',
        field: 'custNumber',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Disconnect Reason',
        field: 'dissconnectReason',
        sort: 'disabled',
        width: 180,
      },
      {
        label: 'Connect Time',
        field: 'connectTime',
        sort: 'disabled',
        width: 200,
      },
      {
        label: 'Disconnect Time',
        field: 'dissconnectTime',
        sort: 'disabled',
        width: 200,
      },
      {
        label: 'Payment Collection',
        field: 'isPaymentCollected',
        sort: 'disabled',
        width: 170,
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
        width: 120,
      },
    ];
    this.setState({ columns });
  };

  getRecording = async (currentPage) => {
    const { roleId, loginId } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.recording}?skip=${skip}&limit=${limit}&search=${keyword}`;
    if (roleId === 4) {
      endPoint += `&customer=${loginId}`;
    }
    if (roleId === 5) {
      endPoint += `&technician=${loginId}`;
    }
    if (
      this.props &&
      this.props.fromComponent === 'customer' &&
      this.props.customerId
    ) {
      endPoint = `${Api.recording}?skip=${skip}&limit=${limit}&search=${keyword}&customer_id=${this.props.customerId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          isShowLoader: false,
          recordData: [...data.data.data],
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  playpause = async (id, cindex) => {
    const { isPlaying, recordData } = this.state;
    recordData &&
      recordData.map((rec, i) => {
        if (i !== cindex) {
          rec.play = false;
          return;
        }
        if (i == cindex && !rec.play) {
          rec.play = true;
          return;
        }
        if (i == cindex && rec.play == true) {
          rec.play = false;
          return;
        }
        return rec;
      });
    this.setState({ isPlaying: !isPlaying, recordData }, () => {
      this.setTableData();
    });
    let cls = await document.getElementsByClassName('playAudio');
    for (let c of cls) {
      c.pause();
    }
    if (!recordData[cindex].play) {
      document.getElementById(id).pause();
    } else {
      document.getElementById(id).play();
    }
  };

  setTableData = () => {
    const { recordData, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    recordData &&
      recordData.length &&
      recordData.map((record, i) => {
        var connect = customFunctions.changeDateFormatMDY(
          record.connected_time
        );
        var dissconnect = customFunctions.changeDateFormatMDY(
          record.disconnected_time
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          agentName: record.agent_name || '',
          agentNumber: record.agent_number || '',
          callType: record.call_type || '',
          custName: record.CustomerName || '',
          custNumber: record.customer_number || '',
          dissconnectReason: record.disconnected_reason || '',
          connectTime: connect,
          dissconnectTime: dissconnect,
          isPaymentCollected: (
            <React.Fragment>
              <input
                type="checkbox"
                // disabled={record.ispaymentcollected}
                checked={record.ispaymentcollected}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    isCollection: true,
                    isChecked: e.target.checked,
                    recordingId: record.id,
                    recordNote: record.note,
                  });
                }}
              />
            </React.Fragment>
          ),
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.playpause(`audio-${i}`, i);
                }}
              >
                {record.play ? (
                  <i className="mdi mdi-pause-circle"></i>
                ) : (
                  <i className="mdi mdi-play-circle"></i>
                )}
              </a>
              <audio
                className="playAudio"
                id={`audio-${i}`}
                src={`${record.recording_url}`}
              ></audio>
              <a
                href={record.recording_url}
                className="pl-2"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <i className="mdi mdi-download" title="Download"></i>
              </a>
              <a
                href="/"
                className="l-m-10"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ noteToggle: true, recordingDetails: record });
                }}
              >
                <i className="mdi mdi-message-text-outline"></i>
              </a>
            </React.Fragment>
          ),
        });
        return record;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  updatePayment = async () => {
    const { isChecked, recordingId, recordNote } = this.state;
    const postData = {
      id: recordingId,
      ispaymentcollected: isChecked ? 1 : 0,
      note: recordNote || '',
    };

    const endPoint = Api.recording;

    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      this.setState({ isCollection: false, recordingId: null }, () => {
        this.getRecording();
      });
      swal('Payment collection is updated!', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getRecording();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getRecording();
    });
  };

  downloadCSV = async () => {
    const { keyword } = this.state;
    let endPoint = `${Api.recording}/recordingsCsv?search=${keyword}`;

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      columns,
      isShowLoader,
      recordData,
      isCollection,
      noteToggle,
      recordingDetails,
    } = this.state;
    return (
      <AUX>
        <div
          className={
            this.props.fromComponent === 'customer'
              ? ''
              : 'page-content-wrapper'
          }
        >
          <div
            className={
              this.props.fromComponent === 'customer' ? '' : 'container-fluid'
            }
          >
            <HelmetComponent title="Pending Work Orders Details" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {isCollection && (
              <SweetAlert
                title="Are you sure, to update payment collection ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.updatePayment();
                }}
                onCancel={() => {
                  this.setState({
                    recordingId: null,
                    isCollection: false,
                  });
                }}
              />
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here by customer and agent number"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6">
                        {recordData && recordData.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light float-right"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                      </div>
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      getData={this.getRecording}
                      noRecordsFoundLabel="No records found"
                      scrollX
                      maxHeight="350px"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getRecording}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {noteToggle && (
          <NotePopup
            noteToggle={noteToggle}
            recordingDetails={recordingDetails}
            popupClose={() => this.setState({ noteToggle: !noteToggle })}
            callbackfun={() => {
              this.getRecording();
            }}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(RecordingComponent);

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Table from '../../../components/Table/simpletable.component';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import StatusUpdatepopupComponent from './statusUpdatepopup.component';
import DispatcherStatusUpdate from './dispatcherStatusUpdate';

class PendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      pendingorders: [],
      rows: [],
      columns: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      orderId: null,
      id: null,
      type: null,
      showDelete: false,
      isWriteAllowed: false,
      isShowLoader: false,
      color: [],
      navSkip: null,
      navCurrentPage: null,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      updatesPopup: false,
      dispatherStatusPopup: false,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'pendingworkorders-accesspendingworkorders'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'pendingworkorders-accesspendingworkorders',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('Pending Work Orders Details');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        () => this.getPendingWorkOrder()
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id },
        async () => {
          await this.getPendingWorkOrder();
        }
      );
    }

    let columns = [
      {
        label: 'Sr. No',
        id: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Vendor ID',
        id: 'vendorid',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Recall',
        id: 'recall',
        sort: 'disabled',
        width: 80,
      },
      {
        label: 'Dispatch #',
        id: 'workorder',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Job Title',
        id: 'jobtitle',
        width: 150,
        sort: 'disabled',
      },
      {
        label: 'Date Recieved',
        id: 'dateRecieved',
        sort: 'disabled',
        width: 140,
      },
      {
        label: '1st Appt Date',
        id: 'FirstApptDate',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'H/O Name',
        id: 'ownerName',
        sort: 'disabled',
        width: 160,
      },
      // {
      //   label: 'Date Completed',
      //   id: 'completeDate',
      //   sort: 'disabled',
      //   width: 140,
      // },
      {
        label: 'Repair Description',
        id: 'RepairDescription',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Parts Needed',
        id: 'parts',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Most Recent Status Update',
        id: 'workStatus',
        sort: 'disabled',
        width: 205,
      },
      {
        label: 'Date of Most Recent Status Update',
        id: 'dateWorkStatusUpdate',
        sort: 'disabled',
        width: 205,
      },
      {
        label: 'Action',
        id: 'actions',
        sort: 'disabled',
        width: 80,
      },
    ];
    if (userdata.role_id === 4) {
      columns.splice(10, 10);
    }
    this.setState({ columns });
  };

  getPendingWorkOrder = async (currentPage) => {
    const { keyword, roleId, loginId, navSkip, navCurrentPage, pagination } =
      this.state;
    this.setState({ currentPage, isShowLoader: true });
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip
      ? navSkip
      : page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&search=${keyword}&isAuthRequired=0&pausedreason=2`;
    if (roleId == 4) {
      endPoint += `&customer=${loginId}`;
    }
    if (roleId == 5 || roleId == 6 || roleId == 3) {
      endPoint += `&technician=${loginId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };

      this.setState(
        {
          isShowLoader: false,
          pendingorders: data.data.data,
          pagination: newPagination,
          // totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { pendingorders, limit, roleId } = this.state;
    const page = npage;
    const rows = [];
    pendingorders &&
      pendingorders.length &&
      pendingorders.map((orders, i) => {
        orders.Created = customFunctions.changeDateFormatMomentMDY(
          orders.Created
        );
        orders.OrderCompleteDate =
          orders.OrderCompleteDate &&
          customFunctions.changeDateFormatMomentMDY(orders.OrderCompleteDate);
        orders.labortodate =
          orders.labortodate &&
          customFunctions.changeDateFormatMomentMDY(orders.labortodate);
        orders.WorkOrderDate = customFunctions.changeDateFormatMomentMDY(
          orders.WorkOrderDate
        );
        orders.FirstApptDate =
          orders.FirstApptDate &&
          customFunctions.changeDateFormatMomentMDY(orders.FirstApptDate);
        const mostUpdatedStatus =
          orders.partdata &&
          orders.partdata.length > 0 &&
          (orders.partdata[0].part_updated
            ? customFunctions.changeDateFormatMomentMDY(
                orders.partdata[0].part_updated
              )
            : orders.partdata[0].part_created
            ? customFunctions.changeDateFormatMomentMDY(
                orders.partdata[0].part_created
              )
            : null);
        const neededParts =
          orders.partdata &&
          orders.partdata.length > 0 &&
          orders.partdata[0].parts;

        rows.push({
          index: (page - 1) * limit + (i + 1),
          vendorid: orders.refid || '-',
          workorder: (
            <React.Fragment>
              <a
                style={{ color: '#034fa1' }}
                title="view order"
                href="/"
                className=""
                disabled={orders.WorkOrder ? orders.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    path: '/pending-orders',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {orders.WorkOrder || '-'}
              </a>
            </React.Fragment>
          ),
          recall: orders.recall ? 'Yes' : 'No',
          ownerName: orders.CustomerName || '-',
          dateRecieved: orders.Created || '-',
          completeDate: orders.OrderCompleteDate || '-',
          workOrderDate: orders.WorkOrderDate || '-',
          invoiceid: orders.InvoiceCode || '-',
          whatwasdone: orders.parts || '-',
          labortodate: orders.labortodate || '-',
          labortotal: orders.labortotal || '-',
          partcost: orders.partcost || '-',
          authoAmount: orders.AuthoAmount || '-',
          authoNumber: orders.AuthoNumber || '-',
          estAvgCost: orders.estAvgCost || '-',
          trueAvgCost: orders.trueAvgCost || '-',
          workStatus: [1, 3, 6].includes(roleId) ? (
            <>
              <a
                href="/"
                style={{ color: '#034fa1' }}
                title="view recent updates"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    orderId: orders.ID,
                    dispatherStatusPopup: true,
                  });
                }}
              >
                View
              </a>
            </>
          ) : (
            <>
              <a
                href="/"
                style={{ color: '#034fa1' }}
                title="view recent updates"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    orderId: orders.ID,
                    updatesPopup: true,
                  });
                }}
              >
                View
              </a>
            </>
          ),
          parts: neededParts || '-',
          RepairDescription: orders.RepairDescription || '-',
          jobtitle: orders.jobTitle || '-',
          FirstApptDate: orders.FirstApptDate || '-',
          color: orders.color || '-',
          dateWorkStatusUpdate: mostUpdatedStatus || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    path: '/pending-orders',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {' '}
                {roleId !== 4 ? (
                  <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
                ) : (
                  'View'
                )}
              </a>
              {(roleId == 1 || roleId == 2) && (
                <a
                  href="/"
                  className="px-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, orderId: orders.ID });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Order"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return orders;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteOrder = async () => {
    const { orderId, currentPage } = this.state;
    const { data } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        swal('Order deleted!', '', 'success').then(() => {
          this.getPendingWorkOrder(currentPage);
        });
      });
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getPendingWorkOrder();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getPendingWorkOrder();
    });
  };

  downloadCSV = async () => {
    const { keyword, roleId, loginId } = this.state;
    let endPoint = `${Api.downloadcsvAhsUrl}?search=${keyword}&isAuthRequired=0&pausedreason=2`;
    if (roleId == 4) {
      endPoint += `&customer=${loginId}`;
    }
    if (roleId == 5 || roleId == 6 || roleId == 3) {
      endPoint += `&technician=${loginId}`;
    }
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      showDelete,
      columns,
      isShowLoader,
      navSkip,
      navCurrentPage,
      pagination,
      updatesPopup,
      orderId,
      dispatherStatusPopup,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Pending Work Orders Details" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the order ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteOrder();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, orderId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by H/O name,Dispatch#"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6">
                        {rows && rows.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light float-right"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                      </div>
                    </div>

                    <Table
                      columns={columns}
                      rows={rows}
                      // bulkActions={actions}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                },
                                () => this.getPendingWorkOrder(pageNo)
                              )
                            : this.getPendingWorkOrder(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {updatesPopup && (
          <StatusUpdatepopupComponent
            updatesPopup={updatesPopup}
            popupClose={() => this.setState({ updatesPopup: !updatesPopup })}
            workorderId={orderId}
          />
        )}
        {dispatherStatusPopup && (
          <DispatcherStatusUpdate
            dispatherStatusPopup={dispatherStatusPopup}
            popupClose={() =>
              this.setState({ dispatherStatusPopup: !dispatherStatusPopup })
            }
            callbackfun={() => {
              this.getPendingWorkOrder();
            }}
            workorderId={orderId}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(PendingOrders);

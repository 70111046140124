import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

class ApproveEmploymentContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      sign_date: '',
      contractId: null,
      employmentcontractid: null,
      editType: false,
      title: '',
      contractName: '',
      contract_body: '',
      signUrl: null,
      technician_sign: null,
      isShowLoader: false,
      roleId: null,
      technicianApproved: false,
      technician_id: null,
    };
  }

  sigPad = {};

  saveSign = (e) => {
    e.preventDefault();
    const { technician_sign, roleId } = this.state;

    if (!technician_sign && roleId === 5) {
      if (
        this.sigPad &&
        this.sigPad.getTrimmedCanvas().width > 1 &&
        this.sigPad.getTrimmedCanvas().height > 1
      ) {
        this.setState(
          { signUrl: this.sigPad.getTrimmedCanvas().toDataURL('image/png') },
          () => {
            this.validateForm();
          }
        );
      } else {
        this.validateForm();
      }
    } else {
      this.validateForm();
    }
  };

  clearSign = () => {
    this.sigPad.clear();
    this.setState({ signUrl: null });
  };

  componentDidMount = async () => {
    // let writeAllowed = await customFunctions.isAllowedRoute('submodule', 'master-premaintenancecontract', 'write');
    // if(!writeAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });

    const contractId = this.props.match.params.contractId;
    const url_segment = this.props.match.url.split('/');
    this.setState({ contractId });
    if (url_segment && url_segment.includes('approve-employment-contract')) {
      this.setState({ editType: true });
    }

    if (contractId) {
      this.setState({ title: `Approve Employment Contract` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      if (
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.contractDetails
      ) {
        const contract = this.props.location.state.contractDetails;
        this.setState(
          {
            contractId: contract.contractid,
            employmentcontractid: contract.id,
            notes: contract.notes,
            technician_sign: contract.technician_sign,
            technician_id: contract.technician_id,
            signUrl: contract.technician_sign,
            sign_date: contract.sign_date,
            contractName: contract.contract_name,
            technicianApproved:
              contract.technician_sign && contract.technician_sign !== null
                ? true
                : false,
          },
          () => {
            this.getEmploymentContract();
          }
        );
      }
    }
  };

  getEmploymentContract = async () => {
    const { contractId } = this.state;
    let endPoint = `${Api.employmentContractUrl}?id=${contractId}`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        contract_body: data.data[0].contract_body,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validateForm = () => {
    const { signUrl, roleId } = this.state;

    let signUrlErr = '',
      isValid = true;

    if (signUrl === null && roleId === 5) {
      signUrlErr = 'Sign is required';
      isValid = false;
    }

    this.setState({
      signUrlErr,
    });
    if (isValid) {
      this.addContract();
    }
  };

  addContract = async (e) => {
    const { contractId, employmentcontractid, signUrl, notes } = this.state;

    const postData = {
      notes,
      technician_sign: signUrl,
      sign_date: new Date().toISOString(),
      status: 'approved',
      employmentcontractid: parseInt(employmentcontractid),
      contractid: parseInt(contractId),
    };
    const callback = AuthApi.putDataToServer;
    const endPoint = Api.employmentUrl;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      swal('Contract Approved successfully', '', 'success');
      this.resetData();
      setTimeout(() => {
        var $this = this;
        $this.props.history.goBack();
      }, 100);
      return;
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  resetData = () => {
    this.setState({
      notes: '',
      technician_sign: '',
      sign_date: '',
      contractId: null,
    });
  };

  render() {
    const {
      contract_body,
      notes,
      technician_sign,
      sign_date,
      title,
      signUrlErr,
      isShowLoader,
      roleId,
      technicianApproved,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12" style={{ minHeight: '60vh' }}>
                {contract_body && (
                  <div dangerouslySetInnerHTML={{ __html: contract_body }} />
                )}
              </div>
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.saveSign} autoComplete="off">
                      <div className="form-group row">
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Notes
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            className="form-control"
                            placeholder="Enter Contract Notes"
                            type="textarea"
                            id="desc"
                            rows="6"
                            cols="190"
                            value={notes}
                            disabled={
                              roleId !== 5 ||
                              technician_sign !== null ||
                              roleId === 1
                            }
                            onChange={(e) => {
                              this.setState({
                                notes: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Technician Signature
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <Card>
                            {technician_sign !== null && (
                              <Card.Body>
                                <img src={technician_sign} alt="image" />
                              </Card.Body>
                            )}
                            {roleId !== 5 && !technician_sign && (
                              <Card.Body>Not signed yet!</Card.Body>
                            )}

                            <Card.Body>
                              {roleId === 5 && !technician_sign && (
                                <>
                                  <SignatureCanvas
                                    penColor="green"
                                    canvasProps={{
                                      width: 365,
                                      height: 250,
                                      className: 'sigCanvas',
                                    }}
                                    ref={(ref) => {
                                      this.sigPad = ref;
                                    }}
                                  />
                                  <div className="text-center">
                                    <Button
                                      variant="secondary"
                                      className="saveBtns m-l-10"
                                      type="reset"
                                      onClick={() => this.clearSign()}
                                    >
                                      Clear
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Card.Body>
                            {technician_sign !== null && (
                              <Card.Footer
                                style={{
                                  textAlign: 'center',
                                  background: '#fff',
                                }}
                              >
                                {sign_date &&
                                  customFunctions.getFormattedDate(sign_date)}
                              </Card.Footer>
                            )}
                          </Card>
                          <span id="err">{signUrlErr}</span>
                        </div>
                      </div>
                      <div className="form-group row d-flex justify-content-center">
                        {!technicianApproved && roleId === 5 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light text-center  m-r-10"
                          >
                            Submit
                          </button>
                        )}
                        <button
                          type="reset"
                          onClick={() => {
                            this.resetData();
                            this.props.history.goBack();
                          }}
                          className="btn btn-secondary waves-effect"
                          style={{ marginRight: '160px' }}
                        >
                          Back
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(ApproveEmploymentContract);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import {
  SetCustomer,
  SetFromDate,
  SetMonth,
  SetMonthYear,
  SetOrderType,
  SetPaymentType,
  SetTechnician,
  SetToDate,
  SetWarrantyCode,
  SetWorkComplete,
  SetYear,
  SetSearchType,
  SetFilterType,
  SetWeekType,
} from '../../../action/user.action';
import Table from '../../../components/Table/simpletable.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import Filter from '../Workorder/AddFilter';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    id: 'id',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Vendor ID',
    id: 'vendorid',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Technician',
    id: 'name',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Daily Salary($)',
    id: 'daily_rate',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Installer ID',
    id: 'InstallerID',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Customer Name',
    id: 'CustomerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Work Order #',
    id: 'payrollref',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Job Title',
    id: 'jobtitle',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'Invoice #',
    id: 'Invoice',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date of Completion',
    id: 'OrderCompleteDate',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Payment Amount',
    id: 'paymentamt',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Adjusted Total Profit',
    id: 'adjustedtotalprofit1',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Payment Type',
    id: 'paymentype',
    sort: 'disabled',
    width: 250,
  },
  {
    label: 'Pay Type Fee',
    id: 'paytypefee',
    sort: 'disabled',
    width: 120,
  },

  {
    label: 'Adjusted Total Profit',
    id: 'adjustedtotalprofit2',
    sort: 'disabled',
    width: 180,
  },

  {
    label: 'PO#',
    id: 'po',
    sort: 'disabled',
    width: 140,
  },
  {
    label: 'PO Amount',
    id: 'POAmount',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Adjusted PO Amount',
    id: 'adjustedpoamount',
    sort: 'disabled',
    width: 150,
  },

  {
    label: 'Adjusted Total Profit',
    id: 'adjustedtotalprofit3',
    sort: 'disabled',
    width: 180,
  },

  {
    label: 'Installer Cost',
    id: 'InstallerCost',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Pieces Of Equipment',
    id: 'PiecesOfEquipmentInstall',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Item Registration Markup',
    id: 'registrationmarkup',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Total Before Commission',
    id: 'beforecommission',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Tech Commission',
    id: 'techcommission',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Tech Deduction',
    id: 'techdeduct',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Tech Payroll',
    id: 'tech_payroll',
    sort: 'disabled',
    width: 150,
  },
  // {
  //   label: 'Paid',
  //   id: 'Paid',
  //   sort: 'disabled',
  //   width: 150,
  // },
  {
    label: '3%',
    id: 'threepercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '5%',
    id: 'fivepercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '7%',
    id: 'sevenpercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '10%',
    id: 'tenpercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '30%',
    id: 'thirtypercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '35%',
    id: 'thirtyfivepercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '40%',
    id: 'fortypercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '45%',
    id: 'fortyfivepercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: '50%',
    id: 'fiftypercent',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    id: 'action',
    width: 150,
    sort: 'disabled',
  },
];

class Payroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      payrollData: [],
      rows: [],
      payrollId: null,
      showDelete: false,
      showFilter: false,
      showCreatePaysheet: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      isWriteAllowed: false,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      showBulkAction: false,
      installerType: '',
      isShowLoader: false,
      searchToggleType: '',
      selectedWorkOrderId: [],
      paginationObject: {},
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'payroll-accesspayroll'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'payroll-accesspayroll',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('Payroll');
    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      await this.getPayroll();
    } else {
      await this.getPayroll();
    }

    // this.getTechnician();

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
  };

  updateTransaction = async () => {
    const { payrollData, selectedWorkOrderId, installerType } = this.state;
    const { technician, fromDate, toDate } = this.props;
    if (!technician) {
      swal('Please select Technician', '', 'error');
      return;
    }
    this.setState({ isShowLoader: true });
    const transactionData = [];
    payrollData &&
      payrollData.map((pay, index) => {
        if (selectedWorkOrderId.includes(Number(index + 1).toString())) {
          // transactionData.push({
          //   WorkOrder: pay.WorkOrder,
          //   amount: installerType || pay.tech_function === "Installation"
          //     ? pay.InstallerCost
          //     : pay.WorkOrder.slice(0, 8) === 'company-name-DS-'
          //     ? pay.daily_rate
          //     : pay.tech_commision,
          // });
          transactionData.push(pay);
        }
        return pay;
      });
    const result = [];
    transactionData.map((trans) => {
      if (trans.status !== 'true') {
        // result.push({
        //   WorkOrder: trans.WorkOrder,
        //   amount: trans.amount,
        // });
        result.push(trans);
      }
      return trans;
    });
    if (result.length === 0) {
      return false;
    }
    const postData = {
      objectstoverify: result,
      technicianid: technician && technician.id,
      is_technician: 1,
      from_date: customFunctions.convertISODatetoOffsetWiseDateForAPI(fromDate),
      to_date: customFunctions.convertISODatetoOffsetWiseDateForAPI(toDate),
    };
    const endPoint = `${Api.technicianTransaction}`;
    const payload = customFunctions.cleanObject(postData);

    const callback = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, payload);
    if (data && data.success !== false) {
      this.setState({ isShowLoader: false });
      swal('Paysheet created successfully', '', 'success');
      this.onFilterSearch();
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  updateBulkPaidStatus = async () => {
    const { payrollData, selectedWorkOrderId } = this.state;
    const payroll_arr = [];
    payrollData &&
      payrollData.map((id) => {
        if (selectedWorkOrderId.includes(id.WorkOrder)) {
          payroll_arr.push({
            work_order: id.WorkOrder,
            is_paid: 1,
          });
        }
        return id;
      });

    const postData = {
      payroll_arr,
    };

    const endPoint = `${Api.payrollUrl}`;

    const callback = AuthApi.putDataToServer;

    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      swal('Paid status changed successfully', '', 'success');
      this.getPayroll();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getPayroll = async (currentPage) => {
    this.setState({ currentPage, selectedWorkOrderId: [], isShowLoader: true });
    const { keyword, pagination, paginationObject } = this.state;
    const { technician } = this.props;

    const page =
      paginationObject && paginationObject.current_page
        ? paginationObject.current_page
        : currentPage || 1;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;

    const endPoint = `${Api.payrollUrl}`;
    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
      search: keyword,
    };
    if (technician) {
      params.technician = technician.id;
    }
    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success !== false) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          payrollData: data.data.data,
          pagination: newPagination,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onFilterSearch = async (limitType = '') => {
    const { installerType, searchToggleType } = this.state;
    var { keyword } = this.state;
    this.setState({ showBulkAction: true, isShowLoader: true });
    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
    } = this.props;
    var { pagination } = this.state;
    var limit = 10;
    var currentPage = 1;
    if (limitType === 'clear') {
      this.setState({ limit: 10, currentPage: 1 });
      limit = 10;
      currentPage = 1;
    } else {
      limit = this.state.currentPage;
      currentPage = this.state.limit;
    }
    const page = currentPage || 1;
    const skip = page * limit - limit;

    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
      search: keyword,
    };

    var endPoint = `${Api.filterPayrollApi}?skip=${params.skip}&limit=${
      params.limit
    }&type=${installerType ? 'installer' : ''}&search=${keyword}&`;
    var str = '';
    str += searchToggleType === 'paysheet' ? `&is_payroll=${Number(1)}` : '';

    str += technician && technician.id ? `&technician=${technician.id}` : '';
    str += customer && customer.id ? `&customer=${customer.id}` : '';
    str += orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    str +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    str +=
      WorkCompleteValue && WorkCompleteValue.id
        ? `&work_complete=${WorkCompleteValue.id}`
        : '';

    str += fromDate
      ? `&from_date=${customFunctions.setDateToMidNight(fromDate)}`
      : '';
    str += toDate
      ? `&to_date=${customFunctions.setDateToMidNight(toDate)}`
      : '';
    str += month ? `&month=${monthyear}-${month}` : '';
    str += year ? `&year=${year}` : '';
    str = str.substring(1);
    const { data } = await AuthApi.getDataFromServer(endPoint + str);
    if (data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          payrollData: data.data.data,
          totalCount: data.data.total_count,
          pagination: newPagination,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
    }
  };

  // onSubmit = () => {
  //   this.setState({ showFilter: false, showBulkAction: true });
  // };

  changePaidStatus = async (payroll, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const paid = isChecked ? 1 : 0;
    const endPoint = `${Api.payrollUrl}?work_order=${payroll.WorkOrder}&is_paid=${paid}`;
    const { data, message } = await AuthApi.putDataToServer(endPoint);
    if (data && data.success !== false) {
      swal('Paid status changed successfully', '', 'success');
      this.getPayroll(currentPage);
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  redirectToWorkOrder = async (work_order_id) => {
    if (work_order_id !== '') {
      await this.props.history.push(`/update-order/${work_order_id}`, {
        path: '/payroll',
        paginationObj: this.state.pagination,
      });
    } else {
      swal(
        'This is a Daily Salary Entry and there is no preview available for this, you can either mark this entry as Paid or Unpaid',
        '',
        'success'
      );
    }
  };

  getTechnician = async () => {
    const { skip, limit } = this.state;
    const endPoint = `${Api.technicianUrl}?skip=${skip}&limit=${limit}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        techniciandata: data.data.data,
      });
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { payrollData, limit, isWriteAllowed } = this.state;
    const page = npage;
    const rows = [];
    payrollData.map((payroll, i) => {
      payroll.OrderCompleteDate = customFunctions.changeDateFormatMomentMDY(
        payroll.OrderCompleteDate
      );
      rows.push({
        rowClass: 'apply',
        id: (page - 1) * limit + (i + 1),
        name: payroll.name,
        daily_rate:
          payroll.daily_rate !== null ? `$${payroll.daily_rate}` : '-',
        InstallerID: payroll.InstallerID || '-',
        CustomerName: payroll.CustomerName,
        payrollref: payroll.WorkOrder,
        Invoice:
          (payroll.invoiceid &&
            payroll.invoiceid
              .split(',')
              .map((i) => 'company-name-INV-' + i)
              .join(',')) ||
          '-',
        OrderCompleteDate:
          payroll.OrderCompleteDate === 'Invalid date'
            ? '-'
            : payroll.OrderCompleteDate,
        paymentamt:
          payroll.PaymentAmount !== 0 ? `$${payroll.PaymentAmount}` : '$0.00',
        adjustedtotalprofit1:
          payroll.adjusted_total_profit1 !== '0'
            ? `$${payroll.adjusted_total_profit1}`
            : '$0.00',
        adjustedtotalprofit2:
          payroll.adjusted_total_profit2 !== '0'
            ? `$${payroll.adjusted_total_profit2}`
            : '$0.00',
        adjustedtotalprofit3:
          payroll.adjusted_total_profit3 !== '0'
            ? `$${payroll.adjusted_total_profit3}`
            : '$0.00',
        adjustedtotalprofit4:
          payroll.adjusted_total_profit4 !== '0'
            ? `$${payroll.adjusted_total_profit4}`
            : '$0.00',
        paymentype: payroll.pay_type || '-',
        vendorid: payroll.vendorid || '-',
        jobtitle: payroll.job_title || '-',
        paytypefee:
          payroll.payment_method_fees !== undefined
            ? `$${payroll.payment_method_fees}`
            : '$0.00',
        po: payroll.PO ? payroll.PO.split(',')[0] : '-',
        InstallerCost:
          payroll.InstallerCost !== 0 ? `$${payroll.InstallerCost}` : '$0.00',
        profitcommission:
          payroll.profit_commission !== 'NaN'
            ? `$${payroll.profit_commission}`
            : '-',
        Total: `$${payroll.Total}` || '-',
        newjobtotal:
          payroll.new_job_total !== 'NaN'
            ? `$${payroll.new_job_total}`
            : '$0.00',
        paymentdate: payroll.OrderCompleteDate || '-',
        beforecommission:
          payroll.total_before_commission !== undefined
            ? `$${payroll.total_before_commission}`
            : '$0.00',
        paymntype: payroll.pay_type || '-',
        adjustpototal:
          payroll.adjusted_po_amount !== 'NaN'
            ? `$${payroll.adjusted_po_amount}`
            : '$0.00',
        registrationmarkup:
          payroll.item_registration_markup !== 'NaN'
            ? `$${payroll.item_registration_markup}`
            : '$0.00',
        pmntmethdfess:
          payroll.payment_method_fees !== 'NaN'
            ? `$${payroll.payment_method_fees}`
            : '-',
        equipmarkup:
          payroll.equip_markup !== 'NaN' ? `$${payroll.equip_markup}` : '-',
        JobTotal: `$${payroll.JobTotal}` || '-',
        newtotalprofit:
          payroll.new_total_profit !== 'NaN'
            ? `$${payroll.new_total_profit}`
            : '$0.00',
        POAmount: payroll.POAmount !== 0 ? `$${payroll.POAmount}` : '$0.00',
        adjustedpoamount:
          payroll.adjusted_po_amount !== 'NaN'
            ? `$${payroll.adjusted_po_amount}`
            : '$0.00',
        PiecesOfEquipmentInstall:
          payroll.PiecesOfEquipmentInstall !== 0.0
            ? payroll.PiecesOfEquipmentInstall
            : 0,
        PayrollPaymentType:
          payroll.PayrollPaymentType !== null
            ? payroll.PayrollPaymentType
            : '-',
        Paid: (
          <React.Fragment>
            <input
              type="checkbox"
              id={payroll.tech_id}
              checked={Number(payroll.Paid) ? 'checked' : ''}
              onChange={(event) => {
                this.changePaidStatus(payroll, event);
              }}
            />
          </React.Fragment>
        ),
        tech_payroll:
          payroll.tech_payroll !== null ? `$${payroll.tech_payroll}` : '-',
        threepercent: payroll['3%'] !== 'NaN' ? `$${payroll['3%']}` : '0.00',
        fivepercent: payroll['5%'] !== 'NaN' ? `$${payroll['5%']}` : '0.00',
        sevenpercent: payroll['7%'] !== 'NaN' ? `$${payroll['7%']}` : '0.00',
        tenpercent: payroll['10%'] !== 'NaN' ? `$${payroll['10%']}` : '0.00',
        thirtypercent: payroll['30%'] !== 'NaN' ? `$${payroll['30%']}` : '0.00',
        thirtyfivepercent:
          payroll['35%'] !== 'NaN' ? `$${payroll['35%']}` : '0.00',
        fortypercent: payroll['40%'] !== 'NaN' ? `$${payroll['40%']}` : '0.00',
        fortyfivepercent:
          payroll['45%'] !== 'NaN' ? `$${payroll['45%']}` : '0.00',
        fiftypercent: payroll['50%'] !== 'NaN' ? `$${payroll['50%']}` : '0.00',
        techdeduct:
          payroll.PayrollAmount !== 0 ? `$${payroll.PayrollAmount}` : '$0.00',
        techcommission: `$${payroll.tech_commision}`,
        approvedstatus: `${payroll.approved_status}`,
        action: (
          <React.Fragment>
            <a
              href="/"
              style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.redirectToWorkOrder(payroll.work_order_id);
              }}
            >
              <i className="mdi mdi-eye" title="View"></i>
            </a>
          </React.Fragment>
        ),
      });
      return payroll;
    });
    this.setState({ rows });
    this.forceUpdate();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    if (this.state.installerType) {
      this.onFilterSearch();
    } else {
      this.getPayroll();
    }
  };

  clearSearch = () => {
    this.onCancelBtn();
    this.setState({ keyword: '' }, () => {
      this.getPayroll();
    });
  };

  onAdvancedSearch = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showCreatePaysheet: false,
      searchToggleType: 'advance',
    });
  };

  onCancelBtn = async () => {
    this.setState({
      showFilter: false,
      showCreatePaysheet: false,
      showBulkAction: false,
      installerType: false,
    });
    const {
      SetTechnician,
      SetWarrantyCode,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetFromDate,
      SetToDate,
      SetMonth,
      SetMonthYear,
      SetYear,
      SetFilterType,
      SetWeekType,
    } = this.props;

    await SetTechnician(null);
    await SetWarrantyCode('');
    await SetCustomer('');
    await SetOrderType('');
    await SetPaymentType('');
    await SetWorkComplete('');
    await SetFromDate('');
    await SetToDate('');
    await SetMonth('');
    await SetMonthYear('');
    await SetYear('');
    await SetFilterType('');
    await SetWeekType('');
    await this.getPayroll(1);
  };

  downloadPayrollCsvUrl = async () => {
    let { keyword } = this.state;
    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
    } = this.props;

    var str = '';
    str += technician && technician.id ? `&technician=${technician.id}` : '';
    str += customer && customer.id ? `&customer=${customer.id}` : '';
    str += orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    str +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    str +=
      WorkCompleteValue && WorkCompleteValue.id
        ? `&work_complete=${WorkCompleteValue.id}`
        : '';

    str += fromDate ? `&from_date=${new Date(fromDate).toISOString()}` : '';
    str += toDate ? `&to_date=${new Date(toDate).toISOString()}` : '';
    str += month ? `&month=${monthyear}-${month}` : '';
    str += year ? `&year=${year}` : '';
    str = str.substring(1);
    let endPoint =
      `${Api.downloadPayrollCsvUrl}?skip=0&limit=10000&search=${keyword}&` +
      str;
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      showFilter,
      pagination,
      roleId,
      showBulkAction,
      installerType,
      isShowLoader,
      showCreatePaysheet,
    } = this.state;

    const { SetSearchType, searchType } = this.props;

    const actions = [
      {
        actionTitle: 'Create Paysheet',
        actionCallback: () => {
          this.updateTransaction();
        },
      },
      // {
      //   actionTitle: 'Paid',
      //   actionCallback: () => {
      //     this.updateBulkPaidStatus();
      //   },
      // },
    ];

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Payroll" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-5">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by technician, customer and work order"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                              />
                            </div>
                            <div className="col-sm-3">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light btn-block"
                              >
                                Search
                              </button>
                            </div>
                            <div className="col-sm-3">
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* {!showFilter && (
                          <div className="col-sm-3">
                            <select
                              className="form-control"
                              value={technicianId}
                              onChange={(e) => {
                                this.setState({
                                  technicianId: e.target.value,
                                  // selectedWorkOrderId: [],
                                });
                              }}
                            >
                              <option value="">Select Technician</option>
                              {techniciandata &&
                                techniciandata.map((technician) => (
                                  <option
                                    value={technician.id}
                                  >{`${technician.name}`}</option>
                                ))}
                            </select>
                          </div>
                        )} */}

                      <div className="col-sm-7 d-flex justify-content-between align-items-center">
                        <button
                          type="reset"
                          onClick={async () => {
                            this.setState({
                              showCreatePaysheet: false,
                            });
                            if (searchType !== 'create-Paysheet') {
                              await this.onCancelBtn();
                              await SetSearchType('create-Paysheet');
                            }
                            this.onAdvancedSearch();
                          }}
                          className="btn btn-primary waves-effect"
                        >
                          Advance Search
                        </button>
                        <button
                          type="reset"
                          onClick={async () => {
                            if (searchType !== 'payroll') {
                              await this.onCancelBtn();
                              await SetSearchType('payroll');
                            }
                            this.setState({
                              showCreatePaysheet: !showCreatePaysheet,
                              // showFilter: !showFilter,
                              searchToggleType: 'paysheet',
                            });
                          }}
                          className="btn btn-primary waves-effect"
                        >
                          Create Paysheet
                        </button>
                        <span className="paid-switch">
                          <span className="pr-1">Installer Pay</span>
                          <input
                            type="checkbox"
                            id="switch"
                            checked={Number(installerType) ? 'checked' : ''}
                            switch="info"
                            onChange={(event) => {
                              let eventVariable = event.target.checked;
                              this.setState(
                                { installerType: event.target.checked },
                                () => {
                                  if (eventVariable) {
                                    this.onFilterSearch();
                                  } else {
                                    this.onCancelBtn();
                                  }
                                }
                              );
                            }}
                          />
                          <label
                            htmlFor="switch"
                            data-on-label=""
                            data-off-label=""
                          ></label>
                        </span>
                        {rows && rows.length ? (
                          <button
                            type="submit"
                            style={{ marginBottom: '5px' }}
                            className="btn btn-primary waves-effect waves-light float-right"
                            onClick={() => {
                              this.downloadPayrollCsvUrl();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {(showFilter || showCreatePaysheet) && (
                      <Filter
                        // onSubmitButtonCallback={() => {
                        //   this.onSubmit();
                        // }}
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={() => this.onFilterSearch('clear')}
                        fromComponent="payroll"
                        showCreatePaysheet={showCreatePaysheet}
                      />
                    )}

                    <Table
                      columns={columns}
                      rows={rows}
                      bulkActions={
                        roleId === 1 &&
                        this.props.technician &&
                        showBulkAction &&
                        showCreatePaysheet
                          ? actions
                          : []
                      }
                      showCheckbox={
                        roleId === 1 &&
                        this.props.technician &&
                        showBulkAction &&
                        showCreatePaysheet
                      }
                      onSelectRowsIndexes={(indexes) => {
                        this.setState({ selectedWorkOrderId: indexes });
                      }}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.setState(
                            { currentPage: pageNo, paginationObject: {} },
                            () => {
                              this.onFilterSearch();
                            }
                          );
                        },
                      }}
                      tableClass="table-bordered payroll"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                    {/* {totalCount > 10 && (
                      <div className="payroll-pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getPayroll}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  warantyCode: state.auth.warantyCode,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
  searchType: state.auth.searchType,
  weektype: state.auth.weektype,
});

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetWarrantyCode: (warantyCode) => dispatch(SetWarrantyCode(warantyCode)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  SetWorkComplete: (WorkCompleteValue) =>
    dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
  SetSearchType: (searchType) => dispatch(SetSearchType(searchType)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
  SetWeekType: (weektype) => dispatch(SetWeekType(weektype)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payroll)
);

import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Table from '../../../components/Table/simpletable.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class AuthorizationRequired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      workorders: [],
      rows: [],
      columns: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      orderId: null,
      id: null,
      type: null,
      showDelete: false,
      isShowLoader: false,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      showBulkAction: false,
      applyBulkAction: false,
      paginationObject: {},
      filterdata: [
        {
          value: 1,
          label: 'Authorized Workorders',
        },
        {
          value: 0,
          label: 'Unauthorized Workorders',
        },
      ],
      isAuthorized: '',
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Authorization required Workorders');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          paginationObject: this.props.location.state.paginationObj,
        },
        async () => {
          await this.getWorkOrder();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id },
        async () => {
          await this.getWorkOrder();
        }
      );
    }

    let columns = [
      {
        label: 'Sr. No.',
        id: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Vendor ID',
        id: 'vendorid',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Dispatch #',
        id: 'workorder',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Job Title',
        id: 'jobtitle',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Recall',
        id: 'recall',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'H/O Name',
        id: 'ownerName',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Date Recieved',
        id: 'dateRecieved',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Date Completed',
        id: 'completeDate',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'What Was Done',
        id: 'whatwasdone',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Autho #',
        id: 'authoNumber',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Autho Amt',
        id: 'authoAmount',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Home Owner OOPs',
        id: 'homeoops',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Dispatch Date',
        id: 'workOrderDate',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Invoice Number',
        id: 'invoiceid',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Labor to Date',
        id: 'labortodate',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Labor Total',
        id: 'labortotal',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Part Cost',
        id: 'partcost',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Est AVG COST PER CLAIM',
        id: 'estAvgCost',
        sort: 'disabled',
        width: 200,
      },
      {
        label: 'True AVG COST PER CLAIM',
        id: 'trueAvgCost',
        sort: 'disabled',
        width: 205,
      },
      {
        label: 'Authorized',
        id: 'submitted',
        width: 80,
      },
      {
        label: 'Action',
        id: 'actions',
        sort: 'disabled',
        width: 80,
      },
    ];
    // if (userdata.role_id === 4) {
    //   columns.splice(13, 4);
    // }
    if (userdata.role_id === 1) {
      columns.splice(12, 6);
    }
    if ([5, 3, 6].includes(userdata.role_id)) {
      columns.splice(12, 7);
    }
    this.setState({ columns });
  };

  getWorkOrder = async (currentPage) => {
    const {
      roleId,
      loginId,
      paginationObject,
      isAuthorized,
      keyword,
      pagination,
    } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    const page =
      paginationObject && paginationObject.current_page
        ? paginationObject.current_page
        : currentPage || 1;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&search=${keyword}&isAuthRequired=1&work_status=Completed,Scheduled,Inprogress,Paused,Cancelled`;
    if (roleId === 4) {
      endPoint += `&customer=${loginId}`;
    }
    if ([3, 5, 6].includes(roleId)) {
      endPoint += `&technician=${loginId}`;
    }
    if (isAuthorized !== '') {
      endPoint += `&autorized=${isAuthorized && isAuthorized.value ? 1 : 0}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          workorders: data.data.data,
          pagination: newPagination,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { workorders, limit, roleId } = this.state;
    const page = npage;
    const rows = [];
    workorders &&
      workorders.length &&
      workorders.map((orders, i) => {
        orders.Created = customFunctions.changeDateFormatMomentMDY(
          orders.Created
        );
        orders.OrderCompleteDate =
          orders.OrderCompleteDate &&
          customFunctions.changeDateFormatMomentMDY(orders.OrderCompleteDate);
        orders.labortodate =
          orders.labortodate &&
          customFunctions.changeDateFormatMomentMDY(orders.labortodate);
        orders.WorkOrderDate = customFunctions.changeDateFormatMomentMDY(
          orders.WorkOrderDate
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: (
            <React.Fragment>
              <a
                style={{ color: '#034fa1' }}
                title="view order"
                href="/"
                className=""
                disabled={orders.WorkOrder ? orders.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    authRequired: true,
                    path: '/authorization-required',
                    paginationObj: this.state.pagination,
                  });
                }}
              >
                {orders.WorkOrder || '-'}
              </a>
            </React.Fragment>
          ),
          id: orders.ID || '-',
          vendorid: orders.refid || '-',
          recall: orders.recall ? 'Yes' : 'No',
          ownerName: orders.CustomerName || '-',
          dateRecieved: orders.Created || '-',
          completeDate: orders.OrderCompleteDate || '-',
          workOrderDate: orders.WorkOrderDate || '-',
          invoiceid: orders.InvoiceCode || '-',
          jobtitle: orders.jobTitle || '-',
          whatwasdone: orders.RepairDescription || '-',
          labortodate: orders.labortodate || '-',
          labortotal: orders.labortotal || '-',
          partcost: orders.partcost || '-',
          authoAmount: orders.AuthoAmount ? orders.AuthoAmount : '-',
          authoNumber: orders.AuthoNumber ? orders.AuthoNumber : '-',
          estAvgCost: orders.estAvgCost || '-',
          trueAvgCost: orders.trueAvgCost || '-',
          homeoops: orders.HoOOps || '-',
          color: orders.authorizedDate ? 'white' : orders.color || '-',
          submitted: (
            <React.Fragment>
              {!orders.authorizedDate ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.updateAuthorised(orders.ID);
                  }}
                >
                  Submitted
                </button>
              ) : (
                <span>
                  {orders.authorizedDate &&
                    customFunctions.getMDYDate(orders.authorizedDate)}
                </span>
              )}
            </React.Fragment>
          ),
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    authRequired: true,
                    path: '/authorization-required',
                    paginationObj: this.state.pagination,
                  });
                }}
              >
                {' '}
                {roleId !== 4 ? (
                  <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
                ) : (
                  'View'
                )}
              </a>
              {(roleId === 1 || roleId === 2) && (
                <a
                  href="/"
                  className="px-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, orderId: orders.ID });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Order"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return orders;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  updateAuthorised = async (orderId) => {
    const postData = {
      work_order_id: Number(orderId),
      button: 'authorized',
    };

    const endPoint = Api.updateStatusAhsButton;
    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      swal('Order authorized successfully', '', 'success');
      this.getWorkOrder();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  deleteOrder = async () => {
    const { orderId } = this.state;
    const { data } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        swal('Order deleted!', '', 'success').then(() => {
          this.getWorkOrder();
        });
      });
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrder();
  };

  clearSearch = () => {
    this.setState({ keyword: '', showBulkAction: false }, () => {
      this.getWorkOrder();
    });
  };

  downloadCSV = async () => {
    const { keyword, roleId, loginId } = this.state;
    let endPoint = `${Api.downloadcsvAhsUrl}?search=${keyword}&isAuthRequired=1`;
    if (roleId === 4) {
      endPoint += `&customer=${loginId}`;
    }
    if ([3, 5, 6].includes(roleId)) {
      endPoint += `&technician=${loginId}`;
    }
    window.open(endPoint, '_blank');
  };

  updateAuthorizationStatus = async () => {
    const { selectedWorkOrderId, workorders } = this.state;
    const items = [];
    workorders &&
      workorders.map((id) => {
        if (selectedWorkOrderId.includes(id.ID.toString())) {
          items.push(id.ID);
        }
        return id;
      });

    const postData = {
      items: items,
      isAuthRequired: 0,
    };
    const endPoint = `${Api.updateahsBillingAuthorization}`;

    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, postData);

    if (data && data.success !== false) {
      this.setState({ applyBulkAction: false });

      swal('Billed Out status changed successfully', '', 'success');
      this.getWorkOrder();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const actions = [
      {
        actionTitle: 'Move to AHS Billing',
        actionCallback: () => {
          this.setState({ applyBulkAction: true });
        },
      },
    ];

    const {
      keyword,
      rows,
      showDelete,
      columns,
      isShowLoader,
      pagination,
      applyBulkAction,
      isAuthorized,
      filterdata,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Pending Work Orders Details" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {(showDelete || applyBulkAction) && (
              <div className="sweetalert">
                <SweetAlert
                  title={
                    showDelete
                      ? 'Are you sure, do you want to delete the order ?'
                      : 'Are you sure, do you want to move to AHS billing?'
                  }
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    showDelete
                      ? this.deleteOrder()
                      : this.updateAuthorizationStatus();
                  }}
                  onCancel={() => {
                    this.setState({
                      showDelete: false,
                      orderId: null,
                      applyBulkAction: false,
                    });
                  }}
                >
                  {showDelete && `You won't be able to revert this!`}
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by H/O name,Dispatch#"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className="select-wrap"
                          style={{ display: 'inline-block' }}
                        >
                          <ReactSelect
                            value={isAuthorized}
                            placeholder="Select"
                            onChange={(e) => {
                              if (e) {
                                let type = {};
                                type.label = e.label;
                                type.value = e.value;
                                this.setState({ isAuthorized: type }, () => {
                                  this.getWorkOrder();
                                  this.forceUpdate();
                                });
                              } else {
                                this.setState({ isAuthorized: '' }, () => {
                                  this.getWorkOrder();
                                });
                              }
                            }}
                            isClearable={true}
                            options={filterdata}
                          />
                        </div>
                        {rows && rows.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light float-right"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                      </div>
                    </div>
                    <Table
                      columns={columns}
                      rows={rows}
                      bulkActions={actions}
                      showCheckbox
                      onSelectRowsIndexes={(indexes) => {
                        this.setState({ selectedWorkOrderId: indexes });
                      }}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.setState(
                            { currentPage: pageNo, paginationObject: {} },
                            () => {
                              this.getWorkOrder(pageNo);
                            }
                          );
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                    {/* <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                      getData={this.getWorkOrder}
                      noRecordsFoundLabel="No records found"
                      scrollX
                      maxHeight="350px"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getWorkOrder}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AuthorizationRequired);

import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
// import CalendarTimeLine from '../../../../components/FullCalender/timeline';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import swal from 'sweetalert';
import { MDBDataTable } from 'mdbreact';
import Loader from '../../../../components/Loader';
import CalendarTimeLine from '../../../../components/FullCalender/timeline';
import customFunctions from '../../../../helper/customFunctions';

const userProfilePath = '/assets/images/users/user.png';

const columns = [
  {
    label: 'Sr.No.',
    field: 'index',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'TRIPS',
    field: 'trips',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'START',
    field: 'start',
    sort: 'disabled',
    width: 150,
  },

  {
    label: 'END',
    field: 'end',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'TIME',
    field: 'time',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'MILES',
    field: 'miles',
    sort: 'disabled',
    width: 150,
  },
];

const TechnicianTravelDetails = (props) => {
  const [isShowLoader, setShowLoader] = useState(false);
  const [technicianData, setTechnicianData] = useState([]);
  const [techDetails, setTechDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [count, forceUpdate] = useState(0);

  let mapRef = React.createRef();
  const currentPage = Number(1);
  const limit = Number(100);

  useEffect(() => {
    setTechnicianData(props.technician);
  }, [props]);

  const getTravelDetails = async (tday = new Date()) => {
    const { callback } = props;
    if (technicianData && !technicianData.id) return;
    setShowLoader(true);
    let date = customFunctions.formatDate(tday);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.travelReportUrl}?id=${technicianData.id}&date=${date}`
      // `${Api.travelReportUrl}?id=41&date=2021-10-27`
    );
    if (data && data.data) {
      setShowLoader(false);
      setTechDetails(data.data.data);
      callback(data.data.data);
    } else {
      setShowLoader(false);
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  useEffect(() => {
    if (!technicianData) return;
    getTravelDetails();
  }, [technicianData]);

  const setTableData = async () => {
    const page = currentPage || 1;
    const rows = [];
    (await techDetails) &&
      techDetails.length > 0 &&
      techDetails.map((technician, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          trips:
            technician &&
            technician.WorkOrder +
              ' ' +
              technician.jobTitle +
              '-' +
              technician.CustomerName,
          start: technician && technician.end_time,
          end: technician && technician.end_time,
          time: technician && technician.time,
          miles: technician && technician.distance ? technician.distance : '-',
        });
        return technician;
      });
    setRows(rows);
    forceUpdate(count + 1);
  };

  useEffect(() => {
    if (!techDetails) return;
    setTableData();
  }, [techDetails]);

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Card>
        <Card.Body>
          <Row>
            <Col md={2}>
              <img src={userProfilePath} alt="profile" width="35" height="35" />
            </Col>
            <Col md={4}>
              <div className="tech-para">
                <h5>{technicianData.name}</h5>
                <span>{technicianData.bio}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col-md-12 travel-calendar">
              <CalendarTimeLine
                ref={mapRef}
                firstDay={1}
                schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_KEY}
                headerToolbar={{
                  left: 'today prev',
                  center: 'title',
                  right: 'next',
                }}
                nowIndicator={true}
                editable={true}
                lazyFetching={true}
                datesSet={(ds) => {
                  var d = new Date(ds.end);
                  d.setDate(d.getDate() - 1);
                  getTravelDetails(d);
                }}
              />
            </div>
          </Row>

          <Row style={{ marginTop: '125px' }} sm={12}>
            <Col>
              <MDBDataTable
                // striped
                noBottomColumns={true}
                paging={false}
                bordered
                hover
                data={{ columns, rows }}
                searching={false}
                noRecordsFoundLabel="No records found"
                scrollX
                maxHeight="350px"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TechnicianTravelDetails;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddTaxRateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxName: '',
      taxNameErr: '',
      taxRate: '',
      taxRateErr: '',
      taxRateId: null,
      editType: false,
      title: '',
    };
  }

  componentDidMount = () => {
    const taxRateId = this.props.match.params.taxRateId;
    const url_segment = this.props.match.url.split('/');
    this.setState({ taxRateId });
    if (url_segment && url_segment.includes('update-tax-rate')) {
      this.setState({ editType: true });
    }

    this.setState({ title: `Add Tax Rate` }, () =>
      customFunctions.setTitle(this.state.title)
    );

    if (taxRateId) {
      this.setState({ title: `Edit Tax Rate` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getTaxRateData(taxRateId);
    }
  };

  getTaxRateData = async (taxRateId) => {
    const endPoint = `${Api.getTaxRate}?tax_id=${taxRateId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        taxName: data.data.data[0].tax_name,
        taxRate: data.data.data[0].tax_rate,
      });
    } else {
      var $this = this;
      swal(message, '', 'success');
      setTimeout(() => {
        $this.props.history.push('/tax-rates');
      }, 1000);
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { taxName, taxRate } = this.state;

    let taxNameErr = '',
      taxRateErr = '',
      isValid = true;

    if (taxName === '') {
      taxNameErr = 'Tax name is required';
      isValid = false;
    }

    if (taxRate === '') {
      taxRateErr = 'Rate is required';
      isValid = false;
    }

    this.setState({
      taxNameErr,
      taxRateErr,
    });

    if (isValid) {
      this.addTaxRates();
    }
  };

  addTaxRates = async (e) => {
    const { taxName, taxRate, taxRateId } = this.state;

    const postData = {
      tax_name: taxName,
      tax_rate: String(taxRate),
    };

    const callback = taxRateId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.addTaxRate;
    if (taxRateId) {
      postData.id = parseInt(taxRateId);
    }
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      if (taxRateId) {
        swal('Tax Rate updated successfully', '', 'success');
        this.resetData();
        setTimeout(() => {
          var $this = this;
          $this.props.history.push('/tax-rates');
        }, 100);
        return;
      }
      swal('Tax Rate added successfully', '', 'success');
      this.resetData();
      setTimeout(() => {
        var $this = this;
        $this.props.history.push('/tax-rates');
      }, 100);
      return;
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      itemName: '',
      taxRate: '',
    });
  };

  render() {
    const { taxName, taxRate, title, taxRateId, taxNameErr, taxRateErr } =
      this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      {taxRateId && (
                        <div className="form-group row">
                          <div className="col-sm-2" />
                          <label
                            htmlFor="taxRateId"
                            className="col-sm-2 col-form-label"
                          >
                            Tax Rate Id
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              disabled
                              value={taxRateId}
                              type="text"
                              placeholder="John"
                              id="taxRateId"
                              onChange={(e) => {
                                this.setState({ taxRateId: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label
                          htmlFor="taxname"
                          className="col-sm-2 col-form-label"
                        >
                          Tax Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={taxName}
                            type="text"
                            placeholder="Tax Name"
                            id="taxname"
                            onChange={(e) => {
                              this.setState({ taxName: e.target.value });
                            }}
                          />
                          <span id="err">{taxNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <label
                          htmlFor="rate-field"
                          className="col-sm-2 col-form-label"
                        >
                          Rate<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                %
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={taxRate}
                              maxLength="5"
                              type="text"
                              placeholder="Rate"
                              id="rate-field"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({ taxRate: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <span id="err">{taxRateErr}</span>
                        </div>
                      </div>

                      <div className="form-group row d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light text-center"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          onClick={() => {
                            this.resetData();
                            this.props.history.push('/tax-rates');
                          }}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddTaxRateComponent);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import customFunctions from '../../../helper/customFunctions';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import { uploadFile } from 'react-s3';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import swal from 'sweetalert';
import ImagePreview from '../../../components/ImagePreview';
import { Card } from 'react-bootstrap';
import Loader from '../../../components/Loader';

let count = 0;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class AddLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      itemName: '',
      itemNameErr: '',
      lineItems: [
        {
          price: '',
          priceErr: '',
          description: '',
          descErr: '',
          material: '',
          materialErr: '',
          isTaxable: false,
          quality: '',
          qualityErr: '',
          RadioButtons: ['Good', 'Better', 'Best'],
          keypoint_benefit: [{ keypoint: '' }],
          attachment: '',
          attachmentErr: '',
          imagePreviewShow: false,
          editType: false,
          contractType: null,
          variantName: '',
          // contractTypeErr: '',
          variantNameErr: '',
        },
      ],
      contractData: [],
      variantArr: [],
      itemsArray: [],
      isShowLoader: false,
      firstQueData: [],
      secondQueData: [],
      thirdQueData: [],
      fourthQueData: [],
      firstSelectionData: null,
      secondSelectionData: null,
      thirdSelectionData: null,
      fourthSelectionData: null,
      firstAnsLabel: '',
      secondAnsLabel: '',
      thirdAnsLabel: '',
      fourthAnsLabel: '',
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-items',
      'write'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });
    const itemId = this.props.match.params.itemId;
    const url_segment = this.props.match.url.split('/');
    this.setState({ itemId });
    if (url_segment && url_segment.includes('update-item')) {
      this.setState({ editType: true });
    }

    this.setState({ title: `Add Line Item` }, () =>
      customFunctions.setTitle(this.state.title)
    );

    if (itemId) {
      this.setState({ title: `Edit Line Item` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getLineItemData(itemId);
    }

    this.getQuestions();
    this.getContracts();
  };

  getContracts = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getContractItem}?type=Line Item Contract`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data && data.data.data.length > 0) {
      const newData = [];
      data.data.data.map((dt) => {
        newData.push({ label: dt.contract_name, value: dt.id });
      });
      this.setState({
        contractData: newData,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getLineItemData = async (itemId) => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getLineItem}?item_id=${itemId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      let linearray = [];
      this.setState(
        {
          firstSelectionData: data.data.data[0].answervalue1 && {
            label: data.data.data[0].answervalue1,
            value: data.data.data[0].question1,
          },
          secondSelectionData: data.data.data[0].answervalue2 && {
            label: data.data.data[0].answervalue2,
            value: data.data.data[0].question2,
            id:
              data.data.data[0].answervalue2 &&
              data.data.data[0].answervalue2.split('Is')[1]
                ? data.data.data[0].answervalue2.split('Is')[1]
                : data.data.data[0].answervalue2,
          },
          thirdSelectionData: data.data.data[0].answervalue3 && {
            label: data.data.data[0].answervalue3,
            value: data.data.data[0].question3,
          },
          fourthSelectionData: data.data.data[0].answervalue4 && {
            label: data.data.data[0].answervalue4,
            value: data.data.data[0].question4,
            id:
              data.data.data[0].answervalue4 &&
              data.data.data[0].answervalue4.split('Is')[1]
                ? data.data.data[0].answervalue4.split('Is')[1]
                : data.data.data[0].answervalue4,
          },
          isShowLoader: false,
        },
        () => {
          this.forceUpdate();
        }
      );

      data.data.data &&
        data.data.data[0] &&
        data.data.data[0].items.map(async (lineItem) => {
          if (lineItem.isactive === 0) return;
          linearray.push({
            id: lineItem.id,
            price: lineItem.item_price,
            description: lineItem.item_description,
            material: lineItem.materiallist,
            isTaxable: lineItem.taxable,
            quality: lineItem.quality,
            keypoint_benefit: lineItem && lineItem.keypoint_benefit,
            RadioButtons: ['Good', 'Better', 'Best'],
            attachment: lineItem.attachment,
            contractType:
              lineItem.contract_name && lineItem.contractid
                ? { label: lineItem.contract_name, value: lineItem.contractid }
                : '',
            variantName: lineItem.variant_name,
          });
          await this.state.variantArr.push(lineItem.quality);
          count = this.state.variantArr && this.state.variantArr.length;
          this.setState(
            {
              itemName: lineItem.item_name,
              lineItems: linearray,
            },
            () => {
              this.forceUpdate();
            }
          );
        });
    } else {
      this.setState({ isShowLoader: false });
      var $this = this;
      swal(message, '', 'success');
      setTimeout(() => {
        $this.props.history.push('/items');
      }, 1000);
    }
  };

  addMoreLineItem = (index) => {
    const { lineItems } = this.state;
    const arr = [];
    if (index >= 0) {
      lineItems.map((li) => {
        arr.push(li.quality);
        return li;
      });
    }
    // if (index === 0) {
    //   variantArr.pop();
    //   variantArr.push(value);
    // } else if (index === 1) {
    //   variantArr.splice(index, 2);
    //   variantArr.push(value);
    // } else if (index === 2) {
    //   variantArr.splice(index, 3);
    //   variantArr.push(value);
    // }
    // variantArr.splice(index, 1);
    // variantArr.push(value);
    const intersection = ['Good', 'Better', 'Best'].filter(
      (element) => !arr.includes(element)
    );
    if (lineItems[index].quality !== '') {
      lineItems.push({
        price: '',
        description: '',
        material: '',
        quality: '',
        keypoint_benefit: [{ keypoint: '' }],
        RadioButtons: intersection,
        attachment: '',
        variantName: '',
      });
      this.setState({ lineItems });
    }
  };

  removeLineItem = async (index, id) => {
    const { lineItems } = this.state;
    // if (itemId && id) {
    //   this.setState({ isShowLoader: true });
    //   const { data, message } = await AuthApi.deleteDataFromServer(
    //     `${Api.deleteVariant}?item_id=${itemId}&primid=${id}`
    //   );
    //   if (data && data.success) {
    //     this.setState({ isShowLoader: false });
    //     lineItems.splice(index, 1);

    //     if (this.state.itemId) variantArr.splice(index, 1);
    //     this.setState({ lineItems, variantArr });
    //   } else {
    //     this.setState({ isShowLoader: false });
    //     swal(message ? message : "Something went wrong", '', 'error');
    //   }
    // } else {
    if (index < lineItems.length - 1) {
      lineItems[index + 1].RadioButtons.push(lineItems[index].quality);
      lineItems.splice(index, 1);
    } else lineItems.splice(index, 1);
    // lineItems[index].quality
    // variantArr.splice(index, 1);
    // variantArr.push(lineItems[index].quality)
    // variantArr.splice(index, 1);
    // variantArr.push(value);
    this.setState({ lineItems });
    // }
  };

  handleFileChnage = async (e, index) => {
    const { lineItems } = this.state;
    if (!e.target.files || e.target.files.length < 1) return;
    lineItems[index].attachment = await this.uploadImage(e.target.files[0]);
    this.setState({
      lineItems,
    });
  };

  uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `lineitem/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    this.setState({
      isShowLoader: false,
    });
    return imgData;
  };

  validate = (e) => {
    e.preventDefault();

    const { itemName, lineItems } = this.state;
    let itemNameErr = '',
      isValid = true;

    if (itemName === '') {
      itemNameErr = 'Please enter item name';
      isValid = false;
    }

    if (lineItems.length > 0) {
      lineItems.map((items, index) => {
        lineItems[index].priceErr = '';
        lineItems[index].materialErr = '';
        // lineItems[index].contractTypeErr = '';
        lineItems[index].isTaxableErr = '';
        lineItems[index].qualityErr = '';
        lineItems[index].attachmentErr = '';
        lineItems[index].variantNameErr = '';

        if (!items.quality) {
          lineItems[index].qualityErr = 'Please select variant';
          isValid = false;
        }

        if (!items.price) {
          lineItems[index].priceErr = 'Price is required';
          isValid = false;
        }

        if (!items.material) {
          lineItems[index].materialErr = 'Please enter material';
          isValid = false;
        }

        if (!items.variantName) {
          lineItems[index].variantNameErr = 'Variant Name is required';
          isValid = false;
        }

        // if (!items.contractType) {
        //   lineItems[index].contractTypeErr = 'Please select contract type';
        //   isValid = false;
        // }

        // if (!items.attachment) {
        //   lineItems[index].attachmentErr = 'Please choose file';
        //   isValid = false;
        // }
      });
    }

    this.setState({
      itemNameErr,
      lineItems,
    });

    if (isValid) {
      this.addLineItem();
    }
  };

  addLineItem = async () => {
    const {
      itemName,
      lineItems,
      itemId,
      firstSelectionData,
      secondSelectionData,
      thirdSelectionData,
      fourthSelectionData,
      paginationOptions,
    } = this.state;

    let itemsArray = [];
    (await lineItems) &&
      lineItems.map(async (item) => {
        await itemsArray.push({
          id: itemId && item.id,
          quality: item.quality,
          price: item.price,
          description: item.description,
          materiallist: item.material,
          attachment: item.attachment,
          keypoint_benefit: item.keypoint_benefit,
          taxable: item.isTaxable ? 1 : 0,
          contractid: item.contractType ? item.contractType.value : '',
          variant_name: item.variantName,
        });
      });

    const payload = {
      // item_name:
      //   (firstSelectionData && firstSelectionData.label) ||
      //   (secondSelectionData && secondSelectionData.label) ||
      //   (thirdSelectionData && thirdSelectionData.label) ||
      //   (fourthSelectionData && fourthSelectionData.label)
      //     ? `${this.getItemName(
      //         fourthSelectionData && fourthSelectionData.id,
      //         secondSelectionData && secondSelectionData.id,
      //         thirdSelectionData && thirdSelectionData.label,
      //         firstSelectionData && firstSelectionData.label
      //       )}`
      //     : `${itemName}`,
      item_name: itemName,
      answervalue1: firstSelectionData && firstSelectionData.label,
      question1: firstSelectionData && firstSelectionData.value,
      answervalue2: secondSelectionData && secondSelectionData.label,
      question2: secondSelectionData && secondSelectionData.value,
      answervalue3: thirdSelectionData && thirdSelectionData.label,
      question3: thirdSelectionData && thirdSelectionData.value,
      answervalue4: fourthSelectionData && fourthSelectionData.label,
      question4: fourthSelectionData && fourthSelectionData.value,
      items: itemsArray && itemsArray,
      item_id: itemId && Number(itemId),
    };
    const postdata = customFunctions.cleanObject(payload);
    const endpoint = Api.AddLineItem;
    const { data, message } = itemId
      ? await AuthApi.putDataToServer(endpoint, postdata)
      : await AuthApi.postDataToServer(endpoint, postdata);
    if (data && data.success) {
      if (itemId) {
        swal('Line item updated successfully', '', 'success');
        setTimeout(() => {
          if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.path
          ) {
            this.props.history.push(`${this.props.location.state.path}`, {
              paginationObj: paginationOptions,
            });
          } else {
            this.props.history.push('/item');
          }
        }, 100);
        return;
      }
      swal('Line item added successfully', '', 'success');
      setTimeout(() => {
        var $this = this;
        $this.props.history.goBack();
      }, 100);
      return;
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  getQuestions = async () => {
    const first = [],
      second = [],
      third = [],
      fourth = [];
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getDisQuestions}?skip=0&limit=100`
    );
    if (data && data.success) {
      data &&
        data.data.map((que) => {
          if (que.questionid === 12) {
            this.setState({ firstAnsLabel: que.question });
            que.options &&
              que.options.length > 0 &&
              que.options.map((option) => {
                first.push({
                  label: option.name,
                  value: option.id,
                });
              });
          } else if (que.questionid === 13) {
            this.setState({ secondAnsLabel: que.question });
            que.options &&
              que.options.length > 0 &&
              que.options.map((option) => {
                second.push({
                  label: option.name,
                  id: option.value,
                  value: option.id,
                });
              });
          } else if (que.questionid === 14) {
            this.setState({ thirdAnsLabel: que.question });
            que.options &&
              que.options.length > 0 &&
              que.options.map((option) => {
                third.push({
                  label: option.name,
                  value: option.id,
                });
              });
          } else if (que.questionid === 15) {
            this.setState({ fourthAnsLabel: que.question });
            que.options &&
              que.options.length > 0 &&
              que.options.map((option) => {
                fourth.push({
                  label: option.name,
                  id: option.value,
                  value: option.id,
                });
              });
          }
        });
      this.setState({
        firstQueData: first,
        secondQueData: second,
        thirdQueData: third,
        fourthQueData: fourth,
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  handleQuestionChange = (selectedOption) => {
    this.setState({
      firstSelectionData: selectedOption,
      // itemName:
      //   selectedOption &&
      //   selectedOption.label &&
      //   `${selectedOption ? selectedOption.label : ''}`,
    });
  };

  secondQuestionChange = (selectedOption) => {
    // const { firstSelectionData } = this.state;
    this.setState({
      secondSelectionData: selectedOption,
      // itemName:
      //   ((firstSelectionData && firstSelectionData.label) ||
      //     (selectedOption && selectedOption.label)) &&
      //   `${firstSelectionData ? firstSelectionData.label : ''}${
      //     selectedOption ? selectedOption.label : ''
      //   }`,
    });
  };

  thirdQuestionChange = (selectedOption) => {
    // const { firstSelectionData, secondSelectionData } = this.state;
    this.setState({
      thirdSelectionData: selectedOption,
      // itemName:
      //   ((firstSelectionData && firstSelectionData.label) ||
      //     (secondSelectionData && secondSelectionData.label) ||
      //     (selectedOption && selectedOption.label)) &&
      //   `${firstSelectionData ? firstSelectionData.label : ''}${
      //     secondSelectionData ? secondSelectionData.label : ''
      //   }${selectedOption ? selectedOption.label : ''}`,
    });
  };

  fourthQuestionChange = (selectedOption) => {
    // const { firstSelectionData, secondSelectionData, thirdSelectionData } =
    //   this.state;
    this.setState({
      fourthSelectionData: selectedOption,
      // itemName:
      //   ((firstSelectionData && firstSelectionData.label) ||
      //     (secondSelectionData && secondSelectionData.label) ||
      //     (thirdSelectionData && thirdSelectionData.label) ||
      //     (selectedOption && selectedOption.label)) &&
      //   `${firstSelectionData ? firstSelectionData.label : ''} ${
      //     secondSelectionData ? secondSelectionData.label : ''
      //   }${thirdSelectionData ? thirdSelectionData.label : ''}${
      //     selectedOption ? selectedOption.label : ''
      //   }`,
    });
  };

  components = {
    DropdownIndicator: null,
  };

  getItemName = (a4, a2, a3, a1) => {
    let key = '';
    if (a4) {
      key += a4;
    }
    if (a2) {
      key += key ? ' ' + a2 : a2;
    }
    if (a3) {
      key += key ? ' ' + a3 : a3;
    }
    if (a1) {
      key += key ? ' ' + a1 : a1;
    }
    return key;
  };

  addMoreKeyPoint = (index) => {
    const { lineItems } = this.state;
    lineItems &&
      lineItems[index] &&
      lineItems[index].keypoint_benefit &&
      lineItems[index].keypoint_benefit.push({ keypoint: '' });
    this.setState({ lineItems });
  };

  removeKeyPoint = async (keyIndex, index) => {
    const { lineItems } = this.state;
    lineItems &&
      lineItems[index] &&
      lineItems[index].keypoint_benefit &&
      lineItems[index].keypoint_benefit.splice(keyIndex, 1);
    this.setState({ lineItems });
  };

  render() {
    const {
      itemName,
      title,
      itemNameErr,
      lineItems,
      imagePreviewShow,
      imagePreview,
      isShowLoader,
      firstQueData,
      secondQueData,
      thirdQueData,
      fourthQueData,
      firstSelectionData,
      secondSelectionData,
      thirdSelectionData,
      fourthSelectionData,
      firstAnsLabel,
      secondAnsLabel,
      thirdAnsLabel,
      fourthAnsLabel,
      paginationOptions,
      contractData,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.validate} autoComplete="off">
                      <Card>
                        <Card.Body>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <label htmlFor="itemname">{firstAnsLabel}</label>
                              <ReactSelect
                                components={this.components}
                                value={firstSelectionData}
                                placeholder="Select Answer"
                                onChange={this.handleQuestionChange}
                                isClearable
                                options={firstQueData}
                              />
                            </div>

                            <div className="col-sm-6">
                              <label htmlFor="itemname">{secondAnsLabel}</label>
                              <ReactSelect
                                components={this.components}
                                value={secondSelectionData}
                                placeholder="Select Answer"
                                onChange={this.secondQuestionChange}
                                isClearable
                                options={secondQueData}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-sm-6">
                              <label htmlFor="itemname">{thirdAnsLabel}</label>
                              <ReactSelect
                                isClearable
                                components={this.components}
                                value={thirdSelectionData}
                                placeholder="Select Answer"
                                onChange={this.thirdQuestionChange}
                                options={thirdQueData}
                              />
                            </div>

                            <div className="col-sm-6">
                              <label htmlFor="itemname">{fourthAnsLabel}</label>
                              <ReactSelect
                                isClearable
                                components={this.components}
                                value={fourthSelectionData}
                                placeholder="Select Answer"
                                onChange={this.fourthQuestionChange}
                                options={fourthQueData}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <div className="form-group row">
                            <label
                              htmlFor="itemname"
                              className="col-sm-2 col-form-label"
                            >
                              Line Item Name{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                // value={
                                //   (firstSelectionData &&
                                //     firstSelectionData.label) ||
                                //   (secondSelectionData &&
                                //     secondSelectionData.id) ||
                                //   (thirdSelectionData &&
                                //     thirdSelectionData.label) ||
                                //   (fourthSelectionData &&
                                //     fourthSelectionData.id)
                                //     ? `${this.getItemName(
                                //         fourthSelectionData &&
                                //           fourthSelectionData.id,
                                //         secondSelectionData &&
                                //           secondSelectionData.id,
                                //         thirdSelectionData &&
                                //           thirdSelectionData.label,
                                //         firstSelectionData &&
                                //           firstSelectionData.label
                                //       )}`
                                //     : (itemName && itemName) || ''
                                // }
                                value={itemName}
                                type="text"
                                placeholder="Item name"
                                id="itemname"
                                // disabled={
                                //   firstSelectionData ||
                                //   secondSelectionData ||
                                //   thirdSelectionData ||
                                //   fourthSelectionData
                                //     ? true
                                //     : false
                                // }
                                onChange={(e) => {
                                  let newInput = e.target.value;
                                  let value = newInput.replace('', '');
                                  if (value.length === 0) {
                                    value = '';
                                  } else {
                                    value = '' + value;
                                  }
                                  this.setState({ itemName: value });
                                }}
                              />
                              <span id="err">
                                {firstSelectionData ||
                                secondSelectionData ||
                                thirdSelectionData ||
                                fourthSelectionData
                                  ? ''
                                  : itemNameErr}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      {lineItems &&
                        lineItems.map((lineitem, index) => (
                          <Card>
                            <Card.Body>
                              <div className="form-group row">
                                <label
                                  htmlFor="variantName"
                                  className="col-sm-2 col-form-label"
                                >
                                  Varian Name{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-4">
                                  <div className="input-group">
                                    <input
                                      className="form-control"
                                      value={lineitem.variantName}
                                      type="text"
                                      placeholder="Variant Name"
                                      id="variantName"
                                      onChange={(e) => {
                                        lineItems[index].variantName =
                                          e.target.value;
                                        this.setState({ lineItems });
                                      }}
                                    />
                                  </div>
                                  <span id="err">
                                    {lineItems[index].variantNameErr}
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="Variant"
                                  className="col-sm-2 col-form-label"
                                >
                                  Variant<span className="text-danger">*</span>
                                </label>

                                <div className="col-sm-4">
                                  {lineitem.RadioButtons.map((rd, i) => (
                                    <>
                                      <Radio
                                        checked={
                                          lineItems[index].quality === rd
                                        }
                                        onChange={(e) => {
                                          // variantArr.push(rd);
                                          lineItems[index].quality = rd;
                                          this.setState({ lineItems });
                                        }}
                                        disabled={
                                          (this.state.itemId &&
                                            lineItems[index].quality) ||
                                          (index == 0 &&
                                            lineItems.length > 1) ||
                                          (index == 1 && lineItems.length > 2)
                                        }
                                        value={rd}
                                        color="default"
                                        name="radio-button-demo"
                                      />
                                      {rd}
                                    </>
                                  ))}
                                  <br />
                                  <span id="err">
                                    {lineItems[index].qualityErr}
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="Price"
                                  className="col-sm-2 col-form-label"
                                >
                                  Price<span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-4">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="jobTotal"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={lineitem.price}
                                      type="text"
                                      placeholder="Price"
                                      id="price"
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (
                                          e.target.value === '' ||
                                          re.test(e.target.value)
                                        ) {
                                          lineItems[index].price =
                                            e.target.value;
                                          this.setState({ lineItems });
                                        }
                                      }}
                                    />
                                  </div>
                                  <span id="err">
                                    {lineItems[index].priceErr}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="description"
                                  className=" col-sm-2 col-form-label"
                                >
                                  Description
                                </label>
                                <div className="col-sm-4">
                                  <textarea
                                    className="form-control"
                                    placeholder="Enter Description Here..."
                                    type="textarea"
                                    id="description"
                                    style={{ resize: 'none' }}
                                    rows="3"
                                    cols="30"
                                    value={lineitem.description}
                                    onChange={(e) => {
                                      lineItems[index].description =
                                        e.target.value;
                                      this.setState({ lineItems });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="Material"
                                  className="col-sm-2 col-form-label"
                                >
                                  Material<span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-4">
                                  <textarea
                                    className="form-control"
                                    placeholder="Enter Material list Here..."
                                    type="textarea"
                                    id="material"
                                    style={{ resize: 'none' }}
                                    rows="3"
                                    cols="30"
                                    value={lineitem.material}
                                    onChange={(e) => {
                                      lineItems[index].material =
                                        e.target.value;
                                      this.setState({ lineItems });
                                    }}
                                  />
                                  <span id="err">
                                    {lineItems[index].materialErr}
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row mb-2">
                                {lineitem.keypoint_benefit &&
                                  lineitem.keypoint_benefit.map((key, i) => (
                                    <>
                                      <label
                                        htmlFor="keypoint_benefitText"
                                        className="col-sm-2 col-form-label"
                                      >
                                        key Points{' '}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <div className="col-sm-4 mb-2">
                                        <input
                                          className="form-control"
                                          value={key.keypoint}
                                          type="text"
                                          placeholder="key Points"
                                          id="keypoint_benefitText"
                                          onChange={(e) => {
                                            lineitem.keypoint_benefit[
                                              i
                                            ].keypoint = e.target.value;
                                            this.setState({ lineitem });
                                          }}
                                        />
                                        {/* <span id="err">
                                    {lineItems[i].keypoint_benefit}
                                  </span> */}
                                      </div>
                                      <div className="col-sm-6">
                                        <button
                                          type="button"
                                          className="btn btn-primary waves-effect waves-light"
                                          onClick={() => {
                                            this.addMoreKeyPoint(index);
                                          }}
                                        >
                                          +
                                        </button>
                                        &nbsp;
                                        {i > 0 && (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              this.removeKeyPoint(i, index);
                                            }}
                                            className="btn btn-secondary waves-effect"
                                          >
                                            -
                                          </button>
                                        )}
                                      </div>
                                    </>
                                  ))}
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="Taxable"
                                  className=" col-sm-2 col-form-label"
                                >
                                  Taxable
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className=" col-sm-4">
                                  <input
                                    className="form-control"
                                    type="checkbox"
                                    name="taxable"
                                    id="example-search-input"
                                    checked={lineitem.isTaxable}
                                    onChange={(e) => {
                                      lineItems[index].isTaxable = e.target
                                        .checked
                                        ? 1
                                        : 0;
                                      this.setState({ lineItems });
                                    }}
                                    style={{
                                      height: '29px',
                                      width: '29px',
                                    }}
                                  />
                                  <span id="err">
                                    {lineItems[index].isTaxableErr}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="image"
                                  className="col-sm-2 col-form-label"
                                >
                                  Attachment
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    type="file"
                                    id="image"
                                    onChange={(e) => {
                                      this.handleFileChnage(e, index);
                                    }}
                                    accept=".pdf,image/jpeg,image/png,"
                                  />
                                  <span id="err">
                                    {lineItems[index].attachmentErr}
                                  </span>
                                </div>
                              </div>
                              {lineItems[index].attachment && (
                                <div className="form-group row">
                                  <div className="col-sm-4">
                                    <span
                                      className="span-container"
                                      key={index}
                                      // style={{ marginRight: '12px' }}
                                    >
                                      <img
                                        className="img-container my-10"
                                        src={lineItems[index].attachment}
                                        alt=""
                                        style={{
                                          height: '110px',
                                          width: '95px',
                                          marginBottom: '12px',
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            imagePreviewShow: true,
                                            imagePreview: lineitem.attachment,
                                          })
                                        }
                                        title={lineitem.attachment}
                                      />

                                      {/* {!this.state.editType && (
                                    <i
                                      className="icon-container mdi mdi-close"
                                      style={{
                                        position: 'absolute',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                          index: index,
                                          showDelete: true,
                                        });
                                      }}
                                    />
                                  )} */}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="form-group row">
                                <label
                                  htmlFor="contractType"
                                  className="col-sm-2 col-form-label"
                                >
                                  Contract Type
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="col-sm-4">
                                  <ReactSelect
                                    value={lineitem.contractType}
                                    placeholder="Select"
                                    onChange={(e) => {
                                      if (e) {
                                        let type = {};
                                        type.label = e.label;
                                        type.value = e.value;
                                        lineItems[index].contractType = type;
                                        this.setState({ lineItems }, () => {
                                          this.forceUpdate();
                                        });
                                      } else {
                                        this.setState(
                                          { contractType: '' },
                                          () => {}
                                        );
                                      }
                                    }}
                                    // isClearable
                                    options={contractData}
                                  />
                                  {/* <span id="err">
                                  {lineItems[index].contractTypeErr}
                                </span> */}
                                </div>
                              </div>
                              {
                                <div className="form-group row justify-content-center">
                                  {/* <div className="col-sm-8"> */}
                                  {((index == 0 && lineItems.length === 1) ||
                                    (index > 0 && lineItems.length !== 3)) && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={() => {
                                          this.addMoreLineItem(
                                            // lineItems[index].quality,
                                            index
                                          );
                                          count = count + 1;
                                        }}
                                      >
                                        +
                                      </button>
                                    </>
                                  )}
                                  &nbsp;
                                  {index > 0 && count >= 0 && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.removeLineItem(index, lineitem.id);
                                        count = count - 1;
                                      }}
                                      className="btn btn-secondary waves-effect"
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                                // </div>
                              }
                            </Card.Body>
                          </Card>
                        ))}

                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-3 col-md-3 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light text-center"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn btn-secondary waves-effect m-l-10"
                            onClick={() => {
                              if (
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.path
                              ) {
                                this.props.history.push(
                                  `${this.props.location.state.path}`,
                                  {
                                    paginationObj: paginationOptions,
                                  }
                                );
                              } else {
                                this.props.history.goBack();
                              }
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {imagePreviewShow && imagePreview && (
          <ImagePreview
            imagePreviewShow={imagePreviewShow}
            imagePreview={imagePreview}
            handleClose={() => this.setState({ imagePreviewShow: false })}
          />
        )}
      </AUX>
    );
  }
}

export default withRouter(AddLineItem);

import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';

class AppointmentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      statuses: '',
      startDate: '',
      endDate: '',
      appointmentsData: [],
    };
  }
  componentDidMount = async () => {
    const columns = [
      {
        label: 'JOBS SHEDULED',
        field: 'job_sheduled',
        width: 140,
      },
      {
        label: 'APPTS SCHEDULED',
        field: 'appts_scheduled',
        width: 180,
      },
      {
        label: 'ON MY WAY COUNT',
        field: 'my_count',
        width: 200,
      },
      {
        label: 'ON MY WAY PERCENTAGE',
        field: 'my_percentage',
        width: 200,
      },
      {
        label: 'APPT COMPLETED',
        field: 'appt_completed',
        width: 150,
      },
      {
        label: 'APPT CANCELS',
        field: 'appt_cancels',
        width: 150,
      },
    ];
    this.setState({ columns }, () => {
      this.getDispatchesReport();
    });
  };

  getDispatchesReport = async (currentPage) => {
    let { startDate, endDate } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.appointmentCountUrl}?from_date=${startDate}&to_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          appointmentsData: data.data,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = () => {
    const { appointmentsData } = this.state;
    const rows = [];
    rows.push({
      job_sheduled: appointmentsData.JOBSSHEDULED || '-',
      appts_scheduled: appointmentsData.APPTSSCHEDULED || '-',
      my_count: appointmentsData.ONMYWAYCOUNT || '-',
      my_percentage: appointmentsData.ONMYWAYPERCENTAGE || '-',
      appt_completed: appointmentsData.APPTCOMPLETED || '-',
      appt_cancels: appointmentsData.APPTCANCELS || '-',
    });

    this.setState({ rows: rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns } = this.state;
    return (
      <>
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
      </>
    );
  }
}

export default withRouter(AppointmentsTable);

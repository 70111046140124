import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

class AddEditWorkOrderPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      partid: null,
      partName: '',
      partidErr: '',
      quantity: '',
      quantityErr: '',
      note: '',
      noteErr: '',
      work_order_id: null,
      workorderpartid: null,
      partsData: [],
      page: 1,
      limit: 100,
      totalCount: 0,
      currentPage: 1,
      isShowLoader: false,
      disabledAll: false,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.workorderid
    ) {
      this.setState(
        {
          work_order_id: this.props.location.state.workorderid,
          primaryTechnician:
            this.props.location.state.primaryTechnician || null,
        },
        () => {}
      );
    }
    // this.getParts();
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getPart();
      customFunctions.setTitle('Edit WorkOrder Part');
      this.setState({
        title: `Edit WorkOrder Part`,
      });
    } else {
      customFunctions.setTitle('Add WorkOrder Part');
      this.setState({
        title: `Add WorkOrder Part`,
      });
    }
    if (userdata.role_id === 4) {
      this.setState({ disabledAll: true, title: 'View WorkOrder Part' });
      customFunctions.setTitle('View WorkOrder Part');
    }
  };

  getPart = async () => {
    const partsData = this.props.location.state.detail;
    this.setState({
      workorderpartid: partsData.id,
      partid: partsData.partid,
      quantity: partsData.quantity,
      note: partsData.note,
    });
  };

  // getParts = async (currentPage) => {
  //   this.setState({ currentPage });
  //   const { primaryTechnician } = this.state;
  //   // const page = currentPage || 1;
  //   // const skip = page * limit - limit;
  //   this.setState({
  //     isShowLoader: true,
  //   });
  //   let endPoint = `${Api.technicianPartsUrl}`;
  //   if (primaryTechnician) {
  //     endPoint += `?technicianid=${primaryTechnician}`;
  //   }
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data) {
  //     this.setState(
  //       {
  //         partsData: data.data.data,
  //         totalCount: data.data.total_count,
  //         isShowLoader: false,
  //       },
  //       () => {
  //         this.forceUpdate();
  //       }
  //     );
  //   } else {
  //     this.setState({
  //       isShowLoader: false,
  //     });
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  add = async () => {
    const { partid, work_order_id, quantity, note, workorderpartid } =
      this.state;
    let postData = {};
    if (!workorderpartid) {
      postData.work_order_id = work_order_id;
      postData.parts = [
        {
          partid,
          quantity,
          note,
          iswaiting: 0,
        },
      ];
    }
    const endPoint = `${Api.workorderpartsUrl}`;
    const callback = workorderpartid
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (workorderpartid) {
      postData.id = parseInt(workorderpartid);
      postData.partid = partid;
      postData.quantity = quantity;
      postData.note = note;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (workorderpartid) {
        swal('WorkOrder Part updated successfully', '', 'success');
        setTimeout(() => {
          this.setState({
            isShowLoader: false,
          });
          $this.props.history.goBack();
        }, 1000);
      } else {
        swal('Part added to work order successfully', '', 'success');
        setTimeout(() => {
          this.setState({
            isShowLoader: false,
          });
          $this.props.history.goBack();
        }, 1000);
      }
    } else {
      if (message === 'Updated') {
        swal('WorkOrder Part updated successfully', '', 'success');
        this.setState({
          isShowLoader: false,
        });
      } else {
        this.setState({
          isShowLoader: false,
        });
        swal(message ? message : 'No data found', '', 'error');
      }
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { partid, quantity, note, partsData } = this.state;

    let partidErr = '',
      quantityErr = '',
      noteErr = '';

    let isValid = true;
    if (!partid) {
      partidErr = 'part is required';
      isValid = false;
    }
    if (!quantity || quantity < 1) {
      quantityErr = 'quantity is required';
      isValid = false;
    }
    if (partid) {
      let partq =
        partsData &&
        partsData.length &&
        partsData.filter((i) => i.partid === partid)[0];

      if (partq && partq.quantity < quantity) {
        quantityErr = `Quantity should not be more than ${partq.quantity}`;
        isValid = false;
      }
    }
    if (!note || (note && note.trim() === '')) {
      noteErr = 'note is required';
      isValid = false;
    }
    this.setState({ partidErr, quantityErr, noteErr });
    if (isValid) {
      this.add();
    }
  };

  render() {
    const {
      title,
      partid,
      partName,
      quantity,
      note,
      partidErr,
      quantityErr,
      noteErr,
      partsData,
      isShowLoader,
      disabledAll,
      primaryTechnician,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <label
                          htmlFor="partid"
                          className="col-sm-2 col-form-label"
                        >
                          Parts<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState(
                                {
                                  partid: e ? e.partid : '',
                                  partName: e ? { name: e.name } : '',
                                },
                                () => {
                                  this.forceUpdate();
                                }
                              );
                            }}
                            className="w-100"
                            isSearch={true}
                            isClearable={partName}
                            value={partName}
                            apiname="technicianParts"
                            endpoint={
                              Api.technicianPartsUrl +
                              `?technicianid=${
                                primaryTechnician ? primaryTechnician : null
                              }&search=`
                            }
                            disabled={disabledAll}
                            selectPlaceholder="Select Part"
                          />
                          <span id="err">{partidErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Quantity<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Quantity"
                            disabled={disabledAll}
                            value={quantity}
                            onChange={(e) => {
                              let event = e;
                              event.target.value =
                                customFunctions.numberMaskingDecimal(
                                  e.target.value
                                );
                              this.setState({ quantity: event.target.value });
                            }}
                          />
                          <span id="err">{quantityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Note<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <textarea
                            className="form-control"
                            placeholder="Note"
                            disabled={disabledAll}
                            rows={6}
                            value={note}
                            onChange={(e) => {
                              this.setState({ note: e.target.value });
                            }}
                          />
                          <span id="err">{noteErr}</span>
                        </div>
                      </div>
                      {!disabledAll && (
                        <div className="form-group row justify-content-center">
                          <div className="col-sm-2 col-form-label" />
                          <div className="col-sm-3">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.props.history.goBack();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddEditWorkOrderPart);

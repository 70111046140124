import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Table from '../../../components/Table/simpletable.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class OfficeAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      adminData: [],
      rows: [],
      columns: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      officeId: null,
      id: null,
      type: null,
      showDelete: false,
      isShowLoader: false,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      paginationObject: {},
      filterValue: '',
      filterdata: [
        {
          value: 1,
          label: 'Active',
        },
        {
          value: 0,
          label: 'In-Active',
        },
      ],
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Office Admin Assistants');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          paginationObject: this.props.location.state.paginationObj,
        },
        async () => {
          await this.getOfficeAdmin();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id },
        async () => {
          await this.getOfficeAdmin();
        }
      );
    }

    let columns = [
      {
        label: 'Sr. No.',
        id: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'First Name',
        id: 'firstname',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Last Name',
        id: 'lastname',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Email',
        id: 'email',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Status',
        id: 'status',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Action',
        id: 'actions',
        sort: 'disabled',
        width: 80,
      },
    ];
    this.setState({ columns: columns });
  };

  getOfficeAdmin = async (currentPage) => {
    const { paginationObject } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    const { keyword, pagination, filterValue } = this.state;
    const page =
      paginationObject && paginationObject.current_page
        ? paginationObject.current_page
        : currentPage || 1;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    let endPoint = `${
      Api.getAdmin
    }?skip=${skip}&limit=${itemsPerPage}&role=2&search=${keyword}&type=${
      filterValue ? filterValue.value : ''
    }`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          adminData: data.data.data,
          pagination: newPagination,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  changeAdminStatus = async (admin, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      login_id: admin.id,
      isactive: isChecked ? '1' : '0',
    };
    const { data } = await AuthApi.putDataToServer(Api.getAdmin, payload);
    if (data) {
      swal('Office admin assistants status changed!', '', 'success');
      this.getOfficeAdmin(currentPage);
    } else {
      swal('Please try again later ');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { adminData, limit } = this.state;
    const page = npage;
    const rows = [];
    adminData &&
      adminData.length &&
      adminData.map((admin, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          firstname: admin.first_name || '-',
          lastname: admin.last_name || '-',
          email: admin.email || '-',
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                id={`switch${admin.id}`}
                switch="info"
                checked={Number(admin.isactive) ? 'checked' : ''}
                onChange={(event) => {
                  this.changeAdminStatus(admin, event);
                }}
              />
              <label
                htmlFor={`switch${admin.id}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/edit-office-admin-assistant/${admin.id}`,
                    {
                      adminDetails: admin,
                      paginationObj: this.state.pagination,
                      path: '/office-admin-assistants',
                    }
                  );
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  title="Edit Office Admin Assistant"
                ></i>
              </a>
              <a
                href="/"
                className="px-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, officeId: admin.id });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Office Admin Assistant"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return admin;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteOrder = async () => {
    const { officeId } = this.state;
    const { data } = await AuthApi.deleteDataFromServer(
      `${Api.getAdmin}?id=${officeId}`
    );
    if (data) {
      this.setState({ showDelete: false, officeId: null }, () => {
        swal('Office Admin Assistant deleted!', '', 'success').then(() => {
          this.getOfficeAdmin();
        });
      });
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getOfficeAdmin();
  };

  clearSearch = () => {
    this.setState({ keyword: '', showBulkAction: false }, () => {
      this.getOfficeAdmin();
    });
  };

  render() {
    const {
      keyword,
      rows,
      showDelete,
      columns,
      isShowLoader,
      pagination,
      filterValue,
      filterdata,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Pending Work Orders Details" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title={
                    showDelete
                      ? 'Are you sure, do you want to delete the office admin assistant ?'
                      : ''
                  }
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteOrder();
                  }}
                  onCancel={() => {
                    this.setState({
                      showDelete: false,
                      officeId: null,
                    });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="select-wrap">
                              <ReactSelect
                                value={filterValue}
                                placeholder="Select"
                                onChange={(e) => {
                                  if (e) {
                                    let type = {};
                                    type.label = e.label;
                                    type.value = e.value;
                                    this.setState({ filterValue: type }, () => {
                                      this.getOfficeAdmin();
                                      this.forceUpdate();
                                    });
                                  } else {
                                    this.setState({ filterValue: '' }, () => {
                                      this.getOfficeAdmin();
                                    });
                                  }
                                }}
                                isClearable={true}
                                options={filterdata}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="right-section">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light m-l-10"
                          onClick={() => {
                            this.props.history.push(
                              '/add-office-admin-assistant'
                            );
                          }}
                        >
                          Add Office Admin Assistant
                        </button>
                      </div>
                    </div>
                    <Table
                      columns={columns}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.setState(
                            { currentPage: pageNo, paginationObject: {} },
                            () => {
                              this.getOfficeAdmin(pageNo);
                            }
                          );
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(OfficeAdmin);

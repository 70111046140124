import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Vendor ID',
    field: 'vendorid',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Dispatch #',
    field: 'workorder',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'Invoice Number',
    field: 'invoiceid',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date Recieved',
    field: 'dateRecieved',
    sort: 'disabled',
    width: 120,
  },
  // {
  //   label: 'Recall',
  //   field: 'recall',
  //   sort: 'disabled',
  //   width: 70,
  // },
  {
    label: 'H/O Name',
    field: 'ownerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Address',
    field: 'address',
    sort: 'disabled',
    width: 205,
  },
  {
    label: 'Zip code',
    field: 'zipcode',
    sort: 'disabled',
    width: 205,
  },
  // {
  //   label: 'Date Completed',
  //   field: 'completeDate',
  //   sort: 'disabled',
  //   width: 140,
  // },
  // {
  //   label: 'Dispatch Date',
  //   field: 'workOrderDate',
  //   sort: 'disabled',
  //   width: 120,
  // },
  // {
  //   label: 'What Was Done',
  //   field: 'whatwasdone',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Labor to Date',
  //   field: 'labortodate',
  //   sort: 'disabled',
  //   width: 120,
  // },
  // {
  //   label: 'Labor Total',
  //   field: 'labortotal',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Part Cost',
  //   field: 'partcost',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Autho Amt',
  //   field: 'authoAmount',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Autho #',
  //   field: 'authoNumber',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Est AVG COST PER CLAIM',
  //   field: 'estAvgCost',
  //   sort: 'disabled',
  //   width: 200,
  // },
  // {
  //   label: 'True AVG COST PER CLAIM',
  //   field: 'trueAvgCost',
  //   sort: 'disabled',
  //   width: 205,
  // },
  {
    label: 'Date Spring PM Complete',
    field: 'dateSpring',
    sort: 'disabled',
    width: 205,
  },
  {
    label: 'Date Fall PM Complete',
    field: 'dateFall',
    sort: 'disabled',
    width: 205,
  },
  {
    label: 'Renewal Invoice',
    field: 'renewalInvoice',
    sort: 'disabled',
    width: 205,
  },
  {
    label: 'Renewal Inv.Paid Date',
    field: 'renewalPaidDate',
    sort: 'disabled',
    width: 205,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 80,
  },
];

class CustomerPreMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      orders: [],
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      itemsPerPage: 10,
      orderId: null,
      showDelete: false,
      id: null,
      type: '',
      viewType: false,
      viewForm: false,
      showFilter: false,
      technician: '',
      warantyCode: '',
      roleId: null,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    const id = await this.props.match.params.customerId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        () => {
          this.getWorkOrder();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id },
        () => {
          this.getWorkOrder();
        }
      );
    }

    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('orderDetails')) {
      this.setState({ viewType: true });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type
    ) {
      this.setState({ type: this.props.location.state.type });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      this.setState({
        orderDetails: await this.props.location.state.orderDetails,
      });
    }
    await this.setState({ id });
  };

  downloadCSV = async () => {
    const { roleId, login_id, keyword } = this.state;

    const {
      technician,
      warantyCode,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;
    let endPoint = `${Api.downloadcsvAhsUrl}?search=${keyword}&order_type=Preventative Maintenance&allpages=1&isAuthRequired=0`;

    endPoint +=
      (roleId === 5 || roleId === 6) && login_id
        ? `&technician=${login_id}`
        : '';
    endPoint += roleId === 4 && login_id ? `&customer=${login_id}` : '';

    endPoint += technician ? `&technician=${technician}` : '';
    endPoint += warantyCode ? `&home_code=${warantyCode}` : '';
    endPoint += customer ? `&customer=${customer}` : '';
    endPoint += orderType ? `&order_type=${orderType}` : '';
    endPoint += paymentType ? `&payment_type=${paymentType}` : '';
    endPoint += WorkCompleteValue ? `&work_complete=${WorkCompleteValue}` : '';
    endPoint += !month && !year && fromDate ? `&from_date=${fromDate}` : '';
    endPoint += !month && !year && toDate ? `&to_date=${toDate}` : '';
    endPoint += month && monthyear ? `&month=${monthyear}-${month}` : '';
    endPoint += year ? `&year=${year}` : '';
    endPoint += vendorid ? `&vendorid=${vendorid}` : '';

    window.open(endPoint, '_blank');
  };

  getWorkOrder = async (currentPage) => {
    const { loginId, limit, keyword, navSkip, navCurrentPage } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${limit}&search=${keyword}&customer=${loginId}&order_type=Preventative Maintenance&isAuthRequired=0`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState(
        {
          isShowLoader: false,
          orders: data.data.data,
          currentPage: page,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onFilterSearch = async (currentPage = '') => {
    this.setState({ isShowLoader: true });
    const { roleId, login_id } = this.state;
    const {
      technician,
      warantyCode,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;

    if (currentPage == 'clear') {
      this.setState({ limit: 10, currentPage: 1 });
      var limit = 10;
      var currentPage = 1;
    } else {
      var { limit } = this.state;
    }

    this.setState({ currentPage });

    const page = currentPage || 1;
    const skip = page * limit - limit;

    var endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${limit}&order_type=Preventative Maintenance&isAuthRequired=0`;
    var str = '';
    str +=
      (roleId === 5 || roleId === 6) && login_id
        ? `&technician=${login_id}`
        : '';
    str += roleId === 4 && login_id ? `&customer=${login_id}` : '';

    str += technician ? `&technician=${technician}` : '';
    str += warantyCode ? `&home_code=${warantyCode}` : '';
    str += customer ? `&customer=${customer}` : '';
    str += orderType ? `&order_type=${orderType}` : '';
    str += paymentType ? `&payment_type=${paymentType}` : '';
    str += WorkCompleteValue ? `&work_complete=${WorkCompleteValue}` : '';
    str +=
      !month && !year && fromDate
        ? `&from_date=${customFunctions.setDateToMidNight(fromDate)}`
        : '';
    str +=
      !month && !year && toDate
        ? `&to_date=${customFunctions.setDateToMidNight(toDate)}`
        : '';
    str += month && monthyear ? `&month=${monthyear}-${month}` : '';
    str += year ? `&year=${year}` : '';
    str += vendorid ? `&vendorid=${vendorid}` : '';

    const { data } = await AuthApi.getDataFromServer(endPoint + str);
    if (data && data.data) {
      this.setState(
        {
          isShowLoader: false,
          orders: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { orders, limit, roleId, loginId } = this.state;
    const page = npage;
    const rows = [];
    orders &&
      orders.length &&
      orders.map((order, i) => {
        order.Created =
          order.Created &&
          moment(new Date(order.Created).toISOString()).format('MM/DD/yyyy');
        order.OrderCompleteDate =
          order.OrderCompleteDate &&
          moment(new Date(order.OrderCompleteDate).toISOString()).format(
            'MM/DD/yyyy'
          );
        order.labortodate =
          order.labortodate &&
          moment(new Date(order.labortodate).toISOString()).format(
            'MM/DD/yyyy'
          );
        order.WorkOrderDate =
          order.WorkOrderDate &&
          moment(new Date(order.WorkOrderDate).toISOString()).format(
            'MM/DD/yyyy'
          );
        let sprdate =
          order.spring_date &&
          customFunctions.changeDateFormatMomentMDY(order.spring_date);
        let falldate =
          order.fall_date &&
          customFunctions.changeDateFormatMomentMDY(order.fall_date);
        let reinvoicedate =
          order.invoice_date &&
          customFunctions.reinvoicedate(order.invoice_date);
        rows.push({
          index: (page - 1) * limit + (i + 1),
          vendorid: (
            <React.Fragment>
              <a
                href="/"
                className=""
                disabled={order.WorkOrder ? order.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${order.ID}`, {
                    orderDetails: order,
                    type: 'orders',
                    path: `/profile-module/${loginId}`,
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {order.refid || '-'}
              </a>
            </React.Fragment>
          ),
          workorder: order.WorkOrder || '-',
          recall: order.recall ? 'Yes' : 'No',
          ownerName: order.CustomerName || '-',
          dateRecieved: order.Created || '-',
          completeDate: order.OrderCompleteDate || '-',
          workOrderDate: order.WorkOrderDate || '-',
          invoiceid: order.InvoiceCode || '-',
          whatwasdone: order.parts || '-',
          labortodate: order.labortodate || '-',
          labortotal: order.labortotal || '-',
          partcost: order.partcost || '-',
          authoAmount: order.AuthoAmount || '-',
          authoNumber: order.AuthoNumber || '-',
          estAvgCost: order.estAvgCost || '-',
          trueAvgCost: order.trueAvgCost || '-',
          address: order.AddressLine1_1 || '-',
          zipcode: order.Zip1 || '-',
          dateSpring: sprdate || '-',
          dateFall: falldate || '-',
          renewalInvoice: order.InvoiceCode || '-',
          renewalPaidDate: reinvoicedate || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${order.ID}`, {
                    orderDetails: order,
                    type: 'orders',
                  });
                }}
              >
                {roleId === 4 ? (
                  <i className="mdi mdi-eye" title="View"></i>
                ) : (
                  <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
                )}
              </a>
              {(roleId === 1 || roleId === 2) && (
                <a
                  href="/"
                  className="px-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, orderId: order.ID });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Order"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return order;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteOrder = async () => {
    const { orderId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        this.getWorkOrder();
      });
      swal('Order deleted!', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrder();
  };

  clearSearch = () => {
    this.setState({ keyword: '', orders: [] }, () => {
      this.getWorkOrder();
    });
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      isShowLoader,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <div>
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <div className="container-fluid">
            <div className={'row'}>
              <div className="card m-b-20">
                <div className="card-body">
                  <div className="col-sm-12">
                    <div className="form-group row">
                      <form onSubmit={this.onSearchSubmit} autoComplete="off">
                        <div className="row d-flex justify-content-start">
                          <div className="col-sm-6">
                            <input
                              className="form-control"
                              value={keyword}
                              type="text"
                              placeholder="Search by H/O name, Dispatch#"
                              id="example-text-input"
                              onChange={(e) => {
                                this.setState({
                                  keyword: e.target.value,
                                });
                                if (!e.target.value) this.clearSearch();
                              }}
                              required={true}
                            />
                          </div>
                          <div className="">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Search
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.clearSearch();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                            {rows && rows.length > 0 && (
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light m-l-10"
                                onClick={() => {
                                  this.downloadCSV();
                                }}
                              >
                                <i className="mdi mdi-download"></i> CSV
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <MDBDataTable
                    noBottomColumns={true}
                    paging={false}
                    bordered
                    hover
                    data={{ columns, rows }}
                    searching={false}
                    noRecordsFoundLabel="No records found"
                    scrollX
                    maxHeight="350px"
                  />
                  {totalCount > 10 && (
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                },
                                () => this.onFilterSearch
                              )
                            : this.onFilterSearch
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(CustomerPreMaintenance);

import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import * as actionTypes from '../../../store/action';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { SetUserData } from '../../../action/user.action';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailErr: '',
      passwordErr: '',
      // fcm_token: '',
    };
  }

  componentDidMount = async () => {
    // let fcm_token = await getFCMToken();
    // if (fcm_token) {
    //   this.setState({ fcm_token });
    // }
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    };
    const userTimeOffset = new Date().getTimezoneOffset();
    customFunctions.setLocalStorage('userTimeOffset', userTimeOffset);
    const token = await customFunctions.getLocalStorage('token');
    if (token) {
      this.props.UpdateLoginAgain();
      this.props.history.push('/dashboard');
    }
  };

  validateForm = (e) => {
    if (e) e.preventDefault();
    const { email, password } = this.state;
    let emailErr = '',
      passwordErr = '',
      isValid = true;

    if (email === '') {
      emailErr = 'Email is required';
      isValid = false;
    } else if (!customFunctions.validateEmail(email)) {
      emailErr = 'Invalid email';
      isValid = false;
    }
    if (password === '') {
      passwordErr = 'Password is required';
      isValid = false;
    }
    this.setState({ emailErr, passwordErr });
    if (isValid) {
      this.loginUser();
    }
  };

  loginUser = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // let offsetMins = new Date().getTimezoneOffset();

    // let minutes = offsetMins % 60;
    // let hour = `${Math.floor((offsetMins - minutes) / 60)}`;
    // const offsetHours =
    //   offsetMins > 0
    //     ? `+${Math.abs(hour)}:${Math.abs(minutes)}`
    //     : `-${Math.abs(hour)}:${Math.abs(minutes)}`;
    const { email, password } = this.state;
    const postData = {
      email,
      password,
      offsetHours:'+5:30',
      // offsetHours:'+5:30',
      timeZone,
      // offsetMins: offsetMins.toString(),
      offsetMins: '+330',
      // fcm_token,
    };
    const { data } = await AuthApi.postDataToServer(Api.loginNewUrl, postData);
    if (data && data.data && data.data.token) {
      await customFunctions.setLocalStorage('token', data.data.token);
      await customFunctions.setDataToLocalStorage(
        'userdata',
        data.data.userData,
        true
      );
      this.props.SetUserData(data.data.userData);
      await customFunctions.setLocalStorage('isLogin', true);
      this.props.history.push('/dashboard');
    } else {
      NotificationManager.error(
        (data && data.message) || 'Incorrect username and password !'
      );
    }
  };

  render() {
    const { email, password, emailErr, passwordErr } = this.state;
    return (
      <AUX>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <NotificationContainer />
          <div className="card">
            <div className="card-body">
              <h3 className="text-center m-0">
                {/* <Link
                  to="/"
                  onClick={() => this.props.UpdateLoginAgain()}
                  className="logo logo-admin"
                > */}
                <img
                  alt=""
                  src="assets/images/logo1.jpg"
                  height="130px"
                  width="280px"
                />
                {/* </Link> */}
              </h3>
              <div className="p-3">
                <p className="text-muted text-center">Sign in to continue</p>
                <form
                  className="form-horizontal m-t-30"
                  onSubmit={this.validateForm}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter username"
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                    <span id="err">{emailErr}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="userpassword">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                    <span id="err">{passwordErr}</span>
                  </div>
                  <div className="form-group row m-t-20">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 text-right">
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20">
                      <Link to="forget-password" className="text-muted">
                        <i className="mdi mdi-lock"></i> Forgot your password?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => ({
  UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
  UpdateLoginAgain: () =>
    dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  SetUserData: (data) => dispatch(SetUserData(data)),
});

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);

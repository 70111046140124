import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import ComplaintFilter from './complaintFilter';
import {
  SetComplaintTechnician,
  SetComplaintCustomer,
  SetComplaintWarrantyCode,
  SetComplaintCategory,
  SetComplaintStatus,
  SetComplaintMonth,
  SetComplaintMonthYear,
  SetComplaintYear,
  SetComplaintFromDate,
  SetComplaintToDate,
  SetWeekType,
  SetSearchType,
  SetFilterType,
} from '../../../action/user.action';
import { connect } from 'react-redux';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';

class ComplaintData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      complaints: [],
      rows: [],
      columns: [],
      complaintId: null,
      showDelete: false,
      showFilter: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      imageFile: null,
      imageUrl: '',
      id: null,
      type: null,
      isWriteAllowed: false,
      addNewAllowed: false,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let addNewAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addnewcomplaint',
      'write'
    );
    if (addNewAllowed) this.setState({ addNewAllowed: true });
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'complaints-accesscomplaints'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'complaints-accesscomplaints',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('All Complaints');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          login_id: userdata.login_id,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          this.getComplaints();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, login_id: userdata.login_id },
        () => {
          this.getComplaints();
        }
      );
    }

    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Complaint #',
        field: 'complaint',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'Technician',
        field: 'technician',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Customer',
        field: 'CustomerName',
        sort: 'disabled',
        width: 150,
      },
      // {
      //   label: 'Closed Date',
      //   field: 'closeddate',
      //   sort: 'disabled',
      //   width: 120,
      // },
      {
        label: 'Complaint Status',
        field: 'complaintstatus',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'Complaint Date',
        field: 'created',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Complaint Details',
        field: 'complaintdetails',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
        width: 80,
      },
    ];
    if (userdata.role_id == 4) {
      columns.splice(3, 1);
    }
    if (userdata.role_id == 5) {
      columns.splice(2, 1);
    }
    this.setState({ columns });
  };

  getComplaints = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, roleId, login_id, navSkip, navCurrentPage } =
      this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${Api.complaintUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    if (roleId == 4 && login_id) {
      endPoint += `&customer=${login_id}`;
    }
    if ((roleId == 5 || roleId == 6) && login_id) {
      endPoint += `&technician=${login_id}`;
    }

    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.success !== 'true') {
      this.setState(
        {
          isShowLoader: false,
          complaints: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { complaints, limit, roleId, isWriteAllowed } = this.state;
    const page = npage;
    const rows = [];
    complaints &&
      complaints.length &&
      complaints.map((complaint, i) => {
        let item = complaint;
        complaint.Created = customFunctions.changeDateFormatMDY(
          complaint.Created
        );
        if (complaint.ComplaintOpenDate) {
          item.ComplaintOpenDate = new Date(complaint.ComplaintOpenDate);
        }
        if (complaint.ComplaintCloseDate) {
          item.ComplaintCloseDate = new Date(complaint.ComplaintCloseDate);
        }

        item.ComplaintOpenDate = item.ComplaintOpenDate
          ? customFunctions.changeDateFormatMDY(complaint.ComplaintOpenDate)
          : '';
        item.ComplaintCloseDate = item.ComplaintCloseDate
          ? customFunctions.changeDateFormatMDY(complaint.ComplaintCloseDate)
          : '';

        rows.push({
          index: (page - 1) * limit + (i + 1),
          complaint: complaint.ComplaintCode || '-',
          homewarrantycode: complaint.HomeWarrantyCode || '-',
          technician: complaint.Technician || '-',
          CustomerName: complaint.CustomerName || '-',
          // opendate: item.ComplaintOpenDate || '',
          // closeddate: item.ComplaintCloseDate || '',
          created: complaint.Created || '',
          complaintstatus: complaint.ComplaintStatus || '-',
          complaintdetails: (
            <div className="strwrap1">{complaint.ComplaintDetails || '-'}</div>
          ),
          actions: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/edit-complaint/${complaint.ID}`, {
                    path: '/view-complaints',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                {roleId === 4 ? (
                  <i className="mdi mdi-eye" title="View Complaint"></i>
                ) : (
                  <i className="mdi mdi-lead-pencil" title="Edit Complaint"></i>
                )}
              </a>
              {roleId !== 5 && roleId !== 6 && roleId !== 4 && (
                <a
                  href="/"
                  style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      showDelete: true,
                      complaintId: complaint.ID,
                    });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Complaint"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return complaint;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  onFilterSearch = async (currentPage) => {
    this.setState({ isShowLoader: true });
    const { limit, roleId } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const {
      complaintTechnician,
      complaintCustomer,
      complaintCategory,
      complaintStatus,
      complaintMonth,
      complaintYear,
      complaintMonthYear,
      complaintFromDate,
      complaintToDate,
    } = this.props;
    var endPoint = `${Api.complaintUrl}?skip=${skip}&limit=${limit}`;

    endPoint +=
      roleId === 5
        ? `&technician=${roleId}`
        : complaintTechnician && complaintTechnician.id
        ? `&technician=${complaintTechnician.id}`
        : '';
    endPoint +=
      complaintCategory && complaintCategory.name
        ? `&&category=${complaintCategory.name}`
        : '';
    endPoint +=
      complaintStatus && complaintStatus.name
        ? `&status=${complaintStatus.name}`
        : '';
    endPoint +=
      roleId === 4
        ? `&customer=${roleId}`
        : complaintCustomer && complaintCustomer.id
        ? `&customer=${complaintCustomer.id}`
        : '';

    endPoint += complaintFromDate
      ? `&from_date=${customFunctions.setDateToMidNight(complaintFromDate)}`
      : '';
    endPoint += complaintToDate
      ? `&to_date=${customFunctions.setDateToMidNight(complaintToDate)}`
      : '';
    endPoint += complaintMonth
      ? `&month_year=${complaintMonthYear}-${complaintMonth}`
      : '';
    endPoint += complaintYear ? `&year=${complaintYear}` : '';

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          isShowLoader: false,
          complaints: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  deleteComplaint = async () => {
    const { complaintId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.complaintUrl}?complaint_id=${complaintId}`
    );
    if (data) {
      this.setState({ showDelete: false, customerId: null }, () => {
        swal('Complaint deleted', '', 'success').then(() => {
          this.getComplaints();
        });
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();

    this.getComplaints();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getComplaints();
    });
  };

  onAdvancedSearch = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  // onSubmit = () => {
  //   this.setState({ showFilter: false });
  // };

  onCancelBtn = async () => {
    this.setState({ showFilter: false });
    const {
      SetComplaintTechnician,
      SetComplaintCustomer,
      SetComplaintWarrantyCode,
      SetComplaintCategory,
      SetComplaintStatus,
      SetComplaintMonth,
      SetComplaintMonthYear,
      SetComplaintYear,
      SetComplaintFromDate,
      SetComplaintToDate,
      SetWeekType,
      SetSearchType,
      SetFilterType,
    } = this.props;

    await SetComplaintTechnician(null);
    await SetComplaintCustomer('');
    await SetComplaintWarrantyCode('');
    await SetComplaintCategory('');
    await SetComplaintStatus('');
    await SetComplaintMonth('');
    await SetComplaintMonthYear('');
    await SetComplaintYear('');
    await SetComplaintFromDate('');
    await SetComplaintToDate('');
    await SetWeekType('');
    await SetSearchType('');
    await SetFilterType('');
  };

  downloadCSV = async () => {
    const { keyword } = this.state;
    const {
      complaintTechnician,
      complaintCustomer,
      complaintCategory,
      complaintStatus,
      complaintMonth,
      complaintYear,
      complaintMonthYear,
      complaintFromDate,
      complaintToDate,
    } = this.props;

    var endPoint = `${Api.downloadComplaintsCsvUrl}?search=${keyword}`;

    endPoint +=
      complaintTechnician && complaintTechnician.id
        ? `&technician=${complaintTechnician.id}`
        : '';
    endPoint +=
      complaintCategory && complaintCategory.name
        ? `&&category=${complaintCategory.name}`
        : '';
    endPoint +=
      complaintStatus && complaintStatus.name
        ? `&status=${complaintStatus.name}`
        : '';
    endPoint +=
      complaintCustomer && complaintCustomer.id
        ? `&customer=${complaintCustomer.id}`
        : '';
    endPoint += complaintFromDate ? `&from_date=${complaintFromDate}` : '';

    endPoint += complaintToDate ? `&to_date=${complaintToDate}` : '';
    endPoint += complaintMonth
      ? `&month_year=${complaintMonthYear}-${complaintMonth}`
      : '';
    endPoint += complaintYear ? `&year=${complaintYear}` : '';

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      showFilter,
      addNewAllowed,
      columns,
      isShowLoader,
      navSkip,
      navCurrentPage,
    } = this.state;
    const { searchType, SetSearchType } = this.props;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Complaints" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="All the complaints raised by the customer will get deleted"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteComplaint();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, complaintId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body mdbtable">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by technician,customer"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-8">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                              <button
                                type="reset"
                                onClick={async () => {
                                  if (searchType !== 'complaintsearch') {
                                    await this.onCancelBtn();
                                    await SetSearchType('complaintsearch');
                                  }
                                  this.onAdvancedSearch();
                                }}
                                className="btn btn-primary waves-effect waves-light m-l-10"
                              >
                                Advance Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6 text-right">
                        {rows && rows.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                        {addNewAllowed && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={() => {
                              this.props.history.push('/add-complaint');
                            }}
                          >
                            Add Complaint
                          </button>
                        )}
                      </div>
                    </div>

                    {showFilter && (
                      <ComplaintFilter
                        // onSubmitButtonCallback={() => {
                        //   this.onSubmit();
                        // }}
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={this.onFilterSearch}
                      />
                    )}

                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      // getData={this.getComplaints}
                      noRecordsFoundLabel="No records found"
                      scrollX
                      maxHeight="400px"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getComplaints
                                )
                              : this.getComplaints
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  complaintTechnician: state.auth.complaintTechnician,
  complaintWarantyCode: state.auth.complaintWarantyCode,
  complaintCustomer: state.auth.complaintCustomer,
  complaintCategory: state.auth.complaintCategory,
  complaintStatus: state.auth.complaintStatus,
  complaintFromDate: state.auth.complaintFromDate,
  complaintToDate: state.auth.complaintToDate,
  complaintMonth: state.auth.complaintMonth,
  complaintMonthYear: state.auth.complaintMonthYear,
  complaintYear: state.auth.complaintYear,
  searchType: state.auth.searchType,
  weektype: state.auth.weektype,
});

const mapDispatchToProps = (dispatch) => ({
  SetComplaintTechnician: (complaintTechnician) =>
    dispatch(SetComplaintTechnician(complaintTechnician)),
  SetComplaintCustomer: (complaintCustomer) =>
    dispatch(SetComplaintCustomer(complaintCustomer)),
  SetComplaintWarrantyCode: (complaintWarantyCode) =>
    dispatch(SetComplaintWarrantyCode(complaintWarantyCode)),
  SetComplaintCategory: (complaintCategory) =>
    dispatch(SetComplaintCategory(complaintCategory)),
  SetComplaintStatus: (complaintStatus) =>
    dispatch(SetComplaintStatus(complaintStatus)),
  SetComplaintMonth: (complaintMonth) =>
    dispatch(SetComplaintMonth(complaintMonth)),
  SetComplaintMonthYear: (complaintMonthYear) =>
    dispatch(SetComplaintMonthYear(complaintMonthYear)),
  SetComplaintYear: (complaintYear) =>
    dispatch(SetComplaintYear(complaintYear)),
  SetComplaintFromDate: (complaintFromDate) =>
    dispatch(SetComplaintFromDate(complaintFromDate)),
  SetComplaintToDate: (complaintToDate) =>
    dispatch(SetComplaintToDate(complaintToDate)),
  SetSearchType: (searchType) => dispatch(SetSearchType(searchType)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
  SetWeekType: (weektype) => dispatch(SetWeekType(weektype)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintData);

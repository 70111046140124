import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HelmetComponent from '../Title/index';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import html2pdf from 'html2pdf.js';
import Table from '../../../components/Table/simpletable.component';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Card } from 'react-bootstrap';
import PaymentPopup from './paymentPopup';
import Loader from '../../../components/Loader';

class TechPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      techniciandata: [],
      rows: [],
      columns: [],
      techPayWorkorders: [],
      technicianid: null,
      technician_sign: null,
      selectedWorkOrderId: null,
      techpaydata: [],
      paymentPopup: false,
      isShowLoader: false,
      ipaddress: '',
    };
  }

  sigPad = {};

  saveSign = () => {
    this.setState(
      {
        technician_sign: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      },
      () => {
        this.approve();
      }
    );
  };

  clearSign = () => {
    this.sigPad.clear();
  };

  approve = async () => {
    const { transactionid, techPayWorkorders, technician_sign, ipaddress } =
      this.state;
    let approvedobjects = [];
    techPayWorkorders &&
      techPayWorkorders.map((item, i) => {
        // if (
        //   selectedWorkOrderId &&
        //   selectedWorkOrderId.length &&
        //   selectedWorkOrderId.includes((i + 1).toString())
        // ) {
        // }
        approvedobjects.push({
          WorkOrder: item.WorkOrder,
          approvedstatus: 'approvedbytechnician',
        });
        return item;
      });
    let tech_sign = {
      signurl: technician_sign,
      date: new Date(),
      ipaddress: ipaddress,
    };
    let postData = {
      transactionid,
      approvedobjects,
      tech_sign: JSON.stringify(tech_sign),
      approvedstatus: 'approvedbytechnician',
    };
    const endPoint = `${Api.technicianTransaction}/by_technician`;
    const callback = AuthApi.putDataToServer;

    const { data, message } = await callback(endPoint, postData);
    if (data) {
      swal('Approved succcessfully!', '', 'success');
      this.props.history.goBack();
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  componentDidMount = async () => {
    customFunctions.setTitle('TechPay Sheet');
    //creating function to load ip address from the API

    fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      }, 'jsonp')
      .then((res) => {
        this.setState({ ipaddress: res.ip });
      });

    await this.getTechnician();

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    const columns = [
      {
        label: 'Date',
        id: 'created',
        width: 150,
      },
      {
        label: 'Customer',
        id: 'customer',
        width: 150,
      },
      {
        label: 'WorkOrder',
        id: 'workOrder',
        width: 150,
      },
      {
        label: 'Invoice',
        id: 'invoice',
        width: 150,
      },
      {
        label: 'PO',
        id: 'po',
        width: 150,
      },
      {
        label: 'Installer Cost',
        id: 'installerPay',
        width: 150,
      },
      {
        label: 'Daily Rate',
        id: 'dailyRate',
        width: 150,
      },
      {
        label: 'Commission',
        id: 'commission',
        width: 150,
      },
      {
        label: 'Deduction',
        id: 'deduction',
        width: 150,
      },
    ];

    this.setState({ roleId: userdata.role_id, columns });
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.techpay
    ) {
      this.setState(
        {
          techPayWorkorders: this.props.location.state.techpay.objectstoverify,
          technicianid: this.props.location.state.techpay.technicianid,
          transactionid: this.props.location.state.techpay.transactionid,
          techpaydata: this.props.location.state.techpay,
          technician_sign: JSON.parse(
            this.props.location.state.techpay.techniciansign
          ),
        },
        () => {
          this.setTableData();
        }
      );
    }
    // else{
    //   await this.getTechPay();
    // }
  };

  getTechnician = async () => {
    const endPoint = `${Api.technicianUrl}?skip=0&limit=10000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        techniciandata: data.data.data,
      });
    }
  };
  // by_technician
  // getTechPay = async () => {
  //   this.setState({ selectedWorkOrderId: [] });
  //   const {technician} = this.props;
  //   const endPoint = `${Api.technicianTransaction}?skip=0&limit=1000`;
  //   const url = customFunctions.generateUrl(endPoint, params);

  //   const { data, message } = await AuthApi.getDataFromServer(url);

  //   if (data && data.success !== false) {
  //     this.setState(
  //       {
  //         paysheetData: data.data.data,
  //         totalCount: data.data.total_count,
  //       },
  //       () => {
  //         this.setTableData();
  //         this.forceUpdate();
  //       }
  //     );
  //   } else {
  //     swal(message ? message : "Something went wrong", '', 'error');
  //   }
  // };

  setTableData = () => {
    const { techPayWorkorders } = this.state;
    const rows = [];
    techPayWorkorders &&
      techPayWorkorders.map((paysheet, i) => {
        paysheet.createdat =
          paysheet.createdat &&
          customFunctions.changeDateFormatMDY(paysheet.createdat);
        paysheet.Created =
          paysheet.Created &&
          customFunctions.changeDateFormatMDY(paysheet.Created);
        rows.push({
          created: paysheet.WorkOrder.startsWith('company-name-WO')
            ? paysheet.Created
            : paysheet.createdat,
          customer: paysheet.customer,
          workOrder: paysheet.WorkOrder || '-',
          invoice: paysheet.invoiceid || '-',
          po: paysheet.PO || '-',
          installerPay: paysheet.InstallerCost || '0',
          dailyRate: paysheet.daily_rate || '0',
          commission: paysheet.tech_commision || '0',
          deduction: paysheet.PayrollAmount || '0',
        });
        return paysheet;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  // onClickDownloadPDF = async (id) => {
  //   const input = document.getElementById(id);
  //   if (input) {
  //     var pdf = await new jsPDF();
  //     if (pdf) {
  //       this.setState({
  //         isShowLoader: false,
  //       });
  //       await domtoimage.toPng(input).then(async (imgData) => {
  //         var width = pdf.internal.pageSize.getWidth();
  //         var height = pdf.internal.pageSize.getHeight();
  //         await pdf.addImage(imgData, 'PNG', 10, 10, width, height);
  //         pdf.save('download.pdf');
  //       });
  //     }
  //   }
  // };

  onClickDownloadPDF = async (id) => {
    this.setState({ isShowLoader: true });
    var element = document.getElementById(id);
    // var opt = {
    //   margin: 1,
    //   filename: 'download.pdf',
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: {
    //     scale: 1,
    //     bottom: 20,
    //   },
    //   pagebreak: { mode: 'avoid-all',   after: '.targetDiv'  },
    //   // pagebreak: { mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' },
    //   jsPDF: {
    //     unit: 'mm',
    //     format: 'a4',
    //     orientation: 'portrait',
    //     pagesplit: true,
    //   },
    //   // jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
    // };
    var opt = {
      margin: 0.5,
      filename: 'download.pdf',
      image: {
        type: 'jpeg',
        quality: 0.98,
      },
      html2canvas: {
        scale: 3,
        letterRendering: true,
      },
      //  pagebreak: {before: '.targetDiv' },
      jsPDF: {
        unit: 'in',
        format: 'a3',
        orientation: 'portrait',
      },
    };
    html2pdf().from(element).set(opt).save();
    this.setState({
      isShowLoader: false,
    });
  };

  render() {
    const {
      rows,
      columns,
      roleId,
      technician_sign,
      techpaydata,
      paymentPopup,
      isShowLoader,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="PaySheet" />
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary float-right mr-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onClickDownloadPDF('pdfDownload');
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <div className="card m-b-20" id="pdfDownload">
                  <div className="card-header">
                    <div className="text-center">
                      <h4>QUALITY company-name LLC</h4>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 style={{ 'white-space': 'nowrap' }}>
                        TECHNICIAN: {techpaydata.technician}
                      </h6>
                      {/* <h6>WEEK OF: {techpaydata.technician1}</h6> */}
                      <span className="float-end">
                        {techpaydata.paysheet_period}
                      </span>
                    </div>
                  </div>
                  <div className="card-body paysheet-table">
                    <div className="">
                      <Table
                        columns={columns}
                        rows={rows}
                        onSelectRowsIndexes={(indexes) => {
                          this.setState({ selectedWorkOrderId: indexes });
                        }}
                        tableClass="table-bordered"
                        dropdownContainerClass="mb-3"
                        emptyRender={() => (
                          <p className="text-center mb-0">No record found</p>
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="tbl-span2">
                        <table>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Total No. Day: </td>
                            <td>{techpaydata.noofdays}</td>
                          </tr>
                          {/* <tr style={{ 'white-space': 'nowrap' }}>
                              <td>Total No. Days: </td>
                              <td>{techpaydata.noofdays}</td>
                            </tr> */}
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>CBR YTD Balance: </td>
                            <td>
                              ${' '}
                              {techpaydata.cbrytdbalance
                                ? techpaydata.cbrytdbalance
                                : '0'}
                            </td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Paycheck Advance Balance: </td>
                            <td>$ {techpaydata.paycheckadvbalance}</td>
                          </tr>
                        </table>
                      </div>
                      <div className="tbl-span2 mr-3">
                        <table>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Daily Rate Amount: </td>
                            <td>$ {techpaydata.daily_rate}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Commission Amount:</td>
                            <td>$ {techpaydata.commission}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Deductions: </td>
                            <td>$ {techpaydata.deduction}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Commission Total:</td>
                            <td>$ {techpaydata.commissionTotal}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>CBR: </td>
                            <td>$ {techpaydata.cbr_amount}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Installer Pay:</td>
                            <td>$ {techpaydata.InstallerCost}</td>
                          </tr>

                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Total Pay: </td>
                            <td>$ {techpaydata.amount}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Paycheck Advance Owed: </td>
                            <td>
                              ${' '}
                              {techpaydata.paycheckadvowed
                                ? techpaydata.paycheckadvowed
                                : '0'}
                            </td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Paycheck Advance: </td>
                            <td>$ {techpaydata.paycheckadv}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Final Pay: </td>
                            <td>
                              ${' '}
                              {techpaydata.finalpay
                                ? techpaydata.finalpay
                                : '0'}
                            </td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Status: </td>
                            <td>{techpaydata.ispaid}</td>
                          </tr>
                          <tr style={{ 'white-space': 'nowrap' }}>
                            <td>Transaction Id: </td>
                            <td>{techpaydata.manualtid}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-12 targetDiv">
                      <div className="col-6 mb-5">
                        <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          I Agree That I Have Been Paid in Full:
                        </p>
                        <p>
                          SUBCONTRACTOR ACKNOWLEDGES THEY HAVE BEEN PAID AS AN
                          INOPENDENT CONTRACTOR AND ACKNOWLEDGES THEY ARE
                          RESPONSIBLE FOR ALL OF THEIR INCOME TAXES, SELF
                          EMPLOYMENT TAXES, WORKMANS COMPENSATION. A WORKMANS
                          COMPENSATION WAIVER CAN BE ON FILE. SUBCONTRACTOR IS
                          RESPONSIBLE FOR All QUALITY WORKMANSHIP OF SERVICE
                          REGARDLESS IF HIRED OR NON HIRED SUBCONTRACTORS ARE
                          USED. SUBCONTRACTOR AGREES TO PROVIDE GENERAL AND AUTO
                          LIABILITY INSURANCE TO QUALITY company-name U.( AND TO
                          MAKE QUALITY company-name AWARE OF ANY CHANGES THAT
                          MAY ACCUR TO INSURANCE. ALL 1099 ISSUED REFLECT ALL
                          PARTS USED ON OUR INVOICES AND HAVE BEEN REIMBURSED
                          100%
                        </p>
                      </div>
                      {(roleId === 5 || roleId === 6) &&
                        technician_sign == null && (
                          <div
                            style={{
                              display: 'inline-block',
                              border: '1px solid darkgrey',
                              marginBottom: '10px',
                            }}
                          >
                            <SignatureCanvas
                              penColor="green"
                              canvasProps={{
                                width: 340,
                                height: 240,
                                className: 'sigCanvas',
                              }}
                              style={{ border: '1px solid' }}
                              ref={(ref) => {
                                this.sigPad = ref;
                              }}
                            />
                          </div>
                        )}
                      {(roleId === 5 || roleId === 6) &&
                        technician_sign == null && (
                          <div>
                            <Button
                              variant="secondary"
                              style={{ marginLeft: '10px' }}
                              type="reset"
                              onClick={() => this.clearSign()}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="secondary"
                              style={{ marginLeft: '10px' }}
                              type="reset"
                              onClick={() => this.saveSign()}
                            >
                              Sign and Approve
                            </Button>
                          </div>
                        )}
                      {technician_sign !== null && (
                        <Card.Body className="sign-image mt-5">
                          <img src={technician_sign.signurl} alt="sign" />
                          <span className="justify-content-center d-flex mt-4">
                            {techpaydata.technician &&
                              `${techpaydata.technician}`}
                          </span>
                          <span className="justify-content-center d-flex">
                            {' '}
                            {technician_sign.date &&
                              `Signature Date: ${customFunctions.getFormattedDate(
                                technician_sign.date
                              )}`}
                            {technician_sign.ipaddress &&
                              ` IP address:${technician_sign.ipaddress}`}
                          </span>
                        </Card.Body>
                      )}
                      {(roleId !== 5 || roleId !== 6) &&
                        technician_sign === null && (
                          <Card.Body style={{ textAlign: 'center' }}>
                            Not yet signed and Approved!
                          </Card.Body>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentPopup
          // invoiceId={invoiceId}
          // due_amount={due_amount}
          // roleId={roleId}
          // totalAmount={total}
          paymentPopup={paymentPopup}
          // callback={() =>
          //   this.setState({ showPaymentPopup: false }, () => {
          //     this.getInvoiceDetailsData(invoiceId);
          //     this.forceUpdate();
          //   })
          // }
          handleClose={() => this.setState({ paymentPopup: false })}
        />
      </AUX>
    );
  }
}

export default withRouter(TechPay);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.places-container {
    position: absolute;
    z-index: 98;
    background-color: #fff;
    max-height: 270px;
    overflow: auto;
    padding: 10px 20px;
    border: 1px solid #ced4da;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}
.floating-label-feild{
    padding-bottom: 10px;
}

.places-container li{
    list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/components/PlaceSearch/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".places-container {\n    position: absolute;\n    z-index: 98;\n    background-color: #fff;\n    max-height: 270px;\n    overflow: auto;\n    padding: 10px 20px;\n    border: 1px solid #ced4da;\n    width: 100%;\n}\n\n.cursor-pointer {\n    cursor: pointer;\n}\n.floating-label-feild{\n    padding-bottom: 10px;\n}\n\n.places-container li{\n    list-style: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

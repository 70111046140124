import html2pdf from 'html2pdf.js';
import React, { Component } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

class ApproveByAdminComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractBody: '',
      contractTerms: '',
      roleId: null,
      loginId: null,
      loanId: null,
      technicianId: null,
      signUrl: null,
      tech_sign: null,
      swalMsg: '',
      isShowLoader: false,
      amountToPay: null,
      requestAmount: null,
      contractTermsId: null,
      amountToDeduct: null,
      requestAmountErr: '',
      amountToDeductErr: '',
      amountToPayErr: '',
      contractErr: '',
      signUrlErr: '',
      title: '',
      checkTerms: '',
      advanceReq: false,
      isApprove: false,
      sign_date: '',
      textAreaToggle: false,
      paginationOptions: {},
      ipaddress: '',
      technicianName: '',
    };
  }

  sigPadTechnician = {};

  saveTechnicianSign = (e) => {
    if (
      this.sigPad &&
      this.sigPad.getTrimmedCanvas().width > 1 &&
      this.sigPad.getTrimmedCanvas().height > 1
    ) {
      this.setState(
        { signUrl: this.sigPad.getTrimmedCanvas().toDataURL('image/png') },
        () => {
          this.validateForm();
        }
      );
    } else {
      this.validateForm();
    }
  };

  componentDidMount = async () => {
    let adv = null;
    let aprroveId = null;
    //creating function to load ip address from the API

    fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      }, 'jsonp')
      .then((res) => {
        this.setState({ ipaddress: res.ip });
      })
      .catch((err) => console.log(err));

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    if (
      userdata.role_id === 5 ||
      userdata.role_id === 6 ||
      userdata.role_id === 1
    ) {
      this.getParentsData();
    }
    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
    });
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.advanceReq
    ) {
      adv = this.props.location.state.advanceReq;
      this.setState({ advanceReq: this.props.location.state.advanceReq });
    }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.technicianId
    ) {
      this.setState({ technicianId: this.props.location.state.technicianId });
    }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isApprove
    ) {
      this.setState({ isApprove: this.props.location.state.isApprove });
    }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.technicianName
    ) {
      this.setState({
        technicianName: this.props.location.state.technicianName,
      });
    }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.loanId
    ) {
      aprroveId = this.props.location.state.loanId;
      this.setState({ loanId: this.props.location.state.loanId });
    }
    if (aprroveId) {
      this.setState({
        title: adv ? `Request advance paycheck` : `Approve advance paycheck`,
      });
      await this.getApprovePaycheckData(aprroveId);
    } else {
      this.setState({
        title: adv ? `Request advance paycheck` : `Approve advance paycheck`,
        invoiceToggle: true,
      });
    }
  };

  getApprovePaycheckData = async (aprroveId) => {
    this.setState({
      isShowLoader: true,
    });
    if (aprroveId === 'null') {
      this.setState({
        isShowLoader: false,
      });
      this.props.history.push('/advance-paycheck');
      return;
    }

    const endPoint = `${Api.getTechnicianLoanById}?id=${aprroveId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success) {
      this.setState({
        isShowLoader: false,
        tech_sign: data && data.data && data.data.technician_sign,
        sign_date: data && data.data && data.data.sign_date,
        requestAmount: data && data.data && data.data.requestedamount,
        amountToDeduct: data && data.data && data.data.deductamount,
        amountToPay: data && data.data && data.data.approvedamount,
      });
      this.forceUpdate();
    } else {
      var $this = this;
      swal(message ? message : 'No data found', '', 'error');
      this.setState({
        isShowLoader: false,
      });
      setTimeout(() => {
        $this.props.history.push('/advance-paycheck');
      }, 1000);
    }
  };

  getParentsData = async () => {
    let endPoint = `${Api.contractLoanUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        contractTerms: data.data.contract_name,
        contractBody: data.data.contract_body,
        contractTermsId: data.data.id,
      });
    }
  };

  validateForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      amountToPay,
      amountToDeduct,
      requestAmount,
      signUrl,
      advanceReq,
      roleId,
    } = this.state;

    let requestAmountErr,
      amountToDeductErr,
      amountToPayErr,
      signUrlErr,
      contractErr,
      isValid = true;

    if (roleId === 1) {
      if (!amountToDeduct) {
        amountToDeductErr = 'Amount to deduct is required';
        isValid = false;
      }

      if (!amountToPay) {
        amountToPayErr = 'Amount to pay is required';
        isValid = false;
      }

      // if (Number(amountToPay) > Number(requestAmount)) {
      //   amountToPayErr = 'Amount should be less than requested amount';
      //   isValid = false;
      // }
    }

    if (roleId === 5 || roleId === 6) {
      if (!signUrl && advanceReq) {
        signUrlErr = 'Technician sign is required';
        isValid = false;
      }
      if (!requestAmount) {
        requestAmountErr = 'Request amount is required';
        isValid = false;
      }
    }
    this.setState({
      amountToPayErr,
      amountToDeductErr,
      requestAmountErr,
      signUrlErr,
      contractErr,
    });

    if (isValid) {
      this.addApprovePaycheckData('signSave');
    }
  };

  addApprovePaycheckData = async (res = '') => {
    const {
      signUrl,
      roleId,
      tech_sign,
      advanceReq,
      requestAmount,
      amountToPay,
      amountToDeduct,
      loanId,
      loginId,
      technicianId,
      contractTermsId,
      isApprove,
      ipaddress,
    } = this.state;
    const postData = {
      requestedamount: Number(requestAmount),
      approvedamount: Number(amountToPay),
      deductamount: Number(amountToDeduct),
      techid: technicianId ? technicianId : loginId,
      id: loanId,
      status: isApprove ? 'approved' : 'pending',
      contractid: contractTermsId,
    };
    let sign = {};
    sign.signature_date = new Date().toISOString();
    sign.ipaddress = ipaddress;

    if ((roleId === 5 || roleId === 6) && signUrl !== null) {
      postData.techsign = tech_sign || signUrl;
      postData.signdate = new Date().toISOString();
    }
    const endpoint = Api.technicianPaycheck;
    const payload = customFunctions.cleanObject(postData);
    const callback =
      technicianId || advanceReq
        ? AuthApi.putDataToServer
        : AuthApi.postDataToServer;

    const { data, message } = await callback(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
      swal('Loan amount request sent successfully', '', 'success').then(() => {
        this.resetData();
      });

      if (technicianId) {
        swal(
          !isApprove ? 'Updated!' : 'Loan approved successfully',
          '',
          'success'
        );
        this.resetData();
        return;
      }
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  resetData = () => {
    const { advanceReq, paginationOptions } = this.state;
    this.setState({
      amountToPay: null,
      amountToDeduct: null,
      signUrl: null,
    });

    if (advanceReq)
      this.setState({
        requestAmount: null,
      });

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.path
    ) {
      this.props.history.push(`${this.props.location.state.path}`, {
        paginationObj: paginationOptions,
      });
    } else {
      this.props.history.push('/advance-paycheck');
    }
  };

  clearSign = (e) => {
    e.preventDefault();
    this.sigPad.clear();
    this.setState({ signUrl: null });
  };

  onClickDownloadPDF = async (id) => {
    var element = document.getElementById(id);

    var opt = {
      margin: 2,
      filename: 'download.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        bottom: 20,
      },
      pagebreak: { mode: 'avoid-all', after: '.targetDiv' },
      jsPDF: {
        unit: 'mm',
        orientation: 'portrait',
      },
    };
    // New Promise-based usage:
    setTimeout(async () => {
      await html2pdf().from(element).set(opt).save();
      await this.setState({ textAreaToggle: false });
      this.setState({ isShowLoader: false });
    }, 3000);
  };

  render() {
    const {
      contractTerms,
      contractBody,
      tech_sign,
      roleId,
      isShowLoader,
      title,
      amountToPayErr,
      amountToDeductErr,
      requestAmountErr,
      signUrlErr,
      amountToPay,
      requestAmount,
      amountToDeduct,
      advanceReq,
      loanId,
      sign_date,
      checkTerms,
      isApprove,
      textAreaToggle,
      paginationOptions,
      technicianName,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            {loanId && !isApprove && (
              <div className="row d-flex justify-content-end">
                <button
                  className="btn btn-primary mr-3"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await this.setState(
                      { textAreaToggle: true, isShowLoader: true },
                      () => {
                        this.onClickDownloadPDF('paycheckPdfDownload');
                      }
                    );
                  }}
                >
                  Download PDF
                </button>
              </div>
            )}
            <div className="row">
              <div className="col-12" id="paycheckPdfDownload">
                <div className="card m-b-23 targetDiv">
                  <div className="card-header">
                    {advanceReq
                      ? `Request advance paycheck`
                      : `Approve advance paycheck`}
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={(e) => {
                        if (roleId === 5 && advanceReq && checkTerms) {
                          e.preventDefault();
                          this.saveTechnicianSign();
                        }
                        this.validateForm(e);
                      }}
                      autoComplete="off"
                    >
                      <>
                        <div className="form-group row">
                          {/* <div className="col-sm-12" /> */}
                          <label
                            htmlFor="request-amount"
                            className="col-sm-4 col-form-label small-size"
                          >
                            Request Amount
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="request-amount"
                                >
                                  $
                                </span>
                              </div>
                              {textAreaToggle ? (
                                <p>{requestAmount}</p>
                              ) : (
                                <input
                                  type="text"
                                  id="request-amount"
                                  className="form-control"
                                  placeholder="Request Amount"
                                  value={requestAmount}
                                  disabled={loanId}
                                  onChange={(e) => {
                                    const re = /^[0-9]*\.?[0-9]*$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        requestAmount: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              )}
                            </div>
                            <span id="err">{requestAmountErr}</span>
                          </div>
                        </div>
                        {loanId && (
                          <div className="form-group row">
                            {/* <div className="col-sm-1" /> */}
                            <label
                              htmlFor="pay-amount"
                              className="col-sm-4 col-form-label small-size"
                            >
                              Amount to Pay
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-7">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="pay-amount"
                                  >
                                    $
                                  </span>
                                </div>
                                {textAreaToggle ? (
                                  <p>{amountToPay}</p>
                                ) : (
                                  <input
                                    className="form-control"
                                    value={amountToPay}
                                    disabled={
                                      roleId === 5 || roleId === 6 || tech_sign
                                        ? true
                                        : false
                                    }
                                    type="text"
                                    placeholder="Amount to pay"
                                    id="pay-amount"
                                    onChange={(e) => {
                                      this.setState({
                                        amountToPay: e.target.value,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                              <span id="err">{amountToPayErr}</span>{' '}
                            </div>
                          </div>
                        )}

                        {loanId && (
                          <div className="form-group row">
                            {/* <div className="col-sm-1" /> */}
                            <label
                              htmlFor="deduct-amount"
                              className="col-sm-4 col-form-label small-size"
                            >
                              Percentage to Deduct
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-7">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="deduct-amount"
                                  >
                                    %
                                  </span>
                                </div>
                                {textAreaToggle ? (
                                  <p>{amountToDeduct}</p>
                                ) : (
                                  <input
                                    className="form-control"
                                    value={amountToDeduct}
                                    type="text"
                                    disabled={
                                      roleId === 5 || roleId === 6 || tech_sign
                                        ? true
                                        : false
                                    }
                                    placeholder="Percentage to deduct"
                                    id="deduct-amount"
                                    onChange={(e) => {
                                      const re = /^[0-9]*\.?[0-9]*$/;
                                      if (
                                        e.target.value === '' ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          amountToDeduct: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              <span id="err">{amountToDeductErr}</span>{' '}
                            </div>
                          </div>
                        )}

                        {advanceReq && (
                          <div className="form-group row mt-4">
                            {/* <div className="col-sm-1" /> */}
                            <label
                              htmlFor="deduct-amount"
                              className="col-sm-4 col-form-label small-size"
                            >
                              TERMS :
                            </label>
                            <br />
                            <div className="col-sm-7">
                              <div>
                                {contractTerms && (
                                  <div>
                                    {textAreaToggle && (
                                      <>
                                        <p>{contractTerms}</p>
                                        <p
                                          style={{
                                            marginLeft: '-45%',
                                            padding: '0',
                                            marginTop: '6%',
                                          }}
                                        >
                                          {contractBody && contractBody}
                                        </p>
                                      </>
                                    )}

                                    {!textAreaToggle && (
                                      <>
                                        <h6> {contractTerms} </h6>
                                        <textarea
                                          className="form-control"
                                          placeholder="some description goes here"
                                          type="textarea"
                                          disabled
                                          id="jobdesc"
                                          style={{
                                            resize: 'none',
                                            minHeight: '250px',
                                          }}
                                          cols="60"
                                          value={contractBody}
                                        />
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {advanceReq &&
                          (Number(roleId) === 5 || Number(roleId) === 6) &&
                          amountToDeduct !== 0 &&
                          amountToPay !== 0 && (
                            <div className="form-group row">
                              <div className="col-sm-4" />
                              <div className="col-sm-7">
                                <input
                                  type="checkbox"
                                  value={checkTerms}
                                  disabled={tech_sign ? true : false}
                                  onChange={(e) => {
                                    this.setState({
                                      checkTerms: e.target.checked,
                                    });
                                  }}
                                  checked={tech_sign && true}
                                />
                                <label className="form-check-label ml-3">
                                  I agree with all the terms & conditions
                                </label>
                              </div>
                            </div>
                          )}
                        {(checkTerms || tech_sign) && (
                          <div className="form-group row">
                            <label
                              htmlFor="desc"
                              className="col-sm-4 col-form-label"
                            >
                              Technician Signature
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-4">
                              <Card>
                                {tech_sign !== null && (
                                  <Card.Body className="justify-content-center d-flex">
                                    <img
                                      src={tech_sign}
                                      alt="_image"
                                      width="100"
                                      height="100"
                                    />
                                  </Card.Body>
                                )}
                                <Card.Body>
                                  {(roleId === 5 || roleId === 6) &&
                                    !tech_sign && (
                                      <>
                                        <SignatureCanvas
                                          penColor="green"
                                          canvasProps={{
                                            width: 280,
                                            height: 250,
                                            className: 'sigCanvas',
                                          }}
                                          ref={(ref) => {
                                            this.sigPad = ref;
                                          }}
                                        />
                                        <div className="text-center">
                                          <Button
                                            variant="secondary"
                                            className="saveBtns m-l-10"
                                            type="reset"
                                            onClick={(e) => this.clearSign(e)}
                                          >
                                            Clear
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                </Card.Body>
                                {tech_sign !== null && (
                                  <Card.Footer
                                    style={{
                                      textAlign: 'center',
                                      background: '#fff',
                                    }}
                                  >
                                    {technicianName && `${technicianName}`}{' '}
                                    <br />
                                    {sign_date &&
                                      ` Signature date: ${customFunctions.getFormattedDate(
                                        sign_date
                                      )}`}
                                    {tech_sign.ipaddress &&
                                      ` IP address:${tech_sign.ipaddress}`}
                                  </Card.Footer>
                                )}
                              </Card>
                              <span id="err">{signUrlErr}</span>
                            </div>
                          </div>
                        )}
                        <Row className="mt7" style={{ textAlign: 'center' }}>
                          <Col>
                            {((roleId === 1 && tech_sign && isApprove) ||
                              (roleId === 1 &&
                                advanceReq &&
                                !tech_sign &&
                                !isApprove) ||
                              ((roleId === 5 || roleId === 6) &&
                                !tech_sign &&
                                amountToDeduct !== 0 &&
                                amountToPay !== 0)) && (
                              <Button
                                variant="primary"
                                type="submit"
                                className="saveBtns"
                                ref="btn"
                              >
                                Submit
                              </Button>
                            )}
                            {!textAreaToggle && (
                              <Button
                                variant="secondary"
                                className="l-m-10 saveBtns"
                                // disabled={(tech_sign && !isApprove) ? true: false}
                                type="reset"
                                onClick={() => {
                                  if (
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.path
                                  ) {
                                    this.props.history.push(
                                      `${this.props.location.state.path}`,
                                      {
                                        paginationObj: paginationOptions,
                                      }
                                    );
                                  } else {
                                    this.props.history.goBack();
                                  }
                                }}
                              >
                                Cancel
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(ApproveByAdminComponent);

/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Card } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import PremaintenanceType from './pre-maintenance.component';

class PreventativeMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Preventative Maintenance',
      roleId: '',
      premaintenanceid: null,
      disabled: false,
      customerId: '',
      preventativeType: '',
      work_order_id: '',
      isShowLoader: false,
      premaintenceTypeData: [],
      secondAccordianName: '',
      flag: false,
    };
  }

  componentDidMount = async () => {
    let fromComponent = this.props.fromComponent || '';
    if (!fromComponent) customFunctions.setTitle(this.state.title);
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    const customerId =
      (this.props.history.location.state &&
        this.props.history.location.state.customerId) ||
      this.props.customerId;
    const premaintenceId =
      this.props.history.location.state &&
      this.props.history.location.state.premaintenceId;
    const work_order_id = this.props.work_order_id;

    this.setState(
      {
        roleId: userdata.role_id,
        login_id: userdata.login_id,
        customerId,
        premaintenceId,
        work_order_id,
        fromComponent,
      },
      () => {
        this.getPremaintenanceType();
      }
    );
  };

  getPremaintenanceType = async () => {
    const { work_order_id } = this.state;
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getPremaintenanceType}?work_order_id=${work_order_id}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success && data.data) {
      this.setState(
        { isShowLoader: false, premaintenceTypeData: data.data, flag: true },
        () => {
          if (
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data[0].type === 'spring'
          ) {
            this.setState({
              secondAccordianName: 'fall',
            });
          } else {
            this.setState({
              secondAccordianName: 'spring',
            });
          }
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const {
      title,
      roleId,
      disabled,
      premaintenanceid,
      preventativeType,
      fromComponent,
      isShowLoader,
      customerId,
      premaintenceId,
      work_order_id,
      premaintenceTypeData,
      secondAccordianName,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {roleId && this.state.flag && (
          <div
            className="page-content-wrapper"
            style={{ paddingTop: fromComponent === 'workorder' ? '0' : '0px' }}
          >
            <div className="container-fluid">
              {!this.props.fromComponent && <HelmetComponent title={title} />}
              {premaintenceTypeData && premaintenceTypeData.length > 0 && (
                <>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id={`headingOne`}>
                        <div className="mb-0 row">
                          <button
                            className="btn btn-link text-left col-sm-3"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapseOne`}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {premaintenceTypeData &&
                              premaintenceTypeData[0].type &&
                              customFunctions.capitalizeFirstLetter(
                                premaintenceTypeData[0].type
                              )}
                          </button>
                        </div>
                      </div>

                      <div
                        id={`collapseOne`}
                        className="collapse"
                        aria-labelledby={`headingOne`}
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          {customerId && work_order_id && (
                            <PremaintenanceType
                              customerId={customerId}
                              premaintenceId={premaintenceId}
                              work_order_id={work_order_id}
                              index={0}
                              multitech={this.props && this.props.multitech}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {premaintenceTypeData &&
                    premaintenceTypeData[0].is_completed === 1 &&
                    ((roleId === 4 &&
                      premaintenceTypeData &&
                      premaintenceTypeData.length > 1) ||
                      roleId !== 4) && (
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id={`headingTwo`}>
                            <div className="mb-0 row">
                              <button
                                className="btn btn-link text-left col-sm-3"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapseTwo`}
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                              >
                                {secondAccordianName &&
                                  customFunctions.capitalizeFirstLetter(
                                    secondAccordianName
                                  )}
                              </button>
                            </div>
                          </div>

                          <div
                            id={`collapseTwo`}
                            className="collapse"
                            aria-labelledby={`headingTwo`}
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">
                              {customerId &&
                                secondAccordianName &&
                                work_order_id && (
                                  <PremaintenanceType
                                    index={1}
                                    pretype={secondAccordianName}
                                    customerId={customerId}
                                    premaintenceId={premaintenceId}
                                    work_order_id={work_order_id}
                                    multitech={
                                      this.props && this.props.multitech
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}
              {premaintenceTypeData &&
                premaintenceTypeData.length === 0 &&
                customerId &&
                work_order_id && (
                  <PremaintenanceType
                    customerId={customerId}
                    premaintenceId={premaintenceId}
                    work_order_id={work_order_id}
                    multitech={this.props && this.props.multitech}
                  />
                )}
            </div>
          </div>
        )}
      </AUX>
    );
  }
}

export default withRouter(PreventativeMaintenance);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import { Tabs, Tab } from 'react-bootstrap';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import LocationMap from '../../../components/LocationMap/index';
import Geocode from 'react-geocode';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class AddCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custmrnme: '',
      cntctprsnme: '',
      // jobtitle: '',
      email: '',
      website: '',
      password: '',
      custmrnmerr: '',
      // cntctprsnmerr: '',
      // jobtitlerr: '',
      emailerr: '',
      websiterr: '',
      passworderr: '',
      isBillingChecked: false,
      isShippingChecked: false,
      isHomeChecked: false,
      PhoneOne: '',
      PhoneOneErr: '',
      PhoneTwo: '',
      PhoneTwoErr: '',
      PhoneThree: '',
      PhoneThreeErr: '',
      PhoneFour: '',
      PhoneFourErr: '',
      numberOne: '',
      numberTwo: '',
      numberThree: '',
      numberFour: '',
      numberOneErr: '',
      numberTwoErr: '',
      numberThreeErr: '',
      numberFourErr: '',

      business_address1: '',
      business_address2: '',
      business_city: '',
      business_state: '',
      business_zip: '',
      business_country: '',

      business_address1Err: '',
      cityErr: '',
      business_stateErr: '',
      business_zipErr: '',
      business_countryErr: '',

      home_address1: '',
      home_address2: '',
      home_city: '',
      home_state: '',
      home_zip: '',
      home_country: '',

      home_address1Err: '',
      home_address2Err: '',
      home_cityErr: '',
      home_stateErr: '',
      home_zipErr: '',
      home_countryErr: '',

      billing_address1: '',
      billing_address2: '',
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: '',

      billing_address1Err: '',
      billing_address2Err: '',
      billing_cityErr: '',
      billing_stateErr: '',
      billing_zipErr: '',
      billing_countryErr: '',

      shipping_address1: '',
      shipping_address2: '',
      shipping_city: '',
      shipping_state: '',
      shipping_zip: '',
      shipping_country: '',

      shipping_address1Err: '',
      shipping_address2Err: '',
      shipping_cityErr: '',
      shipping_stateErr: '',
      shipping_zipErr: '',
      shipping_countryErr: '',

      address1Err: '',
      address2: '',
      address2Err: '',
      city: '',
      states: '',
      statesErr: '',
      codezipp: '',
      codezippErr: '',
      countryErr: '',
      customerID: '',
      isactive: 1,

      phoneType1: [
        {
          value: 'Main',
          label: 'Main',
        },
        {
          value: 'Office',
          label: 'Office',
        },
        {
          value: 'Home',
          label: 'Home',
        },
        {
          value: 'Mobile',
          label: 'Mobile',
        },
        {
          value: 'Fax',
          label: 'Fax',
        },
      ],

      countries: [
        {
          id: 1,
          name: 'India',
        },
        {
          id: 2,
          name: 'USA',
        },
        {
          id: 3,
          name: 'rasia',
        },
        {
          id: 4,
          name: 'Austria',
        },
        {
          id: 5,
          name: 'africa',
        },
      ],

      note: '',
      noteErr: '',
      showCheckbox: true,
      createdDateTime: '',
      createdDateTimeErr: '',
      updatedTime: '',
      updatedTimeErr: '',
      key1: 'Business',
      key2: 'Home',
      key3: 'Billing',
      key4: 'Shipping',
      isSubmitted: false,
      customerId: null,
      homeChkbx: false,
      billingChkbx: false,
      shippingChkbx: false,
      isClicked: '',
      regexp: /^[0-9\b]+$/,
      editType: false,
      title: '',
      preMaintenceContract: '',
      IsPreventiveMaintainance: 0,
      startDate: new Date(),
      endDate: new Date(),
      isSpring: false,
      isFall: false,
      roleId: '',
      selectedLocation: '',
      latitude: '',
      longitude: '',
      updateLat: '',
      updateLng: '',
      showPass: false,
      isShowLoader: false,
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addcustomer'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addcustomer',
      'write'
    );
    if (writeAllowed) {
      this.setState({ disabledAll: false });
    } else {
      this.setState({ disabledAll: true });
    }
    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id === 4) {
      this.props.history.push('/dashboard');
    }
    this.setState({ roleId: userdata.role_id });
    const customerId = await this.props.match.params.customerId;
    customFunctions.setLocalStorage('customerID', customerId);
    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('update-customer')) {
      this.setState({ editType: true });
    }
    this.setState({ customerId }, async () => {
      if (customerId) {
        customFunctions.setTitle('Edit Customer');
        this.setState({ title: `Edit Customer` });
        await this.getCustomerData(customerId);
      } else {
        customFunctions.setTitle('Add Customer');
        this.setState({ title: `Add Customer` });
      }
    });
  };

  getCustomerData = async (customerId) => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getCustomerById}?customer_id=${customerId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const selectedLocation = {
        lat: data.data.latitude && Number(data.data.latitude),
        lng: data.data.longitude && Number(data.data.longitude),
      };
      this.getAddressFromLatLog(selectedLocation);
      this.setState({
        custmrnme: data.data.CustomerName,
        cntctprsnme: data.data.ContactName,
        // jobtitle: data.data.ContactTitle,
        email: data.data.email,
        website: data.data.Website,
        password: data.data.Password,
        note: data.data.Notes,
        PhoneOne: { label: data.data.Type1, value: data.data.Type1 },
        PhoneTwo: { label: data.data.Type2, value: data.data.Type2 },
        PhoneThree: { label: data.data.Type3, value: data.data.Type3 },
        PhoneFour: { label: data.data.Type4, value: data.data.Type4 },
        numberOne: data.data.PhoneNumber1,
        numberTwo: data.data.PhoneNumber2,
        numberThree: data.data.PhoneNumber3,
        numberFour: data.data.PhoneNumber4,
        business_address1: data.data.AddressLine1_1,
        business_address2: data.data.AddressLine2_1,
        home_address1: data.data.AddressLine1_2,
        home_address2: data.data.AddressLine2_2,
        billing_address1: data.data.AddressLine1_3,
        billing_address2: data.data.AddressLine2_3,
        shipping_address1: data.data.AddressLine1_4,
        shipping_address2: data.data.AddressLine2_4,
        business_city: data.data.City1,
        home_city: data.data.City2,
        billing_city: data.data.City3,
        shipping_city: data.data.City4,
        business_state: data.data.State1,
        home_state: data.data.State2,
        billing_state: data.data.State3,
        shipping_state: data.data.State4,
        business_zip: data.data.Zip1,
        home_zip: data.data.Zip2,
        billing_zip: data.data.Zip3,
        shipping_zip: data.data.Zip4,
        createdDateTime: data.data.Created,
        updatedTime: data.data.Updated || '',
        customerID: data.data.ID,
        active: data.data.Active,
        IsPreventiveMaintainance:
          data.data.IsPreventiveMaintainance &&
          data.data.IsPreventiveMaintainance.toString(),
        startDate: (data.data.StartDate && new Date(data.data.StartDate)) || '',
        endDate: (data.data.EndDate && new Date(data.data.EndDate)) || '',
        isSpring: data.data.IsSpring === '1' ? true : false,
        isFall: data.data.IsFall === '1' ? true : false,
        latitude: data.data.latitude && Number(data.data.latitude),
        longitude: data.data.longitude && Number(data.data.longitude),
        selectedLocation,
      });

      const { createdDateTime } = this.state;
      this.setState({
        createdDateTime: customFunctions.getMDYDateTime(createdDateTime),
      });

      const { updatedTime } = this.state;
      if (updatedTime) {
        this.setState({
          updatedTime: customFunctions.getMDYDateTime(updatedTime),
        });
      }
      this.setState({ isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      var $this = this;
      swal(message, '', 'success');
      setTimeout(() => {
        $this.props.history.push('/customer');
      }, 1000);
    }
  };
  validateForm = async (e) => {
    e.preventDefault();
    const {
      custmrnme,
      PhoneOne,
      email,
      country,
      PhoneTwo,
      PhoneThree,
      PhoneFour,
      numberOne,
      numberTwo,
      numberThree,
      numberFour,
      business_address1,
      business_city,
      business_state,
      business_zip,
      password,
      customerId,
      latitude,
      longitude,
    } = this.state;

    let custmrnmerr = '',
      // cntctprsnmerr = '',
      numberOneErr = '',
      numberTwoErr = '',
      numberThreeErr = '',
      numberFourErr = '',
      // jobtitlerr = '',
      emailerr = '',
      websiterr = '',
      passworderr = '',
      PhoneOneErr = '',
      cityErr = '',
      statesErr = '',
      PhoneTwoErr = '',
      countryErr = '',
      PhoneThreeErr = '',
      address1Err = '',
      address2Err = '',
      PhoneFourErr = '',
      noteErr = '',
      codezippErr = '',
      createdDateTimeErr = '',
      updatedTimeErr = '',
      business_address1Err = '',
      business_address2Err = '',
      business_cityErr = '',
      business_stateErr = '',
      business_zipErr = '',
      business_countryErr = '',
      home_address1Err = '',
      home_address2Err = '',
      home_cityErr = '',
      home_stateErr = '',
      home_zipErr = '',
      home_countryErr = '',
      billing_address1Err = '',
      billing_address2Err = '',
      billing_cityErr = '',
      billing_stateErr = '',
      billing_zipErr = '',
      billing_countryErr = '',
      shipping_address1Err = '',
      shipping_address2Err = '',
      shipping_cityErr = '',
      shipping_stateErr = '',
      shipping_zipErr = '',
      shipping_countryErr = '',
      locationErr = '';

    let isValid = true;

    // if (
    //   latitude === '' ||
    //   longitude === '' ||
    //   latitude === null ||
    //   longitude === null
    // ) {
    //   locationErr = 'Location is required';
    //   isValid = false;
    // }
    if (custmrnme === '') {
      custmrnmerr = 'Customer name is required';
      isValid = false;
    }

    // if (cntctprsnme === '') {
    //   cntctprsnmerr = 'Person name is required';
    //   isValid = false;
    // }

    if (numberOne && !numberTwo && !numberThree && !numberFour) {
      numberFourErr = '';
      numberTwoErr = '';
      numberThreeErr = '';
    } else if (numberTwo && !numberOne && !numberThree && !numberFour) {
      numberOneErr = '';
      numberThreeErr = '';
      numberFourErr = '';
    } else if (numberThree && !numberFour && !numberTwo && !numberOne) {
      numberFourErr = '';
      numberTwoErr = '';
      numberOneErr = '';
    } else if (numberFour && !numberOne && !numberTwo && !numberThree) {
      numberOneErr = '';
      numberTwoErr = '';
      numberThreeErr = '';
    } else if (!numberOne && !numberTwo && !numberThree && !numberFour) {
      numberOneErr = 'Mobile is required';
      isValid = false;
    }

    if (business_address1 === '') {
      business_address1Err = 'Address 1 is required';
      isValid = false;
    }
    if (business_city === '') {
      business_cityErr = 'City is required';
      isValid = false;
    }
    if (business_state === '') {
      business_stateErr = 'State is required';
      isValid = false;
    }
    if (business_zip === '') {
      business_zipErr = 'zip is required';
      isValid = false;
    }
    // if (business_zip && !customFunctions.validateZipCode(business_zip)) {
    //   business_zipErr = 'Invalid zip';
    //   isValid = false;
    // }

    if (PhoneOne && PhoneOne.value && !PhoneTwo && !PhoneThree && !PhoneFour) {
      PhoneTwoErr = '';
      PhoneThreeErr = '';
      PhoneFourErr = '';
    } else if (
      PhoneTwo &&
      PhoneTwo.value &&
      !PhoneThree &&
      !PhoneFour &&
      PhoneOne &&
      PhoneOne.value
    ) {
      PhoneOneErr = '';
      PhoneThreeErr = '';
      PhoneFourErr = '';
    } else if (
      PhoneThree &&
      PhoneThree.value &&
      !PhoneFour &&
      !PhoneTwo &&
      PhoneOne &&
      PhoneOne.value
    ) {
      PhoneFourErr = '';
      PhoneTwoErr = '';
      PhoneOneErr = '';
    } else if (
      PhoneFour &&
      PhoneFour.value &&
      !PhoneOne &&
      !PhoneTwo &&
      !PhoneThree
    ) {
      PhoneOneErr = '';
      PhoneTwoErr = '';
      PhoneThreeErr = '';
    } else if (!PhoneOne && !PhoneTwo && !PhoneThree && !PhoneFour) {
      PhoneOneErr = 'Select phone type';
      isValid = false;
    }

    // if (website !== '' && !customFunctions.validateUrl(website)) {
    //   websiterr = 'Please enter valid website';
    // }
    if (!customerId) {
      if (
        (email !== '' && !customFunctions.validateEmail(email)) ||
        email === ''
      ) {
        emailerr = 'Enter valid email';
        isValid = false;
      }
      if (password === '' || !password) {
        passworderr = 'Please enter password';
      }
    }

    this.setState({
      custmrnmerr,
      // cntctprsnmerr,
      // jobtitlerr,
      address1Err,
      address2Err,
      country,
      countryErr,
      codezippErr,
      emailerr,
      websiterr,
      passworderr,
      cityErr,
      statesErr,
      PhoneOneErr,
      PhoneTwoErr,
      PhoneThreeErr,
      PhoneFourErr,
      noteErr,
      createdDateTimeErr,
      updatedTimeErr,
      numberOneErr,
      numberTwoErr,
      numberThreeErr,
      numberFourErr,
      business_address1Err,
      business_address2Err,
      business_cityErr,
      business_stateErr,
      business_countryErr,
      business_zipErr,
      home_address1Err,
      home_address2Err,
      home_cityErr,
      home_stateErr,
      home_countryErr,
      home_zipErr,
      billing_address1Err,
      billing_address2Err,
      billing_cityErr,
      billing_stateErr,
      billing_countryErr,
      billing_zipErr,
      shipping_address1Err,
      shipping_address2Err,
      shipping_cityErr,
      shipping_stateErr,
      shipping_countryErr,
      shipping_zipErr,
      locationErr,
    });

    if (isValid) {
      const address =
        business_address1 +
        ', ' +
        business_city +
        ', ' +
        business_state +
        ' ' +
        business_zip;
      // await this.getLatLogFromAddress(address, (value) => {
      //   this.setState(
      //     { latitude: value.latitude, longitude: value.longitude },
      //     () => {
      this.addCustomer();
      //     }
      //   );
      // });
    }
  };

  addCustomer = async (e) => {
    const {
      custmrnme,
      cntctprsnme,
      // jobtitle,
      email,
      website,
      password,
      PhoneOne,
      PhoneTwo,
      PhoneThree,
      PhoneFour,
      numberOne,
      numberTwo,
      numberThree,
      numberFour,
      note,
      business_address1,
      business_address2,
      business_city,
      business_state,
      business_zip,
      business_country,
      customerId,
      home_address1,
      home_address2,
      home_city,
      home_country,
      home_zip,
      home_state,
      shipping_address1,
      shipping_address2,
      shipping_country,
      shipping_city,
      shipping_zip,
      shipping_state,
      billing_address1,
      billing_address2,
      billing_city,
      billing_country,
      billing_state,
      billing_zip,
      IsPreventiveMaintainance,
      startDate,
      endDate,
      roleId,
      latitude,
      longitude,
      paginationOptions,
    } = this.state;

    this.setState({ isShowLoader: true });
    const postData = {
      customer_name: custmrnme,
      contact_person: cntctprsnme,
      // job_title: jobtitle,
      email: email,
      website: website,
      type_1: PhoneOne && PhoneOne.value,
      type_2: PhoneTwo && PhoneTwo.value,
      type_3: PhoneThree && PhoneThree.value,
      type_4: PhoneFour && PhoneFour.value,
      mobile_1: String(numberOne),
      mobile_2: String(numberTwo),
      mobile_3: String(numberThree),
      mobile_4: String(numberFour),
      notes: note,

      business_address1: business_address1,
      business_address2: business_address2,
      business_city: business_city,
      business_state: business_state,
      business_country: business_country,
      business_zip: business_zip,

      home_address1: home_address1,
      home_address2: home_address2,
      home_city: home_city,
      home_state: home_state,
      home_country: home_country,
      home_zip: home_zip,

      billing_address1: billing_address1,
      billing_address2: billing_address2,
      billing_city: billing_city,
      billing_state: billing_state,
      billing_zip: billing_zip,
      billing_country: billing_country,

      shipping_address1: shipping_address1,
      shipping_address2: shipping_address2,
      shipping_city: shipping_city,
      shipping_state: shipping_state,
      shipping_zip: shipping_zip,
      shipping_country: shipping_country,
      is_active: 1,
      // latitude,
      // longitude,
    };
    const callback = customerId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.customerUrl;
    if (customerId) {
      postData.id = parseInt(customerId);
    }
    if (!customerId) {
      postData.password = password;
    }
    if (roleId === 1) {
      postData.IsPreventiveMaintainance = IsPreventiveMaintainance;
    }
    if (IsPreventiveMaintainance === '1' && roleId === 1) {
      postData.StartDate = startDate;
      postData.EndDate = endDate;
      // postData.IsSpring = isSpring == true ? '1' : '0';
      // postData.IsFall = isFall == true ? '1' : '0';
      postData.IsSpring = '1';
      postData.IsFall = '1';
    }
    console.log('yes~~', endPoint);
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      var $this = this;
      if (customerId) {
        this.setState({ isShowLoader: false });
        swal('Customer updated successfully', '', 'success').then(() => {
          if (paginationOptions && Object.keys(paginationOptions).length > 0) {
            this.props.history.push('/customer', {
              paginationObj: paginationOptions,
            });
          } else {
            this.props.history.goBack();
          }
        });
      } else if (message === 'Updated') {
        this.setState({ isShowLoader: false });
        swal('Customer updated successfully', '', 'success').then(() => {
          this.props.history.push('/customer');
        });
      } else {
        this.setState({ isShowLoader: false });
        swal('Customer added successfully', '', 'success').then(() => {
          this.resetData();
          setTimeout(() => {
            $this.props.history.push('/customer');
          }, 100);
          return;
        });
      }
    } else {
      this.setState({ isShowLoader: false });
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      custmrnme: '',
      cntctprsnme: '',
      // jobtitle: '',
      email: '',
      website: '',
      password: '',
      PhoneOne: '',
      PhoneTwo: '',
      PhoneThree: '',
      PhoneFour: '',
      numberOne: '',
      numberTwo: '',
      numberThree: '',
      numberFour: '',
      city: '',
      states: '',
      codezipp: '',
      country: '',
      business_address1: '',
      business_address2: '',
      home_address1: '',
      home_address2: '',
      billing_address1: '',
      billing_address2: '',
      shipping_address1: '',
      shipping_address2: '',
      business_city: '',
      home_city: '',
      billing_city: '',
      shipping_city: '',
      business_state: '',
      home_state: '',
      billing_state: '',
      shipping_state: '',
      business_zip: '',
      home_zip: '',
      billing_zip: '',
      shipping_zip: '',
      business_country: '',
      home_country: '',
      billing_country: '',
      shipping_country: '',
    });
  };

  copyAdd = () => {
    const {
      business_address1,
      business_address2,
      business_city,
      business_state,
      business_country,
      business_zip,
      isHomeChecked,
    } = this.state;

    if (!isHomeChecked) {
      this.setState({
        home_address1: business_address1,
        home_address2: business_address2,
        home_city: business_city,
        home_state: business_state,
        home_zip: business_zip,
        home_country: business_country,
      });
    } else {
      this.setState({
        home_address1: '',
        home_address2: '',
        home_city: '',
        home_state: '',
        home_zip: '',
        home_country: '',
      });
    }
  };

  copyAdd1 = () => {
    const {
      home_address1,
      home_address2,
      home_city,
      home_country,
      home_zip,
      home_state,
      isBillingChecked,
    } = this.state;

    if (!isBillingChecked) {
      this.setState({
        billing_address1: home_address1,
        billing_address2: home_address2,
        billing_city: home_city,
        billing_state: home_state,
        billing_zip: home_zip,
        billing_country: home_country,
      });
    } else {
      this.setState({
        billing_address1: '',
        billing_address2: '',
        billing_city: '',
        billing_state: '',
        billing_zip: '',
        billing_country: '',
      });
    }
  };

  copyAdd2 = () => {
    const {
      isShippingChecked,
      billing_address1,
      billing_address2,
      billing_city,
      billing_country,
      billing_state,
      billing_zip,
    } = this.state;

    if (!isShippingChecked) {
      this.setState({
        shipping_address1: billing_address1,
        shipping_address2: billing_address2,
        shipping_city: billing_city,
        shipping_state: billing_state,
        shipping_zip: billing_zip,
        shipping_country: billing_country,
      });
    } else {
      this.setState({
        shipping_address1: '',
        shipping_address2: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
        shipping_country: '',
      });
    }
  };

  handleSelect = (res) => {
    if (res === 'Home')
      this.setState({
        homeChkbx: true,
        billingChkbx: false,
        shippingChkbx: false,
      });
    if (res === 'Billing')
      this.setState({
        homeChkbx: false,
        billingChkbx: true,
        shippingChkbx: false,
      });
    if (res === 'Shipping')
      this.setState({
        homeChkbx: false,
        billingChkbx: false,
        shippingChkbx: true,
      });
    if (res === 'Business')
      this.setState({
        homeChkbx: false,
        billingChkbx: false,
        shippingChkbx: false,
      });
  };

  onMapClick = (e, marker, event) => {
    const selectedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    this.setState(
      {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
        selectedLocation,
      },
      async () => {
        await this.getAddressFromLatLog(selectedLocation);
      }
    );
  };

  getAddressFromLatLog = async (location) => {
    if (!location) return '';
    var $this = this;
    Geocode.fromLatLng(location.lat, location.lng).then(
      (response) => {
        var mapResult = response.results;
        let zip = '';
        mapResult &&
          mapResult.forEach((element) => {
            if (element.types.includes('postal_code')) {
              element &&
                element.address_components.filter((item) => {
                  if (item && item.types.includes('postal_code')) {
                    zip = item.short_name;
                  }
                });
            }
          });
        // var result = response.results[0].address_components.filter((item) => {
        //   return item && item.types && item.types.includes('postal_code');
        // })
        // console.log('result', result);
        // if (result && result.length > 0) {
        //   var zip = result[0].short_name;
        // }
        const address = response.results[0].formatted_address;
        let places = address && address.split(',')[2];
        let state = places && places.split(' ')[1];

        $this.setState({
          business_address1: address.split(',')[0],
          business_city: address.split(',')[1],
          business_state: state,
          business_zip: zip,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // getLatLogFromAddress = async (location, callBack = null) => {
  //   if (!location) return '';
  //   Geocode.fromAddress(location).then(
  //     (response) => {
  //       if (callBack) {
  //         callBack({
  //           latitude: response.results[0].geometry.location.lat,
  //           longitude: response.results[0].geometry.location.lng,
  //         });
  //       }
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };

  render() {
    const {
      customerId,
      custmrnme,
      cntctprsnme,
      // jobtitle,
      email,
      password,
      custmrnmerr,
      // cntctprsnmerr,
      // jobtitlerr,
      emailerr,
      passworderr,
      PhoneOne,
      PhoneOneErr,
      PhoneTwo,
      PhoneTwoErr,
      PhoneThree,
      PhoneThreeErr,
      PhoneFourErr,
      PhoneFour,
      phoneType1,
      createdDateTime,
      createdDateTimeErr,
      updatedTime,
      updatedTimeErr,
      numberOne,
      numberTwo,
      numberThree,
      numberFour,
      numberOneErr,
      numberTwoErr,
      numberThreeErr,
      numberFourErr,
      business_address1,
      business_address2,
      business_city,
      business_state,
      business_zip,
      home_address1,
      home_address2,
      home_city,
      home_zip,
      home_state,
      customerID,
      title,
      shipping_address1,
      shipping_address2,
      shipping_city,
      shipping_zip,
      shipping_state,
      billing_address1,
      billing_address2,
      billing_city,
      billing_state,
      billing_zip,
      business_address1Err,
      business_cityErr,
      business_stateErr,
      business_zipErr,
      editType,
      IsPreventiveMaintainance,
      roleId,
      selectedLocation,
      locationErr,
      showPass,
      isShowLoader,
    } = this.state;

    return (
      <AUX>
        <div className={editType ? '' : 'page-content-wrapper'}>
          <div className={editType ? '' : 'container-fluid'}>
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form
                      onSubmit={this.validateForm}
                      autoComplete="off"
                      className="customer-details"
                    >
                      {customerId && (
                        <div className="form-group row">
                          <label
                            htmlFor="customerid"
                            className="col-sm-2 col-form-label"
                          >
                            CustomerID
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              disabled
                              value={customerID}
                              type="text"
                              placeholder="John"
                              id="customerid"
                              onChange={(e) => {
                                this.setState({ customerID: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="customername"
                          className="col-sm-2 col-form-label"
                        >
                          Customer Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={custmrnme}
                            type="text"
                            placeholder="Customer Name"
                            id="customername"
                            onChange={(e) => {
                              const re = /^[a-zA-Z ]*$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                this.setState({ custmrnme: e.target.value });
                              }
                            }}
                          />
                          <span id="err">{custmrnmerr}</span>
                        </div>
                        <label
                          htmlFor="conactpersoname"
                          className="col-sm-2 col-form-label"
                        >
                          Contact Person Name
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={cntctprsnme}
                            type="text"
                            placeholder="Contact Person Name"
                            id="contactpersoname"
                            onChange={(e) => {
                              const re = /^[a-zA-Z ]*$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                this.setState({ cntctprsnme: e.target.value });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        {/* <label
                          htmlFor="jobtitle"
                          className="col-sm-2 col-form-label"
                        >
                          Job Title
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={jobtitle}
                            type="text"
                            placeholder="Job Title"
                            id="jobtitle"
                            onChange={(e) => {
                              const re = /^[a-zA-Z ]*$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                this.setState({ jobtitle: e.target.value });
                              }
                            }}
                          />
                          <span id="err">{jobtitlerr}</span>
                        </div> */}
                        <label
                          htmlFor="email"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                          {!customerId && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={email}
                            type="text"
                            // disabled={customerId ? true : false}
                            autoComplete="new-email"
                            aria-autocomplete="none"
                            placeholder="Email"
                            id="email"
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                          />
                          <span id="err">{emailerr}</span>
                        </div>
                        {!customerId && (
                          <>
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Password
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={password}
                                type={`${showPass ? 'text' : 'password'}`}
                                placeholder="Password"
                                id="example-text-input"
                                autoComplete="new-password"
                                onChange={(e) => {
                                  this.setState({ password: e.target.value });
                                }}
                              />
                              <div
                                className="password__toggle"
                                onClick={() =>
                                  this.setState({ showPass: !showPass })
                                }
                              >
                                <i
                                  className={`${
                                    showPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye`}
                                ></i>
                                <i
                                  className={`${
                                    !showPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye-line-through`}
                                ></i>
                              </div>
                              <span id="err">{passworderr}</span>
                            </div>
                          </>
                        )}
                      </div>
                      {/* {roleId == 1 && (
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-sm-2 col-form-label"
                          >
                            Is Preventative Maintenance
                          </label>
                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6">
                                <Radio
                                  checked={IsPreventiveMaintainance === '1'}
                                  onChange={(e) => {
                                    this.setState({
                                      IsPreventiveMaintainance: e.target.value,
                                    });
                                  }}
                                  value="1"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                Yes
                                <Radio
                                  checked={
                                    IsPreventiveMaintainance === '0' ||
                                    IsPreventiveMaintainance !== '1'
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      IsPreventiveMaintainance: e.target.value,
                                    });
                                  }}
                                  value="0"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                No
                              </div>
                              {IsPreventiveMaintainance == '1' && (
                                <div className="col-sm-6 row">
                                  <input
                                    className="form-control checkbox col-sm-2"
                                    checked={isSpring}
                                    value={isSpring}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({ isSpring: true });
                                      } else {
                                        this.setState({ isSpring: false });
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="example-checkbox-input1"
                                    className="col-form-label col-sm-8"
                                  >
                                    Spring
                                  </label>
                                  <input
                                    className="form-control checkbox col-sm-2"
                                    checked={isFall}
                                    value={isFall}
                                    type="checkbox"
                                    id="example-checkbox-input2"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({ isFall: true });
                                      } else {
                                        this.setState({ isFall: false });
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="example-checkbox-input2"
                                    className="col-form-label col-sm-6"
                                  >
                                    Fall
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          {IsPreventiveMaintainance == '1' && (
                            <div className="col-sm-6">
                              <div className="row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-sm-4 col-form-label"
                                >
                                  Start Date
                                </label>
                                <div
                                  className="col-sm-8"
                                  style={{ paddingBottom: '10px' }}
                                >
                                  <DatePickerComponent
                                    selected={startDate}
                                    placeholderText="Start Date"
                                    onChange={(date) => {
                                      this.setState({ startDate: date });
                                    }}
                                    minDate={new Date()}
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control"
                                  />
                                </div>
                                <label
                                  htmlFor="example-text-input"
                                  className="col-sm-4 col-form-label"
                                >
                                  End Date
                                </label>
                                <div className="col-sm-8">
                                  <DatePickerComponent
                                    selected={endDate}
                                    placeholderText="End Date"
                                    onChange={(date) =>
                                      this.setState({ endDate: date })
                                    }
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Select Location
                        </label>
                        <div className="col-sm-4">
                          <LocationMap
                            location={selectedLocation}
                            onMapClick={this.onMapClick}
                            callback={(loc) => {
                              let selectedLocation = {
                                lat: loc.lat,
                                lng: loc.lng,
                              };
                              this.setState(
                                {
                                  latitude: loc.lat,
                                  longitude: loc.lng,
                                  selectedLocation,
                                }
                                // () => {
                                //   this.getAddressFromLatLog(loc);
                                // }
                              );
                            }}
                          />
                          <br />
                          <span id="err">{locationErr}</span>
                        </div>
                        {roleId === 1 && (
                          <>
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Is Preventative Maintenance
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-6">
                                  <Radio
                                    checked={IsPreventiveMaintainance === '1'}
                                    onChange={(e) => {
                                      this.setState({
                                        IsPreventiveMaintainance:
                                          e.target.value,
                                      });
                                    }}
                                    value="1"
                                    color="default"
                                    name="radio-button-demo"
                                  />
                                  Yes
                                  <Radio
                                    checked={
                                      IsPreventiveMaintainance === '0' ||
                                      IsPreventiveMaintainance !== '1'
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        IsPreventiveMaintainance:
                                          e.target.value,
                                      });
                                    }}
                                    value="0"
                                    color="default"
                                    name="radio-button-demo"
                                  />
                                  No
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Website
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={website}
                            type="text"
                            placeholder="Website"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ website: e.target.value });
                            }}
                          />
                          <span id="err">{websiterr}</span>
                        </div> */}
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-7 col-form-label"
                        >
                          Phone Numbers<span className="text-danger">*</span>
                        </label>
                        {/* <label
                          htmlFor="example-text-input"
                          className="col-sm-5 col-form-label"
                        >
                          Notes
                        </label> */}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-5">
                              <ReactSelect
                                value={PhoneOne}
                                placeholder="Select"
                                onChange={(e) => {
                                  let type = {};
                                  type.label = e.label;
                                  type.value = e.value;
                                  this.setState({ PhoneOne: type }, () => {
                                    this.forceUpdate();
                                  });
                                }}
                                options={phoneType1}
                              />
                              <span id="err">{PhoneOneErr}</span>
                            </div>
                            <div className="col-sm-7">
                              <input
                                className="form-control"
                                value={numberOne}
                                type="text"
                                placeholder="822-456-102-1"
                                maxLength="13"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({
                                    numberOne: customFunctions.getMobile(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                              <span id="err">{numberOneErr}</span>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="col-5">
                              <ReactSelect
                                value={PhoneTwo}
                                placeholder="Select"
                                onChange={(e) => {
                                  let type = {};
                                  type.label = e.label;
                                  type.value = e.value;
                                  this.setState({ PhoneTwo: type }, () => {
                                    this.forceUpdate();
                                  });
                                }}
                                options={phoneType1}
                              />
                              <span id="err">{PhoneTwoErr}</span>
                            </div>
                            <div className="col-sm-7">
                              <input
                                className="form-control"
                                value={numberTwo !== 'null' ? numberTwo : ''}
                                type="text"
                                maxLength="13"
                                placeholder="822-456-102-1"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({
                                    numberTwo: customFunctions.getMobile(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                              <span id="err">{numberTwoErr}</span>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="col-5">
                              <ReactSelect
                                value={PhoneThree}
                                placeholder="Select"
                                onChange={(e) => {
                                  let type = {};
                                  type.label = e.label;
                                  type.value = e.value;
                                  this.setState({ PhoneThree: type }, () => {
                                    this.forceUpdate();
                                  });
                                }}
                                options={phoneType1}
                              />
                              <span id="err">{PhoneThreeErr}</span>
                            </div>
                            <div className="col-sm-7">
                              <input
                                className="form-control"
                                value={
                                  numberThree !== 'null' ? numberThree : ''
                                }
                                type="text"
                                placeholder="822-456-102-1"
                                id="example-text-input"
                                maxLength="13"
                                onChange={(e) => {
                                  this.setState({
                                    numberThree: customFunctions.getMobile(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                              <span id="err">{numberThreeErr}</span>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="col-5">
                              <ReactSelect
                                value={PhoneFour}
                                placeholder="Select"
                                onChange={(e) => {
                                  let type = {};
                                  type.label = e.label;
                                  type.value = e.value;
                                  this.setState({ PhoneFour: type }, () => {
                                    this.forceUpdate();
                                  });
                                }}
                                options={phoneType1}
                              />
                              <span id="err">{PhoneFourErr}</span>
                            </div>
                            <div className="col-sm-7">
                              <input
                                className="form-control"
                                value={numberFour !== 'null' ? numberFour : ''}
                                type="text"
                                maxLength="13"
                                placeholder="822-456-102-1"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({
                                    numberFour: customFunctions.getMobile(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                              <span id="err">{numberFourErr}</span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div className="row">
                            <div className="col-2">
                              <label htmlFor="example-text-input"></label>
                            </div>
                            <div className="col-10">
                              <textarea
                                className="form-control"
                                placeholder="Enter Some Note Here..."
                                type="textarea"
                                id="example-text-input"
                                style={{ resize: 'none' }}
                                rows="9"
                                cols="90"
                                onChange={(e) => {
                                  this.setState({ note: e.target.value });
                                }}
                                value={note}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <br></br>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Address
                        </label>
                      </div>
                      <div className="tab-wrapper">
                        <div className="row">
                          <div className="col-sm-12">
                            <Tabs
                              defaultActiveKey={'Business'}
                              activeKey={this.state.key1}
                              onSelect={(eventKey) => {
                                this.handleSelect(eventKey);
                                this.setState({ key1: eventKey });
                              }}
                            >
                              <Tab eventKey={'Business'} title="Business">
                                <br></br>
                                <div className="form-group row">
                                  <label
                                    htmlFor="example-text-input_add1"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Address Line 1
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-10">
                                    <input
                                      className="form-control"
                                      value={business_address1}
                                      type="text"
                                      placeholder="Address 1"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          business_address1: e.target.value,
                                        });
                                      }}
                                    />
                                    <span id="err">{business_address1Err}</span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="example-search-input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Address Line 2
                                  </label>
                                  <div className="col-sm-10">
                                    <input
                                      className="form-control"
                                      value={business_address2}
                                      type="text"
                                      placeholder="Address 2"
                                      id="example-search-input"
                                      onChange={(e) => {
                                        this.setState({
                                          business_address2: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label
                                    htmlFor="example-text-City"
                                    className="col-sm-2 col-form-label"
                                  >
                                    City<span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-4">
                                    <input
                                      className="form-control"
                                      value={business_city}
                                      type="text"
                                      placeholder="City"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        const re = /^[A-Za-z ]+$/;
                                        if (
                                          e.target.value === '' ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState({
                                            business_city: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                    <span id="err">{business_cityErr}</span>
                                  </div>
                                  <label
                                    htmlFor="example-search-input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    State
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-4">
                                    <input
                                      className="form-control"
                                      value={business_state}
                                      type="text"
                                      placeholder="State"
                                      id="example-search-input"
                                      onChange={(e) => {
                                        const re = /^[a-zA-Z ]*$/;
                                        if (
                                          e.target.value === '' ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState({
                                            business_state: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                    <span id="err">{business_stateErr}</span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Zip<span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-4">
                                    <input
                                      className="form-control"
                                      value={business_zip}
                                      type="text"
                                      maxLength="10"
                                      placeholder="Zip"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        let event = e;
                                        event.target.value =
                                          customFunctions.numberMasking(
                                            e.target.value
                                          );
                                        this.setState({
                                          business_zip: event.target.value,
                                        });
                                      }}
                                    />
                                    <span id="err">{business_zipErr}</span>
                                  </div>
                                </div>
                              </Tab>
                              <Tab
                                eventKey="Home"
                                title="Home"
                                activeKey={this.state.key2}
                                onSelect={(key2) => this.setState({ key2 })}
                              >
                                <br></br>
                                <div className="tab-item-wrapper">
                                  <div className="form-group row">
                                    <div className="col-sm-2"></div>
                                    {this.state.homeChkbx && (
                                      <div className="col-sm-10">
                                        <div className="c-checkbox-wrap">
                                          <input
                                            className="form-control"
                                            type="checkbox"
                                            id="example-search-input"
                                            style={{
                                              height: '16px',
                                              width: '16px',
                                            }}
                                            onChange={(e) => {
                                              this.setState((prevState) => ({
                                                isHomeChecked:
                                                  !prevState.isHomeChecked,
                                              }));
                                              this.copyAdd();
                                            }}
                                          />
                                          <label>
                                            Home address same as business ?
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <label
                                      htmlFor="example-text-input_add1"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 1
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={home_address1}
                                        type="text"
                                        placeholder="Addrress 1"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          this.setState({
                                            home_address1: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 2
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={home_address2}
                                        type="text"
                                        placeholder="Address 2"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          this.setState({
                                            home_address2: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      City
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={home_city}
                                        type="text"
                                        placeholder="City"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          const re = /^[A-Za-z ]+$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              home_city: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      State
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={home_state}
                                        type="text"
                                        placeholder="State"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          const re = /^[a-zA-Z ]*$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              home_state: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Zip
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={home_zip}
                                        type="text"
                                        maxLength="10"
                                        placeholder="Zip"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          let event = e;
                                          event.target.value =
                                            customFunctions.numberMasking(
                                              e.target.value
                                            );
                                          this.setState({
                                            home_zip: event.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab
                                eventKey="Billing"
                                title="Billing"
                                activeKey={this.state.key3}
                                onSelect={(key3) => this.setState({ key3 })}
                              >
                                <br></br>
                                <div className="tab-item-wrapper">
                                  <div className="form-group row">
                                    <div className="col-sm-2"></div>
                                    {this.state.billingChkbx && (
                                      <div className="col-sm-10">
                                        <div className="c-checkbox-wrap">
                                          <input
                                            className="form-control"
                                            type="checkbox"
                                            id="example-search-input"
                                            style={{
                                              height: '16px',
                                              width: '16px',
                                            }}
                                            onChange={(e) => {
                                              this.setState((prevState) => ({
                                                isBillingChecked:
                                                  !prevState.isBillingChecked,
                                              }));
                                              this.copyAdd1();
                                            }}
                                          />
                                          <label>
                                            Billing address same as Home ?
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <label
                                      htmlFor="example-text-input_add1"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 1
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={billing_address1}
                                        type="text"
                                        placeholder="Address 1"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          this.setState({
                                            billing_address1: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 2
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={billing_address2}
                                        type="text"
                                        placeholder="Address 2"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          this.setState({
                                            billing_address2: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      City
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={billing_city}
                                        type="text"
                                        placeholder="City"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          const re = /^[A-Za-z ]+$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              billing_city: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      State
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={billing_state}
                                        type="text"
                                        placeholder="State"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          const re = /^[a-zA-Z ]*$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              billing_state: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Zip
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={billing_zip}
                                        type="text"
                                        maxLength="10"
                                        placeholder="Zip"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          let event = e;
                                          event.target.value =
                                            customFunctions.numberMasking(
                                              e.target.value
                                            );
                                          this.setState({
                                            billing_zip: event.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab
                                eventKey="Shipping"
                                title="Shipping"
                                activeKey={this.state.key4}
                                onSelect={(key4) => this.setState({ key4 })}
                              >
                                <br></br>
                                <div className="tab-item-wrapper">
                                  <div className="form-group row">
                                    <div className="col-sm-2"></div>
                                    {this.state.shippingChkbx && (
                                      <div className="col-sm-10">
                                        <div className="c-checkbox-wrap">
                                          <input
                                            className="form-control"
                                            type="checkbox"
                                            id="example-search-input"
                                            style={{
                                              height: '16px',
                                              width: '16px',
                                            }}
                                            onChange={(e) => {
                                              this.setState((prevState) => ({
                                                isShippingChecked:
                                                  !prevState.isShippingChecked,
                                              }));
                                              this.copyAdd2();
                                            }}
                                          />
                                          <label>
                                            Shipping address same as Billing ?
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <label
                                      htmlFor="example-text-input_add1"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 1
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={shipping_address1}
                                        type="text"
                                        placeholder="Address 1"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          this.setState({
                                            shipping_address1: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Address Line 2
                                    </label>
                                    <div className="col-sm-10">
                                      <input
                                        className="form-control"
                                        value={shipping_address2}
                                        type="text"
                                        placeholder="Address 2"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          this.setState({
                                            shipping_address2: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      City
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={shipping_city}
                                        type="text"
                                        placeholder="City"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          const re = /^[A-Za-z ]+$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              shipping_city: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <label
                                      htmlFor="example-search-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      State
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={shipping_state}
                                        type="text"
                                        placeholder="State"
                                        id="example-search-input"
                                        onChange={(e) => {
                                          const re = /^[a-zA-Z ]*$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            this.setState({
                                              shipping_state: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-sm-2 col-form-label"
                                    >
                                      Zip
                                    </label>
                                    <div className="col-sm-4">
                                      <input
                                        className="form-control"
                                        value={shipping_zip}
                                        type="text"
                                        maxLength="5"
                                        placeholder="Zip"
                                        id="example-text-input"
                                        onChange={(e) => {
                                          let event = e;
                                          event.target.value =
                                            customFunctions.numberMasking(
                                              e.target.value
                                            );
                                          this.setState({
                                            shipping_zip: event.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                        <br></br>
                        <br></br>

                        {customerId && (
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Created Date Time
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={createdDateTime}
                                type="text"
                                disabled
                                placeholder="dd:mm:yyyy hh:mm:ss"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({
                                    createdDateTime: e.target.value,
                                  });
                                }}
                              />
                              <span id="err">{createdDateTimeErr}</span>
                            </div>
                            {updatedTime && (
                              <>
                                <label
                                  htmlFor="example-search-input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Updated Date Time
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    className="form-control"
                                    value={updatedTime}
                                    type="text"
                                    disabled
                                    placeholder="dd:mm:yyyy hh:mm:ss"
                                    id="example-search-input"
                                    onChange={(e) => {
                                      this.setState({
                                        updatedTime: e.target.value,
                                      });
                                    }}
                                  />
                                  <span id="err">{updatedTimeErr}</span>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      {
                        <div className="form-group row d-flex justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light text-center"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.resetData();
                              this.props.history.push('/customer');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddCustomers);

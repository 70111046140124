import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';
import { withRouter } from 'react-router-dom';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 100,
  },
  // {
  //   label: 'Name',
  //   field: 'name',
  //   sort: 'disabled',
  // },
  {
    label: 'Status',
    field: 'description',
    sort: 'disabled',
    width: 200,
  },
  {
    label: 'Created Name',
    field: 'created_name',
    sort: 'disabled',
    width: 200,
  },
  {
    label: 'Created At',
    field: 'createdat',
    sort: 'disabled',
    width: 200,
  },
  // {
  //   label: 'Action',
  //   field: 'action',
  //   sort: 'disabled',
  // },
];

class WorkorderActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      activityData: [],
      page: 1,
      limit: 100,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isShowLoader: false,
      workorderid: null,
      activityid: null,
    };
  }

  componentDidMount = async () => {
    if (this.props && this.props.workorderid) {
      this.setState(
        {
          workorderid: this.props.workorderid,
        },
        () => {
          this.getActivies();
        }
      );
    }
  };

  setTableData = () => {
    const { activityData, currentPage, limit } = this.state;
    const page = currentPage || 1;
    const rows = [];
    activityData &&
      activityData.map((activity, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          name: activity.name,
          description: activity.description,
          created_name: activity.created_name,
          createdat: customFunctions.changeDateFormatMDY(activity.createdat),
          action: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    showDelete: true,
                    activityid: activity.activityid,
                  });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return activity;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getActivies = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, workorderid } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const endPoint = `${Api.workorderactiviesUrl}?skip=${skip}&limit=${limit}&keywords=${keyword}&workorderid=${workorderid}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      data.data.data.map((item) => {
        const dates =
          customFunctions.changeDateWithCurrentTimezone(item.description) || [];
        if (dates.length > 0) {
          item.description = item.description.split(' at ')[0] + ' at ';
          dates.map(async (date, i) => {
            if (date[date.length - 1] === '.') date = date.slice(0, -1);
            date = customFunctions.getDateWithCurrentTimezone(date);
            item.description += i === dates.length - 1 ? date : date + ' to ';
          });
        }
        return item;
      });
      this.setState(
        {
          activityData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  deleteActivity = async () => {
    const { activityid } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.workorderactiviesUrl}?activityid=${activityid}`
    );
    if (data) {
      this.setState({ showDelete: false, activityid: null }, () => {
        this.getActivies();
      });
      swal('Activity deleted!', '', 'success');
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const { rows, limit, totalCount, currentPage, isShowLoader, showDelete } =
      this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {showDelete && (
          <SweetAlert
            title="Are you sure, Do you want to delete this activity?"
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.deleteActivity();
            }}
            onCancel={() => {
              this.setState({ showDelete: false, activityid: null });
            }}
          >
            You won't be able to revert this!
          </SweetAlert>
        )}
        <div
          className="page-content-wrapper"
          style={{ paddingTop: '10px', margin: '0 -15px' }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getActivies}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(WorkorderActivity);

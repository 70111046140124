import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';
import customFunctions from '../../../helper/customFunctions';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      cpassErr: '',
      passwordErr: '',
      token: '',
    };
  }

  componentDidMount = async () => {
    customFunctions.setLocalStorage('isLogin', 'false');
    const token = await this.props.match.params.token;

    this.setState({
      token: token,
    });

    if (!token) {
      window.location = '/login';
      return;
    }
  };

  validateForm = (e) => {
    const { password, confirmPassword } = this.state;
    e.preventDefault();
    let isValid = true,
      cpassErr = '',
      passwordErr = '';

    if (password === '') {
      passwordErr = 'Password is required';
    }
    if (confirmPassword === '') {
      cpassErr = 'Confirm password is required';
    }
    if (password !== confirmPassword) {
      cpassErr = 'Password did not match';
      isValid = false;
    }

    this.setState({
      cpassErr,
      passwordErr,
    });

    if (isValid) {
      this.changePassword();
    }
  };

  changePassword = async (e) => {
    const { password, token } = this.state;
    const payload = {
      password,
      token,
    };
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.forgotPasswordUrl}`,
      payload
    );

    if (data) {
      swal('Password changed successfully', '', 'success');
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1000);
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }

    if (data) {
      await this.resetData();
      NotificationManager.success('Password Changed successfully');
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1000);
    } else {
      NotificationManager.error(message);
      await this.resetData();
    }
  };

  resetData = () => {
    this.setState({
      password: '',
      confirmPassword: '',
      cpassErr: '',
      passwordErr: '',
      token: '',
    });
  };

  render() {
    const { password, confirmPassword, passwordErr, cpassErr } = this.state;
    return (
      <AUX>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <NotificationContainer />
          <div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h3 className="text-center m-0">
                      <Link
                        to="/"
                        onClick={() => this.props.UpdateLoginAgain()}
                        className="logo logo-admin"
                      >
                        <img
                          alt="texas19.png"
                          src="/assets/images/logo1.jpg"
                          height="130px"
                          width="280px"
                        />
                      </Link>
                    </h3>
                    <form
                      onSubmit={(e) => {
                        this.validateForm(e);
                      }}
                      autoComplete="off"
                    >
                      <div className="p-3">
                        <h4 className="font-18 m-b-5 text-center">
                          Reset Password !
                        </h4>
                      </div>
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="example-url-input"
                            className="col-sm-3 col-form-label"
                          >
                            Password
                            <React.Fragment>*</React.Fragment>
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              value={password}
                              type="password"
                              placeholder="*****"
                              id="example-url-input"
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                            />
                            <span id="err">{passwordErr}</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="example-tel-input"
                            className="col-sm-3 col-form-label"
                          >
                            Confirm Password
                            <React.Fragment>*</React.Fragment>
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              value={confirmPassword}
                              type="password"
                              placeholder="*****"
                              id="example-url-input"
                              onChange={(e) => {
                                this.setState({
                                  confirmPassword: e.target.value,
                                });
                              }}
                            />
                            {password !== '' && (
                              <span id="err">{cpassErr}</span>
                            )}
                            {confirmPassword !== '' && (
                              <span className="text-danger">{cpassErr}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="row-sm-5">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Reset Password
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              window.location = `/login`;
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ResetPassword);

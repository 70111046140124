import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
class AddComplaintCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      status: '',
      categoryErr: '',
      categoryId: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-complaintcategory',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      // const categoryId = this.props.location.state.detail.id;
      this.getComplaintCategory();
      customFunctions.setTitle('Edit Complaint Category');
      this.setState({
        title: `Edit Complaint Category`,
      });
    } else {
      customFunctions.setTitle('Add Complaint Category');
      this.setState({
        title: `Add Complaint Category`,
      });
    }
  };

  getComplaintCategory = async () => {
    const categoryData = this.props.location.state.detail;
    this.setState({
      categoryId: categoryData.id,
      category: categoryData.category,
      status: categoryData.status,
    });
  };

  validateForm = (e) => {
    e.preventDefault();
    let categoryErr = '';
    let isValid = true;
    const { category } = this.state;
    if (category.trim() === '') {
      categoryErr = 'Category is required';
      isValid = false;
    }
    this.setState({ categoryErr });
    if (isValid) {
      this.addCategory();
    }
  };

  addCategory = async () => {
    const { category, status, categoryId } = this.state;
    const postData = {
      category: category,
    };
    const endPoint = `${Api.AddcomplaintCategoryUrl}`;
    const callback = categoryId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (categoryId) {
      postData.category_id = parseInt(categoryId);
      postData.status = status;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (categoryId) {
        swal('Complaint category updated successfully', '', 'success').then(
          () => {
            $this.props.history.push('/complaint-category', {
              navSkip:
                this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.navSkip,
              navCurrentPage:
                this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.navCurrentPage,
            });
          }
        );
      } else {
        swal('Complaint category added successfully', '', 'success').then(
          () => {
            $this.props.history.push('/complaint-category');
          }
        );
      }
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  render() {
    const { title, category, categoryErr } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Category<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="category"
                            value={category}
                            onChange={(e) => {
                              this.setState({ category: e.target.value });
                            }}
                          />
                          <span id="err">{categoryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/complaint-category', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddComplaintCategory);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import swal from 'sweetalert';

class AddPaymentType extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      paymentType: '',
      paymentTypeId: '',
      status: '',
      paymentTypeErr: '',
    };
  }

  getPaymentType = async () => {
    const paymentTypeData = this.props.location.state.detail;
    this.setState({
      paymentTypeId: paymentTypeData.id,
      paymentType: paymentTypeData.payment_type,
      status: paymentTypeData.status,
    });
  };

  addPaymentType = async () => {
    const { paymentType } = this.state;
    const { getPaymentTypeCallback } = this.props;
    const postData = {
      payment_type: paymentType,
    };
    const endPoint = `${Api.AddpaymentTypeUrl}`;
    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      swal('Payment type added successfully', '', 'success');
      if (getPaymentTypeCallback) getPaymentTypeCallback();
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal(message ? message : 'No data found', '', 'error');
      await this.flushAllData();
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { paymentType } = this.state;
    let paymentTypeErr = '';
    let isValid = true;
    if (paymentType.trim() === '') {
      paymentTypeErr = 'Payment Type is required';
      isValid = false;
    }
    this.setState({ paymentTypeErr });
    if (isValid) {
      this.addPaymentType();
    }
  };

  flushAllData = () => {
    this.setState({
      paymentTypeId: '',
      paymentType: '',
      paymentTypeErr: '',
    });
  };

  render() {
    const { paymentType, paymentTypeErr } = this.state;
    return (
      <div
        className="modal fade"
        id="paymentType"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Payment Type
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validate} autoComplete="off">
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Payment Type<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        value={paymentType}
                        onChange={(e) => {
                          const re = /^[A-Za-z\s]+$/;
                          if (e.target.value === '' || re.test(e.target.value))
                            this.setState({ paymentType: e.target.value });
                        }}
                      />
                      <span id="err">{paymentTypeErr}</span>
                    </div>
                  </div>
                  <div className="form-group row d-flex justify-content-center">
                    <div className="col-sm-4">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        onClick={() => {
                          this.flushAllData();
                        }}
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AddPaymentType);

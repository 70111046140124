import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Mainbuilder from './containers/mainbuilder/mainbuilder';
import customFunctions from './helper/customFunctions';
import AUX from './hoc/Aux_';
import LexChat from 'react-lex-plus';
// import ShowNotifications from './components/Notification/ShowNotification';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: '',
      options: {},
      userData: {},
      route: '',
    };
  }

  componentDidMount = async () => {
    console.log('Inside Mount');
    const token = await customFunctions.getLocalStorage('token');
    const url_segment = this.props.location.pathname.split('/');
    this.setState({ route: url_segment[1] });
    if (
      !token &&
      !url_segment.includes('reset-password') &&
      !url_segment.includes('job-survey') &&
      !url_segment.includes('live-location')
    ) {
      this.props.history.push('/login');
      return;
    }

    // setInterval(() => {
    //   this.firebaseConfiguration();
    // }, 1000);
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const previous_route = customFunctions.getLocalStorage('previous_route');
      const current_route = customFunctions.getLocalStorage('current_route');
      const url_segment = this.props.location.pathname.split('/');
      this.setState({ route: url_segment[1] });
      if (!previous_route || current_route !== previous_route) {
        customFunctions.setLocalStorage('previous_route', current_route);
      }
      if (previous_route !== location.pathname) {
        customFunctions.setLocalStorage('current_route', location.pathname);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    let layout = null;
    layout =
      this.state.route !== 'login' &&
      this.state.route !== 'job-survey' &&
      this.state.route !== 'live-location' ? (
        <Layout
          header={this.props.header}
          sidebar={this.props.sidebar}
          footer={this.props.footer}
        >
          <>
            <Switch>
              <Route path="/" component={Mainbuilder} />
            </Switch>
            {/* <NotificationPusher /> */}
            <LexChat
              botName="company_name_bot_prod"
              IdentityPoolId={process.env.REACT_APP_LEX_CHAT_ID}
              placeholder="Reply to company name bot..."
              backgroundColor="#FFFFFF"
              height="430px"
              region="us-east-1"
              headerText="Chat with company-name"
              headerStyle={{ backgroundColor: '#ABD5D9', fontSize: '20px' }}
              greeting={"Hello, Say 'hi' to get started...!"}
            />
          </>
        </Layout>
      ) : (
        <Switch>
          <Route path="/" component={Mainbuilder} />
        </Switch>
      );
    return (
      <AUX>
        {/* <ShowNotifications /> */}
        {layout}
      </AUX>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    header: state.ui_red.header,
    sidebar: state.ui_red.sidebar,
    footer: state.ui_red.footer,
  };
};

export default withRouter(connect(mapStatetoProps)(App));

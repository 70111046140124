import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      status: '',
      html_template: '',
      raw_html: '',
      subject: '',
      template_name: '',
      title: '',
      template_type: '',
      template_typeErr: '',
      sms_template: '',
      sms_templateErr: '',
      keywords: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-emailtemplate',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
    }
    customFunctions.setTitle('Email Template');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getEmailTemplate();
      customFunctions.setTitle('Edit Email Template');
      this.setState({
        title: `Edit Email Template`,
      });
    } else {
      customFunctions.setTitle('Add Email Template');
      this.setState({
        title: `Add Email Template`,
      });
    }
  };

  getEmailTemplate = async () => {
    const templateData = this.props.location.state.detail;
    const contentBlock = htmlToDraft(templateData.html_template);
    if (contentBlock && templateData.type === 'email') {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        html_template: editorState,
        raw_html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });
    }
    if (templateData.type === 'msg') {
      this.setState({
        sms_template: templateData.html_template,
      });
    }
    this.setState({
      id: templateData.id,
      status: templateData.status,
      subject: templateData.subject,
      template_name: templateData.template_name,
      template_type: templateData.type,
      keywords: templateData.keywords,
    });
  };

  addEmailTemplate = async () => {
    const {
      id,
      status,
      raw_html,
      subject,
      template_name,
      sms_template,
      template_type,
      keywords,
    } = this.state;
    const postData = {
      status,
      html_template: template_type === 'email' ? raw_html : sms_template,
      subject,
      keywords,
      type: template_type,
    };
    const endPoint = `${Api.emailTemplate}`;
    const callback = id ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    if (id) {
      postData.emailtemplateid = parseInt(id);
      postData.status = status;
    }
    if (!id) {
      postData.template_name = template_name;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success) {
      var $this = this;
      if (id) {
        swal('Template updated successfully', '', 'success').then(() => {
          $this.props.history.push('/email-templates', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Template added successfully', '', 'success').then(() => {
          $this.props.history.push('/email-templates');
        });
      }
    } else if (message === 'Updated') {
      swal('Template updated successfully', '', 'success').then(() => {
        $this.props.history.push('/email-templates');
      });
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const {
      id,
      subject,
      sms_template,
      template_type,
      template_name,
      raw_html,
    } = this.state;
    let subjectErr = '',
      template_nameErr = '',
      html_templateErr = '',
      sms_templateErr = '',
      template_typeErr = '';

    let isValid = true;
    if (!template_type) {
      template_typeErr = 'Template type is required';
      isValid = false;
    }
    if (
      template_type === 'msg' &&
      (!sms_template || sms_template.trim() === '')
    ) {
      sms_templateErr = 'SMS template is required';
      isValid = false;
    }
    if (subject.trim() === '') {
      subjectErr = 'Subject is required';
      isValid = false;
    }
    if (!id) {
      if ((template_name && template_name.trim() === '') || !template_name) {
        template_nameErr = 'Template name is required';
        isValid = false;
      }
    }
    if (
      template_type === 'email' &&
      ((raw_html && raw_html.trim() === '') || !raw_html)
    ) {
      html_templateErr = 'HTML template is required';
      isValid = false;
    }
    this.setState({
      subjectErr,
      template_nameErr,
      html_templateErr,
      sms_templateErr,
      template_typeErr,
    });
    if (isValid) {
      this.addEmailTemplate();
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      html_template: editorState,
      raw_html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  render() {
    const {
      id,
      subject,
      template_name,
      html_template,
      title,
      template_nameErr,
      subjectErr,
      html_templateErr,
      template_type,
      template_typeErr,
      sms_template,
      sms_templateErr,
      keywords,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-4 col-form-label text-center"
                        >
                          Template Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <select
                            className="form-control"
                            value={template_type}
                            onChange={(e) => {
                              this.setState({ template_type: e.target.value });
                            }}
                          >
                            <option>Template Type</option>
                            <option value="email">Email</option>
                            <option value="msg">SMS</option>
                          </select>
                          <span id="err">{template_typeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-4 col-form-label text-center"
                        >
                          Template Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Template Name"
                            disabled={id ? true : false}
                            value={template_name}
                            onChange={(e) => {
                              this.setState({ template_name: e.target.value });
                            }}
                          />
                          <span id="err">{template_nameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-4 col-form-label text-center"
                        >
                          Subject<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => {
                              this.setState({ subject: e.target.value });
                            }}
                          />
                          <span id="err">{subjectErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-4 col-form-label text-center"
                        >
                          Keywords
                        </label>
                        <div className="col-sm-6">
                          <div>
                            {keywords && (
                              <p>
                                {keywords.split(',').map((keyword) => (
                                  <span
                                    style={{ paddingRight: '10px' }}
                                  >{` {{${keyword}}} `}</span>
                                ))}
                              </p>
                            )}
                            <p
                              style={{ border: '1px dashed', padding: '10px' }}
                            >
                              Note: <br />
                              {`Use ${
                                keywords ? 'above' : ''
                              } keywords in template as below example:`}
                              <br />
                              {'{{Name}}'}
                              <br />
                              Hello {'{{Name}}'}
                            </p>
                          </div>
                        </div>
                      </div>
                      {template_type === 'email' && (
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-sm-4 col-form-label text-center"
                          >
                            HTML Template<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-6">
                            <Editor
                              editorState={html_template}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={this.onEditorStateChange}
                            />
                            {/* <textarea
                                cols="50"
                                disabled
                                value={raw_html}
                              /> */}
                            <span id="err">{html_templateErr}</span>
                          </div>
                        </div>
                      )}
                      {template_type === 'msg' && (
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-sm-4 col-form-label text-center"
                          >
                            SMS<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              className="form-control"
                              cols="50"
                              rows="5"
                              value={sms_template}
                              onChange={(e) =>
                                this.setState({
                                  sms_template: e.target.value,
                                })
                              }
                            />
                            <span id="err">{sms_templateErr}</span>
                          </div>
                        </div>
                      )}
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/email-templates', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(EmailTemplate);

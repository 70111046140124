import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';

class RatingConfirmPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workorderid: null,
      selectedUser: null,
      loginId: null,
      createdby: null,
      createdById: null,
      msg: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
      createdById: userdata.id,
    });
  };

  addnotes = async () => {
    const { msg } = this.state;
    const postData = {
      msg,
    };
    const endPoint = `${Api.addNotes}`;
    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      swal('Notification sent successfully', '', 'success');
      setTimeout(() => {
        $this.props.handleClose();
        $this.props.handleCallback();
      }, 1000);
    } else {
      swal(message ? message : 'No data found', '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { msg } = this.state;
    let msgErr = '';
    let isValid = true;
    if (msg.trim() === '') {
      msgErr = 'Message is required';
      isValid = false;
    }
    this.setState({ msgErr });
    if (isValid) {
      this.props.callback(msg);
    }
  };

  render() {
    const { msg, msgErr } = this.state;
    return (
      <AUX>
        <Modal
          centered
          size="md"
          className="rating-popup"
          show={this.props.showRatingConfirm}
        >
          <Modal.Header>
            <Modal.Title className="text-primary">
              <h6>
                Do you mind if we speak with you in hopes of turning this
                experience around for you in hopes of earning a 5 Star?
              </h6>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.handleClose()}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="page-content-wrapper" style={{ paddingTop: '0px' }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-12 col-form-label"
                        >
                          Leave Message<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-12">
                          <textarea
                            rows="3"
                            cols="80"
                            className="form-control"
                            value={msg}
                            onChange={(e) => {
                              this.setState({ msg: e.target.value });
                            }}
                          />
                          <span id="err">{msgErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center text-center">
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Yes
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.handleClose();
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default withRouter(RatingConfirmPopup);

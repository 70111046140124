import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Api from '../../../helper/api';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';

class InvoiceCustomerPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAmount: null,
      cardName: '',
      cardNumber: null,
      cardExpMonth: '',
      cardExpYear: '',
      cardCvv: '',
      cardPaymentDate: new Date(),
      cardAmountErr: '',
      cardNameErr: '',
      cardNumberErr: '',
      cardExpMonthErr: '',
      cardExpYearErr: '',
      cardCvvErr: '',
      cardPaymentDateErr: '',
      invoiceErr: '',
      invoiceId: null,
      totalAmount: null,
      title: 'Invoice Payment',
      due_amount: null,
      touched: {},
      isValidate: false,
      isShowLoader: false,
      grandTotal: 0,
      ipaddress: '',
      taxPercentage: null,
    };
  }

  async componentDidMount() {
    //creating function to load ip address from the API
    let perValue = null;
    fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      }, 'jsonp')
      .then((res) => {
        this.setState({ ipaddress: res.ip });
      })
      .catch((err) => console.log(err));

    if (this.props && this.props.taxPercentage) {
      perValue = Number(this.props.taxPercentage) / 100;
      await this.setState({ taxPercentage: perValue }, () => {
        this.forceUpdate();
      });
    }

    if (this.props && this.props.fromComponent === 'payment') {
      this.setState(
        {
          invoiceId: this.props.invoiceId,
          totalAmount:
            this.props.due_amount > 0
              ? this.props.due_amount
              : this.props.totalAmount,
          title: 'Invoice Payment',
          due_amount:
            this.props.due_amount &&
            this.props.due_amount +
              parseFloat(this.props.due_amount) * Number(perValue),
          cardAmount:
            this.props.due_amount > 0
              ? this.props.due_amount +
                parseFloat(this.props.due_amount) * Number(perValue)
              : this.props.totalAmount +
                parseFloat(this.props.totalAmount) * Number(perValue),
          grandTotal:
            this.props.totalAmount +
            parseFloat(this.props.totalAmount) * Number(perValue),
        },
        () => {
          customFunctions.setTitle(this.state.title);
        }
      );
    } else {
      this.setState(
        {
          invoiceId: this.props.location.state.invoiceId,
          totalAmount:
            this.props.location.state.due_amount > 0
              ? this.props.location.state.due_amount
              : this.props.location.state.totalAmount,
          title: 'Invoice Payment',
          due_amount:
            this.props.location.state.due_amount &&
            this.props.location.state.due_amount +
              parseFloat(this.props.location.state.due_amount) *
                Number(perValue),
          cardAmount:
            this.props.location.state.due_amount > 0
              ? this.props.location.state.due_amount +
                parseFloat(this.props.location.state.due_amount) *
                  Number(perValue)
              : this.props.location.state.totalAmount +
                parseFloat(this.props.location.state.totalAmount) *
                  Number(perValue),
          grandTotal:
            this.props.totalAmount +
            parseFloat(this.props.totalAmount) * Number(perValue),
        },
        () => {
          customFunctions.setTitle(this.state.title);
        }
      );
    }
  }
  validateCard = () => {
    const {
      cardAmount,
      cardName,
      cardNumber,
      cardExpMonth,
      cardExpYear,
      cardCvv,
      due_amount,
      grandTotal,
    } = this.state;

    let isValid = true,
      cardAmountErr = '',
      cardNameErr = '',
      cardNumberErr = '',
      cardExpMonthErr = '',
      cardExpYearErr = '',
      cardCvvErr = '';

    if (!cardAmount || (cardAmount && cardAmount <= 0)) {
      isValid = false;
      cardAmountErr = 'Please enter valid amount';
    }
    if (cardAmount && parseFloat(cardAmount) > grandTotal) {
      isValid = false;
      cardAmountErr = `Entered total should not be greater than ${
        due_amount ? 'due' : ''
      } amount`;
    }
    if (!cardName || (cardName && cardName.trim() === '')) {
      isValid = false;
      cardNameErr = 'Please enter a valid card name';
    }
    if (cardNumber && cardNumber.trim() !== '') {
      let cardNumber1 = customFunctions.validateCard(cardNumber);
      if (cardNumber1 === null) {
        isValid = false;
        cardNumberErr = 'Please enter a valid card number';
      }
    }
    if (
      !cardNumber ||
      (cardNumber && cardNumber.trim() === '') ||
      isNaN(cardNumber)
    ) {
      isValid = false;
      cardNumberErr = 'Please enter a card number';
    }
    if (!cardExpMonth) {
      isValid = false;
      cardExpMonthErr = 'Please enter a month';
    }
    if (cardExpMonth > 12) {
      isValid = false;
      cardExpMonthErr = 'Please enter a valid month';
    }
    if (cardExpMonth && cardExpMonth.trim() !== '') {
      let cardExpMonths = customFunctions.validateMonth(
        cardExpMonth,
        cardExpYear
      );
      if (cardExpMonths === null && cardExpYear) {
        isValid = false;
        cardExpMonthErr = 'Please enter a valid month';
      }
    }
    if (!cardExpYear) {
      isValid = false;
      cardExpYearErr = 'Please enter a year';
    }
    if (cardExpYear && cardExpYear.trim() !== '') {
      let cardExpYears = customFunctions.validateYear(cardExpYear);
      if (cardExpYears === false) {
        isValid = false;
        cardExpYearErr = 'Please enter a valid year';
      }
    }
    if (
      !cardCvv ||
      (cardCvv && cardCvv.trim() === '') ||
      isNaN(cardCvv) ||
      (cardCvv && cardCvv.length < 3)
    ) {
      isValid = false;
      cardCvvErr = 'Please enter a valid cvv';
    }

    this.setState({
      cardAmountErr,
      cardNameErr,
      cardNumberErr,
      cardExpMonthErr,
      cardExpYearErr,
      cardCvvErr,
    });
    return isValid;
  };

  handleTouch(field_name) {
    let { touched } = this.state;
    if (field_name && touched[field_name] !== true) {
      touched[field_name] = true;
      this.setState(
        {
          touched,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  startPayment = async (e) => {
    e.preventDefault();
    this.setState({
      isValidate: true,
    });
    let isValid = this.validateCard();
    if (isValid) {
      const {
        cardAmount,
        cardName,
        cardNumber,
        cardExpMonth,
        cardExpYear,
        cardCvv,
        invoiceId,
        ipaddress,
      } = this.state;
      const payload = {
        cc: cardNumber,
        cvv: cardCvv,
        expire: cardExpMonth + '' + cardExpYear,
        amount: cardAmount.toString(),
        name: cardName,
        refid: invoiceId,
        id_address: ipaddress,
      };

      const endPoint = `${Api.cardPayment}`;

      const callback = AuthApi.postDataToServer;
      this.setState({
        isShowLoader: true,
      });
      const { data, message } = await callback(endPoint, payload);
      if (data && data.success) {
        this.setState({
          isShowLoader: false,
        });
        let msg =
          data.data &&
          data.data.transactionResponse &&
          data.data.transactionResponse.errors &&
          data.data.transactionResponse.errors.error.length > 0 &&
          data.data.transactionResponse.errors.error[0] &&
          data.data.transactionResponse.errors.error[0].errorText;
        if (data.data) this.addPayment(data.data, msg);
      } else {
        this.setState({
          isShowLoader: false,
        });

        if (!message.success) {
          swal(message ? message : 'No data found', '', 'error');
          return;
        }
        let cardNumberErr,
          cardExpMonthErr,
          cardExpYearErr,
          cardCvvErr,
          invoiceErr;

        if (message && typeof message !== 'string' && message.errors) {
          message.errors.forEach((er) => {
            if (er.param === 'cc') {
              cardNumberErr = er.msg;
            }
            if (er.param === 'cvv') {
              cardCvvErr = er.msg;
            }
            if (er.param === 'expire') {
              cardExpMonthErr = er.msg;
              cardExpYearErr = er.msg;
            }
            if (er.param === 'invoice') {
              invoiceErr = er.msg;
            }
          });
        } else {
          if (message.toLowerCase().includes('card')) {
            cardNumberErr = 'Please enter a valid card number';
          }
          if (message.toLowerCase().includes('code')) {
            cardCvvErr = 'Please enter a valid cvv number';
          }
          if (message.toLowerCase().includes('date')) {
            cardExpMonthErr = 'Please enter a valid month';
            cardExpYearErr = 'Please enter a valid year';
          }
        }
        this.setState({
          cardNumberErr,
          cardExpMonthErr,
          cardExpYearErr,
          cardCvvErr,
          invoiceErr,
        });
      }
    }
  };

  addPayment = async (response, msg) => {
    const {
      cardAmount,
      cardPaymentDate,
      invoiceId,
      totalAmount,
      taxPercentage,
    } = this.state;
    const payload = {
      invoice_id: invoiceId,
      amount: Number(totalAmount),
      payment_type: 'card',
      payment_date: cardPaymentDate && new Date(cardPaymentDate).toISOString(),
      cheque_no: '',
      received_amt: Number(cardAmount),
      after_charges:
        totalAmount + parseFloat(totalAmount) * Number(taxPercentage),
      response_code: response.transactionResponse.responseCode,
      transaction_id: response.transactionResponse.transId,
      status:
        response.transactionResponse.responseCode === '1'
          ? 'success'
          : 'failed',
      payment_response: response,
    };

    const endPoint = `${Api.addPayment}`;

    const callback = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, payload);
    if (data && data.success) {
      swal(msg ? msg : 'Payment successfull', '', msg ? 'error' : 'success');

      this.setState(
        {
          isShowLoader: false,
          checkAmount: null,
          checkNumber: null,
          checkPaymentDate: '',
          cashAmount: null,
          cashPaymentDate: '',
          cardAmount: null,
          cardName: '',
          cardNumber: null,
          cardExpMonth: '',
          cardExpYear: '',
          cardCvv: '',
          cardPaymentDate: '',
        },
        () => {
          this.props.callback();
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message ? message : 'No data found', '', 'error');
      return;
    }
  };

  render() {
    const {
      cardAmount,
      cardName,
      cardNumber,
      cardExpMonth,
      cardExpYear,
      cardCvv,
      cardAmountErr,
      cardNameErr,
      cardNumberErr,
      cardExpMonthErr,
      cardExpYearErr,
      cardCvvErr,
      invoiceErr,
      title,
      isValidate,
      touched,
      due_amount,
      isShowLoader,
      grandTotal,
      taxPercentage,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div
          className="page-content-wrapper"
          style={{
            paddingTop: this.props.fromComponent === 'payment' ? '0px' : '80px',
          }}
        >
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row" style={{ margin: '0 -15px' }}>
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body ">
                    <form onSubmit={this.startPayment}>
                      <div className="card1">
                        <div className="card-body1 m-t-10">
                          <div className="row">
                            <div className="col-sm-4"></div>
                            {/*<p className="col-sm-8 my-0">
                              Note: Additional charges 5% on amount
                            </p>*/}
                          </div>
                          {due_amount && (
                            <div className="row">
                              <div className="col-sm-4" />
                              <p className="col-sm-4 my-0">
                                Balance due amount: ${due_amount}
                              </p>
                            </div>
                          )}
                          <div className="form-group row">
                            <div className="col-sm-1" />
                            <label
                              htmlFor=""
                              className="col-sm-3 text-secondary"
                            >
                              AMOUNT
                            </label>
                            <div className="col-sm-7">
                              {grandTotal} (Including{' '}
                              {`${Number(taxPercentage) * 100}%`} Additional
                              charges on amount)
                            </div>
                            <div className="col-sm-1" />
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-1" />
                            <label
                              htmlFor=""
                              className="col-sm-3 text-secondary"
                            >
                              GRAND TOTAL<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                placeholder="$0"
                                value={cardAmount}
                                className="form-control"
                                onChange={async (e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMaskingDecimal(
                                      e.target.value
                                    );
                                  this.setState(
                                    {
                                      cardAmount: event.target.value,
                                      // grandTotal:
                                      //   parseFloat(event.target.value) +
                                      //   parseFloat(event.target.value) * Number(taxPercentage),
                                    },
                                    () => {
                                      this.validateCard();
                                      this.handleTouch('amount');
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('amount');
                                }}
                              />
                              <span id="err">
                                {((touched &&
                                  touched.amount &&
                                  cardAmountErr) ||
                                  (isValidate && cardAmountErr)) &&
                                  cardAmountErr}
                              </span>
                            </div>
                            <div className="col-sm-1" />
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-1" />
                            <label
                              htmlFor=""
                              className="col-sm-3 text-secondary"
                            >
                              CARD FULL NAME
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                placeholder="Enter Full Name"
                                value={cardName}
                                className="form-control"
                                onChange={async (e) => {
                                  const re = /^[a-zA-Z ]*$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        cardName: e.target.value,
                                      },
                                      () => {
                                        this.validateCard();
                                        this.handleTouch('name');
                                      }
                                    );
                                  }
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('name');
                                }}
                              />
                              <span id="err">
                                {((touched && touched.name && cardNameErr) ||
                                  (isValidate && cardNameErr)) &&
                                  cardNameErr}
                              </span>
                            </div>
                            <div className="col-sm-1" />
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-1" />
                            <label
                              htmlFor=""
                              className="col-sm-3 text-secondary"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              CREDIT CARD NUMBER
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                placeholder="xxxx-xxxx-xxxx-xxxx"
                                value={cardNumber}
                                maxLength="16"
                                minLength="11"
                                className="form-control"
                                onChange={async (e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState(
                                    {
                                      cardNumber: event.target.value,
                                    },
                                    () => {
                                      this.validateCard();
                                      this.handleTouch('cardnumber');
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('cardnumber');
                                }}
                              />
                              <span id="err">
                                {((touched &&
                                  touched.cardnumber &&
                                  cardNumberErr) ||
                                  (isValidate && cardNumberErr)) &&
                                  cardNumberErr}
                              </span>
                            </div>
                            <div className="col-sm-1" />
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-1" />
                            <label
                              htmlFor=""
                              className="col-sm-3 text-secondary"
                            >
                              EXPIRATION DATE
                              <span className="text-danger">*</span>
                            </label>

                            <div className="col-sm-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Month"
                                value={cardExpMonth}
                                maxLength="2"
                                onChange={async (e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState(
                                    {
                                      cardExpMonth: event.target.value,
                                    },
                                    () => {
                                      this.validateCard();
                                      this.handleTouch('month');
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('month');
                                }}
                              />
                              <span id="err">
                                {((touched &&
                                  touched.month &&
                                  cardExpMonthErr) ||
                                  (isValidate && cardExpMonthErr)) &&
                                  cardExpMonthErr}
                              </span>
                            </div>
                            <div className="col-sm-2">
                              <input
                                type="text"
                                className="form-control"
                                value={cardExpYear}
                                placeholder="Year"
                                maxLength="2"
                                onChange={async (e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState(
                                    {
                                      cardExpYear: event.target.value,
                                    },
                                    () => {
                                      this.validateCard();
                                      this.handleTouch('year');
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('year');
                                }}
                              />
                              <span id="err">
                                {((touched && touched.year && cardExpYearErr) ||
                                  (isValidate && cardExpYearErr)) &&
                                  cardExpYearErr}
                              </span>
                            </div>
                            <div className="col-sm-1">
                              <label
                                htmlFor=""
                                className="col-sm-3 text-secondary"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                CVV<span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-sm-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="cvv"
                                value={cardCvv}
                                maxLength="4"
                                minLength="3"
                                onChange={async (e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState(
                                    {
                                      cardCvv: event.target.value,
                                    },
                                    () => {
                                      this.validateCard();
                                      this.handleTouch('cvv');
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  this.validateCard();
                                  this.handleTouch('cvv');
                                }}
                              />
                              <span id="err">
                                {((touched && touched.cvv && cardCvvErr) ||
                                  (isValidate && cardCvvErr)) &&
                                  cardCvvErr}
                              </span>
                              <span id="err">
                                {isValidate && invoiceErr && invoiceErr}
                              </span>
                            </div>
                            <div className="col-sm-1" />
                          </div>
                        </div>

                        <div className="row" style={{ paddingBottom: '28px' }}>
                          <div className="col-sm-7" />
                          <Button variant="primary" type="submit">
                            Submit Payment
                          </Button>
                          <Button
                            variant="secondary"
                            className="l-m-10 saveBtns"
                            type="reset"
                            onClick={() => {
                              this.props.callback();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(InvoiceCustomerPayment);
